import axios from 'axios';

export const getInspectors = context => {

    return axios.get('/api/users/inspectors').then(response => {
        context.commit('setInspectors', response.data);
    }).catch(error => {
        window.notify.apiError(error);
    });
}


export const getProjectInspection = (context, data) => {
    return axios.get('/api/projects/' + data.project_id + '/inspections/' + data.phase_id)
        .then(res => {
            if(res.data) { // there may not be an inspection for this project or phase
                context.commit('addInspection', res.data);
            }
        }).catch(error => {
        window.notify.apiError(error);
    });
}


export const assignAndOpenInspection = (context, {projectId, phaseId, inspectorId}) => {

    return axios.post('/api/projects/' + projectId + '/inspections', {
        phaseId,
        inspectorId
    }).then(response => {
        context.commit('addInspection', response.data);
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const reAssignInspector = (context, {projectId, inspectionId, inspectorId}) => {


    return axios.patch('/api/projects/' + projectId + '/inspections/' + inspectionId + '/assign', {
        inspectorId
    }).then(response => {
        window.notify.message('Successfully reassigned inspector', 'success');
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const rewindInspection = (context, {projectId, inspectionId}) => {

    return axios.delete('/api/projects/' + projectId + '/inspections/' + inspectionId).catch(error => {
        window.notify.apiError(error);
    })

}


export const waiveInspection = (context, {projectId, inspection}) => {

    return new Promise((resolve, reject) => {

        axios.patch('/api/projects/' + projectId + '/inspections/' + inspection.id + '/waive', {
            waive: 1
        }).then(response => {
            context.commit('updateInspection', response.data);
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });

    })

}

export const getProjectLogsForInspection = (context, {phase_id, project_id}) => {


    axios.get('/api/projects/' + project_id + '/logs', {
        params: {
            phaseId: phase_id
        }
    }).then(response => {
        context.commit('setInspectionLogs', response.data);
    }).catch(error => {
        window.notify.apiError(error);
    })

}
