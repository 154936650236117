<template>
    <tr class="text-center">
        <td>
            {{ record.sharing_company }}
        </td>
        <td>
            {{ record.sharing_user }}
        </td>
        <td>
            {{ record.created_at }}
        </td>
        <td v-if="accepted">
            {{ record.lead_project_shared_id }}
        </td>
        <td>
            <button class="button grid-row-button" v-if="open" @click="acceptProjectLead" :disabled="runningAccept">
                <span v-if="runningAccept" class="fa fa-spinner fa-spin"></span>
                <span v-else>Accept</span>
            </button>
            <button class="button grid-row-button" v-if="open" @click="declineProjectLead">
                <span v-if="runningDecline" class="fa fa-spinner fa-spin"></span>
                <span v-else>Decline</span>
            </button>
            <button class="button grid-row-button" v-if="accepted" @click="goToProject">Go to project</button>
        </td>
    </tr>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            record: {
                required: true,
                type: Object,
            }
        },

        data() {
            return {
                runningAccept: false,
                runningDecline: false,
            }
        },

        computed: {
            accepted() {
                return this.record.accepted_at !== null && this.record.lead_project_id !== null;
            },
            open() {
                return this.record.lead_project_id === null;
            }
        },

        methods: {
            goToProject() {
                window.location = '/project/' + this.record.lead_project_id + '/pre_approval';
            },
            acceptProjectLead() {
                this.runningAccept = true;
                axios.post('/api/project_leads/' + this.record.id + '/accept', {}).then(response => {

                    window.notify.message(response.data.message, 'success', 'Project Lead Created');

                    setTimeout(() => {
                        this.runningAccept = false;
                        window.location = '/project/' + response.data.project_id + '/pre_approval';
                    }, 2000);

                }).catch(error => {
                    this.runningAccept = false;
                    window.notify.apiError(error);
                })
            },
            declineProjectLead() {
                this.runningDecline = true;
                axios.post('/api/project_leads/' + this.record.id + '/decline', {}).then(response => {

                    window.notify.message(response.data.message, 'success', 'Project Lead Declined');

                    setTimeout(() => {
                        this.runningDecline = false;
                        window.location.reload();
                    }, 2000);

                }).catch(error => {
                    this.runningDecline = false;
                    window.notify.apiError(error);
                })
            }
        }
    }
</script>
