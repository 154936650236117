<template>
    <div class="measureslot filledmeasureslot">
        <div class="measuredeletewrapper">
            <div class="measuredetailstablediv">
                <table class="measuredetailstable">
                    <tbody>
                        <tr class="measureheaderrow">
                            <td class="measurenamecolumn">
                                <span v-if="groupedProjectMeasure.measure.incentivized === 1">Energy-Saving Improvement</span>
                                <span v-else>Additional Cost / Additional Opportunity</span>
                            </td>
                        </tr>
                        <tr class="measuredatarow">
                            <td class="measurenamecolumn measurenametr">
                                <span v-html="groupedProjectMeasure.name"></span>
                            </td>
                        </tr>

                        <tr class="savingsdata">
                            <td class="littlenote">
                                <span v-if="groupedProjectMeasure.measure.incentivized === 1">

                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.rebate_quantity)">
                                        Rebate Quantity:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.rebate_quantity)">
                                        {{ groupedProjectMeasure.rebate_quantity |format_decimals(2) }}
                                    </div> |
                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.kWh_savings_deemed)">
                                        kWh Deemed Savings:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.kWh_savings_deemed)">
                                        {{ groupedProjectMeasure.kWh_savings_deemed |format_decimals(2) }}
                                    </div> |
                                    <div class="savingssubdiv">Deemed Water Savings:&nbsp;</div>
                                    <div class="savingssubdiv req">{{ groupedProjectMeasure.water_savings_deemed |format_decimals(2) }}</div> |
                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.kWh_savings)">
                                        kWh Actual Savings:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.kWh_savings)">
                                        {{ groupedProjectMeasure.kWh_savings |format_decimals(2) }}
                                    </div> |
                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.full_cost)">
                                        Total Full Cost:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.full_cost)">
                                        {{ groupedProjectMeasure.full_cost | format_dollars }}
                                    </div> |
                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.electric_rebate)">
                                        Total Incentive:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.electric_rebate)">
                                        {{ groupedProjectMeasure.electric_rebate | format_dollars }}
                                    </div>
                                </span>
                                <span v-else>
                                    <div class="savingssubdiv" :class="getSavingsSubDivClass(groupedProjectMeasure.full_cost)">
                                        Total Full Cost:&nbsp;
                                    </div>
                                    <div class="savingssubdiv req" :class="getSavingsSubDivClass(groupedProjectMeasure.full_cost)">
                                        {{ groupedProjectMeasure.full_cost | format_dollars }}
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import { format_decimals } from 'etap/filters/format_decimals'
    import { format_dollars } from 'etap/filters/format_dollars'
    import ProjectMeasure from 'etap/mixins/project_measure';
    export default {

        props: {
            groupedProjectMeasure: {
                required: true,
                type: Object,
            }
        },

        mixins: [
            ProjectMeasure
        ],

        filters: {
            format_decimals,
            format_dollars
        },

    }
</script>
