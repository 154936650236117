import Model from 'etap/admin/classes/models/model';
import Audit from 'etap/admin/classes/models/audit';

class Measure extends Model {


    defaults() {
        return {
            name: '',
            order: 0
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
        }
    }
}

export default Measure;
