<template>
    <div id="measuresummary">
        <div class="measuresummaryinlineblockleft">
            <div class="equipment-search">
                <div id="equipment-search-title">
                    <span class="fa fa-search"></span> Equipment Library Search
                </div>
                <div class="search-controls row">

                    <div class="form-group col-sm-3">
                        <label>Measure Groups</label>
                        <div class="measureGroupSelector">
                            <multi-select
                                :options="measureGroups"
                                track-by="id"
                                label="name"
                                placeholder="All Measures"
                                v-model="selectedMeasureGroup"
                                :disabled="!searchActive"
                            ></multi-select>
                        </div>
                    </div>
                    <div class="form-group col-sm-3">
                        <label>Search</label>
                        <input type="text" v-model="currentSearch" name="equipment_search" :disabled="!searchActive" />
                    </div>
                    <div class="form-group col-sm-3">
                        <label>Search By</label>
                        <div class="radio-group">
                            <input type="radio" v-model="searchBy" value="measure_name" :disabled="!searchActive" />Measure Name
                        </div>
                        <div class="radio-group">
                            <input type="radio" v-model="searchBy" value="make_model" :disabled="!searchActive" />Product Make/Model
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="measuresummaryinlineblockright">
            <div>
                <span class="fa fa-refresh"
                      :class="{ 'fa-spin' : isRefreshing }"
                      style="font-size: 20px"
                      @click="refreshEquipment"
                      title="Click to refresh equipment"
                ></span>
            </div>
            <div title="Click on eye symbol to show all equipment for measures"
                 id="measurepickerviewdetails"
                 :class="{ 'measurepickerviewdetailsexiticon' : showAllEquipment, 'measurepickerviewdetailsicon' : !showAllEquipment }"
                @click="toggleShowAllEquipment"
            ></div>
            <div v-if="canUpload">
                <span class="fa fa-upload" style="font-size: 25px;" @click="importEquipmentFile"></span>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
    import MultiSelect from 'vue-multiselect';
    export default {

        components: {
            MultiSelect
        },

        data() {
            return {
                showProducts: false,
                isRefreshing: false,
            }
        },

        created() {
            this.getMeasureGroups();
        },

        computed: {
            ...mapState('equipment_library', [
                'showAllEquipment',
                'mainView',
            ]),
            ...mapGetters('equipment_library', [
                'measureGroups'
            ]),
            currentSearch: {
                get() {
                   return this.$store.state.equipment_library.currentSearch;
                },
                set(currentSearch) {
                    this.$store.commit('equipment_library/updateCurrentSearch', currentSearch)
                }
            },
            searchBy: {
                get() {
                    return this.$store.state.equipment_library.searchBy;
                },
                set(searchBy) {
                    this.$store.commit('equipment_library/updateSearchBy', searchBy)
                }
            },
            selectedMeasureGroup: {
                get() {
                    return this.$store.state.equipment_library.selectedMeasureGroup
                },
                set(measureGroup) {
                    this.$store.commit('equipment_library/setSelectedMeasureGroup', measureGroup);
                }
            },
            searchActive() {

                if(this.mainView !== 'equipment-library-measure-list') {
                    return false;
                }

                return true;
            },
            canUpload() {

                // TODO: Always return false, until we fix equipment upload and program year / equipment forms
                return false;

                if(this.$can('create', this.getModelStringForType('equipment')) && this.$can('update', this.getModelStringForType('equipment')) ) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapMutations('equipment_library', [
                'toggleShowAllEquipment',
                'setMainView',
            ]),
            ...mapActions('equipment_library', [
                'getMeasureGroups'
            ]),
            refreshEquipment() {

                this.isRefreshing = true;
                this.getMeasureGroups().then(response => {
                    this.isRefreshing = false;
                });

            },
            importEquipmentFile() {
                this.setMainView({
                    mainView: 'import-equipment',
                    mainViewArgs: {}
                });
            }
        }

    }
</script>
