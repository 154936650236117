<script>
import FormField from "../../mixins/FormField";

export default {

    mixins: [FormField],

    name: "form-float-label-text",

    computed: {
        hasValue() {
            return (this.value !== "" && this.value !== null); // added null check as loading a null value wasn't getting caught
        },
    },


    methods: {
        updateValue(value) {
            this.form.errors.clear(this.fieldConfig.value_field);
            this.$emit("input", value);
        },
        clearValue() {
            this.$emit("input", null);
        },
        focusInput() {
            this.$refs.floatLabelInput.focus();
        }
    },

    props: {
        type: {
            type: String,
            default: "text",
        },
        closeBtnColor: {
            type: String,
            default: "blue",
        },
        fontSize: {
            type: Number,
            default: 16,
        },
        textOffsetBot: {
            type: Number,
            default: 12,
        },
    }

}

</script>
<template>
    <div
        :id="fieldName + '-text-field'"
        class="form-group"
        :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
    >
        <div class="">
            <div class="float-input-wrapper" :class="{'hasValue': hasValue}">
                <input
                    class=""
                    autocomplete="off"
                    :type="type"
                    :value="value"
                    @input="updateValue($event.target.value)"
                    :name="fieldConfig.fieldName"
                    :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
                    ref="floatLabelInput"
                />

                <div
                    class="field-placeholder"
                    @click="focusInput"
                >
                    <span v-html="fieldConfig.label"></span>
                </div>
            </div>
            <span class="errors" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
              </span>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
