<template>
    <div>
        <div>
            <preview-pdf
                :preview-url="measureListUrl"
                :download-url="measureListDownloadUrl"
                open-label="Preview Measure List"
                class="mb-4"
                :disabled="!canPreviewAndGenerate"
            ></preview-pdf>
        </div>
    </div>
</template>
<script>

    export default {

        props: {
            reportPhase: {
                type: Object,
            },
            reportScenarioId: {
                type: Number,
            },
            project: {
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            download: {
                type: Boolean,
                default: false,
            },
            validateGeneration: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                canPreviewAndGenerate: false,
            }
        },

        created() {

            if(!this.currentScenario.id) {
                return;
            }

            this.validateMeasureListGeneration().then(success => {
                this.canPreviewAndGenerate = true;
            }).catch( error => {
                if(error !== 'apiError') {
                    this.canPreviewAndGenerate = false;
                }
            });
        },

        computed: {
            reportMPID() {
                if(!this.$store.state.mp_layout.assessmentMPID) {
                    return null;
                }
                return this.$store.state.mp_layout.assessmentMPID;
            },
            currentScenario() {

                if(!this.reportMPID && this.reportScenarioId) {
                    let reportScenario = this.project.scenarios.find(scenario => {
                        return scenario.id === this.reportScenarioId;
                    });
                    if(reportScenario) {
                        return reportScenario;
                    } else {
                        return {
                            id: '',
                            scenario_nickname: ''
                        }
                    }

                }else if(!this.reportMPID) {
                    return {
                        id: '',
                        scenario_nickname: ''
                    }
                }

                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.$store.state.mp_layout.assessmentMPID)
            },
            phase() {
                if(!this.reportMPID) {
                    return this.reportPhase;
                }
                return this.$store.getters['mp_layout/getPhase'](this.reportMPID);
            },
            scenarioRooms() {
                if(!this.reportMPID || !this.currentScenario) {
                    return [];
                }
                return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.phase)
            },
            buttonText() {
                return this.trans('audit_tool.project_workflow.pre_approval.measure_list_report_generation_button',
                    {scenario_name: this.currentScenario.scenario_nickname});
            },
            measureListUrl() {
                return '/project/' + this.project.id + '/measure_list_report?phase=' +
                    this.phase.id + '&scenario=' + this.currentScenario.id;
            },
            measureListDownloadUrl() {
                return '/project/' + this.project.id + '/measure_list_report?phase=' +
                    this.phase.id + '&scenario=' + this.currentScenario.id + '&download=1';
            }
        },

        watch: {
            scenarioRooms: {
                handler(newRooms) {
                    this.validateMeasureListGeneration().then(success => {
                        this.canPreviewAndGenerate = true;
                    }).catch( error => {
                        if(error !== 'apiError') {
                            this.canPreviewAndGenerate = false;
                        }
                    });
                },
                deep: true,
            },
            currentScenario() {
                this.validateMeasureListGeneration().then(success => {
                    this.canPreviewAndGenerate = true;
                }).catch( error => {
                    if(error !== 'apiError') {
                        this.canPreviewAndGenerate = false;
                    }
                });
            }
        },

        methods: {
            validateMeasureListGeneration() {

                if(!this.validateGeneration) {
                    return new Promise( resolve => { resolve(true)});
                }

                return new Promise((resolve, reject) => {

                    axios.get('/api/projects/' + this.project.id + '/validateMeasureListReportGeneration', {
                        params: {
                            phase: this.phase.id,
                            scenario: this.currentScenario.id
                        }
                    }).then(response => {
                        if(response.data.is_valid) {
                            resolve(true);
                        }else {
                            reject('invalid');
                        }

                    }).catch(error => {
                        window.notify.apiError(error);
                        reject('apiError');
                    })

                })
            }
        }

    }
</script>
