<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import {byString} from "etap/utilities/utils";
export default {

    props: {
        config: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            initializing: false,
            showConfirmViewChangeModal: false,
            confirmingViewToChangeTo: null,
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'mainView',
            'equipmentLibraryConfiguration',
            'equipmentFormDirty',
        ]),
        qplEnabled() {
            return byString(
                this.equipmentLibraryConfiguration,
                'qpl.enabled',
            ) || false
        }
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView',
            'setEquipmentLibraryConfiguration',
        ]),
        goToView(view) {
            if(this.mainView === 'equipment-form' && this.equipmentFormDirty) {
                this.showConfirmViewChangeModal = true;
                this.confirmingViewToChangeTo = view;
                return;
            }

            this.setMainView({mainView: view});
        },
        viewChangeConfirmed() {
            if(
                !this.confirmingViewToChangeTo
            ) {
                window.notify.error('There is no view to change to');
                return
            }

            this.setMainView({
                mainView: this.confirmingViewToChangeTo
            });
            this.$store.commit('equipment_library/equipmentFormDirty', false);
            this.showConfirmViewChangeModal = false;
        },
        visibilityChanged(event) {
            if(this.mainView === 'equipment-form' && this.equipmentFormDirty) {
                return 'You have unsaved changes. If you leave this page, your changes will be lost.'

            }

            window.removeEventListener('visibilitychange', this.visibilityChanged);
        }
    },

    async created() {

        this.setEquipmentLibraryConfiguration(
            this.config
        );

        if(
            this.equipmentLibraryConfiguration.approval &&
            this.equipmentLibraryConfiguration.approval.enabled
        ) {
            await this.$store.dispatch('lookup/getLookupForModel', {
                model: this.equipmentLibraryConfiguration.approval.approvalStatuses,
                date_flag_filter: true
            });
        }

        if(window.location.hash) {
            this.setMainView({
                mainView: window.location.hash.replace('#', '')
                    .replaceAll('_', '-')
            })
        } else {

            if(
                this.qplEnabled
            ) {
                window.location.hash = '#qpl_lookup';
                this.setMainView({
                    mainView: 'qpl-lookup'
                });
                return;
            }

            window.location.hash = '#equipment_library_items';
            this.setMainView({
                mainView: 'equipment-library-items'
            });
        }

        window.addEventListener('visibilitychange', this.visibilityChanged);
    },
}
</script>

<template>
    <div>
        <div class="flex justify-around items-center text-center border-b mt-2 shadow-md">
            <a
                v-if="qplEnabled"
                class="w-1/3 p-4 cursor-pointer m-1 rounded"
                :class="{
                    'bg-primary text-white': mainView === 'qpl-lookup' ,
                    ' bg-lighter-gray hover:bg-lightest-gray text-gray-900': mainView !== 'qpl-lookup'
                }"
                @click="goToView('qpl-lookup')"
                href="#qpl_lookup"
            >
                QPL Lookup
            </a>
            <a
                class="w-1/3 p-4 cursor-pointer m-1 rounded"
                :class="{
                    'bg-primary text-white': mainView === 'equipment-library-items' ,
                    ' bg-lighter-gray hover:bg-lightest-gray text-gray-900': mainView !== 'equipment-library-items'
                }"
                @click="goToView('equipment-library-items')"
                href="#equipment_library_items"
            >
                Equipment Library Items
            </a>
            <a
                class="w-1/3 p-4 cursor-pointer m-1 rounded"
                :class="{
                    'bg-primary text-white': mainView === 'equipment-library-bulk-import' ,
                    ' bg-lighter-gray hover:bg-lightest-gray text-gray-900': mainView !== 'equipment-library-bulk-import'
                }"
                @click="goToView('equipment-library-bulk-import')"
                href="#equipment_library_bulk_import"
            >
                Equipment Library Bulk Import
            </a>
        </div>
        <div class="inset p-4">
            <component :is="mainView" v-if="!initializing"></component>
            <div class="text-center flex justify-center mb-4" v-else>
                <div class="loading-bar w-1/3 text-white rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
                &nbsp;
            </div>
        </div>
        <modal
            v-if="showConfirmViewChangeModal"
            :is-confirm="true"
            @confirmed="viewChangeConfirmed"
            @close="showConfirmViewChangeModal = false"
            >
            <template #header>
                <h3>Are you sure you want to leave this page?</h3>
            </template>
            <template #body>
                <p>
                    You have unsaved changes. If you leave this page, your changes will be lost.
                </p>
            </template>
        </modal>
    </div>
</template>
