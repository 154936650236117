<script>
    import MeasurePickerUtils from "etap/mixins/measure_picker/measure_picker_utils";
    import MultiSelect from 'vue-multiselect';
    import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons";

    export default {

        components: {
            MultiSelect
        },
        mixins: [MeasurePickerUtils],

        props: {
            roomForMeasures: {
                type: Object,
                required: true
            },
            simpleMeasurePickerFieldConfig: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                icons: {
                    spinner: faSpinner,
                    plus: faPlus
                },
                showModal: false,
                tabSelected: {
                    id: 1
                },
                loadingList: true,
                selectedMeasureSelectionItem: null,
                addingMeasure: false,
            }
        },

        computed: {
            measureList() {
                return this.$store.getters['mp_layout/getMeasureList'](this.mpid);
            },
            activeTab() {
                return 'measureTab' + this.tabSelected?.id
            },
            measureEquipmentEligibilityItem() {
                if (!this.selectedMeasureSelectionItem) {
                    return null;
                }
                return this.$store.getters['equipment_library/getMeasureEquipmentEligibilityItemForMeasureId'](
                    this.selectedMeasureSelectionItem.measure.id
                )
            },
            noMeasuresAvailableText() {
                return this.simpleMeasurePickerFieldConfig.field_extra.noMeasuresText ?? 'No equipment available to add';
            }
        },

        methods: {
            async confirmAddMeasure(measureSelectionItem) {
                this.selectedMeasureSelectionItem = measureSelectionItem;

                if(!this.measureEquipmentEligibilityItem) {
                    await this.$store.dispatch('equipment_library/getMeasureEligibility', {
                        measureId: this.selectedMeasureSelectionItem.measure.id,
                        projectId: this.mp_project?.id
                    });
                }

            },
            closeAndResetModal() {
                this.showModal = false;
                this.selectedMeasureSelectionItem = null;
            },
            async addMeasureToRoom() {
                this.addingMeasure = true;
                const newMeasure = await this.$store.dispatch('mp_project_measures/addMeasureToRoom', {
                    measureId: this.selectedMeasureSelectionItem.measure.id,
                    roomId: this.roomForMeasures.id,
                    mpid: this.mpid
                });
                this.addingMeasure = false;
                this.closeAndResetModal();

                if(newMeasure) {
                    window.notify.success('Equipment added to application.');
                    this.$emit('measureAdded', newMeasure);
                }
            },
            async loadMeasureList() {
                const vm = this;
                this.loadingList = true;
                await vm.$store.dispatch('mp_layout/initializeMeasureList', this.mpid).then(measureList => {
                    if(Array.isArray(measureList) && measureList.length > 0) {
                        vm.tabSelected = measureList[0];
                    }
                });

                setTimeout(() => {
                    this.loadingList = false;
                }, 200);
            },
            async runShowModal() {
                this.showModal = true;
                await this.loadMeasureList();
            }
        }
    }
</script>
<template>
    <div>
        <button class="button" @click="runShowModal">
            <i class="fa fa-plus"></i>
            Add Equipment
        </button>
        <modal v-if="showModal" @close="closeAndResetModal" modal-width="85%">
            <template #header>
                <h3>Add New Equipment</h3>
            </template>
            <template #body>
                <div class="min-h-50vh">
                    <div v-if="!loadingList" class="mb-4 mx-1">
                        <div v-if="(measureList.length === 0)" class="flex justify-center my-8">
                            <div class="text-center text-red">
                                <h4>{{ noMeasuresAvailableText }}</h4>
                            </div>
                        </div>
                        <div v-else>
                            <label>Select a category</label>
                            <multi-select
                                :options="measureList"
                                track-by="id"
                                label="name"
                                placeholder="Select a group"
                                v-model="tabSelected"
                                class="mt-1 mb-2"
                                :show-labels="false"
                                :allow-empty="false"
                            ></multi-select>

                            <div class="flex justify-between">
                                <div class="w-1/3">
                                    <div
                                        v-for="measureTab in measureList"
                                        :id="'measureTab' + measureTab.id"
                                        class=" h-60vh"
                                        :class="{'h-95vh': fullPicker }"
                                        v-show="activeTab == 'measureTab' + measureTab.id"
                                    >

                                        <expansion-panel v-for="group in measureTab.measure_groups" :key="group.id" :show-panel="true">
                                            <template #header-title>
                                                <div class="w-full text-left">
                                                    <h4 class="text">{{ group.name }}</h4>
                                                </div>
                                            </template>
                                            <template #default>
                                                <measure-selection-item
                                                    v-for="measure_selection_item in group.measure_selection_items"
                                                    :measure_selection_item="measure_selection_item"
                                                    :key="measure_selection_item.id"
                                                    :is-draggable="false"
                                                    class="cursor-pointer rounded border inset"
                                                    :class="{
                                                    'bg-primary text-white': selectedMeasureSelectionItem?.id === measure_selection_item.id,
                                                    'bg-lightest-gray': selectedMeasureSelectionItem?.id !== measure_selection_item.id
                                                }"
                                                    @measureSelected="confirmAddMeasure"
                                                ></measure-selection-item>
                                            </template>
                                        </expansion-panel>
                                    </div>
                                </div>
                                <div
                                    class="w-2/3"
                                    v-if="measureEquipmentEligibilityItem &&
                                measureEquipmentEligibilityItem.measure_eligibility_requirements_text"
                                >
                                    <div
                                        class="my-4 p-2 border-b border-t bg-lightest-gray rounded"
                                        v-html="measureEquipmentEligibilityItem.measure_eligibility_requirements_text"></div>
                                </div>
                            </div>
                            <div class="my-8" v-if="selectedMeasureSelectionItem">
                                <button class="button" :disabled="addingMeasure" @click="addMeasureToRoom">
                                    <fa-icon :icon="icons.spinner" v-if="addingMeasure" />
                                    <fa-icon :icon="icons.plus" v-else />
                                    Add {{ selectedMeasureSelectionItem.name }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="w-full"
                    >
                        <form-skeleton :fields="1" :columns="1" class="mb-4" />
                        <div class="flex justify-between">
                            <div class="w-1/3 p-2">
                                <table-skeleton :columns="1" :rows="10" />
                            </div>
                            <div class="w-2/3">
                                <text-skeleton :lines="1" class="mb-4 w-1/3" />
                                <text-skeleton :lines="4" />
                            </div>
                        </div>

                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>
