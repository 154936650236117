import Vue from 'vue';

export const setChecklist = (state, {type, model, checklist}) => {

    if (!state.checklist_items[type]) {
        Vue.set(state.checklist_items, type, {});
    }

    Vue.set(state.checklist_items[type], model.id, checklist);
}


export const setChecklistConfigurationForProgramTrackAndYear = (state, {programTrack, programYear, configurations}) => {

    if (!state.checklist_configurations[programTrack.name]) {
        Vue.set(state.checklist_configurations, programTrack.name, {});
    }

    Vue.set(state.checklist_configurations[programTrack.name], programYear.program_year, configurations);
}
