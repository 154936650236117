import admin from 'vision/store/admin';
import universal from 'vision/store/universal';

export default {
    namespaced: true,

    modules: {
        admin,
        universal
    },

    state: {},
    actions: {},
    mutations:{}
}
