import { updateQueryString, getParameterByName } from 'etap/utilities/url_utils';

export default {

    methods: {
        updateQueryString,
        getParameterByName
    }

}
