
import 'jquery-ui/dist/jquery-ui';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch';

/**
 * Add Tooltip to titles within the application
 * TODO: Look for other possibilities
 */

$.widget.bridge('uitooltip', $.ui.tooltip);
$.widget.bridge('uibutton', $.ui.button);

$(document).ready(function(){
    $("input[type=submit] .button").uibutton();
    $("[title]").uitooltip({
        items: '*:not(.ui-dialog-titlebar-close)'
    });
});
