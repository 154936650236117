<template>
    <div>
        <expansion-panel v-model="showPanel">
            <div slot="header-title" class="w-3/4">
                <div class="grid-container">
                    <div v-for="field in displayFields" :key="field.value_field" class="grid-item text-center">
                        <div v-if="field.widget && field.widget === 'checkbox'">
                            <label class="w-1/2">{{ field.label }}</label>
                            <div>
                                <font-awesome-icon :icon="faCheckSquare" v-if="field.value"></font-awesome-icon>
                                <font-awesome-icon :icon="faSquare" v-else></font-awesome-icon>
                            </div>
                        </div>
                        <div v-else-if="field.widget && field.widget === 'address'">
                            <google-map-link
                                :field-name="field.value_field + 'google-map-link'"
                                :value="field.value"
                                :label="field.label"
                            ></google-map-link>
                        </div>
                        <div v-else>
                            <label>{{ field.label }}</label>
                            <div>{{ field.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="header-controls">
                <div v-if="disableActions">
                    <font-awesome-icon :icon="faSpinner" :spin="true"></font-awesome-icon>
                </div>
                <div v-else>
                    <font-awesome-icon :icon="faCopy" @click.stop="copyProjectBillAccount" v-if="usesFomConfig && args.allowCopy"></font-awesome-icon>
                    <font-awesome-icon class="mx-2" :icon="faEdit" @click.stop="showPanel = !showPanel" v-if="usesFomConfig"></font-awesome-icon>
                    <font-awesome-icon :icon="faTimes" v-if="usesFomConfig && args.allowDelete" @click.stop="showDeleteModal = true"></font-awesome-icon>
                </div>
            </div>
            <div>
                <p v-if="!usesFomConfig">
                    No form configuration found for editing
                </p>
                <div v-else>
                    <vue-form
                        :form-config="args.formConfig"
                        :form-data="record"
                        :form-submit-url="formApiEndpoint"
                        @created="upsertCreatedProjectBillAccount"
                        @updated="upsertProjectBillAccount"
                        @cancel-form="showPanel = false"
                    ></vue-form>
                </div>
            </div>
        </expansion-panel>
        <modal
            v-if="showDeleteModal"
            :isConfirm="true"
            confirmText="Confirm Delete"
            modalWidth="300px"
            @close="showDeleteModal = false"
            @confirmed="deleteProjectBillAccount"
        >
            <span slot="header">Delete Project Bill Account?</span>
            <div slot="body" id="measure-delete-dialog-text">
                Are you sure you want to delete this project bill account?
            </div>
        </modal>
    </div>
</template>
<script>
import {
    faPlus,
    faCopy,
    faEdit,
    faTimes,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
    faCheckSquare,
    faSquare
} from "@fortawesome/free-regular-svg-icons"
import {byString} from "laravel-vue-forms";
import {debounce} from 'lodash';
export default {
    props: {
        record: {
            required: true,
        },
        args: {
            type: Object
        }
    },
    data() {
        return {
            showPanel: false,
            disableActions: false,
            showDeleteModal: false,
            faEdit,
            faCopy,
            faTimes,
            faCheckSquare,
            faSquare,
            faSpinner,
        }
    },
    computed: {
        displayFields() {
            let fields = this.args.displayFields ?? [];
            return fields.map(field => {
                let formField = null;
                if(this.usesFomConfig) {
                    formField = this.args.formConfig.fields.find(ff => {
                        return ff.value_field === field.value_field;
                    })
                }
                let value = byString(this.record, field.value_field);
                if(field.value_field === 'address') {
                    return {
                        label: field.label,
                        widget: 'address',
                        value_field: field.value_field,
                        value
                    }
                } else if(formField && formField.widget === 'checkbox') {
                    let trueValue = formField.field_extra.trueValue ?? true;
                    return {
                        label: field.label,
                        value_field: field.value_field,
                        widget: 'checkbox',
                        value: value == trueValue
                    }
                } else if (formField && formField.widget === 'radio') {
                    const radioOptions = formField.field_extra?.options_config?.options
                    if(Array.isArray(radioOptions)) {
                        const radioOption = radioOptions.find(rO => {
                            return rO.value == value;
                        });
                        if(radioOption) {
                            return {
                                label: field.label,
                                value_field: field.value_field,
                                value: radioOption.name
                            }
                        }
                    }
                }else if(field.value_field === 'account_type') {
                    value = this.accountTypeText;
                }else if(field.value_field === 'primary') {
                    value = this.primaryText;
                }
                return {
                    label: field.label,
                    value_field: field.value_field,
                    value
                }
            });
        },
        usesFomConfig() {
            return this.args.formConfig !== null;
        },
        accountTypeText() {
            switch (this.record.account_type) {
                case 'electric':
                    return 'Electric';
                    break;
                case 'gas':
                    return 'Gas';
                    break;
                case 'electric_and_gas':
                    return 'Electric & Gas';
                    break;
            }
            return null;
        },
        primaryText() {
            switch (this.record.primary) {
                case 0:
                    return 'No';
                case 1:
                    return 'Yes';
            }
            return null;
        },
        formApiEndpoint() {
            return '/api/bill_accounts/project/' + this.record.project_id
        }
    },
    methods: {
        upsertCreatedProjectBillAccount(projectBillAccount) {
            this.$store.commit('bill_accounts/removeProjectBillAccount', this.record);
            this.$store.commit('bill_accounts/upsertProjectBillAccount', projectBillAccount);

            if(projectBillAccount.primary) {
                this.makeOtherAccountNotPrimary(projectBillAccount);
            }
        },
        upsertProjectBillAccount(projectBillAccount) {
            this.$store.commit('bill_accounts/upsertProjectBillAccount', projectBillAccount);

            if(projectBillAccount.primary) {
                this.makeOtherAccountNotPrimary(projectBillAccount);
            }

            this.showPanel = false;
        },
        async copyProjectBillAccount() {
            this.disableActions = true;
            try {
                await this.$store.dispatch('bill_accounts/copyProjectBillAccount', {
                    formConfig: this.args.formConfig,
                    projectBillAccount: Object.assign({}, this.record, {'primary': 0}) // default primary to be 0
                });
            }catch(err) {
                console.log(err);
            }
            this.disableActions = false;
        },
        async deleteProjectBillAccount() {
            this.showDeleteModal = false;
            this.disableActions = true;
            try{
                if(this.record.id) {
                    await this.$store.dispatch('bill_accounts/deleteProjectBillAccount', this.record);
                }
                else {
                    this.$store.commit('bill_accounts/removeTempProjectBillAccountRow');
                }

            }catch(err) {
                console.log(err);
            }
            this.disableActions = false;
        },
        makeOtherAccountNotPrimary(projectBillAccount)
        {
            this.$store.commit('bill_accounts/makeOtherAccountNotPrimary', projectBillAccount);
        }
    }
}
</script>
<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 10px;
    row-gap: 10px;
}
.grid-item {
    padding: 5px;
}
</style>
