
export default {

    projectsNeedingConfigurationUpdate: {},

    configurationStatusProject: null,
    currentProjectConfigurationUpdateStatus: null,
    projectConfigurationUpdateIsRunning: false,
    projectConfigurationStatusUpdater: null
}
