<script>
import axios from 'axios';
export default {
    props: {
        record: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            residentialProjectMeasures: []
        }
    },


    created() {
        if(this.programTrack.name === 'residential') {
            this.getResidentialProjectMeasures();
        }
    },

    computed: {
        projectPhases() {
            return this.$store.state.projectPhases
        },
        programTrack() {
            return this.$store.state.programTracks.find(programTrack => {
                return programTrack.id === this.record.program_track_id;
            });
        },
        programYear() {
            return this.$store.state.programYears.find(programYear => {
                return programYear.id === this.record.program_year_id;
            });
        },
        addressLine1() {

            if(this.record.project_address) {
                return this.record.project_address.address
            }

            return null;
        },
        tradeAllyName() {

            if(
                !this.record.user ||
                (
                    !this.record.user.first_name &&
                    !this.record.user.last_name
                )
            ) {
                return ' ';
            }

            return this.record.user.first_name ?? '' + ' ' + this.record.user.last_name ?? '';
        },
        tradeAllyCompany() {

            if(!this.record.user || !this.record.user.company) {
                return null;
            }

            return this.record.user.company.name ?? '';
        },
        measureNames() {
            const measureNames = [];

            this.residentialProjectMeasures.forEach(projectMeasure => {
                if(measureNames.indexOf(projectMeasure.measure.name) === -1) {
                    measureNames.push(projectMeasure.measure.name);
                }
            });

            return measureNames;
        }
    },


    methods: {
        async getResidentialProjectMeasures() {

            const measureResponse = await axios.get(
                '/api/projects/' + this.record.id + '/measures', {
                    params: {
                        phase: this.projectPhases.find(phase => phase.name === 'residential_application').id
                    }
                }
            );

            this.residentialProjectMeasures = measureResponse.data;
        }
    }
}
</script>
<template>
    <div class="flex items-center text-center">
        <div class="companyname col-md-4" v-if="programTrack.name !== 'residential'">{{ record.customer_company_name }}&nbsp;</div>
        <div class="companyname col-md-4" v-else>
            <span v-for="(measureName,index) in measureNames"><span v-if="index !== 0">, </span>{{ measureName }}</span>
        </div>

        <span class="companyaddress col-md-4" v-if="programTrack.name !== 'residential'">{{ addressLine1 }}</span>
        <div class="companyaddress col-md-4" v-else>
            <span>
                {{ record.status.name }}
            </span>
        </div>

        <div class="tradeally-contact col-md-4">
            <div>
                {{ tradeAllyName }}
            </div>
            <div>
                {{ tradeAllyCompany }}
            </div>
        </div>
    </div>
</template>
