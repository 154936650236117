<template>
        <div id="measureGroupsContainer">
            <div class="measureGroupButton"
                 :class="{ 'selected': selectedMeasureGroup === null}"
            >
                <button @click="setSelectedMeasureGroup(null)">
                    All Measures
                </button>
            </div>
            <div class="measureGroupButton"
                 v-for="measureGroup in measureGroups"
                 :key="measureGroup.id"
                 :class="{ 'selected': selectedMeasureGroup == measureGroup}"
            >
                <button @click="setSelectedMeasureGroup(measureGroup)">
                    {{ measureGroup.name }}
                </button>
            </div>
        </div>
</template>
<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    export default {

        created() {
            this.getMeasureGroups();
        },

        computed: {
            ...mapGetters('equipment_library', [
                'measureGroups'
            ]),
            ...mapState('equipment_library', [
                'selectedMeasureGroup'
            ])
        },

        methods: {
            ...mapActions('equipment_library', [
                'getMeasureGroups'
            ]),
            ...mapMutations('equipment_library', [
                'setSelectedMeasureGroup'
            ])
        }
    }
</script>
