<script>
import { mapActions, mapMutations } from 'vuex';

export default {

    props: {
        mpid: {
            type: String,
            required: true
        },
        room: {
            type: Object,
            required: true
        },
        allowMeasureDetailsForm: {
            type: Boolean,
            default: false
        },
        showMeasureCalcDetails: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            roomName: this.room.room_name,
            roomHours: this.room.operating_hours,
            loadingRoomMeasures: false,
        }
    },

    created() {
        if(this.roomExpanded === null) {
            this.$store.commit('mp_layout/setRoomExpanded', {
                mpid: this.mpid,
                room: this.room,
                expanded: false
            });
        }
    },

    computed: {
        projectMeasuresForRoom() {
            if(!this.currentScenario || !this.currentPhase || !this.room) {
                return [];
            }

            return this.$store.getters['mp_project_measures/getProjectMeasuresForScenarioPhaseAndRoom'](
                this.currentScenario,
                this.currentPhase,
                this.room
            );
        },
        roomExpanded: {
            get() {
                return this.$store.getters['mp_layout/getRoomExpanded'](this.room, this.mpid);
            },
            set(expanded) {
                this.$store.commit('mp_layout/setRoomExpanded', {mpid: this.mpid, room: this.room, expanded});
            }
        },
        currentScenario() {
            return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
        },
        currentPhase() {
            return this.$store.getters['mp_layout/getPhase'](this.mpid);
        },

    },

    watch: {
        roomExpanded(newRoomExpanded) {
            if(!newRoomExpanded) {
                return // no need to load
            }

            this.loadRoomMeasures();
        },

    },

    methods: {
        ...mapMutations('mp_layout', [
            'setRoomExpanded',
        ]),
        ...mapActions('mp_project_measures', [
            'getProjectMeasuresForRoom',
        ]),
        toggleRoomExpanded() {
            this.roomExpanded = !this.roomExpanded;
        },
        async loadRoomMeasures() {
            this.loadingRoomMeasures = true;
            await this.getProjectMeasuresForRoom({mpid: this.mpid, roomId: this.room.id});
            this.loadingRoomMeasures = false;
        }
    },

}
</script>
<template>
    <div class="room" :data-roomid="room.id">
        <div class="flex justify-between items-center roomhead">
            <div class="p-1 flex">
                <button
                    :id="'room-' + room.id + '-expandcollapsebutton'"
                    class="inputbutton1 roomcontrolbutton"
                    :title=" roomExpanded ? 'Close' : 'Open'"
                    @click.stop="toggleRoomExpanded">
                    <span class="fa fa-chevron-right" :class="{ 'fa-chevron-down': roomExpanded }"></span>
                </button>
            </div>
            <div class="lg:flex justify-between items-center w-full text-right">

                <div>
                    {{ room.room_name }}
                </div>

                <div v-if="!roomExpanded">
                    Measures: {{ room.measure_count }}
                </div>

                <div class="w=1/3">
                    <span v-show="room.room_operating_hours">
                        Operating hours: {{ room.room_operating_hours }}
                    </span>
                </div>

            </div>
        </div>
        <div v-show="roomExpanded" class="identifiedmeasurelist">

            <measure-selection-field-row
                v-show="!loadingRoomMeasures"
                v-for="projectMeasure in projectMeasuresForRoom"
                :key="projectMeasure.id"
                :mpid="mpid"
                :project-measure="projectMeasure"
                :allow-measure-details-form="allowMeasureDetailsForm"
                :show-measure-calc-detals="showMeasureCalcDetails"
            />

            <div v-if="loadingRoomMeasures" class="text-center flex justify-center mb-4 relative">&nbsp;
                <div class="loading-bar w-3/4 text-white p-12 rounded">
                    <span class="ml-4">Loading ...</span>&nbsp;
                </div>
            </div>

        </div>

    </div>
</template>
<style scoped>
.room {
    margin-top: 20px;
}

.roomcontrolbutton {
    width: 30px;
    margin-right: 10px;
    font-size: 21px;
    padding: 4px 0;
}

</style>
