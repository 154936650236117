<script>
export default {
    props: {
        taskable: {
            required: true,
            type: Object
        }
    },

    mounted() {
        $('.phase-status-box').uitooltip({});
    },

    computed: {
        projectPhases() {
            return this.$store.state.projectPhases
        },
        programTrack() {
            return this.$store.state.programTracks.find(programTrack => {
                return programTrack.id === this.taskable.program_track_id;
            });
        },
        programYear() {
            return this.$store.state.programYears.find(programYear => {
                return programYear.id === this.taskable.program_year_id;
            });
        },
        visibleProjectPhasesForProgramTrack() {
            return this.programTrack.project_phases.filter(projectPhase => {
                return projectPhase.config.hidden === 0 && projectPhase.config.program_year_id === this.taskable.program_year_id;
            });
        },
        currentPhaseOrder() {
            return this.programTrack.project_phases.find(phase => {
                return phase.id === this.taskable.phase_id;
            }).config.order;
        },
        addressLine1() {

            if(this.taskable.project_address) {
                return this.taskable.project_address.address
            }

            return null;
        },
        tradeAllyName() {

            if(!this.taskable.user) {
                return null;
            }

            return this.taskable.user.first_name + ' ' + this.taskable.user.last_name;
        },
        tradeAllyCompany() {

            if(!this.taskable.user || !this.taskable.user.company) {
                return null;
            }

            return this.taskable.user.company.name;
        },
        usesDSMC() {
            return window.MAT.features.find(feature => {
                return feature.name === 'dsmc_api' && feature.enabled;
            }) !== undefined;
        },
        programName() {
            return this.programTrack.title + ' ' + this.programYear.program_year;
        },
        reservationId() {
            if(!this.usesDSMC || !this.taskable.dsmc_project) {
                return null;
            }

            return this.taskable.dsmc_project.project_name;
        },
    },


    methods: {
        getProjectPhaseStatusClass(projectPhase) {
            if(projectPhase.config.order > this.currentPhaseOrder) {
                return 'projectstatusinactive'
            } else if(projectPhase.config.order < this.currentPhaseOrder) {
                return 'projectstatuscomplete';
            }

            return '';
        },
        goToPhase(projectPhase) {

            let projectApprovalPhase = this.projectPhases.find(phase => phase.name === 'project_approval');
            let finalProjectApprovalPhase = this.projectPhases.find(phase => phase.name === 'final_project_approval');


            if(!this.$can('qc_projects') && (projectPhase.id === projectApprovalPhase.id || projectPhase.id === finalProjectApprovalPhase.id)) {
                return;
            }

            window.location = '/project/' + this.taskable.id + '/' + projectPhase.name;
        },
    }
}
</script>
<template>
    <div>
        <div class="border-b py-2 mb-2">
            <h5>Project</h5>
        </div>
        <div class="flex justify-between items-center">
            <div class="w-1/4">
                <div class="flex justify-between">
                    <label class="block font-bold">Project Name</label>
                    {{ taskable.name ?? taskable.customer_company_name }}
                </div>
                <div class="flex justify-between">
                    <label class="block font-bold">Service Provider</label>
                    {{ tradeAllyCompany }}
                </div>
            </div>
            <div class="w-1/4 mx-4">
                <div class="flex justify-between">
                    <label class="block font-bold">Application / Tool ID</label>
                    {{ taskable.shared_project_id }}
                </div>
                <div class="flex justify-between">
                    <label class="block font-bold">Program Track</label>
                    {{ programTrack.title }}
                </div>
                <div class="flex justify-between">
                    <label class="block font-bold">Program Year</label>
                    {{ programYear.program_year }}
                </div>
            </div>
            <div class="w-1/3">
                <h5>Project Workflow:</h5>
                <div class="text-right statusboxes">
                    <a
                        class="projectstatusbackground mr-2"
                        :class="getProjectPhaseStatusClass(projectPhase)"
                        v-for="(projectPhase, index) in visibleProjectPhasesForProgramTrack"
                        v-if="projectPhase.hidden !== 1"
                        @click.stop="goToPhase(projectPhase)"
                        :key="index"
                    >
                        <span v-if="projectPhase.icon" class="phase-status-box fa" :class="'fa-' + projectPhase.icon" :title="projectPhase.title"></span>
                        <span v-else class="phase-status-box" :title="projectPhase.title">{{ index + 1}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
