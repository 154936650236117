import Model from "./model";


class Task extends Model {

    defaults() {
        return {
            id: null,
            taskable_id: null,
            taskable_type: null,
            assignable_id: null,
            assignable_type: null,
            task_type_id: null,
            task_status_id: null,
            task_state_id: null,
            date_due: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {

        }
    }



}

export default Task;
