import Model from 'etap/admin/classes/models/model';
import moment from 'moment';

class Feature extends Model {


    defaults() {
        return {
            name: null,
            title: null,
            enabled: false,
            options: {},
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            enabled: Boolean,
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }


}

export default Feature;
