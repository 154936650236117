import Model from 'etap/admin/classes/models/model';

class FormField extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            value_field: null,
            visible: true,
            disabled: false,
            is_eav: false,
            cast_to: null,
            parent_id: null,
            field_extra: {}
        }
    }

    casts() {
        return {
            visible: Boolean,
            disabled: Boolean,
            is_eav: Boolean,
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default FormField;
