import axios from 'axios';

export default {
    namespaced: true,

    state: {
        payments: [],
    },
    mutations: {
        updatePayments(state, payments) {
            state.payments = payments;
        },
        addPayment(state, payment) {
            state.payments.push(payment);
        },
        updatePayment(state, payment) {

            state.payments.forEach((p, index) => {
                if(p.id === payment.id) {
                    state.payments.splice(index, 1, payment);
                }
            });
        },
        deletePayment(state, payment) {
            state.payments.forEach((p, index) => {
                if(p.id === payment.id) {
                    state.payments.splice(index, 1);
                }
            });
        }
    },
    actions: {
        getPaymentsForProject(context, projectId) {

            return new Promise((resolve, reject) => {

                axios.get('/api/projects/' + projectId + '/payments').then(response => {

                    if(response.data) {
                        context.commit('updatePayments', response.data);
                        resolve(response.data);
                    }else {
                        window.notify.message('Invalid response in getting project payments');
                        reject('invalid');
                    }

                }).catch(error => {
                   window.notify.apiError(error);
                   reject(error);
                });
            });
        },
        deletePayment(context, { payment, project}) {

            return new Promise((resolve, reject) => {

                axios.delete('/api/projects/' + project.id + '/payments/' + payment.id).then(response => {

                    if(response.data.success) {
                        resolve(true);
                        context.commit('deletePayment', payment);
                        window.notify.message(response.data.message, 'success');
                    }else {
                        window.notify.message('Unable to delete payment');
                        reject(false);
                    }

                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });

            })
        }
    }

}
