<style>
    .create-project-modal-description {
        text-align: left;
        & ul, ol {
            list-style: initial;
            margin: initial;
            padding: 0 0 0 40px;
        }
        & li {
            display: list-item;
        }
    }
</style>
<style scoped>
    .create-project-modal-body {
        min-height: 25vh;
    }
    @media screen and (max-height:768px) {
        .create-project-modal-body {
            min-height: 45vh;
        }
    }
</style>
<template>
    <div>
        <a @click.prevent="handleCreateProjectButton" class="inputbutton2">
            <i class="fa fa-plus"></i>
            Create
        </a>

        <modal
            v-if="showModal"
            modalWidth="90%"
            @close="closeModal"
            class="create-project-modal"
        >
            <span slot="header">Create Application / Project</span>

            <template #body>
                <div class="rounded mt-1 flex justify-center create-project-modal-body" >
                    <div class="w-1/3" style="flex-grow:6;">
                        <div>
                            <form-select
                                class="mx-4"
                                style="width:90%;"
                                field-name="program_track"
                                v-model="selectedProgramTrackId"
                                label="Program Track"
                                :options="programTrackOptions"
                                :show-multiselect-labels="false"
                                option-label-field="title"
                                option-value-field="id"
                            ></form-select>
                        </div>
                        
                        <div>
                            <form-select
                                v-show="selectedProgramTrackId && (!selectedProgramYearId || programYearOptions.length > 1)"
                                class="mx-4"
                                style="width:90%;"
                                field-name="program_year"
                                v-model="selectedProgramYearId"
                                label="Program Year"
                                :options="programYearOptions"
                                :show-multiselect-labels="false"
                                option-label-field="program_year"
                                option-value-field="id"
                                :disabled="selectedProgramYearId && programYearOptions.length === 1 ? 1 : 0"
                            ></form-select>
                        </div>
                        
                        <div v-if="selectedProgramYearId && selectedProgramTrackId">
                            <div class="fa fa-spinner fa-spin mx-2" v-if="creating"></div>
                            <button
                                @click="createProjectOrDirectToEligibilityForSelection"
                                class="inputbutton1"
                                v-html="createProjectButtonInModalLabel"
                                :disabled="creating"
                            >
                                <span v-html="createProjectButtonInModalLabel"></span>
                            </button>
                        </div>
                    </div>
                    <div class="w-2/3" >
                        <div v-if="selectedProgramTrack?.description" class="create-project-modal-description" style="width:90%;">
                            <span v-html="selectedProgramTrack.description"></span>
                        </div>
                    </div>
                </div>
                
            </template>
        </modal>
    </div>
</template>
<script>
export default {
    props: [
        'createProjectUrl',
        'createProjectExistingUrl',
    ],
    data() {
        return {
            showModal: false,
            selectedProgramYearId: null,
            selectedProgramTrackId: null,
            creating: false
        }
    },

    created() {
        this.init();
    },

    computed: {
        validTracksForUser() {
            return this.$store.getters.validTracksForUser;
        },
        programYears() {
            return this.$store.getters.programYears
        },
        validProgramYears() {

            const validProgramTrackProgramYearIds = this.validTracksForUser.map(pTrack => {
                return pTrack.pivot.program_year_id;
            });

            let today = moment();
            return this.programYears.filter(programYear => {
                if(
                    (today.isAfter(programYear.valid_from) && today.isBefore(programYear.valid_to) ) ||
                    (today.isSame(programYear.valid_from) || today.isSame(programYear.valid_to))
                ) {
                    return true;
                }

                return false;
            }).filter(pY => {
                return validProgramTrackProgramYearIds.includes(pY.id);
            });
        },
        validProgramTracksWithPivots() {
            const validProgramYearIds = this.validProgramYears.map(py => py.id);
            return this.validTracksForUser.filter(programTrack => {
                return !!validProgramYearIds.includes(
                    programTrack.pivot.program_year_id
                )
            });
        },
        // need to ensure no duplicates in program tracks
        programTrackOptions() {
            const programTracks = [];
            this.validProgramTracksWithPivots.forEach(pTrack => {
                let existingProgramTrack = programTracks.find(pT => {
                    return pT.name === pTrack.name;
                });
                if(!existingProgramTrack) {
                    programTracks.push({
                        id: pTrack.id,
                        name: pTrack.name,
                        title: pTrack.title
                    });
                }
            });

            return programTracks;
        },
        // need to filter program years based on which are valid for the selected track
        programYearOptions() {
            const programYears = [];
            this.validProgramYears.forEach(pY => {

                if(
                    this.selectedProgramTrackId && 
                    this.validProgramTracksWithPivots.filter(pTrack => {
                        return pTrack.id === this.selectedProgramTrackId && pTrack.pivot.program_year_id === pY.id;
                    }).length === 0
                ) {
                    return;
                }

                programYears.push(pY);
            });

            return programYears;
        },
        eligibilityFeature() {
            return this.$store.getters['system/getFeatureForName']('eligibility_check')
        },
        programTrackEligibilityConfig() {
            if(
                this.eligibilityFeature &&
                this.eligibilityFeature.enabled &&
                this.eligibilityFeature.options.valid_program_track &&
                this.eligibilityFeature.options.valid_program_track.length > 0 &&
                this.selectedProgramYearId &&
                this.selectedProgramTrackId
            ) {
                return this.eligibilityFeature.options.valid_program_track.find(vPT => {
                    return this.validProgramTracksWithPivots.find(programTrack => {
                        return Number(programTrack.id) === Number(this.selectedProgramTrackId) &&
                                Number(programTrack.pivot.program_year_id) === Number(this.selectedProgramYearId) &&
                                vPT.program_track === programTrack.name;
                    }) !== undefined;
                });
            }

            return null;
        },
        // currenlty selected track to get description from
        selectedProgramTrack() {
            if(
                this.selectedProgramTrackId
            ) {
                return this.validProgramTracksWithPivots.find(pTrack => {
                    return pTrack.id ===this.selectedProgramTrackId;
                });
            }

            return null;
        },
        useEligibilityWithProjectCreate() {
            if(!this.programTrackEligibilityConfig) {
                return false
            }

            if(typeof this.programTrackEligibilityConfig.use_with_project_create === 'undefined') {
                return true;
            }

            return this.programTrackEligibilityConfig.use_with_project_create;
        },
        createProjectButtonInModalLabel() {
            if(!this.programTrackEligibilityConfig || !this.useEligibilityWithProjectCreate) {
                return '+ Create New';
            }

            return this.programTrackEligibilityConfig?.createProjectFromExistingCustomerBtnLabel
                ?? 'Create Project from existing customer';
        },
    },

    watch: {
        selectedProgramTrackId() {
            if(
                this.selectedProgramTrackId &&
                this.programYearOptions.length === 1
            ) {
                // If there is only one program year option, then select it
                this.selectedProgramYearId = this.programYearOptions[0].id;
            } else {
                // reset selected program year is no track is selected or multiple options
                this.selectedProgramYearId = null;
            }
        }
    },

    methods: {
        // track & year option possibilities
        // - 1 valid track / 1 valid year
        // - multiple valid tracks / 1 valid year
        // - 1 valid track / 1 valid year
        // - multiple valid tracks / multiple valid years

        // handle project create
        // - requires customer eligibility lookup
        // - create project directly


        init() {
            // reset selectedProgramYearId and selectedProgramTrackId
            this.selectedProgramYearId = this.validProgramYears.length === 1 ? this.validProgramYears[0].id : null;
            this.selectedProgramTrackId = this.programTrackOptions.length === 1 ? this.programTrackOptions[0].id : null;
        },
        handleCreateProjectButton() {
            // if these were defaulted from initialization, then go strait to create project or eligibility selection
            if(this.selectedProgramTrackId && this.selectedProgramYearId) {
                this.createProjectOrDirectToEligibilityForSelection();
                return;
            }

            this.showModal = true;
        },
        createProjectOrDirectToEligibilityForSelection() {
            if(
                !this.selectedProgramYearId ||
                !this.selectedProgramTrackId ||
                this.validProgramYears.filter(pY => {
                    return pY.id === this.selectedProgramYearId;
                }).length === 0 ||
                this.validProgramTracksWithPivots.filter(pTrack => {
                    return pTrack.id === this.selectedProgramTrackId && pTrack.pivot.program_year_id === this.selectedProgramYearId
                }).length === 0
            ) {
                window.notify.error("Unable to create project.   Invalid program year & program track");
                return;
            }
            this.creating = true;
            let url = '';
            if(this.programTrackEligibilityConfig && this.useEligibilityWithProjectCreate) {
                url = this.createProjectExistingUrl + '?program_track_id=' + this.selectedProgramTrackId + '&program_year_id=' + this.selectedProgramYearId;
                window.location = url;
                return;
            }

            url = this.createProjectUrl + '?program_track_id=' + this.selectedProgramTrackId + '&program_year_id=' + this.selectedProgramYearId;
            window.location = url;
        },
        closeModal() {
            this.showModal = false;
            this.init();

        }
    }
}
</script>
