<template>
    <div class="collapse-list">
        <div class="header" @click="toggleList">
            <i aria-label="Expand/Collapse List Items" class="fa" :class="{ 'fa-chevron-down' : listVisible, 'fa-chevron-right': !listVisible }"></i>
            <slot class="header-text" name="header">{{ title }}</slot>
        </div>
        <div class="list-items" v-if="listVisible">
            <slot name="items">
                <ul v-if="listItems.length > 0">
                    <li v-for="item in listItems" :key="item[listKey]">
                        <span v-if="typeof item === 'object'">{{ item[listKey]}}</span>
                        <span v-else>{{ item }}</span>
                    </li>
                </ul>
            </slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'collapse-list',

        props: {
            title: {
                type: String,
                default: 'List Items'
            },
            listItems: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            textKey: {
                default: 'name',
                type: String,
            },
            listKey: {
                default: 'id',
                type: String,
            },
            initiallyOpen: {
                default: false,
                type: Boolean
            }
        },

        data() {
            return {
                listVisible: false,
            }
        },

        created() {
            this.listVisible = this.initiallyOpen;
        },

        methods: {
            toggleList() {
                this.listVisible = !this.listVisible;
            }
        }
    }
</script>