<template>
    <div id="app-grid">
        <div class="relative bg-grey-lighter outline-none" v-on-clickaway="closeGrid">
            <button
                class="bg-color3 color-white border-0 ml-8 shadow outline-none h-32 w-32 flex align-middle content-center"
                @click="gridOpen = !gridOpen"
                aria-label="Application Switcher"
            >
                <img :src="gridIconUrl" class="h-20 w-20" alt="Switch applications" />
            </button>
            <transition
                enter-active-class="animated faster flipInX"
                leave-active-class="animated faster flipOutX"
            >
                <div
                    class="app-drawer"
                    v-if="gridOpen"
                >
                    <div class="flex">
                        <a
                            v-for="link in links.row1"
                            :href="link.url" target="_blank">
                            <img :src="assetBaseUrl + link.icon" alt="Application"/>
                        </a>

                    </div>
                    <div class="flex">
                        <a
                            v-for="link in links.row2"
                            :href="link.url" target="_blank">
                            <img :src="assetBaseUrl + link.icon" alt="Application"/>
                        </a>

                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    import { mixin as clickaway } from 'vue-clickaway';
    export default {
        mixins: [ clickaway ],

        props: {
            links: {
                default() {
                    return {
                        row1: [
                            {
                                icon: 'images/grid-icons/app-grid-icon_SPP.png',
                                url: 'https://tradeallypartner.com/comedsb',
                            },
                            {
                                icon: 'images/grid-icons/app-grid-icon_edash.png',
                                url: 'https://comed-smallbiz-dashboard.tradeallypartners.com/saml2/login',
                            }
                        ],
                        row2: [
                            {
                                icon: 'images/grid-icons/app-grid-icon_MAT.png',
                                url: 'https://comed.tradeallypartners.com/saml2/login',
                            },
                            {
                                icon: 'images/grid-icons/app-grid-icon_MMT.png',
                                url: 'https://comed-smallbiz-dashboard.tradeallypartners.com/saml2/login',
                            }
                        ]
                    }
                }
            }
        },

        data() {
            const assetBaseUrl = window.MAT.asset_base;
            return {
                gridOpen: false,
                gridIconUrl: assetBaseUrl + 'images/grid-icons/grid-icon.svg',
                assetBaseUrl
            }
        },

        methods: {
            closeGrid() {
                if(this.gridOpen) {
                    this.gridOpen = false;
                }
            }
        }

    }
</script>
<style lang="scss">



</style>
