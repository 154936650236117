import axios from 'axios';

export const getProjectLead = (context, data) => {
    return axios.get('/api/projects/' + data.project_id + '/leads')
        .then(res => {
            if(res.data) { // there may not be an inspection for this project or phase
                context.commit('addLead', res.data);
            }
        }).catch(error => {
        window.notify.apiError(error);
    });
}
