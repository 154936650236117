<template>
    <div
        class="form-group autocomplete"
        v-on:keyup.esc="closeResults"
        v-on:keyup.up="previousItem"
        v-on:keyup.down="nextItem"
        :id="fieldName + '-text-field'"
        :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
    >
        <label class="form-control-label" v-if="showLabel"
        ><span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required"
            >&nbsp;&nbsp;(*)</span
            >
            <span
                v-if="withHelpIcon"
                :class="fieldConfig.field_extra.withIcon"
                :title="fieldConfig.field_extra.helpText"
            ></span>
        </label>
        <div v-on-clickaway="closeResults">
            <div class="auto-complete-input-container">
                <div
                    v-if="optionValue && ! loadingResults"
                    class="autocomplete-selected-item flex items-center justify-between grow-2"
                >
                    <div class="px-1">
                        {{ optionValue[fieldConfig.optionLabelField] }}
                    </div>
                    <font-awesome-icon
                        v-if="!fieldConfig.disabled"
                        class="cursor-pointer mx-1"
                        :icon="times"
                        @click.stop="deselectCurrentItem"
                    ></font-awesome-icon>
                </div>
                <div v-else-if="optionValue && loadingResults" class="autocomplete-selected-item flex items-center">
                    <div class="px-1">
                        Loading...
                        <fa-icon :icon="spinner" :spin="true"></fa-icon>
                    </div>
                </div>
                <input
                    v-show="!optionValue"
                    type="text"
                    class="grow-2"
                    v-model="searchQuery"
                    @keydown.down.once="nextItem"
                    @keydown.up.once="previousItem"
                    @keydown.enter="onEnter"
                    @focus="showResults = true"
                    :disabled="optionValue || loadingResults || Boolean(fieldConfig.disabled)"
                    :aria-disabled="optionValue"
                    ref="searchInput"
                />
                <button
                    class="border-0 mx-1"
                    v-show="showResults || loadingResults"
                    :disabled="loadingResults"
                >
                    <font-awesome-icon
                        class="cursor-pointer"
                        :icon="refresh"
                        :spin="loadingResults"
                        @click.stop="getOptions"
                    ></font-awesome-icon>
                </button>
            </div>
            <ul v-show="showResults" class="autocomplete-results">
                <li
                    v-for="(result, i) in filteredResults"
                    :key="i"
                    @click="setResult(result)"
                    class="autocomplete-result"
                    :class="{ 'is-active': i === arrowCounter }"
                >
                    {{ result[fieldConfig.optionLabelField] }}
                </li>
                <li
                    v-if="filteredResults.length === 0 && tagging && loadingResults === false"
                    class="autocomplete-result"
                    @click="createItem"
                >
                    Create this {{ fieldConfig.label }}
                </li>
                <li v-if="loadingResults === true" class="autocomplete-result">
                    Loading...
                    <fa-icon :icon="spinner" :spin="true"></fa-icon>
                </li>
            </ul>
            <span
                class="help-block"
                v-if="form.errors.has(this.fieldConfig.value_field)"
            >
        {{ form.errors.get(this.fieldConfig.value_field, true) }}
      </span>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
<script>
import FormField from "../../mixins/FormField";
import {debounce, get} from "lodash";
import Parser from "../../classes/jsonapi_parser";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import { mixin as clickaway } from 'vue-clickaway';
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";

export default {
    name: "form-autocomplete",

    mixins: [FormField, clickaway],

    components: {
        FaIcon: FontAwesomeIcon
    },

    props: {
        tagging: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default() {
                return [];
            }
        },
        optionsUrl: {
            type: String,
            default: null
        },
        optionsUrlParams: {
            type: Object,
            default() {
                return {};
            }
        },
        vuexPath: {
            type: String,
            default: null
        },
        optionLabelField: {
            type: String,
            default: "name"
        },
        optionValueField: {
            type: String,
            default: "id"
        }
    },

    data() {
        return {
            searchQuery: "",
            showResults: false,
            loadingResults: false,

            arrowCounter: 0,
            spinner: faSpinner,
            refresh: faSync,
            times: faTimes,

            fieldsToWatch: [],
            vuexStorePath: null,
            vuexGetterPath: null
        };
    },

    created() {
        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name === this.fieldName) {
                    if (!field.field_extra.options_config) {
                        window.notify.message(
                            "Invalid field configuration for field: " +
                            field.name,
                            "error"
                        );
                        return;
                    }
                    this.$set(this.fieldConfig, "options", []);
                    this.$set(this.fieldConfig, "optionsUrlParams", {});

                    if (
                        field.field_extra.options_config.vuex_store_path &&
                        field.field_extra.options_config.vuex_getter_path
                    ) {
                        window.notify.message(
                            "Can not specify both vuex_store_path and vuex_getter_path in configuration."
                        );
                        return;
                    }

                    if (field.field_extra.options_config.vuex_store_path) {
                        this.vuexStorePath =
                            field.field_extra.options_config.vuex_store_path;
                    }

                    if (field.field_extra.options_config.vuex_getter_path) {
                        this.vuexGetterPath =
                            field.field_extra.options_config.vuex_getter_path;
                    }

                    if (field.field_extra.options_config.optionsUrlParams) {
                        this.$set(
                            this.fieldConfig,
                            "optionsUrlParams",
                            field.field_extra.options_config.optionsUrlParams
                        );
                    }

                    this.$set(this.fieldConfig, "optionValueField", "id");
                    this.$set(this.fieldConfig, "optionLabelField", "name");

                    if (field.field_extra.options_config.optionValueField) {
                        this.fieldConfig.optionValueField =
                            field.field_extra.options_config.optionValueField;
                    }

                    if (field.field_extra.options_config.optionLabelField) {
                        this.fieldConfig.optionLabelField =
                            field.field_extra.options_config.optionLabelField;
                    }

                    if (field.field_extra.options_config.default) {
                        this.fieldConfig.default =
                            field.field_extra.options_config.default;
                    }

                    if (field.field_extra.options_config.options) {
                        this.setUpOptions(field);
                    }

                    if (field.field_extra.options_config.optionsURL) {
                        this.setUpOptionsURL(field);
                        this.getOptions();
                    }
                }
            });
        } else {
            if(Array.isArray(this.options)) {
                this.$set(this.fieldConfig, "options", this.options);
            } else {
                this.$set(this.fieldConfig, "options", []);
            }

            this.$set(
                this.fieldConfig,
                "optionValueField",
                this.optionValueField
            );
            this.$set(
                this.fieldConfig,
                "optionLabelField",
                this.optionLabelField
            );
            this.$set(this.fieldConfig, "optionsURL", this.optionsUrl);
            this.$set(
                this.fieldConfig,
                "optionsUrlParams",
                this.optionsUrlParams
            );
            this.vuexStorePath = this.vuexPath;
            this.vuexGetterPath = this.vuexPath;
        }
    },

    computed: {
        currentOptionsUrl() {
            if (this.fieldConfig.optionsURL) {
                var optionsURL = this.fieldConfig.optionsURL;
                this.fieldsToWatch.forEach(match => {
                    let fieldValue = get(this.form, match[1], "");
                    optionsURL = optionsURL.replace(match[0], fieldValue);
                });

                var params = JSON.parse(
                    JSON.stringify(this.fieldConfig.optionsUrlParams)
                );
                if(
                    this.searchQuery !== null &&
                    this.searchQuery !== undefined &&
                    this.searchQuery !== ''
                ) {
                    params.q = encodeURIComponent(this.searchQuery);
                }

                if(this.value) {
                    params.selected_record = this.value;
                }

                let queryString = Object.keys(params)
                    .map(key => key + "=" + params[key])
                    .join("&");

                if(optionsURL.includes("?")) {
                    optionsURL += "&";
                } else {
                    optionsURL += "?";
                }

                optionsURL += queryString;


                if(this.useJsonApi && Array.isArray(this.jsonApiIncludes) && this.jsonApiIncludes.length > 0) {
                    if(optionsURL.includes("?")) {
                        optionsURL += "&";
                    } else {
                        optionsURL += "?";
                    }
                    this.jsonApiIncludes.forEach(include => {
                        optionsURL += "include[]=" + include + "&";
                    });

                    optionsURL = optionsURL.slice(0, -1);
                }


                return optionsURL;
            }
            return "";
        },
        filteredResults() {
            if(!this.fieldConfig.options) {
                return []
            } else if (!this.searchQuery) {
                return this.fieldConfig.options;
            }

            return this.fieldConfig.options.filter(item => {
                if (!item) {
                    return false;
                }

                return item[this.fieldConfig.optionLabelField]
                    .toLowerCase()
                    .includes(this.searchQuery.toLowerCase());
            });
        },
        optionValue() {
            if (!this.value && this.value !== 0) {
                return null;
            }

            return this.fieldConfig.options?.find(option => {
                return option[this.fieldConfig.optionValueField] == this.value;
            }) ?? null;
        },
    },

    watch: {
        currentOptionsUrl: debounce(function() {
            this.getOptions();
        }, 800),
        options: function(newOptions) {
            this.$set(this.fieldConfig, "options", newOptions);

            this.defaultField();
        },
        searchQuery() {
            this.arrowCounter = 0;
        }
    },

    methods: {
        setUpOptionsURL(field) {
            this.$set(
                this.fieldConfig,
                "optionsURL",
                field.field_extra.options_config.optionsURL
            );
            var pattern = /:([^:]*):/g,
                match;

            while ((match = pattern.exec(this.fieldConfig.optionsURL))) {
                this.fieldsToWatch.push(match);
            }
        },
        // set up options based on options param in field config
        setUpOptions(field) {
            var options = [];
            field.field_extra.options_config.options.forEach(fieldOption => {
                options.push(fieldOption);
            });

            this.$set(this.fieldConfig, "options", options);
        },
        setResult(result) {
            this.showResults = false;
            this.searchQuery = null;

            this.$emit("input",
                result[this.fieldConfig.optionValueField]
            );
        },
        previousItem() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        nextItem() {
            if (this.arrowCounter < this.filteredResults.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onEnter() {
            const result = this.filteredResults[this.arrowCounter];
            this.setResult(result);
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        async getOptions() {
            if(this.currentOptionsUrl === '') {
                return;
            }

            // first check if the current input has focus or not
            // if yes, we will later re-focus after getting options
            let hasFocus = false;
            if(
                this.$refs.searchInput === document.activeElement
            ) {
                hasFocus = true;
            }

            this.loadingResults = true;
            try {
                let headers = { Accept: "application/json" };
                if(this.jsonApi) {
                    headers.Accept = "application/vnd.api+json";
                }
                const response = await this.apiClient.get(this.currentOptionsUrl, {
                        headers
                });
                let options = response.data;
                if (this.jsonApi) {
                    // parse the response and get the array of models
                    options = Parser.parseJSONAPIResponse(
                        response.data
                    ).getModels();
                }
                this.$set(this.fieldConfig, "options", options);
                this.$emit(
                    "optionsUpdated",
                    JSON.parse(JSON.stringify(options))
                );
                this.$emit(
                    'options-updated',
                    JSON.parse(JSON.stringify(options))
                );
                this.defaultField();
                this.loadingResults = false;
                if(hasFocus) { // if we had focus re-focus the input
                    this.$nextTick(() => {
                        this.$refs.searchInput.focus();
                    });
                }
            }catch(err) {
                console.log(err);
                window.notify.apiError(err);
                this.loadingResults = false;
            }

        },
        createItem() {
            if (!this.tagging) {
                return;
            }

            if (this.fieldConfig.optionsURL) {
                this.loading = true;
                var data = {};
                data[this.fieldConfig.fieldName] = this.search;
                this.apiClient
                    .post(this.fieldConfig.optionsURL, data)
                    .then(response => {
                        this.loading = false;

                        var option = response.data;
                        if (this.jsonApi) {
                            // parse the response and then only pull the attributes out of the model generated
                            option = Parser.parseJSONAPIResponse(response.data).toJSON();
                        }

                        this.fieldConfig.options.push(option);
                        this.setResult(option[this.fieldConfig.optionLabelField]);
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.$emit("create-tag", this.search);
            }
        },
        closeResults() {
            this.showResults = false;
        },
        deselectCurrentItem() {
            this.$emit("input", null);
        },
        defaultField() {
            try {
                var defaultField = false;
                if (!this.optionValue) {
                    defaultField = true;
                } else if (
                    typeof this.optionValue === "object" &&
                    Object.keys(this.optionValue).length === 0
                ) {
                    defaultField = true;
                } else if (this.optionValue === this.fieldConfig.default) {
                    defaultField = true;
                }
                if (defaultField && this.fieldConfig.default) {
                    this.fieldConfig.options.forEach(option => {
                        if (
                            option[this.fieldConfig.optionLabelField] ===
                            this.fieldConfig.default
                        ) {
                            this.updateValue(option);
                        }
                    });
                }
            } catch (error) {
                //console.trace(error);
            }
        },
        paramsAreEmpty(params) {
            for (var key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key))
                    return false;
            }
            return true;
        }
    }
};
</script>
