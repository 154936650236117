<script>
import FormField from "../../mixins/FormField";
import FormConfig from "../../mixins/FormConfig";
import UpdatesValuesAndConditions from "../../mixins/UpdatesValuesAndConditions";
export default {
    name: "FormColumnGroup",
    mixins: [FormField, FormConfig, UpdatesValuesAndConditions],

    props: {

    },

    data() {
        return {
        }
    },

    created() {

        this.generateConditionValues();
    },

    computed: {
        columnCount() {
            let columnCount = 0;
            this.children.forEach((field) => {
                if (field.widget === "column") {
                    columnCount++;
                }
            });

            if (columnCount === 0) {
                columnCount = 1;
            }

            return columnCount;
        },
        columnWidth() {
            if (this.columnCount.value === 0) {
                return null;
            }

            return "w-1/" + this.columnCount;
        },
        visibleChildren() {

            const visibleChildren = this.children.filter(child => {
                return child.visible;
            });

            visibleChildren.forEach((childField) => {
                childField.children = this.form.formConfig.fields.filter((field) => {
                    return field.parent_id == childField.id;
                });
            });

            return visibleChildren;

        }
    }

}
</script>
<template>
    <div :class="{ 'flex': columnCount > 1 }">
        <component
            v-for="field in visibleChildren"
            :key="field.id"
            :is="getFormFieldComponent(field.widget)"
            v-show="field.visible && conditionValues[field.name]"
            :field-name="field.name"
            :value="getFieldValue(form.data, field)"
            @input="(newVal) => updateValueAndConditionals(newVal, field)"
            @options-updated="
                (newOptions) => updateOptionsForField(newOptions, field)
            "
            :children="field.children || null"
            :class="columnWidth + ' ' + 'm-2'"
        ></component>
    </div>
</template>
