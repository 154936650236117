import {mapState} from "vuex";

export default {

    inject: {
        mpid: {
            default() {
                return this.$store.state.mp_layout.assessmentMPID
            }
        }
    },


    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
        ]),
        measureSummary() {
            return this.$store.getters['mp_layout/getMeasureSummary'](this.mpid)
        },


        // Features
        add_measures() {
            return this.$store.getters['mp_layout/measurePickerCan']('add_measures', this.mpid)
        },
        edit_measures() {
            return this.$store.getters['mp_layout/measurePickerCan']('edit_measures', this.mpid)
        },
        delete_measures() {
            return this.$store.getters['mp_layout/measurePickerCan']('delete_measures', this.mpid)
        },
        show_scenarios() {
            return this.$store.getters['mp_layout/measurePickerCan']('show_scenarios', this.mpid)
        },
        select_and_create_scenarios() {
            return this.$store.getters['mp_layout/measurePickerCan']('select_and_create_scenarios', this.mpid)
        },
        show_zero_warning() {
            return this.$store.getters['mp_layout/measurePickerCan']('show_zero_warning', this.mpid)
        },
        update_costs() {
            return this.$store.getters['mp_layout/measurePickerCan']('update_costs', this.mpid)
        },
        select_measures() {
            return this.$store.getters['mp_layout/measurePickerCan']('select_measures', this.mpid)
        },
        only_selected_measures() {
            return this.$store.getters['mp_layout/measurePickerCan']('only_selected_measures', this.mpid)
        },
        update_room_hours() {
            return this.$store.getters['mp_layout/measurePickerCan']('update_room_hours', this.mpid)
        },
        showProjectMeasureDetails() {
            return this.$store.getters['mp_layout/showProjectMeasureDetails'](this.mpid)
        },
        measuresSelectable() {
            return this.$store.getters['mp_layout/measurePickerCan']('select_measures', this.mpid)
        },
        fullPicker() {
            return this.$store.getters['mp_layout/getFullPicker'](this.mpid);
        },
        mp_phase() {
            return this.$store.getters['mp_layout/getPhase'](this.mpid);
        },
        mp_show_review_filters() {
            return this.$store.getters['mp_layout/measurePickerCan']('show_review_filters', this.mpid)
        },
        mp_project() {
            return this.$store.getters['mp_layout/getProject'](this.mpid);
        }
    }


}
