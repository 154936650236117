<script>
import {mapMutations, mapState} from 'vuex';
import {faPlus, faTimes, faExclamationTriangle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import Equipment from "etap/admin/classes/models/equipment";
import EquipmentMeasure from "etap/admin/classes/models/equipmentMeasure";

export default {


    data() {
        return {
            spinnerIcon: faSpinner,
            plusIcon: faPlus,
            timesIcon: faTimes,
            warningIcon: faExclamationTriangle,
            measuresForAssociation: [],
            loadingFormView: false,
            selectedEquipmentMeasureId: null,
            showNewAssociationModal: false,
            selectedNewAssociationMeasureId: null,
            showRemoveAssociationModal: false,
            showConfirmDeleteModal: false,
            deleting: false,
            showConfirmViewChangeModal: false,
            confirmingEquipmentMeasureToChangeTo: null,
        }
    },

    async created() {

        if(
            !this.mainViewArgs.equipmentId &&
            !this.mainViewArgs.defaultAttributes &&
            !this.mainViewArgs.defaultAttributes.measure
        ) {
            window.notify.error('Missing editing equipment Id');
            return;
        }

        if(this.mainViewArgs.equipmentId && !this.editingEquipment) {

            // get equipment from the api
            await this.$store.dispatch('equipment_library/getEquipmentForId', {
                equipmentId: this.mainViewArgs.equipmentId,
                include: [
                    'equipment_measures',
                    'equipment_measures.measure',
                    'files'
                ]
            })
        }

        if(this.editingEquipment && this.equipmentMeasures.length > 0) {
            this.$store.commit('equipment_library/equipmentFormDirty', false);
            await this.showMeasureForm(this.equipmentMeasures[0].id);
        }

    },

    computed: {
        ...mapState('equipment_library', [
            'mainViewArgs',
            'equipmentLibraryConfiguration',
            'equipmentFormDirty',
        ]),
        editingEquipment() {
            if (!parseInt(String(this.mainViewArgs.equipmentId))) {
                return this.getDefaultEquipmentRecord();
            }

            return this.$store.getters['equipment_library/getEquipmentById'](this.mainViewArgs.equipmentId);
        },
        equipmentMeasures() {

            if (!this.editingEquipment || !this.editingEquipment.equipment_measures) {
                return [];
            }

            return this.editingEquipment.equipment_measures.getModels().map(equipmentMeasure => {
                let labelName = equipmentMeasure.measure?.name;
                if (this.approvalEnabled) {
                    const approvalLookup = this.approvalStatuses.find(approvalStatus => {
                        return approvalStatus.id === equipmentMeasure.approval_status_id;
                    })
                    if (approvalLookup) {
                        labelName += ' (' + approvalLookup.name + ')';
                    } else {
                        labelName += ' (No Status Assigned)';
                    }
                }
                equipmentMeasure.labelName = labelName;

                return equipmentMeasure;
            });
        },
        currentEquipmentMeasure() {
            if(this.selectedEquipmentMeasureId === null) {
                return null;
            }

            return this.equipmentMeasures.find(equipmentMeasure => {
                return equipmentMeasure.id === this.selectedEquipmentMeasureId;
            }) ?? null
        },
        equipmentMeasureFormConfiguration() {
            if(!this.currentEquipmentMeasure) {
                return null;
            }

            const equipmentMeasureFormConfiguration =  this.$store.state.form_configurations
                .formConfigurationMeasures.find(formConfigurationMeasure => {
                    if (formConfigurationMeasure.measure_id === this.currentEquipmentMeasure.measure_id) {
                        return true;
                    }
                });

            return equipmentMeasureFormConfiguration?.form_configuration;
        },
        approvalEnabled() {
            if (
                !this.equipmentLibraryConfiguration.approval ||
                !this.equipmentLibraryConfiguration.approval.enabled
            ) {
                return false;
            }

            return true;
        },
        approvalStatuses() {
            if (
                !this.approvalEnabled
            ) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](
                this.equipmentLibraryConfiguration.approval.approvalStatuses
            );
        },
        hasNewEquipmentMeasure() {
            return this.equipmentMeasures.find(equipmentMeasure => {
                return !equipmentMeasure.id;
            }) !== undefined;
        },
        measureEquipmentEligibilityItem() {
            if (!this.currentEquipmentMeasure) {
                return null;
            }
            return this.$store.getters['equipment_library/getMeasureEquipmentEligibilityItemForMeasureId'](
                this.currentEquipmentMeasure.measure_id
            )
        },
        formActions() {

            let actions = [];
            if (!this.editingEquipment || !this.editingEquipment.id) {
                actions.push({name: 'save', label: 'Create', action: 'submitForm'});
            } else {
                actions.push({name: 'save', label: 'Save', action: 'submitForm'});
                actions.push({name: 'delete', label: 'Delete Equipment', action: 'deleteEquipment'});
            }

            return actions;
        },
        recordIsDeleted() {
            return this.editingEquipment?.deleted_at !== null && this.editingEquipment?.deleted_at !== undefined;
        }
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView',
            'upsertEquipmentRecord',
        ]),
        async showMeasureForm(equipmentMeasureId) {
            const equipmentMeasure = this.equipmentMeasures.find(equipmentMeasureToFind => {
                return equipmentMeasureToFind.id === parseInt(equipmentMeasureId);
            })

            if (!equipmentMeasure) {
                window.notify.error("Unable to find measure to show form for");
                return;
            }

            if(this.equipmentFormDirty) {
                this.showConfirmViewChangeModal = true;
                this.confirmingEquipmentMeasureToChangeTo = equipmentMeasureId;
                return;
            }

            this.loadingFormView = true;
            this.selectedEquipmentMeasureId = equipmentMeasureId;

            if (!this.equipmentMeasureFormConfiguration) {
                await this.$store.dispatch('form_configurations/getFormConfigurationsForMeasuresAndType', {
                    measureIds: [equipmentMeasure.measure_id],
                    formType: 'equipment',
                    onlyLatestFormConfiguration: 1
                })
            }

            await this.$store.dispatch('equipment_library/getMeasureEligibility', {
                measureId: equipmentMeasure.measure_id
            });
            this.loadingFormView = false;

            this.$nextTick(() => {
                $('span[title]').uitooltip({});
            })
        },
        measureFormChangeConfirmed() {
            if(
                !this.confirmingEquipmentMeasureToChangeTo
            ) {
                window.notify.error('There is no equipment measure to change to');
                return
            }

            this.$store.commit('equipment_library/equipmentFormDirty', false);
            this.showConfirmViewChangeModal = false;
            this.showMeasureForm(this.confirmingEquipmentMeasureToChangeTo);
        },
        getDefaultEquipmentRecord() {

            const defaultAttributes = JSON.parse(JSON.stringify(this.mainViewArgs.defaultAttributes));

            let measure = defaultAttributes.measure;
            if(!measure || typeof measure !== 'object') {
                throw new Error("Missing measure for new equipment record");
            }

            delete defaultAttributes.measure;

            const equipmentRecord = new Equipment(
                defaultAttributes
            );

            equipmentRecord.equipment_measures.add(
                new EquipmentMeasure({
                    id: 0,
                    equipment_id: equipmentRecord.id,
                    measure_id: measure.id,
                    approval_status_id: null,
                    measure: measure,
                })
            )

            return equipmentRecord;
        },
        createdEquipmentForMeasure(createdEquipment) {
            this.selectedEquipmentMeasureId = null;
            this.upsertEquipmentRecord(createdEquipment);
            this.setMainView({
                mainView: 'equipment-form',
                mainViewArgs: {
                    equipmentId: createdEquipment.id,
                }
            });

            window.notify.success('Equipment created successfully');
            this.$store.commit('equipment_library/equipmentFormDirty', false);
            this.showMeasureForm(this.editingEquipment.equipment_measures.last().id);
        },
        updatedEquipmentForMeasure(updatedEquipment) {
            let currentSelectedEquipmentMeasureId = this.selectedEquipmentMeasureId;
            this.selectedEquipmentMeasureId = null;
            this.$store.commit('equipment_library/upsertEquipmentRecord', updatedEquipment);

            if(!currentSelectedEquipmentMeasureId) { // i.e. was a new equipment measure id
                this.selectedEquipmentMeasureId = this.editingEquipment.equipment_measures.last()?.id;
                window.notify.success('Equipment associated and updated successfully');
            } else {
                this.selectedEquipmentMeasureId = currentSelectedEquipmentMeasureId;
                window.notify.success('Equipment updated successfully');
            }
            this.$store.commit('equipment_library/equipmentFormDirty', false);
        },
        storeMeasuresForAssociation(measures) {
            this.measuresForAssociation = measures;
        },
        equipmentFormIsDirty() {
            this.$store.commit('equipment_library/equipmentFormDirty')
        },
        associateToMeasure() {

            const measure = this.measuresForAssociation.find(measure => {
                return measure.id === this.selectedNewAssociationMeasureId;
            })

            if (!measure) {
                window.notify.error('Unable to find measure to associate');
                return;
            }

            const currentEquipmentMeasure = this.equipmentMeasures.find(equipmentMeasure => {
                return equipmentMeasure.measure_id === measure.id;
            })

            if(
                currentEquipmentMeasure
            ) {
                window.notify.error('Equipment is already associated with this measure', 'Already Added');
                this.$store.commit('equipment_library/equipmentFormDirty', false);
                this.showNewAssociationModal = false;
                this.showMeasureForm(currentEquipmentMeasure.id)
                this.selectedNewAssociationMeasureId = null;
                return;
            }

            this.$store.commit('equipment_library/associateMeasureWithEditingEquipment', {
                equipmentId: this.editingEquipment.id,
                measure,
            })

            this.$store.commit('equipment_library/equipmentFormDirty', false);
            this.showMeasureForm(
                this.equipmentMeasures[this.equipmentMeasures.length - 1].id
            )

            this.selectedNewAssociationMeasureId = null;
            this.showNewAssociationModal = false;
            this.selectedNewAssociationMeasureId = null;
        },
        async removeAssociationFromCurrentMeasure() {

            if(!this.editingEquipment.id) {
                window.notify.error('You can only remove measures from created equipment');
                return;
            }

            this.deleting = true;
            this.showRemoveAssociationModal = false;
            try {
                await this.$store.dispatch(
                    'equipment_library/removeCurrentEquipmentFromMeasure',
                    {
                        equipmentId: this.editingEquipment.id,
                        equipmentMeasure: this.currentEquipmentMeasure
                    }
                )
                this.selectedEquipmentMeasureId = null;
                await this.showMeasureForm(
                    this.equipmentMeasures[this.equipmentMeasures.length - 1].id
                )
                window.notify.success('Equipment association removed successfully');
            } catch(err) {
                window.notify.apiError(err);
            } finally{
                this.deleting = false;
            }
        },
        async deleteEquipment() {
            this.deleting = true;
            this.showConfirmDeleteModal = false;
            await this.$store.dispatch('equipment_library/deleteEquipment', this.editingEquipment.id);
            window.notify.success('Equipment deleted successfully');
            this.deleting = false;
        },
        async restoreEquipment() {
            this.deleting = true;
            await this.$store.dispatch('equipment_library/restoreEquipment', this.editingEquipment.id);
            window.notify.success('Equipment restored successfully');
            this.deleting = false;
        }
    }
}
</script>
<template>
    <div>
        <div v-if="editingEquipment">
            <div class="bg-secondary p-4 m-1 mb-4 text-white" >
            <span v-if="!editingEquipment || !editingEquipment.id">
                Add New Equipment Record
            </span>
                <span v-else>
                Edit Existing Equipment
            </span>
            </div>
            <div class="p-4 bg-lightest-gray">
                <div class="bg-yellow p-4" v-if="recordIsDeleted">
                    <p>This equipment is deleted.   You will not be able to perform any action on it unless you restore the equipment record.</p>
                    <button class="button mt-4" @click="restoreEquipment" :disabled="deleting">
                        <font-awesome-icon :icon="spinnerIcon" spin v-if="deleting"></font-awesome-icon>
                        Restore Equipment
                    </button>
                </div>
                <div class="form-group flex items-center mt-4">
                    <label class="">Equipment Name</label>
                    <span class="mb-1 ml-4">{{ editingEquipment?.name }}</span>
                </div>

                <div class="flex justify-between items-center text-center border-b mt-2 shadow bg-white p-2">
                    <div class="flex items-center text-center w-1/2">
                        <form-select
                            v-if="equipmentMeasures.length > 3"
                            class="w-full text-left"
                            label="Associated Measures"
                            :options="equipmentMeasures"
                            field-name="equipmentMeasureSelector"
                            :value="currentEquipmentMeasure?.id"
                            @input="showMeasureForm"
                            option-label-field="labelName"
                            option-value-field="id"
                        ></form-select>
                        <a
                            v-else
                            v-for="equipmentMeasure in equipmentMeasures"
                            class="p-2 px-3 cursor-pointer m-1 rounded"
                            :class="{
                            'bg-primary text-white': currentEquipmentMeasure?.id === equipmentMeasure.id ,
                            'bg-lighter-gray hover:bg-lightest-gray text-gray-900': currentEquipmentMeasure?.id !== equipmentMeasure.id
                        }"
                            @click="showMeasureForm(equipmentMeasure.id)"
                        >
                            <span>{{ equipmentMeasure.labelName }}</span>
                            <span v-if="!equipmentMeasure.id">
                            (New)
                        </span>
                        </a>
                    </div>

                    <div class="flex items-center">
                        <button
                            class="button-secondary"
                            v-if="equipmentMeasures.length > 1"
                            @click="showRemoveAssociationModal = true"
                            :disabled="deleting || recordIsDeleted"
                        >
                            <font-awesome-icon :icon="spinnerIcon" spin v-if="deleting"></font-awesome-icon>
                            <font-awesome-icon :icon="timesIcon" v-else></font-awesome-icon>&nbsp;
                            Remove from measure
                        </button>
                        <button
                            class="button p-2 px-4 rounded"
                            v-if="editingEquipment && editingEquipment.id"
                            @click="showNewAssociationModal = true"
                            :disabled="hasNewEquipmentMeasure || deleting || recordIsDeleted"
                        >
                            <font-awesome-icon :icon="plusIcon"></font-awesome-icon>&nbsp;Add Measure Association
                        </button>
                    </div>
                </div>

                <div class="p-2 bg-white">
                    <div v-if="!loadingFormView && equipmentMeasureFormConfiguration && currentEquipmentMeasure">
                        <div
                            v-if="measureEquipmentEligibilityItem && measureEquipmentEligibilityItem.equipment_eligibility_requirements_text">
                            <div class="bg-lightest-gray p-4 rounded">
                                <div class="text-center">
                                    <h3 class="text-xl">Equipment Eligibility Requirements</h3>
                                </div>
                                <div class="mt-4">
                                    <div
                                        v-html="measureEquipmentEligibilityItem.equipment_eligibility_requirements_text"></div>
                                </div>
                            </div>
                        </div>
                        <vue-form
                            class="mt-4"
                            :form-config="equipmentMeasureFormConfiguration"
                            :form-data="Object.assign({}, editingEquipment.toJSON(), {
                                // this is left for legacy reasons.
                                // Many of the configured forms have a required `measure id` field on them
                                measure_id: currentEquipmentMeasure.measure_id
                            })"
                            @created="createdEquipmentForMeasure"
                            @updated="updatedEquipmentForMeasure"
                            @deleteEquipment="showConfirmDeleteModal = true"
                            :formSubmitUrl="'/api/measures/' + currentEquipmentMeasure.measure_id + '/equipment' + (editingEquipment.id ? '/' + editingEquipment.id : '')"
                            :use-json-api="true"
                            :show-close-icon="false"
                            controls-area="top-right"
                            :actions="formActions"
                            :disabled="deleting || recordIsDeleted"
                            :additional-json-api-includes="['equipment_measures', 'equipment_measures.measure']"
                            @changed="equipmentFormIsDirty"
                        />
                    </div>
                    <div v-else class="text-center flex justify-center mb-4">&nbsp;
                        <div class="loading-bar w-3/4 text-white p-12 rounded">
                            <span class="ml-4">Loading ...</span>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center flex justify-center mb-4">&nbsp;
            <div class="loading-bar w-3/4 text-white p-12 rounded">
                <span class="ml-4">Loading ...</span>&nbsp;
            </div>
        </div>
        <modal
            v-if="showNewAssociationModal"
            @close="showNewAssociationModal = false"
            modal-width="60%"
            :close-on-click-away="false"
        >
            <template #header>
                Associate To New Measure
            </template>
            <template #body>
                <div class="min-h-35vh">
                    <form-autocomplete
                        field-name="newEquipmentMeasureId"
                        label="Measure"
                        option-label-field="name"
                        option-value-field="id"
                        options-url="/api/measures"
                        :use-json-api="true"
                        v-model="selectedNewAssociationMeasureId"
                        @optionsUpdated="storeMeasuresForAssociation"
                        :find-in-form="false"
                    />
                    <button class="button" @click="associateToMeasure">
                        Associate
                    </button>
                </div>
            </template>
        </modal>
        <modal
            v-if="showRemoveAssociationModal"
            @close="showRemoveAssociationModal = false"
            modal-width="50%"
            :close-on-click-away="false"
            :is-confirm="true"
            @confirmed="removeAssociationFromCurrentMeasure"
        >
            <template #header>
                Remove Measure Association?
            </template>
            <template #body>
                <div>
                    <div class="flex items-center justify-between">
                        <font-awesome-icon :icon="warningIcon" size="2x" class="text-warning mr-4"></font-awesome-icon>
                        <p>
                            Are you sure you want to remove this measure from the equipment? Please note that removing
                            the association only removes it from showing up on the available equipment for a measure. It
                            does not remove attributes or remove it entirely.
                        </p>
                    </div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showConfirmDeleteModal"
            @close="showConfirmDeleteModal = false"
            modal-width="50%"
            :close-on-click-away="false"
            :is-confirm="true"
            @confirmed="deleteEquipment"
        >
            <template #header>
                Delete Equipment Record?
            </template>
            <template #body>
                <div>
                    <div class="flex items-center justify-between">
                        <font-awesome-icon :icon="warningIcon" size="2x" class="text-warning mr-4"></font-awesome-icon>
                        <p>
                            Are you sure you want to delete this equipment record?  Please note that deleting the equipment
                            will not remove it from projects and measures that it's already been added to.   It will only
                            remove it from the list of possible items to add to a project or measure.
                        </p>
                    </div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showConfirmViewChangeModal"
            :is-confirm="true"
            @confirmed="measureFormChangeConfirmed"
            @close="showConfirmViewChangeModal = false"
        >
            <template #header>
                <h3>Are you sure you want to leave this page?</h3>
            </template>
            <template #body>
                <p>
                    You have unsaved changes. If you leave this page, your changes will be lost.
                </p>
            </template>
        </modal>
    </div>
</template>
