<template>
  <div class="form-file-dropzone">
    <file-pond
      name="file"
      ref="filePondInstance"
      :allow-multiple="true"
      :server="server"
      :onprocessfile="removeFileFromInstance"
      :max-files="maxFiles"
      :max-parallel-uploads="1"
      credits="false"
    ></file-pond>
  </div>
</template>
<script>
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css";
// Create component
const FilePond = vueFilePond()
import axios from 'axios';

export default {
  props: {
    maxFiles: Number,
    uploadMultiple: {
      type: Boolean,
      default: true
    },
    uploadApiUrl: String,
    uploadParams: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  components: {
    FilePond
  },

  methods: {
    handleSending(file, xhr, formData) {

    },
    handleSuccess(fileInfo) {
      this.$emit('addFile', fileInfo);
      window.notify.message("Successfully uploaded file", "success");
    },
    removeFileFromInstance(err, file) {
      file.abortLoad();
    }
  },


  data() {
    var vm = this;

    return {
      server: {
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort
        ) => {

          const response = await axios.post('/api/signed-storage-url', {
              'content_type': file.type
          });

          const signedResponse = response.data
          if(!signedResponse.meta) {
            throw new Error("Invalid signed response")
          }
          const signedInfo = signedResponse.meta
          const headers = signedInfo.headers;

          if ('Host' in headers) {
            delete headers.Host;
          }

          const request = new XMLHttpRequest();
          request.open('PUT', signedInfo.url);
          for (const rHeader in headers) {
            if (Object.hasOwnProperty.call(headers, rHeader)) {
              request.setRequestHeader(rHeader, headers[rHeader]);
            }
          }

          request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
          };

          request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
              signedInfo.extension = file.name.split('.').pop();
              signedInfo.name = file.name;
              signedInfo.contentType = file.type
              signedInfo.temporary = true;
              load(signedInfo.uuid);
              vm.handleSuccess(signedInfo);
            } else {
              console.error(request.status, request.responseText, request);
              error('unable to upload file');
            }
          };

          request.send(file);
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button
              request.abort();
              abort();
            },
          };
        },
        revert: async (
          uniqueFileId,
          load,
          error,
        ) => {
          try {
            await axios.delete(
              this.uploadApiUrl + '/' + uniqueFileId
            );
          }catch(err) {
            error(err.toString());
          }

          load();
        }
      }
    }
  }
};
</script>
