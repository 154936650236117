import Vue from 'vue';

export const setEditFormConfig = (state, {formConfig, mpid}) => {
    state.measure_pickers[mpid].editFormConfig = formConfig;
}

export const addCachedFormConfig = (state, {measureId, formConfiguration, project}) => {

    if(!state.cachedFormConfigs[project.program_year_id]) {
        Vue.set(state.cachedFormConfigs, project.program_year_id, {});
    }

    Vue.set(state.cachedFormConfigs[project.program_year_id], measureId, formConfiguration);
}

export const setMPIDForAssessment = (state, mpid) => {
    state.assessmentMPID = mpid
}

export const defaultMeasurePicker = (state, mpid) => {

    let defaultConfig = JSON.parse(JSON.stringify(state.default_measure_picker_configuration))
    Vue.set(state.measure_pickers, mpid, defaultConfig);
}

export const setMeasureCalculationConfiguration = (state, {mpid, configuration}) => {
    state.measure_pickers[mpid].calculationConfiguration = configuration;
}

export const setMeasurePickerType = (state, {mpid, pickerType}) => {
    state.measure_pickers[mpid].pickerType = pickerType;
}

export const setFeature = (state, {mpid, feature, value}) => {
    state.measure_pickers[mpid].features[feature] = value;
}

export const setMainView = (state, {mpid, setView}) => {
    state.measure_pickers[mpid].mainView = setView;
}

export const setShowingProjectMeasureDetailsForm = (state, {mpid, show}) => {
    state.measure_pickers[mpid].showingProjectMeasureDetailsForm = show;
}

export const updateProject = (state, {mpid, project}) => {
    state.measure_pickers[mpid].project = project;
}

export const setPhase = (state, {mpid, phase}) => {
    state.measure_pickers[mpid].phase = phase;
}

export const initMeasureList = (state, {mpid, measureList}) => {
    state.measure_pickers[mpid].measureList = measureList;
}

export const updateMeasureSummary = (state, {mpid, measureSummary}) => {
    state.measure_pickers[mpid].measureSummary = measureSummary;
}

export const toggleProjectMeasureDetails = (state, mpid) => {
    state.measure_pickers[mpid].showProjectMeasureDetails = !state.measure_pickers[mpid].showProjectMeasureDetails;
}

export const toggleFullPicker = (state, mpid) => {
    state.measure_pickers[mpid].fullPicker = !state.measure_pickers[mpid].fullPicker;
}

export const setCurrentProjectMeasure = (state, {mpid, projectMeasure}) => {
    state.measure_pickers[mpid].currentProjectMeasure = projectMeasure
}

export const setRoomsExpanded = (state, {mpid, expanded}) => {
    for (let [roomId, currentExpandedValue] of Object.entries(state.measure_pickers[mpid].roomsExpanded)) {
        Vue.set(state.measure_pickers[mpid].roomsExpanded, roomId, expanded);
    }
}

export const setRoomExpanded = (state, {mpid, room, expanded}) => {
    Vue.set(state.measure_pickers[mpid].roomsExpanded, room.id, expanded);
}

export const updateRoomListFilter = (state, {mpid, filterName, filterValue} ) => {
    Vue.set(state.measure_pickers[mpid].roomListFilters, filterName, filterValue);
}


// export const isQC = state => {
//     state.isQC = true;
// }
//
// export const isLocked = state => {
//     state.isLocked = true;
// }

// export const setSelectable = (state, selectable) => {
//     state.measuresSelectable = selectable;
// }
//
// export const disableRoomHours = (state, disabled) => {
//     state.roomHoursDisabled = disabled;
// }

// export const setEditOnly = (state, disabled) => {
//     state.editOnly = disabled;
// }

