import apiClient from 'etap/admin/apiClient';
import Collection from 'etap/admin/classes/collection';
import User from 'etap/admin/classes/models/user';
import Parser from 'etap/admin/classes/jsonapi_parser';
import api from "etap/admin/apiClient";



export const search = (context, searchParams) => {

    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/users', {
            params: searchParams
        }).then( response => {

            context.commit('setSearchResults', Parser.parseJSONAPIResponse(response.data));
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const getUserWithRelationships = (context, {userId, relationships}) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/users/' + userId, {
            params: {
                includes: relationships
            }
        }).then(response => {
            context.commit('updateOrAddUser', Parser.parseJSONAPIResponse(response.data));
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const updateOrCreateUser = (context, {record, eagerLoads}) => {


    var method = 'post';
    var url = '/api/admin/users';
    if(record.id) {
        method = 'patch';
        url += '/' + record.id;
    }

    record.includes = eagerLoads;

    return new Promise((resolve, reject) => {
        apiClient[method](url, record).then(response => {
            var user = Parser.parseJSONAPIResponse(response.data);
            switch(method) {
                case 'patch':
                    context.commit('updateOrAddUser', user);
                    break;
                default:
                    if(!context.state.users) {
                        let userCollection = new Collection([user], {
                            model: User
                        });
                        context.commit('setUsers', userCollection);
                        return;
                    }

                    context.commit('updateOrAddUser',user);
            }
            resolve(user);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}


export const resetPassword = (context, {user, relationships}) => {

    return new Promise((resolve, reject) => {
        apiClient.patch('/api/admin/users/' + user.id + '/reset_password', {
            params: {
                includes: relationships
            }
        }).then(response => {
            var user = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddUser', user);
            resolve(user);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })

}

export const sendPasswordResetNotification = (context, {user, pwLink}) => {

    return new Promise((resolve, reject) => {
        apiClient.post('/api/admin/users/' + user.id + '/send_pw_reset_notification', {
            reset_link: pwLink
        }).then(response => {
            window.notify.message('Sent notification', 'success')
            resolve(Parser.parseJSONAPIResponse(response.data));
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });
    })
}

export const deleteUser = (context, user) => {

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/users/' + user.id).then(response => {
            context.commit('removeUser', user);
            resolve(Parser.parseJSONAPIResponse(response.data));
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const restoreUser = (context, {record, relationships}) => {

    var includes = [];
    if(Array.isArray(relationships) && relationships.length > 0) {
        includes = relationships;
    }

    return new Promise((resolve, reject) => {
        apiClient.patch('/api/admin/users/' + record.id + '/restore', {includes}).then(response => {
            var restoredUser = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddUser', restoredUser);
            resolve(restoredUser);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}


export const addCompanyToUser = (context, {userId, owner, companyId}) => {

    return new Promise((resolve, reject) => {
        apiClient.post('/api/admin/users/' + userId + '/companies', {
            companyId,
            owner
        }).then(response => {
            var company = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddCompanyToUser', {company, userId});
            resolve(company);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const removeCompanyFromUser = (context, {userId, companyId}) => {

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/users/' + userId + '/companies/' + companyId).then(response => {
            context.commit('removeCompanyFromUser', {userId, companyId});
            resolve(true);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}


export const addRoleToUser = (context, {userId, roleId}) => {

    return new Promise((resolve, reject) => {
        apiClient.post('/api/admin/users/' + userId + '/roles', {
            roleId
        }).then(response => {
            var role = Parser.parseJSONAPIResponse(response.data);
            context.commit('addOrUpdateRoleToUser', {role, userId});
            resolve(role);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })

}

export const removeRoleFromUser = (context, {userId, roleId}) => {

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/users/' + userId + '/roles/' + roleId).then(response => {
            context.commit('removeRoleFromUser', {userId, roleId});
            resolve(true);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });

}
