<template>
    <div>
        <div class="vue-form text-center">
            <h3>Project Status</h3>
            <div class="text-left">
                <form-select
                    field-name="project_status"
                    :options="statusOptions"
                    option-label-field="name"
                    option-value-field="id"
                    v-model="selectedStatusId"
                    :show-multiselect-labels="false"
                    :show-label="false"
                    label="Project Status"
                    :allow-empty="false"
                    :disabled="disabled || updatingStatus ? 1 : 0"
                    class="w-full"
                ></form-select>
                <base64-wysiwyg
                    v-if="canUpdateStatusReasons"
                    field-name="status_reasons"
                    v-model="status_reasons"
                    :label="trans('audit_tool.universal.status_reasons')"
                    :disabled="disabled || updatingStatus ? 1: 0"
                ></base64-wysiwyg>
            </div>
            <button
                :disabled="updatingStatus"
                class="button m-2"
                @click="updateStatus"
                >
                <span v-if="updatingStatus">
                    Updating <span class="fa fa-spinner fa-spin"></span>
                </span>
                <span v-else>
                    Update
                </span>
            </button>
        </div>
    </div>
</template>
<script>

    import axios from 'axios';

    export default {
        props: {
            project: {
                required: true,
                type: Object
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                selectedStatusId: null,
                status_reasons: null,
                selectedStatus: {},
                updatingStatus: false
            }
        },

        created() {
            this.selectedStatus = this.statusOptions.find(status => {
                return status.id === this.project.status_id;
            });

            if(Object.entries(this.selectedStatus).length !== 0) {
                this.selectedStatusId = this.selectedStatus.id;
            }

            this.status_reasons = this.project.status_reasons;
        },

        watch: {
            selectedStatusId: {
                handler(newStatusId) {
                    this.selectedStatus = this.statusOptions.find(status => {
                        return status.id === newStatusId;
                    });
                }
            },
        },

        computed: {
            statusOptions() {
                return this.$store.state.lookups['project.status'];
            },
            canUpdateStatusReasons() {
                return this.$can('update_project_status_reasons');
            },
        },

        methods: {
            updateStatus(){
                if(this.updatingStatus) {
                    return; // don't re-call updating status
                }

                this.updatingStatus = true;

                axios.patch('/api/projects/'+ this.project.id +'/status', {
                    'status': this.selectedStatus.name,
                    'reasons': this.status_reasons
                }).then(response => {
                    this.updatingStatus = false;
                    window.notify.message(response.data.message, 'success');
                }).catch(error => {
                    window.notify.apiError(error);
                    this.updatingStatus = false;
                });
            }
        }
    }
</script>
