<template>
    <div class="reassign-project">
        <loading-overlay :loading="loadingData"></loading-overlay>
        <h3>Re-assign Project</h3>
        <div class="text-left vue-form">
            <form-select
                    field-name="company"
                    v-model="currentCompanyId"
                    label="Select a company to change companies"
                    :show-label="false"
                    :options="companies"
                    option-label-field="name"
                    option-value-field="id"
            ></form-select>
            <form-select
                    field-name="user"
                    v-model="currentUserId"
                    label="Select a user to change assigned user"
                    :show-label="false"
                    :options="users"
                    option-label-field="username"
                    option-value-field="id"
            ></form-select>
            <button class="inputbutton1" @click="reassignProject">Re-assign</button>
        </div>

        <modal
            v-if="confirmingChange"
            :is-confirm="true"
            modal-width="500px"
            @close="confirmingChange = false"
            @confirmed="runReassignment"
        >

            <h4 slot="header">
                Confirm Re-assignment?
            </h4>

            <div slot="body">
                <p>Are you sure you want to re-assign this project to a different company?</p><br />
                <p class="warning">Note: that this will remove all equipment from all project measures, and will re-calculate all project measures.</p>
            </div>

        </modal>
    </div>
</template>
<script>
    import LoadingOverlay from './../LoadingOverlay.vue';
    export default {

        components: {
            LoadingOverlay
        },

        props: {
            currentProjectCompany: {
                type: Object,
            },
            currentProjectUser: {
                type: Object,
                required: true,
            },
            project: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                loadingData: false,
                confirmingChange: false,
                currentCompanyId: null,
                currentUserId: null,
                companies: [],
                users: []
            }
        },

        watch: {
            'currentCompanyId': function(newCompany) {

                if(this.loadingData) { return; } // don't run if we're loading data already

                this.loadingData = true;
                this.getData(false).then( success => {
                    this.loadingData = false;
                });
            }
        },

        created() {
            this.loadingData = true;
            this.currentCompanyId = this.currentProjectCompany.id;
            this.currentUserId = this.currentProjectUser.id;

            this.getData().then( success => {
                this.loadingData = false;
            });
        },


        methods: {
            async getData(withCompanies = true) {
                try {

                    var dataPromises = [
                        axios.get('/api/users', {
                            params: {
                                company: this.currentCompanyId
                            }
                        })
                    ];

                    if(withCompanies) {
                        dataPromises.push(axios.get('/api/companies'));
                    }

                    let responses = await Promise.all(dataPromises);

                    // validate our response to be arrays
                    responses.forEach(response => {
                        if(!Array.isArray(response.data)) {
                            throw new Error('Invalid data returned from api');
                        }
                    });

                    // users will always be run
                    this.users = responses[0].data;

                    if(withCompanies) { // responses are returned in the order that they were pushed into the promises array
                        this.companies = responses[1].data;
                    }

                    return true;
                }catch (error) {

                    console.error(error);
                    window.notify.apiError(error);

                    return false;
                }
            },
            reassignProject() {

                // determine if we should show warning with project re-assignment
                if(this.currentCompanyId !== this.currentProjectCompany.id) {
                    this.confirmingChange = true;
                }else {
                    this.runReassignment();
                }

            },
            runReassignment() {
                this.confirmingChange = false;
                this.loadingData = true; // not really loading data, but will allow the overlay to pop up.

                axios.post('/api/projects/' + this.project.id + '/re-assign', {
                    newCompany: this.currentCompanyId,
                    newUser: this.currentUserId
                }).then( response => {

                    this.loadingData = false;
                    window.notify.message(response.data.message, 'success');

                }).catch( error => {
                    window.notify.apiError(error);
                    this.loadingData = false;
                })

            }
        }

    }
</script>
