

export const getBaseURLForModel = state =>  {
    return (modelType, model) => {
        switch (modelType) {
            case 'project':
                return '/api/projects/' + model.id + '/checklist';
                break;
            case 'project_review':
                return '/api/projects/' + model.project_id + '/reviews/' + model.id + '/checklist';
                break;
            case 'project_inspection':
                return '/api/projects/' + model.project_id + '/inspections/' + model.id + '/checklist';
                break;
            default:
                console.error('Invalid Model for getting create url');
        }
    }
}

export const checklistItemsForModel = state => {
    return (model, type) => {

        if(!state.checklist_items[type]) {
            return [];
        }

        if (state.checklist_items[type][model.id]) {
            return state.checklist_items[type][model.id]
        }

        return [];
    }
}

export const checklistItemForModalAndConfigurationItem = (state, getters) => {
    return (model, type, checklistConfigurationItem) => {
        let checklistConfigurationItemsForModel = getters.checklistItemsForModel(model, type);

        if(!checklistConfigurationItemsForModel || checklistConfigurationItemsForModel.length === 0) {
            return null;
        }
        return checklistConfigurationItemsForModel.find(checklistItem => {
            return checklistItem.checklist_configuration_item_id === checklistConfigurationItem.id;
        });
    }
}

export const getChecklistConfigurationForProgramTrackAndYear = state => {

    return (programTrack, programYear) => {
        if (!state.checklist_configurations[programTrack.name]) {
            return [];
        }

        if (!state.checklist_configurations[programTrack.name][programYear.program_year]) {
            return [];
        }

        return state.checklist_configurations[programTrack.name][programYear.program_year];
    }
}


export const getChecklistConfigurationForType =(state, getters) => {

    return (programTrack, programYear, type) => {


        let configurationsForProgramYear = getters.getChecklistConfigurationForProgramTrackAndYear(programTrack, programYear);
        if(!configurationsForProgramYear || configurationsForProgramYear.length === 0) {
            return null;
        }

        return configurationsForProgramYear.find(configuration => {
            return configuration.pivot.type === type;
        });
    }

}
