<template>
    <div
        class="form-group"
        :class="{'flex justify-between items-center leader-line': flexDataInline}"
        :id="fieldName + '-readonly-field'"
    >
        <label :class="{'form-control-label': !flexDataInline, 'my-1': flexDataInline}">
            <span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required">
                &nbsp;&nbsp;(*)
              </span>
            <span
                v-if="withHelpIcon"
                :class="fieldConfig.field_extra.withIcon"
                :title="fieldConfig.field_extra.helpText"
            ></span>
        </label>
        <div class="">
            <span class="block ml-4" v-html="valueForField">&nbsp;</span>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
<script>
import {FormFieldMixin, HasOptionsMixin} from "laravel-vue-forms";

export default {
    mixins: [FormFieldMixin, HasOptionsMixin],

    name: "form-readonly",

    props: {
        displayInLine: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            flexDataInline: false,
            validateHasOptions: false
        }
    },

    created() {

        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name !== this.fieldName) {
                    return;
                }

                const fieldExtra = this.getFormFieldFieldExtra(field);
                if (fieldExtra.displayInLine) {
                    this.flexDataInline = true;
                }
            });

        } else {
            this.flexDataInline = this.displayInLine;
        }
    },

    computed: {
        valueForField() {
            let value = this.value;
            if(this.fieldConfig.options && this.fieldConfig.options.length > 0) {
                const foundOption = this.fieldConfig.options.find(option => {
                    if(option[this.fieldConfig.optionValueField] == this.value) {
                        return true;
                    }
                });

                if(foundOption) {
                    value = foundOption[this.fieldConfig.optionLabelField];
                } else {
                    value = '';
                }
            }

            return value;
        }
    }
};
</script>
<style scoped>

.leader-line {
    background-image: linear-gradient(to right, rgb(203 213 225) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}
</style>

