<script>
import {byString} from "laravel-vue-forms";
import {
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Parser from "etap/admin/classes/jsonapi_parser"

export default {
    props: {
        record: {
            required: true,
            type: Object,
        },
        args: {
            required: true,
            type: Object,
        }
    },

    data() {
        return {
            creatingProject: false,
            attaching: false,
            spinnerIcon: faSpinner,
        }
    },

    computed: {
        eligibilityConfig() {
            return this.args.eligibilityConfig;
        },
        displayFieldValues() {
            let displayFieldValues = [];
            if(Object.keys(this.eligibilityConfig) === 0 || !this.eligibilityConfig?.display_fields) {
                return displayFieldValues;
            }
            displayFieldValues = this.eligibilityConfig.display_fields.map(displayField => {
                return byString(this.record, displayField.value_field);
            });
            return displayFieldValues;
        },
        ineligibleText() {
            return this.eligibilityConfig?.ineligible_text || 'This customer is ineligible to create a project.';
        },
        projectForRow() {
            return this.args.project;
        }
    },

    methods: {
        handleProjectCreation() {
            this.creatingProject = true;

            if(
                !this.args.programTrackId ||
                !this.args.programYearId
            ) {
                this.creatingProject = false;
                window.notify.error("Unable to create project. Missing required data.");
                return;
            }

            axios.post('/api/projects/create', {
                program_track_id: this.args.programTrackId,
                program_year_id: this.args.programYearId,
                bill_account_data: this.record,
            }).then((res) => {
                if(res.data.active_url) {
                    window.location.replace(res.data.active_url);
                } else {
                    this.creatingProject = false;
                }
            }).catch((error) => {
                this.creatingProject = false;
                window.notify.apiError(error);
            });
        },
        async handleProjectAttachment() {
            if(
                !this.args.project
            ) {
                window.notify.error("Unable to attach customer account. Missing required data.");
                return;
            }

            this.attaching = true;
            try {
                const response = await axios.post('/api/projects/' + this.args.project.id + '/project_bill_accounts', {
                    billAccountData: this.record,
                });

                const projectResponse = Parser.parseJSONAPIResponse(response.data);


                if(window.Bus) {
                    window.Bus.$emit('project-bill-account-attached', projectResponse);
                }

                window.notify.success("Account attached to project successfully.");


            }catch (error) {
                window.notify.apiError(error);
            }finally {
                this.attaching = false;
            }
        }
    }
}
</script>
<template>
    <tr>
        <td v-for="displayFieldValue in displayFieldValues">
            {{ displayFieldValue }}
        </td>
        <td>
            <span v-if="record.isEligible">
                <span v-if="creatingProject || attaching" class="mr-1">
                    <font-awesome-icon
                        :icon="spinnerIcon"
                        :spin="true"
                    ></font-awesome-icon>
                </span>
                <button class="inputbutton1" @click="handleProjectCreation" :disabled="creatingProject" v-if="!projectForRow">
                    <span class="fa fa-plus"></span>
                    Create Project
                </button>
                <button class="button" @click="handleProjectAttachment" :disabled="attaching" v-else>
                    <span class="fa fa-plus"></span>
                    Attach to Project
                </button>
            </span>
            <span v-else>
                {{ ineligibleText }}
            </span>
        </td>
    </tr>
</template>
