
import {  getKeyForType, addItemToStateArray, updateItemValueOnStateArray, removeItemFromStateArray } from 'etap/utilities/utils';
import EquipmentMeasure from "../../admin/classes/models/equipmentMeasure";
import Equipment from "etap/admin/classes/models/equipment";



export const setEquipmentLibraryConfiguration = (state, configuration) => {
    state.equipmentLibraryConfiguration = configuration;
}

export const setMainView = (state, {mainView, mainViewArgs}) => {
    state.mainView = mainView;
    if(mainViewArgs && typeof mainViewArgs === 'object') {
        state.mainViewArgs = Object.assign({}, mainViewArgs);
    }
}

export const setEquipmentLibraryMeasure = (state, measure) => {
    state.equipmentLibraryMeasure = measure;
}

export const selectEquipmentForEquipmentLibraryMeasure = (state, equipment) => {
    state.selectedEquipmentForEquipmentLibraryMeasure = equipment;
}

export const selectQplRecord = (state, qplRecord) => {
    state.selectedQplRecord = qplRecord;
}

export const upsertMeasureEquipmentEligibilityItems = (state, items) => {

    if(typeof items.getModels == 'function') {
        items = items.getModels();
    }

    items.forEach(item => {
        const existingIndex = state.measureEquipmentEligibilityItems.findIndex( (existingItem) => {
            return existingItem.id === item.id;
        });

        if(existingIndex !== -1) {
            state.measureEquipmentEligibilityItems.splice(existingIndex, 1, item);
        } else {
            state.measureEquipmentEligibilityItems.push(item);
        }
    });
}

export const measureEligibilityItemsLoadedForMeasure = (state, measureId) => {
    state.measureEquipmentEligibilityItemsLoaded.push(measureId);
}

export const equipmentFormDirty = (state, dirty = true ) => {
    state.equipmentFormDirty = dirty;
}

export const upsertEquipmentRecord = (state, equipment) => {

    const existingEquipment = state.equipment.find( (existingEquipment) => {
        return existingEquipment.id === equipment.id;
    });

    if(!existingEquipment) {
        state.equipment.push(equipment);
        return;
    }

    if(typeof equipment.toJSON === 'function') {
        equipment = equipment.toJSON();
    }

    let equipmentMeasures = null;
    if(equipment.equipment_measures && equipment.equipment_measures.length > 0) {
        equipmentMeasures = equipment.equipment_measures;
    }

    delete equipment.equipment_measures
    existingEquipment.assign(equipment);

    if(equipmentMeasures && equipmentMeasures.length > 0) {
        // splice out any existing equipment measures that are not in the new equipment measures
        // this is useful for if we added a new association, saved, and now want to remove it
        existingEquipment.equipment_measures.getModels().forEach( (existingEquipmentMeasure, index) => {
            const newEquipmentMeasure = equipmentMeasures.find( (newEquipmentMeasure) => {
                return newEquipmentMeasure.id === existingEquipmentMeasure.id;
            });

            if(!newEquipmentMeasure) {
                existingEquipment.equipment_measures.remove(existingEquipmentMeasure);
            }
        });

        // add / update equipment measures passed into the mutation
        equipmentMeasures.getModels().forEach( (newEquipmentMeasure) => {
            const existingEquipmentMeasure = existingEquipment.equipment_measures.getModels().find( (existingEquipmentMeasure) => {
                return existingEquipmentMeasure.id === newEquipmentMeasure.id;
            });

            if(!existingEquipmentMeasure) {
                existingEquipment.equipment_measures.add(newEquipmentMeasure);
            } else {
                existingEquipmentMeasure.assign(newEquipmentMeasure);
            }
        });
    }
}


export const associateMeasureWithEditingEquipment = (state, {measure, equipmentId}) => {

    const existingEquipment = state.equipment.find( (existingEquipment) => {
        return existingEquipment.id === equipmentId;
    });

    if(!existingEquipment) {
        throw new Error("Could not find equipment with id " + equipmentId);
    }

    existingEquipment.equipment_measures.add( new EquipmentMeasure({
        id: 0,
        equipment_id: equipmentId,
        measure_id: measure.id,
        approval_status_id: null,
        measure: measure,
    }));
}


export const removeEquipmentMeasureFromEditingEquipment = (state, equipmentMeasure) => {

    const existingEquipment = state.equipment.find( (existingEquipment) => {
        return existingEquipment.id === equipmentMeasure.equipment_id;
    });

    if(!existingEquipment) {
        throw new Error("Could not find equipment with id " + equipmentMeasure.equipment_id);
    }

    if(typeof existingEquipment.equipment_measures.getModels === 'function') {
        existingEquipment.equipment_measures.remove(
            equipmentMeasure
        );
    } else {
        const equipmentMeasureIndexForMeasureId = existingEquipment.equipment_measures.findIndex(equipmentMeasure => {
            return equipmentMeasure.measure_id === equipmentMeasure.measure_id;
        });

        if(equipmentMeasureIndexForMeasureId !== -1) {
            existingEquipment.equipment_measures.splice(equipmentMeasureIndexForMeasureId, 1);
        }
    }
}





export const addMeasureGroup = (state, filter) => {
    addItemToStateArray(state, 'measureGroups', getKeyForType('measureGroups', filter.id), filter, 'id');
}

export const removeAllMeasureGroups = state => {
    state.measureGroups = {};
    state.measureGroupsList = [];
}

export const setSelectedMeasureGroup = (state, measureGroup) => {
    state.selectedMeasureGroup = measureGroup;
}

export const updateCurrentSearch = (state, currentSearch) => {
    state.currentSearch = currentSearch;
}

export const updateSearchBy = (state, searchBy) => {
    state.searchBy = searchBy;
}

export const toggleShowAllEquipment = (state) => {
    state.showAllEquipment = !state.showAllEquipment;
}

export const setEditingRecord = (state, record) => {
    state.editingRecord = record;
}

export const setEquipmentEditMeasure = (state, measure) => {
    state.equipmentEditMeasure = measure;
}

export const setEditFormConfig = (state, formConfig) => {
    state.editFormConfig = formConfig;
}

export const setIsLoading = (state, isLoading) => {
    state.isLoading = isLoading;
}

export const addEquipmentToMeasure = (state, equipmentMeasure) => {

    for (var measureGroupId in state.measureGroups) {
        var measureGroup = state.measureGroups[measureGroupId];

        measureGroup.measures.forEach(measure => {
            if(measure.id === equipmentMeasure.measure.id) {
                measure.equipment.push(equipmentMeasure.equipment);
            }
        });

    }
}

export const updateEquipmentToMeasure = (state, equipmentMeasure) => {
    for (var measureGroupId in state.measureGroups) {
        var measureGroup = state.measureGroups[measureGroupId];

        measureGroup.measures.forEach(measure => {
            if(measure.id === equipmentMeasure.measure.id) {
                measure.equipment.forEach((mEquipment, index, measureEquipmentArray) => {
                    if (mEquipment.id === equipmentMeasure.equipment.id) {
                        measureEquipmentArray.splice(index, 1, equipmentMeasure.equipment);
                    }
                });
            }
        });

    }
}

export const updateAllEquipmentInstances = (state, { equipment }) => {

    for (var measureGroupId in state.measureGroups) {
        var measureGroup = state.measureGroups[measureGroupId];

        measureGroup.measures.forEach(measure => {

            measure.equipment.forEach((mEquipment, index, mesaureEquipmentArray) => {
                if (mEquipment.id === equipment.id) {
                    mesaureEquipmentArray.splice(index, 1, equipment);
                }
            });

        });
    }
}

export const deleteMeasureEquipment = (state, equipmentMeasure) => {


    for (var measureGroupId in state.measureGroups) {
        var measureGroup = state.measureGroups[measureGroupId];

        measureGroup.measures.forEach(measure => {
            if(measure.id === equipmentMeasure.measure.id) {
                measure.equipment.forEach( (mEquipment, index, measureEquipmentArray) => {
                    if(mEquipment.id === equipmentMeasure.equipment.id) {
                        measureEquipmentArray.splice(index, 1);
                    }
                });
            }
        });
    }

}


export const setMeasureTabs = (state, measureTabs) => {
    state.measureTabs = measureTabs;
}

export const setEquipmentPricingJobs = (state, importJobs) => {
    state.importJobs = importJobs;
}

export const addEquipmentPricingJob = (state, job) => {
    state.importJobs.push(job);
}
