import axios from 'axios';

export const initializeMeasureList = (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid)

    return new Promise((resolve, reject) => {

        axios.get(context.rootState.apiPrefix + 'measures/project/' + project.id).then( response => {
            context.commit('initMeasureList', {
                mpid,
                measureList: response.data
            });
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });

    });
}

export const getMeasureSummary = (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    axios.get(context.rootState.apiPrefix + 'projects/' + project.id + '/measures/summary', {
        params: {
            scenario: currentScenario.id,
            phase: phase.id
        }
    }).then( response => {
        context.commit('updateMeasureSummary', {
            mpid,
            measureSummary: response.data
        })
    }).catch(error => {
        window.notify.apiError(error);
    });
}


export const showProjectMeasureDetails = (context, {projectMeasure, mpid}) => {

    let project = context.getters.getProject(mpid)

    context.dispatch('getProjectMeasureFormConfiguration', {projectMeasure, project, mpid}).then(formConfig => {
        context.commit('setEditFormConfig', {formConfig, mpid});
        context.commit('setShowingProjectMeasureDetailsForm', {mpid, show: true});
    });

}

export const hideProjectMeasureDetails = (context, mpid) => {
    context.commit('setShowingProjectMeasureDetailsForm', {mpid, show: false});
    context.commit('setEditFormConfig', {formConfig: {}, mpid});
    context.commit('setCurrentProjectMeasure', {mpid, projectMeasure: {}});
}

export const getProjectMeasureFormConfiguration = (context, {projectMeasure, project, mpid}) => {

    return new Promise((resolve, reject) => {


        if(context.getters.formConfigurationForProjectMeasure(projectMeasure, project)) {
            resolve(context.getters.formConfigurationForProjectMeasure(projectMeasure, project));
        }else {

            const params = {
                formType: 'project_measure',
            }

            if(mpid) {
                const pickerType = context.getters.getPickerType(mpid);
                if(pickerType === 'simple') {
                    params.formType = 'simple_project_measure';
                }
            }


            axios.get('/api/project_measures/' + projectMeasure.id + '/form_configuration', {
                params
            }).then( response => {
                context.commit('addCachedFormConfig', {
                    measureId: projectMeasure.measure.id,
                    project,
                    formConfiguration: response.data
                });
                resolve(response.data)
            }).catch( error => {
                window.notify.apiError(error);
                reject(error);
            });
        }

    });

}

export const getProjectMeasureFormConfigurationsForMeasureIds = (context, {measureIds, project, mpid}) => {

    return new Promise((resolve, reject) => {

        // filter already loaded form configurations;
        const cachedFormConfigsForProject = context.state.cachedFormConfigs[project.program_year_id];
        measureIds = measureIds.filter(measureId => {
            if(cachedFormConfigsForProject && cachedFormConfigsForProject[measureId]) {
                return false;
            }

            return true;
        });

        if(!measureIds || measureIds.length === 0){
            resolve();
            return;
        }

        let params = {
            measureIds
        }

        const pickerType = context.getters.getPickerType(mpid);
        if(pickerType === 'simple') {
            params.formType = 'simple_project_measure';
        }

        axios.get('/api/projects/' + project.id + '/form_configurations', {
            params
        }).then(response => {

            for (var measureId in response.data) {

                if(!response.data.hasOwnProperty(measureId)) continue;

                context.commit('addCachedFormConfig', {
                    measureId,
                    project,
                    formConfiguration: response.data[measureId]
                })
            }

        }).catch( error => {
            window.notify.apiError(error);
            reject(error);
        });

    })
}


const loadingConfigs = {};

export const getMeasureCalculationConfigForProjectAndPhase = async (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    try {

        if(loadingConfigs[project.id + '_' + phase.id]) {
            if(!loadingConfigs[project.id + '_' + phase.id].includes(mpid)) {
                loadingConfigs[project.id + '_' + phase.id].push(mpid);
            }
            return;
        }

        loadingConfigs[project.id + '_' + phase.id] = [mpid];

        const response = await axios.get('/api/projects/' + project.id + '/config', {
            params: {
                phaseId: phase.id,
                type: 'measure_configuration'
            }
        });
        let config = response.data?.meta;

        if(!config) {
            throw new Error("Unable to get measure calculation config.  Using phase: " + phase.id);
        }

        loadingConfigs[project.id + '_' + phase.id].forEach(mpid => {
            context.commit('setMeasureCalculationConfiguration', {
                mpid,
                configuration: JSON.parse(JSON.stringify(config))
            });
        });

    } catch (err) {
        window.notify.apiError(err);

        loadingConfigs[project.id + '_' + phase.id].forEach(mpid => {
            context.commit('setMeasureCalculationConfiguration', {
                mpid,
                configuration: []
            });
        });
    }

}
