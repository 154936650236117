import { map } from 'lodash';
import {  getKeyForType } from 'etap/utilities/utils';
import Fuse from 'fuse.js';

export const getMeasureEquipmentEligibilityItemForMeasureId = (state) => (measureId) => {
    return state.measureEquipmentEligibilityItems.find(item => item.measure_id === measureId);
}


export const getEquipmentById = (state) => (equipmentId) => {
    return state.equipment.find(equipment => equipment.id === equipmentId);
}
