import Address from 'etap/admin/classes/models/address';

export const setCompanies = (state, companies) => {
    state.companies = companies;
}

export const setSearchResults = (state, companies) => {
    state.companySearchResults =  companies;
}

export const updateOrAddCompany = (state, company) => {

    var existingCompany = state.companies.find({id: company.id});

    if(existingCompany) {
        existingCompany.assign(company);
    }else {
        state.companies.add(company)
    }
}

export const setCompaniesWithUsers = (state, companies) => {

    // perform push or update company record instead of complete overwrite or just updating
    companies.forEach(company => {
        updateOrAddCompany(state, company);
    });
}


export const updateCompanyAddress = (state, {companyId, address}) => {

    var company = state.companies.filter({id: companyId}).first();

    if(!company) {
        console.error('Invalid company to update address for: ' + companyId);
        return;
    }

    if(!company.company_address || Array.isArray(company.company_address.models)) {
        company.company_address = new Address(address);
        return;
    }

    console.log(company.company_address);

    company.company_address.assign(address);
}


export const removeCompany = (state, company) => {

    var existingCompany = state.companies.filter({id: company.id}).first();
    var existingSearchCompany = state.companySearchResults.filter({id: company.id}).first();

    if(!existingCompany && !existingSearchCompany) {
        console.error('Invalid company to remove : ' + company.id);
        return;
    }

    if(existingCompany) {
        state.companies.remove(existingCompany);
    }

    if(existingSearchCompany) {
        state.companySearchResults.remove(existingSearchCompany);
    }
}

export const updateOrAddUserToCompany = (state, {companyId, user}) => {

    var company = state.companies.filter({id: companyId}).first();

    if(!company) {
        console.error('Invalid company to add user for: ' + companyId);
        return;
    }

    company.ensureRelationshipIsSet('users');

    var existingUser = company.users.filter({id: user.id}).first();

    if(existingUser) {
        existingUser.assign(user);
    }else {
        company.users.add(user)
    }
}

export const removeUserFromCompany = (state, {userId, companyId}) => {

    var company = state.companies.filter({id: companyId}).first();

    if(!company) {
        console.error('Invalid company to add user for: ' + companyId);
        return;
    }

    company.ensureRelationshipIsSet('users');


    var existingUser = company.users.filter({id: userId}).first();

    if(existingUser) {
        company.users.remove(existingUser);
    }
}

export const addProgramTrackToCompany = (state, {companyId, programTrack, programYear}) => {

    var company = state.companies.filter({id: companyId}).first();

    if(!company) {
        console.error('Invalid company to add program track for: ' + companyId);
        return;
    }

    company.ensureRelationshipIsSet('valid_program_tracks');
    company.valid_program_tracks.add(programTrack);

    programYear = JSON.parse(JSON.stringify(programYear));
    Vue.set(programYear, 'pivot', programTrack.pivot);
    company.valid_program_years.add(programYear)
}

export const removeYearFromCompany = (state, programYear) => {

    var company = state.companies.filter({id: programYear.pivot.company_id}).first();

    if(!company) {
        console.error('Invalid company to remove program track for: ' + companyId);
        return;
    }

    company.ensureRelationshipIsSet('valid_program_years');
    company.valid_program_years.filter((py) => {
        return py.id === programYear.id && py.pivot.program_track_id === programYear.pivot.program_track_id;
    }).forEach( foundYear => {
        company.valid_program_years.remove(foundYear);
    });
}
