import Model from 'etap/admin/classes/models/model';
import FormField from 'etap/admin/classes/models/formField'

class FormConfiguration extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            title: null,
            type: null,
            entity_name: null,
            entity_model: null,
            options: {}
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

    relationships() {
        return {
            fields: FormField
        }
    }

}

export default FormConfiguration;
