<script>

import FormField from "laravel-vue-forms/src/mixins/FormField";
import FormConfig from "laravel-vue-forms/src/mixins/FormConfig";
import UpdatesValuesAndConditions from "laravel-vue-forms/src/mixins/UpdatesValuesAndConditions";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default {
    mixins: [FormField, FormConfig, UpdatesValuesAndConditions],
    name: "form-section",

    props: {
        children: {
            default() {
                return [];
            }
        },
        defaultOpen: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isOpen: false,
            chevronDownIcon: faChevronDown,
            chevronRightIcon: faChevronRight
        }
    },

    created() {
        this.generateConditionValues();

        if(this.fieldConfig.field_extra.open) {
            this.isOpen = this.fieldConfig.field_extra.open;
        } else if(this.defaultOpen) {
            this.isOpen = true;
        }
    },

    computed: {
        visibleChildren() {
            if(!this.children) {
                return [];
            }

            return this.children.filter(child => {
                return Boolean(child.visible);
            });
        }
    }

}

</script>
<template>
    <div>
        <div
            class="border-2 w-full rounded flex items-center p-2 shadow-md cursor-pointer flex items-center w-full"
            @click="isOpen = !isOpen"
        >
            <div class="flex justify-around">
                <div class="mx-2">
                    <font-awesome-icon
                        v-if="isOpen"
                        :icon="chevronDownIcon"
                        size="lg"
                    ></font-awesome-icon>
                    <font-awesome-icon
                        v-else
                        :icon="chevronRightIcon"
                        size="lg"
                    ></font-awesome-icon>
                </div>
            </div>
            <div>{{ fieldConfig.label }}&nbsp;</div>
        </div>
        <transition name="slide-fade">
            <div v-if="isOpen" class="ml-3">
                <component
                    v-for="field in visibleChildren"
                    :key="field.id"
                    :is="getFormFieldComponent(field.widget)"
                    v-show="conditionValues[field.name]"
                    :field-name="field.name"
                    :value="getFieldValue(form.data, field)"
                    @input="newVal => updateValueAndConditionals(newVal, field)"
                    @options-updated="newOptions => updateOptionsForField(newOptions, field)"
                    :children="field.children || null"
                ></component>
            </div>
        </transition>
    </div>
</template>
