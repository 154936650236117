import axios from 'axios';
import Vue from 'vue';


export const getScenarioRooms = (context, mpid) => {

    return new Promise((resolve, reject) => {

        let project = context.rootGetters['mp_layout/getProject'](mpid);
        let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
        let phase = context.rootGetters['mp_layout/getPhase'](mpid);

        var params = {
            phase: phase.id
        };

        if (context.rootGetters['mp_layout/measurePickerCan']('only_selected_measures', mpid)) {
            params.selected = 1
        }

        axios.get(
            context.rootState.apiPrefix + 'projects/' + project.id +
            '/scenarios/' + currentScenario.id + '/rooms',
            {
                params
            }
        ).then(response => {

            context.commit('setScenarioRooms', {
                rooms: response.data,
                scenario: currentScenario,
                phase,
            });

            resolve(response.data);

        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });

    });
}

export const getProjectMeasuresForRoom = async (context, {mpid, roomId, filters, include}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    var params = {
        phase: phase.id
    };

    if (context.rootGetters['mp_layout/measurePickerCan']('only_selected_measures', mpid)) {
        params.selected = 1
    }

    if (filters) {
        params = {...params, ...filters};
    }

    if(include) {
        params.include = include;
    }

    try {
        const response = await axios.get(
            context.rootState.apiPrefix + 'projects/' + project.id +
            '/scenarios/' + currentScenario.id + '/rooms/' + roomId + '/measures',
            {
                params
            }
        );

        context.commit('upsertProjectMeasures', {
            projectId: project.id,
            scenario: currentScenario,
            roomId: roomId,
            phase,
            projectMeasures: response.data,
        });


        let pmMeasureIds = context.getters.PMMeasureIds(currentScenario, phase, roomId)
        let pmEquipmentIds = context.getters.PMEquipmentIds(currentScenario, phase, roomId)

        context.dispatch('mp_layout/getProjectMeasureFormConfigurationsForMeasureIds', {measureIds: pmMeasureIds, project, mpid}, { root: true} );
        context.dispatch('mp_equipment/getMeasureEquipmentForEquipmentIds', {measureIds: pmMeasureIds, equipmentIds: pmEquipmentIds}, {root: true});
        context.dispatch('mp_technology/getMeasureTechnologiesForMeasureIdsAndProject', {measureIds: pmMeasureIds, project}, {root: true});

    }catch (error) {
        window.notify.apiError(error);
    }
}


export const canUpdateCosts = (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);

    axios.get(
        context.rootState.apiPrefix + 'projects/' + project.id + '/can_update_costs',
    ).then(response => {
        context.commit('canUpdateCosts', response.data);
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const addRoom = async (context, {mpid, roomName}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    try {
        const addRoomResponse = await axios.post(context.rootState.apiPrefix + 'projects/' + project.id +
            '/scenarios/' + currentScenario.id + '/rooms', {
            phase: phase.id,
            name: roomName
        });

        context.commit('addRoom', {room: addRoomResponse.data, scenario:currentScenario, phase});
        Vue.nextTick(() => {
            if(
                !window.document.querySelector("[data-roomid='" + addRoomResponse.data.id + "']")
            ) {
                return;
            }

            window.document.querySelector("[data-roomid='" + addRoomResponse.data.id + "']").scrollIntoView({
                behavior: 'smooth'
            });
        });
    }catch(err) {
        window.notify.apiError(err);
    }
}

export const updateRoom = (context, {payload, mpid}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    var request = {};
    if (typeof payload.newName !== 'undefined') {
        request.room_name = payload.newName
    } else if (typeof payload.newHours !== 'undefined') {
        request.room_operating_hours = payload.newHours;
    } else {
        return;
    }

    axios.patch(context.rootState.apiPrefix + 'projects/' + project.id +
        '/scenarios/' + currentScenario.id + '/rooms/' + payload.room.id, request).then(response => {
        context.commit('updateScenarioRoom', {scenarioRoom: response.data, scenario: currentScenario, phase});
    }).catch(error => {
        window.notify.apiError(error);
    })
}

export const deleteRoom = (context, {payload, mpid}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    axios.delete(context.rootState.apiPrefix + 'projects/' + project.id +
        '/scenarios/' + currentScenario.id + '/rooms/' + payload.room.id).then(response => {
        context.commit('deleteRoom', {room: response.data, scenario:currentScenario, phase});
        context.commit('resetProjectMeasuresToDelete', mpid);
        context.dispatch('getProjectMeasuresForCurrentScenarioAndPhase', mpid);
        context.dispatch('mp_layout/getMeasureSummary', mpid, {root: true});

        if(window.Bus) {
            setTimeout(() => {
                window.Bus.$emit('updateChecklist');
            }, 2000);
        }
    }).catch(error => {
        window.notify.apiError(error);
    })
}

export const addMeasureToRoom = async (context, {roomId, measureId, mpid}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    const payload = {
        measure: measureId,
        room: roomId,
        phase: phase.id
    }

    try {
        const response = await axios.post(
            context.rootState.apiPrefix +
            'projects/' + project.id +
            '/scenarios/' + currentScenario.id +
            '/rooms/' + payload.room +
            '/measures'
            , payload);

        await context.dispatch('mp_layout/getProjectMeasureFormConfiguration', {projectMeasure: response.data, project, mpid}, { root: true });
        await context.dispatch('mp_equipment/getMeasureEquipmentForMeasureIds', {measureIds: [response.data.measure.id], project}, {root: true});
        context.commit('addProjectMeasureToRoom', {projectMeasure: response.data, scenario: currentScenario, phase});
        await context.dispatch('mp_layout/getMeasureSummary', mpid, { root: true });

        if(window.Bus) {
            setTimeout(() => {
                window.Bus.$emit('updateChecklist');
            }, 2000);
        }

        return response.data;
    }catch(err) {
        window.notify.apiError(err);
    }

    return null;
}

export const updateProjectMeasure = (context, {projectMeasure, mpid}) => {

    let scenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);


    context.commit('updateProjectMeasure', {projectMeasure, scenario, phase});

    if(window.Bus) {
        setTimeout(() => {
            window.Bus.$emit('updateChecklist');
        }, 2000);
    }
    // context.dispatch('getProjectMeasuresForCurrentScenarioAndPhase', mpid);
    context.dispatch('mp_project_measures/getProjectMeasuresForRoom', {
        mpid,
        roomId: projectMeasure.room_id
    }, {root: true})
    context.dispatch('mp_layout/getMeasureSummary', mpid, { root: true });


}

export const getProjectMeasuresForCurrentScenarioAndPhase = (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let scenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    axios.get(
        context.rootState.apiPrefix + 'projects/' + project.id + '/measures', {
            params: {
                scenario: scenario.id,
                phase: phase.id
            }
        }).then(response => {
        context.commit('upsertProjectMeasures', {
            projectMeasures: response.data,
            scenario,
            phase,
        });
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const toggleMeasureSelection = (context, {projectMeasure, mpid}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);

    // clone project measure for post to avoid vuex mutation warnings
    projectMeasure = Object.assign({}, projectMeasure);

    if(projectMeasure.selected == 1) {
        projectMeasure.selected = 0;
    } else {
        projectMeasure.selected = 1;
    }

    let projectMeasureFormConfiguration = context.rootGetters['mp_layout/formConfigurationForProjectMeasure'](projectMeasure, project);
    if(!projectMeasureFormConfiguration) {
        console.error('Invalid Form configuration for project measure selection');
        return;
    }

    axios.patch(
        '/api/projects/' + projectMeasure.project_id +
        '/scenarios/' + projectMeasure.scenario_id  +
        '/rooms/' + projectMeasure.room_id +
        '/measures/' + projectMeasure.id,
        {
            formConfigurationId: projectMeasureFormConfiguration.id,
            data: projectMeasure,
            entityId: projectMeasure.id
        }
    ).then(response => {

        context.dispatch('updateProjectMeasure', {
            projectMeasure: response.data,
            mpid
        });
    }).catch( error => {
        window.notify.apiError(error);
    })
}

export const duplicateMeasure = (context, {mpid, measureId, roomId}) => {
    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);

    axios.post(
        context.rootState.apiPrefix +
        'projects/' + project.id +
        '/measures/duplicate/' + measureId,
        {
            data: { roomid: roomId }
        }
    ).then( response => {
        //context.dispatch('addMeasureToRoom', {roomId, measureId, mpid});
        context.dispatch('getProjectMeasuresForCurrentScenarioAndPhase', mpid);
        context.dispatch('mp_layout/getMeasureSummary', mpid, { root: true });

        if(window.Bus) {
            setTimeout(() => {
                window.Bus.$emit('updateChecklist');
            }, 2000);
        }
    }).catch( error => {
        window.notify.apiError(error);
    });
}

export const deleteSelectedMeasures = (context, mpid) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid);
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
    let phase = context.rootGetters['mp_layout/getPhase'](mpid);
    let projectMeasuresToDelete = context.getters.projectMeasuresToDelete(mpid);

    var deletePMIDs = [];
    projectMeasuresToDelete.forEach(pm => {
        deletePMIDs.push(pm.id);
    });

    if(deletePMIDs.length === 0) {
        return; // no need to run action if there are no project measures to delete
    }

    axios.delete(
        context.rootState.apiPrefix +
        'projects/' + project.id +
        '/measures',
        {
            data: {
                scenario: currentScenario.id,
                phase: phase.id,
                pMeasureId: deletePMIDs
            }
        }
    ).then( response => {
        context.commit('removeProjectMeasures', {projectMeasureIDs: deletePMIDs, scenario: currentScenario, phase});
        context.commit('resetProjectMeasuresToDelete', mpid);
        context.dispatch('getProjectMeasuresForCurrentScenarioAndPhase', mpid);
        context.dispatch('mp_layout/getMeasureSummary', mpid, {root: true});

        if(window.Bus) {
            setTimeout(() => {
                window.Bus.$emit('updateChecklist');
            }, 2000);
        }
    }).catch( error => {
        window.notify.apiError(error);
    });
}


export const deleteProjectMeasure = async (context, {mpid, projectMeasureId}) => {

    try {

        let project = context.rootGetters['mp_layout/getProject'](mpid);
        let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);
        let phase = context.rootGetters['mp_layout/getPhase'](mpid);
        const deletePMIDs = [projectMeasureId];

        const response = await axios.delete(
            context.rootState.apiPrefix +
            'projects/' + project.id +
            '/measures',
            {
                data: {
                    scenario: currentScenario.id,
                    phase: phase.id,
                    pMeasureId: deletePMIDs
                }
            }
        )

        context.commit('removeProjectMeasures', {projectMeasureIDs: deletePMIDs, scenario: currentScenario, phase});
        context.commit('resetProjectMeasuresToDelete', mpid);
        await context.dispatch('mp_layout/getMeasureSummary', mpid, {root: true});

    }catch(err) {
        window.notify.apiError(err);
    }


}
