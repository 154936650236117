<script>
import {mapMutations, mapState} from "vuex";
import {byString} from "laravel-vue-forms";
import { faEdit, faHandPointer } from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        record: {
            required: true,
            type: Object
        },
        args: {
            type: Object
        }
    },

    data() {
        return {
            editIcon: faEdit,
            handIcon: faHandPointer
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
            'equipmentLibraryMeasure',
        ]),
        display() {

            if(
                !this.equipmentLibraryConfiguration.results_display_columns_by_view ||
                !this.equipmentLibraryConfiguration.results_display_columns_by_view.equipment
            ) {
                return [
                    {
                        type: 'text',
                        value_field: 'name',
                        value: this.record.name,
                    }
                ]
            }
            return this.equipmentLibraryConfiguration.results_display_columns_by_view.equipment.map(column => {

                if(column.value_field === 'measures') {
                    return {
                        type: 'list',
                        value_field: 'measures',
                        value: this.record.equipment_measures?.getModels().map(equipmentMeasure => {
                            return equipmentMeasure?.measure?.name;
                        })
                    }
                }

                return {
                    type: 'text',
                    value_field: column.value_field,
                    value: byString(this.record, column.value_field)
                }
            })
        },
        canBeSelectedForUse() {
            return this.equipmentLibraryMeasure !== null && this.equipmentLibraryMeasure !== undefined;
        }
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView',
        ]),
        goToRecord() {
            this.setMainView({
                mainView: 'equipment-form',
                mainViewArgs: {
                    equipmentId: this.record.id,
                }
            });
        },
        selectEquipmentForUse() {
            this.$store.commit('equipment_library/selectEquipmentForEquipmentLibraryMeasure', this.record);
        }
    }
}
</script>
<template>
    <tr class="cursor-pointer" @click.stop="goToRecord">
        <td v-for="columnValue in display" :key="columnValue.value_field">
            <div v-if="columnValue.type === 'list'" class="text-left">
                <ul>
                    <li v-for="value in columnValue.value" :key="value">
                        {{ value }}
                    </li>
                </ul>
            </div>
            <div v-else>
                {{ columnValue.value }}
            </div>
        </td>
        <td>
            <font-awesome-icon
                v-if="equipmentLibraryMeasure"
                :icon="handIcon"
                @click.stop="selectEquipmentForUse"
                class="cursor-pointer text-primary mx-2"
            />
            <font-awesome-icon
                :icon="editIcon"
                @click.stop="goToRecord"
                class="cursor-pointer text-primary"
                />
        </td>
    </tr>
</template>
