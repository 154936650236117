import Parser from "etap/admin/classes/jsonapi_parser";
import Collection from "../../admin/classes/collection";

export default {
    namespaced: true,

    state: {
        loadingEntityTypes: [],
        entityTypes: [],
    },

    actions: {
        async getEntityTypeForName({commit, state}, name) {

            if (state.loadingEntityTypes.includes(name)) {
                return;
            }

            commit("addLoadingEntityType", name);

            const response = await axios.get("/api/forms/admin/entity_types?name=" + name);

            let entityType = Parser.parseJSONAPIResponse(response.data);

            if (entityType instanceof Collection && entityType.length > 0) {
                entityType = entityType.first();
            } else if (entityType instanceof Collection && entityType.length == 0) {
                throw new Error("No entity type found for name: " + name);
            }

            commit("addEntityType", entityType);
            commit("removeLoadingEntityType", name);

            return entityType;
        },
    },

    mutations: {
        addEntityType(state, entityType) {
            const existingEntityType = state.entityTypes.find(
                (existingEntityType) => {
                    return existingEntityType.name === entityType.name;
                }
            );

            if (!existingEntityType) {
                state.entityTypes.push(entityType);
            } else {
                existingEntityType.assign(entityType.toJSON());
            }
        },
        addLoadingEntityType(state, name) {
            state.loadingEntityTypes.push(name);
        },
        removeLoadingEntityType(state, name) {
            state.loadingEntityTypes = state.loadingEntityTypes.filter(
                (loadingEntityType) => {
                    return loadingEntityType !== name;
                }
            );
        }
    },

    getters: {
        getEntityTypeByName(state) {
            return (name) => {
                return state.entityTypes.find((entityType) => {
                    return entityType.name === name;
                });
            };
        },
    },
};
