
export const getCompaniesForIds = (state) => (companyIds) => {

    return state.companies.filter(company => {
        return companyIds.indexOf(company.id) !== -1;
    });
}

export const getCompanyForId = state => companyId => {
    return state.companies.filter({id: companyId}).first();
}
