<script>
import { FormErrors } from "laravel-vue-forms";
export default {
    props: {
        projectId: {
            required: true,
            type: Number
        },
        phase: {
            required: true,
            type: Object
        },
        formConfiguration: {
            required: true,
            type: Object
        },
        formData: {
            required: true,
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoSave: {
            type: Boolean,
            default: true,
        },
        additionalBaseRequestIncludes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        additionalDataIncludes: {
            type: Object ,
            default: () => {
                return {};
            },
        },
        formActions: {
            type: Array,
            default: () => {
                return [{
                    name: 'save',
                    label: 'Save',
                    action: 'submitForm',
                }];
            },
        }
    },

    data() {
        return {
            isSaving: false,
            formErrors: new FormErrors(),
        }
    },

    computed: {
        calculatedFormActions() {
            return this.formActions.map(action => {
                if(action.action === 'runWorkflow') {
                    action.action = 'runWorkflow_' + action.workflow;
                }

                return action;
            });
        },
        workflowRunValidationErrors() {
            let errorMessages = [];

            for(let valueField in this.formErrors.fieldErrors) {
                let formField = this.formConfiguration.fields.find(field => {
                    return field.value_field === valueField;
                });

                if(formField) {
                    errorMessages.push({
                        formField: formField,
                        messages: this.formErrors.fieldErrors[valueField]
                    })
                } else {
                    if(errorMessages.find(message => message.formField.label === 'General')) {
                        errorMessages.find(message => message.formField.label === 'General').messages.push(this.formErrors.fieldErrors[valueField]);
                    } else {
                        errorMessages.push({
                            formField: {
                                label: 'General'
                            },
                            messages: [this.formErrors.fieldErrors[valueField]]
                        });
                    }

                }
            }

            return errorMessages;
        }
    },

    methods: {
        updated() {
            window.notify.success('Project Updated!');
            if (window.Bus) {
                setTimeout(() => {
                    window.Bus.$emit('updateChecklist');
                }, 2000);
            }
        },
        handleFormAction(formActionInfo) {
            if(formActionInfo.action.includes('runWorkflow_')) {
                let workflow = formActionInfo.action.replace('runWorkflow_', '');
                this.runWorkflow(workflow, formActionInfo.formData);
                return;
            }

            window.notify.error('Unknown form action: ' + formActionInfo.action);
        },
        async runWorkflow(workflowKey, formData) {
            this.isSaving = true;
            this.formErrors.clear(null);

            try {
                const response =  await axios.post('/api/projects/' + this.projectId + '/workflow', {
                    formData: formData,
                    formConfigurationId: this.formConfiguration.id,
                    workflowKey: workflowKey,
                    workflowTriggerType: 'button'
                });

                let message = '';
                if(Array.isArray(response.data.message)) {
                    message = '<ul>';
                    response.data.message.forEach(msg => {
                        message += '<li>' + msg + '</li>';
                    });
                    message += '</ul>';
                }else {
                    message = response.data.message;
                }

                window.notify.message(message, 'success');

                if(response.data?.reload){
                    setTimeout(function() {
                        window.location.reload();
                    },1500)
                }

            }catch(error) {
                if(error.response && error.response.status === 422) {
                    this.formErrors.report(error);
                    window.notify.error('There was an error with the form data. Please check the form and try again.');
                } else {
                    window.notify.apiError(error);
                }
            }finally{
                this.isSaving = false;
            }
        }
    }
}

</script>
<template>
    <div>
        <div v-if="workflowRunValidationErrors.length > 0">
            <div class="w-full text-red">
                <h4 class="my-4">There were errors when saving:</h4>
                <div v-for="error in workflowRunValidationErrors">
                    <ul class="ml-4">
                        <li v-for="message in error.messages">{{ message }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <vue-form
            :form-config="formConfiguration"
            :form-data="Object.assign(formData, {id: projectId})"
            :disabled="disabled || isSaving"
            :form-submit-url="'/api/projects/' + projectId + '/phase/' + phase.id + ''"
            :auto-save="autoSave"
            :auto-save-timeout="2000"
            :actions="calculatedFormActions"
            :additional-base-request-includes="additionalBaseRequestIncludes"
            :additional-data-includes="additionalDataIncludes"
            :form-errors="formErrors"
            @updated="updated"
            @runAction="handleFormAction"
        />
        <button class = "button" v-if="isSaving" disabled = true>
            Updating <span class="fa fa-spinner fa-spin"></span>
        </button>
    </div>
</template>
