<script>
import { FormFieldMixin } from 'laravel-vue-forms';
import MeasureSelectionFieldRoom from "./MeasureSelectionFieldRoom.vue";
import {guid} from "etap/utilities/utils";
import { mapActions, mapMutations} from 'vuex';

export default {

    mixins: [
        FormFieldMixin
    ],

    data() {
        return {
            mpid: null,
            showPanel: false,
            initializingMeasureSelection: false
        }
    },

    async created() {
        let selectedMeasures = [];
        if(this.value) {
            selectedMeasures = JSON.parse(this.value);
        }

        this.initializingMeasureSelection = true;
        this.mpid = guid();

        const project = JSON.parse(JSON.stringify(this.form.data));

        // set up field here
        this.defaultMeasurePicker(this.mpid);
        this.defaultProjectMeasureSelections(this.mpid);
        this.defaultMPScenarios(this.mpid);
        this.updateProject({mpid: this.mpid, project});

        // load existing selections here
        if(selectedMeasures.length > 0) {
            await this.loadSelectedProjectMeasures({mpid: this.mpid, selectedMeasures});
        }


        if(!this.phase) {
            //Default calculation config to empty:
            this.setMeasureCalculationConfiguration({mpid: this.mpid, configuration: []});

            this.initializingMeasureSelection = false;
            return;
        }

        try {
            this.setPhase({mpid: this.mpid, phase: this.phase});

            const promises = [
                //Fetch the calculation config from the phase and track configuration if it exists
                this.getMeasureCalculationConfigForProjectAndPhase(this.mpid),
                this.loadScenarios(this.mpid)
            ];

            await Promise.all(promises);

            const selectedScenario = this.availableScenarios.find(scenario => {
                return scenario.selected;
            });

            if(!selectedScenario) {
                window.notify.error("No scenarios found for this project");
                this.initializingMeasureSelection = false;
                return;
            }

            this.updateCurrentScenario({
                mpid: this.mpid,
                scenario: selectedScenario
            });

            await this.getScenarioRooms(this.mpid);

        }catch(err) {
            window.notify.apiError(err);
            console.log(err);
        }

        this.initializingMeasureSelection = false;
    },

    watch: {
        projectMeasureSelections() {
            this.$emit('input', this.projectMeasureSelections.map((projectMeasure) => {
                return  {
                    measure: projectMeasure.shared_uuid,
                    version: projectMeasure.id
                };
            }));
        }
    },

    computed: {
        measureSelectionConfig() {
            if(this.fieldConfig.field_extra.measureSelectionConfiguration) {
                return this.fieldConfig.field_extra.measureSelectionConfiguration;
            }
            return null;
        },
        phase() {

            //TODO: Eventually update this to allow phase to be set through a field
            if(
                !this.measureSelectionConfig ||
                !this.measureSelectionConfig.phaseName
            ) {
                return null;
            }

            return this.$store.state.projectPhases.find(projectPhase => {
                return projectPhase.name === this.fieldConfig.field_extra.measureSelectionConfiguration.phaseName;
            }) ?? null;
        },

        availableScenarios() {
            return this.$store.getters['mp_scenarios/getScenarios'](this.mpid);
        },
        currentScenario() {
            return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
        },
        currentPhase() {
            return this.$store.getters['mp_layout/getPhase'](this.mpid);
        },
        projectMeasureSelections() {
            return this.$store.getters['measure_selection/projectMeasureSelections'](this.mpid);
        },
        scenarioPhaseRooms() {
            return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.currentPhase)
        },
        allowMeasureDetailsForm() {
            if(
                this.measureSelectionConfig &&
                this.measureSelectionConfig.hasOwnProperty('showForm')
            ) {
                return this.measureSelectionConfig.showForm;
            }
            return false;
        },
        showMeasureCalculationDetails() {
            if(
                this.measureSelectionConfig &&
                this.measureSelectionConfig.hasOwnProperty('showCalculationDetails')
            ) {
                return this.measureSelectionConfig.showCalculationDetails;
            }
            return true;
        }

    },

    methods: {
        ...mapMutations('mp_layout', [
            'defaultMeasurePicker',
            'setMeasureCalculationConfiguration',
            'updateProject',
            'setPhase',
            'setMPIDForAssessment',
            'setMeasurePickerType',
        ]),
        ...mapActions('mp_layout', [
            'getMeasureCalculationConfigForProjectAndPhase',
        ]),
        ...mapMutations('mp_scenarios', [
            'defaultMPScenarios',
            'updateCurrentScenario',
        ]),
        ...mapActions('mp_scenarios', [
            'loadScenarios'
        ]),
        ...mapActions('mp_project_measures', [
            'getScenarioRooms',
            'getProjectMeasuresForRoom',
        ]),
        ...mapMutations('measure_selection', [
            'defaultProjectMeasureSelections',
        ]),
        ...mapActions('measure_selection', [
            'loadSelectedProjectMeasures',
        ]),

        getDisplaySharedUUID(projectMeasure) {
            const sharedParts = projectMeasure.shared_uuid?.split('-');

            if(sharedParts) {
                return sharedParts[sharedParts.length - 1]
            }

            return null;
        },
    },

}
</script>
<style scoped>
    #roomlist {
        padding-bottom: 20px;
    }
</style>
<template>
    <div class="form-group"
            :id="fieldName + '-measure-selection-field'"
            :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }">
            <label class="form-control-label">
        <span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required">
                &nbsp;&nbsp;(*)
            </span>
            <span
                v-if="withHelpIcon"
                :class="fieldConfig.field_extra.withIcon"
                :title="fieldConfig.field_extra.helpText"
            ></span>
        </label>
        <div class="flex items-start justify-between mx-2" style="width:95%">
            <div class="w-3/4" v-if="!initializingMeasureSelection">
                <div v-if="projectMeasureSelections.length > 0">
                    <span><strong>{{measureSelectionConfig?.measuresSelectedText ?? 'Selected measures:'}}</strong></span>
                    <ul>
                        <li v-for="projectMeasure in projectMeasureSelections">
                            {{ projectMeasure.measure.name }}<span class="littlenote"> - {{ getDisplaySharedUUID(projectMeasure) }}</span>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <span>{{measureSelectionConfig?.noMeasuresSelectedText ?? 'No measures selected.'}}</span>
                </div>
            </div>
            <div class="w-3/4" v-else>
                <span class="fa fa-spinner fa-spin fa-2x"></span>
                <span class="ml-4">Loading ...</span>
            </div>
            <button
                class="w-1/4 p-2 mb-2 mr-2 button"
                style="height: 35px;"
                @click="showPanel = true"
                :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
            >
                {{measureSelectionConfig?.buttonHeaderText ?? 'Select Measures'}}
            </button>
        </div>

        <right-panel
            v-if="showPanel"
            width="80%"
            @close="showPanel = false"
            >

            <template #header>
                <div class="my-4 mx-4 bg-white p-4 rounded shadow w-full">
                    <h2>{{measureSelectionConfig?.buttonHeaderText ?? 'Select Measures'}}</h2>
                </div>
            </template>
            <template #default>
                <div class="mx-4 bg-white rounded shadow">

                    <div id="roomlist" v-if="!initializingMeasureSelection">
                        <div class="flex justify-end">
                            <button class="mt-2 button" @click="showPanel = false">Save & Close Selection</button>
                        </div>
                        <measure-selection-field-room
                            v-for="room in scenarioPhaseRooms"
                            :key="room.id"
                            :mpid="mpid"
                            :room="room"
                            :allow-measure-details-form="allowMeasureDetailsForm"
                            :show-measure-calc-details="showMeasureCalculationDetails"
                        />
                    </div>
                    <table-skeleton
                        v-else
                        class="mx-2"
                        :rows="3"
                        grid-type="div"
                    />

                </div>
            </template>

        </right-panel>
    </div>
</template>
