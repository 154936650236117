import apiClient from 'etap/admin/apiClient';
import Parser from 'etap/admin/classes/jsonapi_parser';

import Collection from 'etap/admin/classes/collection';
import Feature from 'etap/admin/classes/models/feature';


import CompanyModule from 'etap/store/admin/companies/module';
import UserModule from 'etap/store/admin/users/module';
import RoleModule from 'etap/store/admin/roles/module';

export default {
    namespaced: true,

    modules: {
        companies: CompanyModule,
        users: UserModule,
        roles: RoleModule
    },

    state: {
        dashboard_statistics: {},
        systemInfo: null,
        features: new Collection([], {
            model: Feature
        })
    },

    actions: {
        getDashboardStatistics(context) {
            return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/dashboardStats').then(response => {
                    context.commit('setDashboardStats', response.data);
                    resolve();
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            })
        },
        getSystemInfo(context) {
            return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/systemInfo').then(response => {
                    let data = Parser.parseJSONAPIResponse(response.data);
                    context.commit('setSystemInfo', data);
                    resolve(data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            })
        },
        getFeatures(context) {
            return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/features').then(response => {
                    let data = Parser.parseJSONAPIResponse(response.data);
                    context.commit('setFeatures', data);
                    resolve(data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            })
        }
    },


    mutations: {
        setDashboardStats(state, stats) {
            state.dashboard_statistics = stats;
        },
        setSystemInfo(state, info) {
            state.systemInfo = info;
        },
        setFeatures(state, features) {
            state.features = features;
        },
        updateFeature(state, feature) {

            var existingFeature = state.features.filter({id: feature.id}).first();

            if(!existingFeature) {
                console.error('invalid feature to update');
                return;
            }

            existingFeature.assign(feature);
        }
    }

}
