import apiClient from 'etap/admin/apiClient';
import Collection from 'etap/admin/classes/collection';
import Generic from 'etap/admin/classes/models/generic';
import Parser from 'etap/admin/classes/jsonapi_parser';

export default {
    namespaced: true,


    state: {
        states: new Collection([], {
            model: Generic
        })
    },


    actions: {
        getStates(context) {

            return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/system/states').then(response => {
                    var states = Parser.parseJSONAPIResponse(response.data);
                    context.commit('setStates', states);
                    resolve(states);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            })
        }
    },

    mutations: {
        setStates(state, states) {
            state.states = states;
        }
    }

}
