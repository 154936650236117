<template>

    <div id="records">
        <div
            v-if="pinnedProjects.length > 0"
            v-for="record in pinnedProjects"
            :key="record.pinnedKey"
            :is="recordType"
            :record="record"
            :base-record-id="baseRecordId"
            v-on:remove="removeRecord(record, $event)"
            :class="{
                    'selected' : selectedRecords.find(r => r.pinnedKey === record.pinnedKey),
                    'cursor-pointer' : recordsAreSelectable
                }"
            @click.native="handeSelectionClick(record)"
            @record-selected="selectRecord(record)"
            @record-unselected="deselectRecord(record)"
            :args="args">
        </div>
        <hr v-if="pinnedProjects.length > 0 && records.length > 0" class="bg-primary border-primary" style="width: 95%; margin: 15px; height: 5px">
        <div
            v-for="record in records"
            :key="record.id"
            :is="recordType"
            :record="record"
            :base-record-id="baseRecordId"
            v-on:remove="removeRecord(record, $event)"
            :class="{
                    'selected' : selectedRecords.find(r => r.id === record.id && typeof r.pinnedKey === 'undefined'),
                    'cursor-pointer' : recordsAreSelectable
                }"
            @click.native="handeSelectionClick(record)"
            @record-selected="selectRecord(record)"
            @record-unselected="deselectRecord(record)"
            :args="args">

        </div>
        <div v-if="records.length == 0" v-html="args.noRecordsText">
        </div>
    </div>

</template>
<script>
    import { IsGridView } from 'v-grid';

    export default {

        mixins: [IsGridView],

        data() {
            return {
                baseRecordId: 0,
            }
        },

        mounted() {
            this.baseRecordId = this.$parent.baseRecordId;
        },

        computed: {
            pinnedProjects() {

                if(!this.args.pinned || !Array.isArray(this.args.pinned)) {
                    return [];
                }

                return this.args.pinned.map(pinnedProject => {
                    pinnedProject.pinnedKey = 'pinned-' + pinnedProject.id;
                    return pinnedProject;
                });
            }
        },

        methods: {
            handeSelectionClick(record) {
                // only run section if records are selectable through a simple click
                if(!this.recordsAreSelectable) {
                    return;
                }

                let foundRecord = this.selectedRecords.find(rec => {
                    return rec.id === record.id;
                });

                if(foundRecord) {
                    this.deselectRecord(record);
                }else {
                    this.selectRecord(record);
                }
            },
            selectRecord(record) {
                this.$emit('record-selected', record);
            },
            deselectRecord(record) {
                this.$emit('record-unselected', record);
            },
            removeRecord(record, event) {
                this.$emit('removeRecord', record);
            }
        }

    }
</script>
