import Vue from 'vue';

export const setSchedulesForProject = (state, {projectId, schedules}) => {
    Vue.set(state.projectSchedules, projectId, schedules);
}

export const addScheduleForProject = (state, {projectId, schedule}) => {
    if(!state.projectSchedules[projectId]) {
        Vue.set(state.projectSchedules, projectId, []);
    }

    state.projectSchedules[projectId].push(schedule);
}

export const updateScheduleForProject = (state, {projectId, schedule}) => {
    let index = state.projectSchedules[projectId].findIndex(projectSchedule => {
        return projectSchedule.id === schedule.id
    });

    if(index === -1) {
        console.error('Invalid schedule to update for project', schedule, projectId);
        return;
    }

    state.projectSchedules[projectId].splice(index, 1, schedule);
}

export const deleteScheduleForProject = (state, {projectId, scheduleId}) => {
    let index = state.projectSchedules[projectId].findIndex(projectSchedule => {
        return projectSchedule.id === scheduleId
    });

    if(index === -1) {
        console.error('Invalid schedule to delete for project', scheduleId, projectId);
        return;
    }

    state.projectSchedules[projectId].splice(index, 1);
}
