<template>
    <div id="pre-approval-qc-zip">
        <div v-if="currentStatus === null">
            <button @click="createZip" class="inputbutton1">Create Zip Archive</button>
        </div>
        <div v-else-if="in_progress">
            <span class="fa fa-spin fa-spinner"></span>Creating Zip file
        </div>
        <div v-else-if="failed">
            <p>Your recent job has failed: {{ currentStatus.output.message }}</p>
            <button @click="createZip" class="inputbutton1">Re-generate Zip Archive</button>
        </div>
        <div v-else-if="finished">
            <a class="inputbutton1" @click.prevent="getZipLinkAndDownload" href="">Download Zip</a>
            <br /><br />
        </div>
        <div v-else>
            <p>Unknown zip file creation status</p>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            project: {
                required: true,
                type: Object,
            },
            phase: {
                required: true,
                type: Object
            }
        },

        data() {
            return {
                currentStatus: null,
                intervalHandle: null,
            }
        },

        computed: {
            in_progress() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'queued' || this.currentStatus.status === 'executing') {
                        return true;
                    }
                }
                return false
            },
            failed() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'failed') {
                        return true;
                    }
                }
                return false;
            },
            finished() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'finished') {
                        return true;
                    }
                }
                return false;
            }
        },

        methods: {
            getZipStatus() {

                if(!this.currentStatus || !this.currentStatus.id) {
                    window.notify.message('Invalid Status ID to get status', 'error');
                    return;
                }

                axios.get('/api/projects/' + this.project.id + '/phase/' + this.phase.id + '/zip/status', {
                    params: {
                        'job_status_id': this.currentStatus.id
                    }
                }).then( response => {
                    if(response.data) {
                        this.currentStatus = response.data
                        if( response.data.status === 'finished' || response.data.status === 'failed') {
                            clearInterval(this.intervalHandle);
                        }
                    }
                }).catch(error => {
                    window.notify.apiError(error);
                });

            },
            createZip() {
                this.currentStatus = null;
                if(this.intervalHandle) {
                    clearInterval(this.intervalHandle);
                }
                axios.post('/api/projects/' + this.project.id + '/phase/' + this.phase.id + '/zip', {}).then(response => {
                    this.startInterval();
                    this.currentStatus = response.data;
                }).catch(error => {
                    window.notify.apiError(error);
                });
            },
            async getZipLinkAndDownload() {
                try {
                    const response = await axios.get('/project/' + this.project.id + '/phase/' + this.phase.id + '/zip/download?job_status_id=' + this.currentStatus.id);
                    if(!response.data || !response.data.fileLink) {
                        throw new Error("Invalid file link");
                    }
                    window.open(response.data.fileLink);
                }catch(err) {
                    if(process.env.NODE_ENV !== 'production') {
                        console.error(err);
                    }

                    window.notify.apiError(err);
                }
            },
            startInterval() {
                if(this.intervalHandle === null) {
                    this.intervalHandle = setInterval(this.getZipStatus, 5000);
                }
            },
        }
    }
</script>
