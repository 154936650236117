import Model from 'etap/admin/classes/models/model';
import Ability from 'etap/admin/classes/models/ability';
import moment from 'moment';

class Role extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            title: '',
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

    relationships() {
        return {
            abilities: Ability
        }
    }

    routes() {
        return {
            abilities: 'admin/roles/getAbilitiesForRole'
        }
    }

}

export default Role;
