<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="ui-widget-content modal-container" :style="{ width: modalWidth}" v-on-clickaway="closeModal">
                    <div class="dialog-header ui-widget-header ui-corner-all inline-children justify-space-between">
                        <slot name="header"></slot>
                        <button @click="closeModalButton"
                                type="button"
                                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close modal-close-button"
                                >
                            <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
                            <span class="ui-button-text">Close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-content">
                            <slot name="body"></slot>
                        </div>
                        <div class="center-text action-buttons" v-if="isConfirm">
                            <button class="inputbutton1 mr-2 border-0" @click="confirmModal" :disabled="confirming">{{ confirmText }}</button>
                            <button class="inputbutton1" @click="closeModalButton" :disabled="confirming">{{ cancelText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import { mixin as clickaway } from 'vue-clickaway';

    export default {
        mixins: [ clickaway ],

        props: {
            isConfirm: {
                type: Boolean,
                default: false
            },
            confirmText: {
                type: String,
                default: 'Yes'
            },
            cancelText: {
                type: String,
                default: 'Cancel'
            },
            modalWidth: {
                type: String,
                default: '400px'
            },
            closeOnClickAway: {
                type: Boolean,
                default: true
            },
            confirming: {
                type: Boolean,
                default: false
            }
        },


        methods: {
            closeModal() {
                if(this.closeOnClickAway) {
                    this.$emit('close');
                }
            },
            closeModalButton() {
                this.$emit('close')
            },
            confirmModal() {
                this.$emit('confirmed');
            }
        }
    }
</script>
