import Model from 'etap/admin/classes/models/model';
import moment from 'moment';

class Ability extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            title: '',
            entity_id: null,
            entity_type: null,
            scope: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default Ability;
