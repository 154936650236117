import Model from 'etap/admin/classes/models/model';

class ProgramTrack extends Model {

    defaults() {
        return {
            id: null,
            name: null,
            title: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default ProgramTrack;
