import axios from 'axios';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        measure_technology: {},
        loadingFor: {},
    },

    actions: {
        getTechnologyForMeasureAndProject(context, {measureId, project}) {

            if(typeof context.state.measure_technology[measureId] !== 'undefined' || context.getters.loadingForMeasureId(measureId)) {
                return;
            }

            context.commit('loadingFor', [measureId]);
            axios.get('/api/measures/' + measureId + '/technologies', {
                params: {
                    programYearId: project.program_year_id
                }
            }).then( response => {
                context.commit('addMeasureTechnologies', response.data);
                context.commit('loadingNotFor', [measureId]);
            }).catch( error => {
                window.notify.apiError(error);
                context.commit('loadingNotFor', [measureId]);
            });

        },
        getMeasureTechnologiesForMeasureIdsAndProject(context, {measureIds, project}) {

            var loadForMeasureIds = [];
            measureIds.forEach(mId => {
                if(
                    typeof context.state.measure_technology[mId] === 'undefined' &&
                    !context.getters.loadingForMeasureId(mId) &&
                    context.state.measure_technology[mId] === null ||
                    context.state.measure_technology[mId] === undefined
                ) {
                    loadForMeasureIds.push(mId);
                }
            });

            if(!Array.isArray(loadForMeasureIds) || loadForMeasureIds.length === 0) {
                return;
            }

            context.commit('loadingFor', loadForMeasureIds);
                axios.get('/api/technologies', {
                    params: {
                        measureIds: loadForMeasureIds,
                        programYearId: project.program_year_id
                    }
                }).then(({data}) => {
                    if(typeof data !== 'object') {
                        console.error('invalid response');
                        return;
                    }

                    if(data.measureID) {
                        context.commit('addMeasureTechnologies', data);
                    } else if(Array.isArray(data) && data.length > 0 && data[0].measureID) {
                        data.forEach(measureTechnology => {
                            context.commit('addMeasureTechnologies', measureTechnology);
                        });
                    } else {
                        throw new Error("Invalid data return from measure technologies");
                    }

                    context.commit('loadingNotFor', loadForMeasureIds);
                }).catch(error => {
                    window.notify.apiError(error);
                    context.commit('loadingNotFor', loadForMeasureIds);
                });
        }
    },

    mutations: {
        loadingFor(state, measureIds) {
            measureIds.forEach(measureId => {
                Vue.set(state.loadingFor, measureId, true);
            });
        },
        loadingNotFor(state, measureIds) {
            measureIds.forEach(measureId => {
                Vue.set(state.loadingFor, measureId, false);
            });
        },
        addMeasureTechnologies(state, measureTechnologies) {
            Vue.set(state.measure_technology, measureTechnologies.measureID, {
                baseline_technology: measureTechnologies.baseline_technology,
                proposed_technology: measureTechnologies.proposed_technology
            });
        }
    },

    getters: {
        loadingForMeasureId: state => (measureId) => {
            return state.loadingFor[measureId] || false;
        },
        baselineTechnologyForId: (state, getters) => (measureId, technologyId) => {

            if(state.measure_technology[measureId]) {
                var technology = state.measure_technology[measureId].baseline_technology.find( equip => equip.id === parseInt(technologyId));

                if(technology) {
                    return technology;
                }
            }

            return {
                technology: 'N/A'
            };
        },
        proposedTechnologyForId: (state, getters) => (measureId, technologyId) => {
            if(state.measure_technology[measureId]) {
                var technology = state.measure_technology[measureId].proposed_technology.find( equip => equip.id === parseInt(technologyId));

                if(technology) {
                    return technology;
                }
            }

            return {
                technology: 'N/A'
            };
        },
        getBaselineTechnologyForMeasure: (state, getters) => (measureId) => {
            if(typeof state.measure_technology[measureId] !== 'undefined') {
                return state.measure_technology[measureId].baseline_technology
            }

            return [];
        },
        getProposedTechnologyForMeasure: (state, getters) => (measureId) => {
            if(typeof state.measure_technology[measureId] !== 'undefined') {
                return state.measure_technology[measureId].proposed_technology
            }

            return [];
        }
    }
}
