import Model from 'etap/admin/classes/models/model';

class UserSchedule extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            user_id: '',
            schedule: {}
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default UserSchedule;
