<script>
import { FormFieldMixin } from 'laravel-vue-forms';
import {guid} from "etap/utilities/utils";
import {mapActions, mapMutations} from "vuex";
export default {

    mixins: [
        FormFieldMixin
    ],

    data() {
        return {
            mpid: null,
            initializing: false,
            newlyAddedMeasureIds: [],
        }
    },

    computed: {
        phase() {

            if(
                !this.fieldConfig.field_extra.measurePickerConfiguration ||
                !this.fieldConfig.field_extra.measurePickerConfiguration.phaseName
            ) {
                return null;
            }

            return this.$store.state.projectPhases.find(projectPhase => {
                 return projectPhase.name === this.fieldConfig.field_extra.measurePickerConfiguration.phaseName;
            });
        },
        scenariosForPicker() {
            return this.$store.getters['mp_scenarios/getScenarios'](this.mpid);
        },
        currentScenario() {
            return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
        },
        roomForField() {

            if(!this.currentScenario) {
                return null;
            }

            return this.$store.getters['mp_project_measures/getScenarioRoomsForPhaseAndRoomName'](
                this.currentScenario,
                this.phase,
                this.fieldConfig.field_extra.measurePickerConfiguration.roomName
            );
        },
        projectMeasuresForRoom() {

            if(
                !this.currentScenario ||
                !this.phase ||
                !this.roomForField
            ) {
                return [];
            }

            return this.$store.getters['mp_project_measures/getProjectMeasuresForScenarioPhaseAndRoom'](
                this.currentScenario,
                this.phase,
                this.roomForField
            );
        },
        noEquipmentText() {
            if(this.fieldConfig.field_extra.noEquipmentText) {
                return this.fieldConfig.field_extra.noEquipmentText;
            }

            return 'No equipment added';
        },
        projectMeasureFormsAutoSave() {
            return this.fieldConfig.field_extra.projectMeasureFormsAutoSave ?? false;
        }
    },


    /**
     * The presumption of this component is that we are adding this to a form where the project
     * is the subject of the form.   this is why the form's data is being used as the project
     */
    async created() {
        this.mpid = guid();

        if(!this.phase) {
            throw new Error("Phase not found for measure picker");
        } else if(
            !this.fieldConfig.field_extra.measurePickerConfiguration ||
            !this.fieldConfig.field_extra.measurePickerConfiguration.roomName
        ) {
            throw new Error("Room name not found for measure picker");
        }

        this.initializing = true;

        const project = JSON.parse(JSON.stringify(this.form.data));

        this.defaultMeasurePicker(this.mpid);
        this.setMeasurePickerType({mpid: this.mpid, pickerType: 'simple'});
        this.defaultMPScenarios(this.mpid);
        this.updateProject({mpid: this.mpid, project});
        this.setPhase({mpid: this.mpid, phase: this.phase});
        this.setMPIDForAssessment(this.mpid);

        try {
            await this.loadScenarios(this.mpid);

            const selectedScenario = this.scenariosForPicker.find(scenario => {
                return scenario.selected;
            });

            if(!selectedScenario) {
                window.notify.error("No scenarios found for this project");
                return;
            }

            this.updateCurrentScenario({
                mpid: this.mpid,
                scenario: selectedScenario
            });

            await this.getScenarioRooms(this.mpid);

            if(!this.roomForField) {
                await this.addRoom({
                    mpid: this.mpid,
                    phase: this.phase,
                    roomName: this.fieldConfig.field_extra.measurePickerConfiguration.roomName
                });
            }

            await this.getProjectMeasuresForRoom({
                mpid: this.mpid,
                roomId: this.roomForField.id
            });

            this.initializing = false;
        }catch(err) {
            window.notify.apiError(err);
            console.log(err);
        }
    },


    methods: {
        ...mapMutations('mp_layout', [
            'defaultMeasurePicker',
            'setMeasureCalculationConfiguration',
            'updateProject',
            'setPhase',
            'setMPIDForAssessment',
            'setMeasurePickerType',
        ]),
        ...mapMutations('mp_scenarios', [
            'defaultMPScenarios',
            'updateCurrentScenario',
        ]),
        ...mapActions('mp_scenarios', [
            'loadScenarios'
        ]),
        ...mapActions('mp_project_measures', [
            'getScenarioRooms',
            'addRoom',
            'getProjectMeasuresForRoom',
        ]),
        measureAdded(newMeasure) {
            this.newlyAddedMeasureIds.push(newMeasure.id);
        }
    },

    watch: {
        projectMeasuresForRoom: {
            handler(newVal) {
                if(this.initializing) {
                   return;
                }

                this.$emit('input', this.projectMeasuresForRoom.map(projectMeasure => {
                    return projectMeasure.id;
                }));
            },
        }
    },


    provide() {

        let provide = {};

        Object.defineProperty(provide, 'mpid', {
            enumerable: true,
            get: () => this.mpid
        });

        return provide;
    },
}
</script>
<template>
    <div>
        <div v-if="!initializing">
            <div class="m-1">
                <div class="flex justify-between items-center bg-gray-200 p-2 border-b">
                    <div class="font-bold">
                        {{fieldConfig.label}}
                    </div>
                    <simple-measure-picker-add-measure-modal
                        v-if="fieldConfig.disabled !== 1 &&  fieldConfig.disabled !== true"
                        :room-for-measures="roomForField"
                        :simple-measure-picker-field-config="fieldConfig"
                        @measureAdded="measureAdded"
                    />
                </div>
                <div class="my-4 p-1 py-4">
                    <div v-if="projectMeasuresForRoom.length === 0">
                        {{ this.noEquipmentText }}
                    </div>
                    <simple-project-measure-row
                        v-for="projectMeasure in projectMeasuresForRoom"
                        :key="projectMeasure.id"
                        :project-measure="projectMeasure"
                        :open-measure-row="newlyAddedMeasureIds.indexOf(projectMeasure.id) !== -1"
                        :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
                        :auto-save="projectMeasureFormsAutoSave"
                    />
                </div>
            </div>
        </div>
        <div
            v-else
            style="min-height: 10vh; display: flex; align-items: center; justify-content: center;"
        >
            <div class="text-center flex justify-center">
                <div class="loading-bar w-1/2 text-white p-12 rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
            </div>
        </div>
    </div>
</template>
