import Model from 'etap/admin/classes/models/model';
import moment from 'moment';

class User extends Model {


    defaults() {
        return {
            id: null,
            username: null,
            password: null,
            force_pw_change: null,
            pw_updated_at: null,
            title: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            force_pw_change: Boolean,
            pw_updated_at: this.parseDate,
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }


}

export default User;
