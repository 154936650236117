import { guid } from 'etap/utilities/utils';

export default {


    data() {
        return {
            mpid: null,
        }
    },

    created() {
        this.mpid = guid();
    },

    methods: {
        /*getPosition(el) {
            var xPos = 0;
            var yPos = 0;

            while (el) {
                if (el.tagName == "BODY") {
                    // deal with browser quirks with body/window/document and page scroll
                    var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                    var yScroll = el.scrollTop || document.documentElement.scrollTop;

                    xPos += (el.offsetLeft - xScroll + el.clientLeft);
                    yPos += (el.offsetTop - yScroll + el.clientTop);
                } else {
                    // for all other non-BODY elements
                    xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                    yPos += (el.offsetTop - el.scrollTop + el.clientTop);
                }

                el = el.offsetParent;
            }
            return {
                x: xPos,
                y: yPos
            };
        },
        checkIfLocking() {
            if ($('#disablewrapper').hasClass("disablewrapper") == false && $("#measurepickerfullscreen").hasClass("measurepickerfullscreenexiticon") == false) {

                let position = this.getPosition($('#' + this.widgetUniqueId + " .measuretopreference")[0]);
                //console.log('Postion of... ' + this.widgetUniqueId, position);
                // if ($('#' + this.widgetUniqueId + " .measuretopreference").position() !== 'undefined' &&
                //     $(document).scrollTop() < $('#' + this.widgetUniqueId + " .measuretopreference").position().top)
                // {
                if(position.y > 0) {
                    this.lockMeasurePickerArea(true);
                }  else {
                    this.lockMeasurePickerArea(false);
                }
            }
        },
        lockMeasurePickerArea(lock) {
            if (lock == true) {
                $('#' + this.widgetUniqueId + ' #measureaccordionscroll').removeClass('positionfixed');
                $('#' + this.widgetUniqueId + ' .measuresummary').removeClass('positionfixed widthfix');
                $('#' + this.widgetUniqueId + ' #measuresummaryplaceholder').removeClass('measuresummaryplaceholderdiv');
                $('#' + this.widgetUniqueId + ' #disablearea').removeClass('positionfixed');
                $('#' + this.widgetUniqueId + ' #measureaccordionwrapper').removeClass('sidebar-stuck');
            } else {
                $('#' + this.widgetUniqueId + ' #measureaccordionscroll').addClass('positionfixed');
                $('#' + this.widgetUniqueId + ' .measuresummary').addClass('positionfixed widthfix');
                $('#' + this.widgetUniqueId + ' #measuresummaryplaceholder').addClass('measuresummaryplaceholderdiv');
                $('#' + this.widgetUniqueId + ' #disablearea').addClass('positionfixed');
                $('#' + this.widgetUniqueId + ' #measureaccordionwrapper').addClass('sidebar-stuck');

            }
        },*/
    }

}
