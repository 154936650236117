<script>
import { FormFieldMixin } from 'laravel-vue-forms';
import Parser from "etap/admin/classes/jsonapi_parser";
export default {
    mixins: [FormFieldMixin],

    props: {
        projectPayeeModelType: {
            required: false,
            type: String
        },
        projectId: {
            required: false,
            type: Number
        }
    },

    data() {
        return {
            selectedPayeeTypeId: null,
            payeeTypeIdToUpdateTo: null,
            projectPayeeTypes: [],
            mappingPayee: false,
            showPayeeForm: false,
            payeeData: {},
            resettingPayeeType: false,
            showResetPayeeTypeModal: false,
            showResetPayeeModal: false,
            resettingPayee: false,
        }
    },

    computed: {
        projectPayeeFormUrl() {
            if(!this.fieldConfig.projectId) {
                return null;
            }else if(this.payeeData.id) {
                return '/api/projects/' + this.fieldConfig.projectId + '/payee/' + this.payeeData.id;
            }

            return '/api/projects/' + this.fieldConfig.projectId + '/payee';
        },
        selectedPayeeType() {
            if(!this.selectedPayeeTypeId) {
                return null;
            }
            return this.projectPayeeTypes.find(payeeType => payeeType.id === this.selectedPayeeTypeId);
        },
        projectPayeeEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('project_payee');
        },
        projectPayeeFormConfiguration() {
            if(
                !this.projectPayeeEntityType ||
                !this.projectPayeeEntityType.default_form_configuration_id
            ) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationById'](
                this.projectPayeeEntityType.default_form_configuration_id
            );
        }
    },

    async created() {
        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name !== this.fieldName) {
                    return;
                }

                const fieldExtra = this.getFormFieldFieldExtra(field);
                if(fieldExtra.payeeTypesLookupModel) {
                    this.fieldConfig.projectPayeeModelType = fieldExtra.payeeTypesLookupModel;
                }

                if(fieldExtra.projectIdFieldFromFormData) {
                    this.fieldConfig.projectId = this.form.data[fieldExtra.projectIdFieldFromFormData];
                }

            });
        } else {
            this.fieldConfig.projectPayeeModelType = this.projectPayeeModelType;
            this.fieldConfig.projectId = this.projectId;
        }

        const loadingPromises = [];
        if(this.projectPayeeTypes.length === 0 && this.fieldConfig.projectPayeeModelType) {
            loadingPromises.push(this.getPayeeTypesForProject());
        }

        if(!this.projectPayeeEntityType) {
            loadingPromises.push(this.$store.dispatch('entity_types/getEntityTypeForName', 'project_payee'));
        }

        loadingPromises.push(this.getProjectPayee());

        if(loadingPromises.length > 0) {
            await Promise.all(loadingPromises);
        }

        if(
            this.projectPayeeEntityType &&
            this.projectPayeeEntityType.default_form_configuration_id &&
            !this.projectPayeeFormConfiguration
        ) {
            await this.$store.dispatch(
                'form_configurations/getFormConfigurationById',
                this.projectPayeeEntityType.default_form_configuration_id
            );
        }
    },

    methods: {
        async getProjectPayee() {
            if(!this.fieldConfig.projectId) {
                return;
            }

            try {

                const projectPayeeResponse = await axios.get('/api/projects/' + this.fieldConfig.projectId + '/payee', {
                    params: {
                        include: ['billing_address', 'files']
                    },
                    headers: {
                        Accept: 'vnd.api+json',
                    }
                });

                if(!projectPayeeResponse.data || !projectPayeeResponse.data.data) {
                    return;
                }

                const parsedPayee = Parser.parseJSONAPIResponse(projectPayeeResponse.data);

                if(!parsedPayee || typeof parsedPayee.toJSON !== 'function') {
                    return;
                }

                this.payeeData = parsedPayee.toJSON();
                this.selectedPayeeTypeId = this.payeeData.payee_type_id;
                this.showPayeeForm = true;

            }catch(err) {
                console.log(err);
                window.notify.apiError(err);
            }
        },
        async updateSelectedPayeeType(value) {

            const originalPayeeTypeId = this.selectedPayeeTypeId;
            this.payeeTypeIdToUpdateTo = value;
            if(!originalPayeeTypeId) {
                await this.updatePayeeTypeConfirmed()
                return;
            }

            this.showResetPayeeTypeModal = true;
        },
        async updatePayeeTypeConfirmed() {

            this.showPayeeForm = false;
            this.resettingPayeeType = true;

            if(!this.payeeTypeIdToUpdateTo) {
                if(this.payeeData.id) {
                    await this.resetProjectPayee();
                }
                this.selectedPayeeTypeId = null;
                this.payeeTypeIdToUpdateTo = null;
                this.showResetPayeeTypeModal = false;
                this.resettingPayeeType = false;
                return;
            }

            this.selectedPayeeTypeId = this.payeeTypeIdToUpdateTo;

            this.$nextTick(async () => {

                if(!this.selectedPayeeType) {
                    this.payeeTypeIdToUpdateTo = null;
                    this.resettingPayeeType = false;
                    this.showResetPayeeTypeModal = false;
                    return;
                }

                if(this.payeeData.id) { // delete and reset any existing project payee
                    await this.resetProjectPayee();
                    this.selectedPayeeTypeId = this.payeeTypeIdToUpdateTo;
                }

                if(this.selectedPayeeType?.attributes?.payeeMapsByTrack) {
                    this.mappingPayee = true;

                    const mappedPayee = await axios.get('/api/projects/' + this.fieldConfig.projectId + '/mapped_payee_for_type', {
                        params: {
                            payee_type_id: this.selectedPayeeTypeId
                        },
                        headers: {
                            Accept: 'vnd.api+json',
                        }
                    });

                    this.payeeData = Object.assign({
                        payee_type_id: this.selectedPayeeTypeId
                    }, mappedPayee.data.meta);

                    this.mappingPayee = false;
                    this.showPayeeForm = true;
                }else if(this.selectedPayeeType) {
                    this.payeeData = {
                        payee_type_id: this.selectedPayeeTypeId
                    };
                    this.showPayeeForm = true;
                }else {
                    this.resettingPayeeType = false;
                    this.showResetPayeeTypeModal = false;
                    throw new Error("Invalid payee type: " + this.selectedPayeeTypeId + " selected");
                }

                this.payeeTypeIdToUpdateTo = null;
                this.resettingPayeeType = false;
                this.showResetPayeeTypeModal = false;
            })
        },
        async getPayeeTypesForProject() {

            try {
                const types = await axios.get('/api/projects/' + this.fieldConfig.projectId + '/payee_types', {
                    params: {
                        model: this.fieldConfig.projectPayeeModelType
                    },
                    headers: {
                        Accept: 'vnd.api+json',
                    }
                });
                const parsedTypes =  Parser.parseJSONAPIResponse(types.data);

                if(typeof parsedTypes.getModels !== 'function') {
                    throw new Error('Invalid response');
                }

                this.projectPayeeTypes = parsedTypes.getModels();
            }catch(err) {
                console.log(err);
                window.notify.apiError(err);
            }
        },
        projectPayeeSaved(projectPayee) {
            this.payeeData = projectPayee;
            window.notify.success('Payee details saved successfully');
        },
        async resetProjectPayee() {
            if(!this.payeeData.id) {
                window.notify.error('No payee to reset');
                this.showResetPayeeModal = false;
                return;
            }

            this.resettingPayee = true;
            try {

                await axios.delete('/api/projects/' + this.fieldConfig.projectId + '/payee/' + this.payeeData.id, {
                    headers: {
                        Accept: 'vnd.api+json',
                    }
                });

                this.showPayeeForm = false;
                this.payeeData = {};
                this.selectedPayeeTypeId = null;
                this.resettingPayee = false;
                this.showResetPayeeModal = false;

                window.notify.success('Payee details reset successfully');

            }catch(err) {
                console.log(err);
                window.notify.apiError(err);
                this.resettingPayee = false;
                this.showResetPayeeModal = false;
            }
        }
    }
}
</script>
<template>
    <div class="border-b">
        <div>
            <div class="flex justify-between">
                <div>
                    <label class="form-control-label">
                        <span v-html="fieldConfig.label"></span>
                        <span class="required" v-if="fieldConfig.field_extra.required">
                    &nbsp;&nbsp;(*)
                </span>
                        <span
                            v-if="withHelpIcon"
                            :class="fieldConfig.field_extra.withIcon"
                            :title="fieldConfig.field_extra.helpText"
                        ></span>
                    </label>
                </div>
                <div v-if="payeeData.id">
                    <button class="button" :disabled="resettingPayee" @click="showResetPayeeModal = true">
                        Reset Payee <span v-if="resettingPayee"><i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </div>
            <div class="border-t border-1 p-4">
                <form-select
                    v-if="projectPayeeTypes.length > 0"
                    field-name="project_payee_type_id"
                    label="Project Payee Type"
                    :options="projectPayeeTypes"
                    :value="selectedPayeeTypeId"
                    @input="updateSelectedPayeeType"
                    :find-in-form="false"
                    :disabled="resettingPayee ? 1 : 0"
                />

                <vue-form
                    v-if="showPayeeForm && projectPayeeFormConfiguration"
                    :form-config="projectPayeeFormConfiguration"
                    :form-data="payeeData"
                    :form-submit-url="projectPayeeFormUrl"
                    :actions="[{name: 'submitForm', label: 'Save Payee Details', action: 'submitForm'}]"
                    :use-json-api="true"
                    :disabled="resettingPayee"
                    @created="projectPayeeSaved"
                    @updated="projectPayeeSaved"
                />

                <div v-if="mappingPayee" class=" mb-4 w-3/4 h-8 text-center mx-auto">
                    <div class="loading-bar w-1/2 text-white p-12 rounded">
                        <span class="ml-4">Mapping Payee ...</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
        <modal :is-confirm="true" v-if="showResetPayeeTypeModal" @close="showResetPayeeTypeModal = false" @confirmed="updatePayeeTypeConfirmed" :confirming="resettingPayeeType">
            <template #header>
                <h3>Reset Payee Type?</h3>
            </template>
            <template #body>
                <p>Are you sure you want to reset the payee type? Doing so will also reset any saved project payee data</p>
            </template>
        </modal>
        <modal :is-confirm="true" v-if="showResetPayeeModal" @close="showResetPayeeModal = false" @confirmed="resetProjectPayee" :confirming="resettingPayee" >
            <template #header>
                <h3>Reset Payee?</h3>
            </template>
            <template #body>
                <p>Are you sure you want to reset the payee?</p>
            </template>
        </modal>
    </div>
</template>
