<script>
export default {

    data() {
        return {
            runningAction: false,
            templateDownloadUrl: null,
            measureTabsForTemplate: null,
            importFile: [],
            queueLogsRefresh: false,
        }
    },

    computed: {
        measureTabs() {
            return this.$store.state.equipment_library.measureTabs
        }
    },

    async created() {

        if(this.measureTabs.length === 0) {
            await this.$store.dispatch('equipment_library/getMeasureTabs');
        }
        this.measureTabsForTemplate = this.measureTabs.map(mTab => {
            return mTab.id;
        })
    },


    methods: {
        async createAndDownloadTemplate() {
            this.templateDownloadUrl = null;
            this.runningAction = true;
            try {
                this.templateDownloadUrl = await this.$store.dispatch(
                    'equipment_library/createAndDownloadBulkImportTemplate',
                    this.measureTabsForTemplate
                );

                setTimeout(() => {
                    this.runningAction = false;
                    window.open(this.templateDownloadUrl);
                }, 2000);
            }catch(err) {
                console.log(err);
                window.notify.error("Unable to generate template for equipment bulk import");
                this.runningAction = false;
            }
        },
        async setupBulkImportJobsForImport() {
            if(
                !this.importFile ||
                !Array.isArray(this.importFile) ||
                this.importFile.length === 0 ||
                this.importFile.length > 1
            ) {
                window.notify.error("Unable to setup import for file");
                return;
            }

            try {
                this.runningAction = true;
                const response = await this.$store.dispatch(
                    'equipment_library/createBulkImportJobForUpload',
                    this.importFile
                );
                window.notify.success("Import setup successfully.  Please check your email for when it completes.");
                setTimeout(() => {
                    this.importFile = null;
                    this.queueLogsRefresh = true
                }, 1000);
            }catch(err) {
                console.log(err);
                window.notify.apiError(err);
            }

            this.runningAction = false;
        }
    }

}
</script>
<template>
    <div>
        <div class="shadow rounded-lg p-4">
            <h4 class="text-bold text-md text-center">Generate Template for Bulk Import</h4>
            <form-multi-select
                label="Measure groups to use on template"
                :options="measureTabs"
                field-name="measure-template-group-selector"
                v-model="measureTabsForTemplate"
            />
            <div class="flex justify-end">
                <button
                    class="button"
                    @click="createAndDownloadTemplate"
                    :disabled="runningAction"
                >
                    <span v-if="runningAction" class="fa fa-spinner fa-spin"></span>
                    Generate Template
                </button>
            </div>
        </div>
        <div class="border-t my-4 py-4">
            <h4 class="text-bold text-md text-center">Upload & Bulk Import Equipment</h4>
            <div class="w-1/2 mx-auto">
                <form-files
                    v-model="importFile"
                    field-name="bulk-import-equipment-file"
                    :max-files="1"
                    label="File to import"
                />
                <div class="flex justify-end">
                    <button class="button" @click="setupBulkImportJobsForImport" :disabled="runningAction">
                        <span v-if="runningAction" class="fa fa-spinner fa-spin"></span>
                        <span class="fa fa-upload" v-else></span>
                        Setup import
                    </button>
                </div>
            </div>
            <div>
                <h4 class="text-md">Recent Imports</h4>
                <v-grid
                    grid-type="divRow"
                    record-type="equipment-bulk-import-result-row"
                    record-url="/api/equipment/bulk_import/logs"
                    :use-json-api="true"
                    :allow-refresh="true"
                    v-model:queue-refresh="queueLogsRefresh"
                ></v-grid>
            </div>
        </div>
    </div>
</template>
