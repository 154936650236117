<script>
import {mapMutations, mapState} from "vuex";
import {byString} from "laravel-vue-forms";
import { faPlus, faSpinner, faHandPointer } from "@fortawesome/free-solid-svg-icons";


export default {
    props: {
        record: {
            required: true,
            type: Object
        },
        args: {
            type: Object
        },
    },

    data() {
        return {
            plusIcon: faPlus,
            spinnerIcon: faSpinner,
            handIcon: faHandPointer,
            showCreateModal: false,
            measuresForCreate: [],
            gettingQplItemDetails: false,
            newEquipment: {
                name: '',
                measureId: null
            },
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
            'equipmentLibraryMeasure',
        ]),
        selectedQplTypeConfig() {
            const qplConfigs = byString(this.equipmentLibraryConfiguration, 'qpl.qpls');
            if(!qplConfigs || !Array.isArray(qplConfigs)) {
                return null;
            }

            if(
                !this.args ||
                !this.args.selectedQplType
            ) {
                return null;
            }

            return qplConfigs.find(qplConfig => {
                return qplConfig.name === this.args.selectedQplType;
            });
        },
        displayColumnsForTable() {
            if(
                !this.selectedQplTypeConfig ||
                !this.selectedQplTypeConfig.searchDisplayFields
            ) {
                return [];
            }

            return this.selectedQplTypeConfig.searchDisplayFields.map(displayField => {
                return {
                    type: 'text',
                    value_field: displayField.value_field,
                    value: byString(this.record, displayField.value_field)
                }
            })
        },
        selectableForField() {
            return this.args.selectableForField ?? false;
        }
    },

    created() {
        if(this.equipmentLibraryMeasure) {
            this.newEquipment.measureId = this.equipmentLibraryMeasure.id;
        }
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView'
        ]),
        storeMeasuresForCreate(measures) {
            this.measuresForCreate = measures;
        },
        async createEquipmentFromQplItem() {

            this.gettingQplItemDetails = true;
            const measureForQPLItemWithDefaults = await this.$store.dispatch('equipment_library/getDefaultAttributesForQPLItem', {
                qplType: this.args.selectedQplType,
                qplItemId: this.record.id,
                equipmentName: this.newEquipment.name,
                measureId: this.newEquipment.measureId,
            });

            this.gettingQplItemDetails = false;
            if(!measureForQPLItemWithDefaults) {
                window.notify.error("Unable to get QPL Item for creating equipment");
                return;
            }

            this.setMainView({
                mainView: 'equipment-form',
                mainViewArgs: {
                    equipmentId: null,
                    defaultAttributes: Object.assign({
                        name: this.newEquipment.name,
                        measureId: this.newEquipment.measureId,
                        measure: measureForQPLItemWithDefaults,
                    }, measureForQPLItemWithDefaults.getOption('default_attributes'))
                }
            })
        },
        selectQpl() {
            if(!this.selectableForField) {
                return;
            }

            this.$store.commit('equipment_library/selectQplRecord', this.record);
        }
    }
}
</script>
<template>
    <tr @click="selectQpl" :class="{'cursor-pointer': selectableForField}">
        <td v-for="displayColumn in displayColumnsForTable" :key="displayColumn.value_field">
            {{ displayColumn.value }}
        </td>
        <td>
            <button class="p-1 bg-lightest-gray" @click="showCreateModal = true" v-if="!selectableForField">
                <font-awesome-icon :icon="plusIcon" />
            </button>
            <modal
                v-if="showCreateModal"
                @close="showCreateModal = false"
                modal-width="60%"
                :close-on-click-away="false"
            >
                <template #header>
                    Create Equipment
                </template>
                <template #body>
                    <div class="min-h-35vh text-left">
                        <form-text
                            field-name="name"
                            label="Name"
                            v-model="newEquipment.name"
                            :disabled="gettingQplItemDetails ? 1 : 0"
                            :find-in-form="false"
                        />
                        <form-autocomplete
                            field-name="newEquipmentMeasureId"
                            label="Measure"
                            option-label-field="name"
                            option-value-field="id"
                            options-url="/api/measures"
                            :options-url-params="{qpl_type: selectedQplTypeConfig.name}"
                            :use-json-api="true"
                            v-model="newEquipment.measureId"
                            @optionsUpdated="storeMeasuresForCreate"
                            :disabled="gettingQplItemDetails ? 1 : 0"
                            :find-in-form="false"
                        />
                        <button class="button mt-2" @click="createEquipmentFromQplItem" :disabled="gettingQplItemDetails">
                            <font-awesome-icon :icon="spinnerIcon" spin v-if="gettingQplItemDetails"/>
                            Create
                        </button>
                    </div>
                </template>
            </modal>
            <font-awesome-icon
                v-if="selectableForField"
                :icon="handIcon"
                @click="selectQpl"
                class="cursor-pointer text-primary mx-2"
            />
        </td>
    </tr>
</template>
