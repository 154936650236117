<script>
import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
import { format_dollars } from 'etap/filters/format_dollars'

export default {

    props: {
        mpid: {
            type: String,
            required: true
        },
        projectMeasure: {
            type: Object,
            required: true
        },
        formConfig: {
            type: Object,
            required: true
        }
    },

    computed: {
        measureCalculationConfig() {
            return this.$store.state.mp_layout.measure_pickers[this.mpid].calculationConfiguration;
        },
        calculationResultDisplay() {
            if (!this.measureCalculationConfig.calc_types || !Array.isArray(this.measureCalculationConfig.calc_types)) {
                return;
            }

            var calcResultDisplay = [];
            this.measureCalculationConfig.calc_types.forEach(type => {

                if(!type.display) {
                    return;
                }

                type.method.forEach(method => {

                    var pmAttribute = null;
                    var label = null;
                    if(method === 'actual') {
                        pmAttribute = type.type;
                        label = type.label;
                    }else {
                        pmAttribute = type.type + '_' + method;
                        label = type.label + ' (' + method + ')'
                    }

                    // if the attribute is empty and we don't display if empty, return
                    if(!this.projectMeasure[pmAttribute] && !type.display_if_empty) {
                        return;
                    }

                    var value = null;
                    var flag = null;
                    if(type.is_money) {
                        value = format_dollars(this.projectMeasure[pmAttribute]);
                    }
                    else if(type.display_zero_if_negative && this.projectMeasure[pmAttribute] < 0) {
                        value = 0;
                        flag = false;
                    }
                    else {
                        value = Number(this.projectMeasure[pmAttribute]).toFixed(this.measureCalculationConfig.rounding[type.type]);
                        value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add commas to large numbers
                    }


                    if(flag !== null) {
                        // do nothing flag is set from a previous check
                    } else if(value !== null && typeof value === "string" && value.substr(0, 1) === '$') {
                        flag = false;
                    } else if ( (parseFloat(value) == 0 || isNaN(parseFloat(value)) ) &&
                        ( value !== "" || value == null) ){
                        flag = true;
                    }

                    calcResultDisplay.push({
                        label,
                        value,
                        flag
                    });
                });
            });
            return calcResultDisplay;
        },
        projectMeasureEquipment() {
            var equipmentName = 'TBD';
            if(this.measureEquipment && this.projectMeasure && this.projectMeasure.equipment_id) {
                this.measureEquipment.forEach(equipment => {
                    if(equipment.id === this.projectMeasure.equipment_id) {
                        equipmentName = equipment.name;
                    }
                });
            }
            return equipmentName;
        },
        measureEquipment() {
            return this.$store.getters['mp_equipment/measureEquipmentForMeasure'](this.projectMeasure.measure.id)
        },
        hasBaselineWattage() {
            var hasBaselineWattage = false;
            if(this.formConfig) {
                this.formConfig.fields.forEach(field => {
                    if(field.value_field === 'baseline_wattage') {
                        hasBaselineWattage = true;
                    }
                });
            }
            return hasBaselineWattage;
        },
        hasReplacementWattage() {
            var hasReplacementWattage = false;
            if(this.formConfig) {
                this.formConfig.fields.forEach(field => {
                    if(field.value_field === 'replacement_wattage') {
                        hasReplacementWattage = true;
                    }
                });
            }
            return hasReplacementWattage;
        },
        baselineCount() {

            if(!this.formConfig) {
                return false;
            }


            let baselineCountField = this.formConfig.fields.find(ff => {
                if(
                    (ff.value_field === 'generic_number_1' && ff.label === 'Baseline Count') ||
                    (ff.value_field === 'baseline_count' && ff.label === 'Baseline Count')
                ) {
                    return true;
                }
            });

            if(!baselineCountField) {
                return false;
            }

            return this.getFieldValue(this.projectMeasure[baselineCountField.value_field]);
        },
        hasProposedCount() {
            var hasProposedCount = false;
            if(this.formConfig) {
                this.formConfig.fields.forEach(field => {
                    if(field.value_field === 'count' && field.label === 'Proposed Count') {
                        hasProposedCount = true;
                    }
                });
            }
            return hasProposedCount;
        },
        isNonWattsReduced() {
            var isNonWattsReduced = false;
            if(this.formConfig) {
                this.formConfig.fields.forEach(field => {
                    if(field.value_field === 'count' && field.label != 'Proposed Count') {
                        isNonWattsReduced = true;
                    }
                });
            }
            return isNonWattsReduced;
        },
        countLabel() {
            var countLabel = '';

            if(this.formConfig) {
                var countFields = this.formConfig.fields.filter(field => {
                    if(field.value_field === 'count') {
                        return field;
                    }
                });
                if(countFields.length === 1) {
                    countLabel = countFields[0].label;
                }

            }

            return countLabel
        },
        specialTextDetailsWidgets() {
            var specialTextDetailsWidgets = [];
            if(this.formConfig) {
                this.formConfig.fields.forEach(field => {

                    if(!field.value_field) {
                        return;
                    }

                    if(field.value_field.includes('special_text') && !field.label.includes('Technology')) {
                        specialTextDetailsWidgets.push({
                            widget: field.widget + '-details',
                            value_field: field.value_field,
                            label: field.label,
                            field_extra: field.field_extra
                        });
                    }

                    if(field.value_field.includes('generic_number_1')) {
                        specialTextDetailsWidgets.push({
                            value_field: field.value_field,
                            label: field.label,
                            field_extra: field.field_extra
                        });
                    }

                    if(field.label.includes('Proposed Count')) {
                        specialTextDetailsWidgets.push({
                            value_field: field.label,
                            label: field.label,
                            field_extra: field.field_extra
                        });
                    }
                })
            }
            return specialTextDetailsWidgets;
        },
        shouldDisplayEquipment() {
            if(this.formConfig) {
                let hasProposedEquipmentField = false;
                this.formConfig.fields.forEach(field => {
                    if(field.value_field === 'equipment_id') {
                        hasProposedEquipmentField = true;
                    }
                });
                if (! hasProposedEquipmentField) {
                    return false;
                }
            }
            if (this.projectMeasureEquipment || this.projectMeasure.measure.incentivized) {
                return true;
            }
            return false;
        }
    },

    filters: {
        format_dollars
    },

    methods: {
        getFieldValue(value) {
            if(value != null) {
                return value;
            }
            return 'TBD';
        },

        getSavingsSubDivClass(value) {

            if(value !== null && typeof value === "string" && value.substr(0, 1) === '$') {
                return '';
            }

            if ( (parseFloat(value) == 0 || isNaN(parseFloat(value)) ) &&
                ( value !== "" || value == null) ){
                return 'adminqczero';
            }
            return '';
        },
    }
}
</script>
<template>
    <div>
    <div v-if="projectMeasure.measure.incentivized">

        <span v-for="calculationResult in calculationResultDisplay">
            <div class="savingssubdiv" :class="{'adminqczero':calculationResult.flag}">{{ calculationResult.label}}:&nbsp;</div>
            <div class="savingssubdiv req" :class="{'adminqczero':calculationResult.flag}">{{ calculationResult.value }}</div> |
        </span>

        <table>
            <tr v-if="hasBaselineWattage || hasReplacementWattage">
                <div class="savingssubdiv req" v-if="hasBaselineWattage">Baseline Wattage: {{ projectMeasure.baseline_wattage}} |&nbsp;</div>
                <div class="savingssubdiv req" v-if="hasReplacementWattage">Replacement Wattage: {{ projectMeasure.replacement_wattage}}</div>
            </tr>
        </table>
        <component
            v-for="(specialTextDetailsWidget, index) in specialTextDetailsWidgets"
            :key="specialTextDetailsWidget.value_field"
            :label="specialTextDetailsWidget.label"
            :value_field="specialTextDetailsWidget.value_field"
            :is="specialTextDetailsWidget.widget"
            :field-extra="specialTextDetailsWidget.field_extra"
            :project-measure="projectMeasure"
        >
        </component>
        <!-- Watts Reduced measures Show Baseline and Proposed -->
        <div class="savingssubdiv req" v-if="baselineCount">Baseline Count: {{ baselineCount }} |&nbsp;</div>
        <div class="savingssubdiv req" v-if="hasProposedCount">Proposed Count: {{ getFieldValue(projectMeasure.count) }} |&nbsp;</div>

        <!-- Non Watts Reduced Measures, some measures will use the Generic Number field or the Count Field for display, but not both. -->
        <div class="savingssubdiv req" v-if="isNonWattsReduced && projectMeasure.count != null && countLabel">{{ countLabel }}: {{ projectMeasure.count}} |&nbsp;</div>
        <div class="savingssubdiv req" v-else-if="isNonWattsReduced && countLabel">{{ countLabel }}: TBD |&nbsp;</div>
    </div>
    <div v-else>
        <div class="savingssubdiv" :class="getSavingsSubDivClass(projectMeasure.full_cost)">Total Full Cost:&nbsp;</div>
        <div class="savingssubdiv req" :class="getSavingsSubDivClass(projectMeasure.full_cost)">{{ projectMeasure.full_cost | format_dollars }}</div> |
    </div>
    <div v-show="shouldDisplayEquipment" class="savingssubdiv req" :class="getSavingsSubDivClass(projectMeasure.equipment_id)"> Equipment: {{ projectMeasureEquipment }}</div>
    </div>
</template>