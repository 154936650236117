import Vue from 'vue';
import Collection from "etap/admin/classes/collection";
import FormConfigurationMeasure from "../../admin/classes/models/formConfigurationMeasure";
export const setFormConfiguration = (state, formConfig) => {

    Vue.set(state.formConfigurations, formConfig.id, formConfig);
}

export const setFormConfigurationForType = (state, {formConfig, programTrackId, programYearId, formType}) => {

    if(!state.formConfigurationsByType[programTrackId]) {
        Vue.set(state.formConfigurationsByType, programTrackId, {});
    }

    if(!state.formConfigurationsByType[programTrackId][programYearId]) {
        Vue.set(state.formConfigurationsByType[programTrackId], programYearId, {});
    }

    Vue.set(state.formConfigurations, formConfig.id, formConfig);
    Vue.set(state.formConfigurationsByType[programTrackId][programYearId], formType, formConfig);
}

export const setMeasureFormConfigurationForType = (state, {measureId, formConfig, programTrackId, programYearId, formType}) => {

    if(!state.measureFormConfigurationsByType[measureId]) {
        Vue.set(state.measureFormConfigurationsByType, measureId, {});
    }

    if(!state.measureFormConfigurationsByType[measureId][programTrackId]) {
        Vue.set(state.measureFormConfigurationsByType[measureId], programTrackId, {});
    }

    if(!state.measureFormConfigurationsByType[measureId][programTrackId][programYearId]) {
        Vue.set(state.measureFormConfigurationsByType[measureId][programTrackId], programYearId, {});
    }

    Vue.set(state.formConfigurations, formConfig.id, formConfig);
    Vue.set(state.measureFormConfigurationsByType[measureId][programTrackId][programYearId], formType, formConfig[0]);
}



export const upsertFormConfigurationMeasures = (state, formConfigurationMeasures) => {

    if(formConfigurationMeasures instanceof Collection) {
        formConfigurationMeasures = formConfigurationMeasures.getModels();
    } else if(formConfigurationMeasures  instanceof FormConfigurationMeasure) {
        formConfigurationMeasures = [formConfigurationMeasures];
    } else if(!Array.isArray(formConfigurationMeasures)) {
        throw new Error("invalid form configuration measures to upsert");
    }

    formConfigurationMeasures.forEach(formConfigurationMeasure => {

        const existingIndex = state.formConfigurationMeasures.findIndex(existingFormConfigurationMeasure => {
            return existingFormConfigurationMeasure.id === formConfigurationMeasure.id;
        });

        if(existingIndex !== -1) {
            state.formConfigurationMeasures.splice(existingIndex, 1, formConfigurationMeasure);
        } else {
            state.formConfigurationMeasures.push(formConfigurationMeasure);
        }
    });

}
