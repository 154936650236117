<template>
    <div class="bill_account_container">
        <div class="bill_account_add" v-show="canLookupAndAddBillAccounts">
            <button class="inputbutton1" :disabled="disabled" @click.prevent="showLookupModal = true">{{ trans('audit_tool.project_workflow.customer_information.bill_accounts.lookup_and_add')}}</button>
            <modal
                    v-if="showLookupModal"
                    modalWidth="60%"
                    @close="showLookupModal = false">

                <span slot="header">{{ trans('audit_tool.project_workflow.customer_information.bill_accounts.lookup_and_add')}}</span>

                <slot slot="body">
                    <div class="min-h-50vh">
                        <bill-account-lookup
                            @addBillAccountToList="addBillAccount"
                            :bill-account-form-configuration="billAccountFormConfiguration"
                            @close="showLookupModal = false"
                            :disabled="disabled"
                        >
                        </bill-account-lookup>
                    </div>
                </slot>
            </modal>
        </div>
        <div v-for="billAccount in billAccountRecords" :key="billAccount.id">
            <bill-account
                :bill-account="billAccount"
                @updateBillAccount="updateBillAccount"
                @deleteBillAccount="removeBillAccount"
                :disabled="disabled"
            ></bill-account>
        </div>
    </div>
</template>
<script>
    export default {

        props: {
            projectBillAccounts: {
                type: Array,
                required: true,
            },
            maxBillAccounts: {
                type: Number,
                default: 0
            },
            billAccountFormConfiguration: {
                type: Object,
                required: true,
            },
            projectId: {
                type: Number,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        provide() {
            let provide = {};

            Object.defineProperty(provide, 'projectId', {
                enumerable: true,
                get: () => this.projectId
            });

            Object.defineProperty(provide, 'billAccountFormConfiguration', {
                enumerable: true,
                get: () => this.billAccountFormConfiguration
            })

            return provide;
        },

        data() {
            return {
                billAccountRecords: [],
                showLookupModal: false,
                formConfig: {},
            }
        },

        created() {
            this.billAccountRecords = this.projectBillAccounts;
        },

        computed: {
            canLookupAndAddBillAccounts() {
                if(this.$can('lookup_and_add_bill_accounts_to_project') &&
                    (this.maxBillAccounts === 0 || this.billAccountRecords.length < this.maxBillAccounts) && !this.formDisabled) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        methods: {
            addBillAccount(billAccount) {


                if(billAccount.primary) {
                    this.makeOtherAccountsNotPrimary(billAccount);
                }

                this.billAccountRecords.push(billAccount);
                this.showLookupModal = false;

                this.$emit('savedAccount', this.billAccountRecords);
            },

            updateBillAccount(billAccount) {

                let billAccountIndex = this.getIndexForBillAccount(billAccount);

                if(billAccountIndex !== -1) {
                    this.$set(this.billAccountRecords, billAccountIndex, billAccount);
                }

                if(billAccount.primary) {
                    this.makeOtherAccountsNotPrimary(billAccount);
                }

                this.$emit('savedAccount', this.billAccountRecords);
            },
            removeBillAccount(billAccount) {

                let billAccountIndex = this.getIndexForBillAccount(billAccount);

                if(billAccountIndex !== -1) {
                    this.billAccountRecords.splice(billAccountIndex, 1);
                }

                this.$emit('savedAccount', this.billAccountRecords);
            },
            getIndexForBillAccount(billAccount) {

                return this.billAccountRecords.findIndex(function(account) {
                    if(billAccount.id === account.id) {
                        return true;
                    }
                    return false;
                });
            },
            makeOtherAccountsNotPrimary(billAccount) {

                this.billAccountRecords.forEach(accountRecord => {
                    if(accountRecord.id !== billAccount.id) {
                        accountRecord.primary = 0;
                    }
                })
            }


        }


    }
</script>
