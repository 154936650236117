<template>
    <div >
        <div class="ui-tabs ui-widget ui-widget-content ui-corner-all w-310 h-65vh" :class="{ 'h-95vh' : fullPicker }">
            <multi-select
                :options="measureList"
                track-by="id"
                label="name"
                placeholder="Select a group"
                v-model="tabSelected"
                class="mt-1 mb-2"
                ></multi-select>
            <div v-if="!loadingList">
                <div
                    v-for="measureTab in measureList"
                    :id="'measureTab' + measureTab.id"
                    class="ui-tabs-panel ui-widget-content ui-corner-bottom overflow-y-scroll h-60vh"
                    :class="{'h-95vh': fullPicker }"
                    v-show="activeTab == 'measureTab' + measureTab.id"
                >
                    <tab-accordion
                        :groups="measureTab.measure_groups"
                    ></tab-accordion>
                </div>
            </div>
            <div v-else class="text-center flex justify-center mb-4">&nbsp;
                <div class="loading-bar w-3/4 text-white p-12 rounded">
                    <span class="ml-4">Loading ...</span>&nbsp;
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import MultiSelect from 'vue-multiselect';
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';

    export default {


        components: {
            MultiSelect
        },

        mixins: [MeasurePickerUtils],

        data() {
            return {
                tabSelected: {
                    id: 1
                },
                loadingList: false,
            }
        },

        async created() {
            const vm = this;
            this.loadingList = true;
            await vm.$store.dispatch('mp_layout/initializeMeasureList', this.mpid).then(measureList => {
                if(Array.isArray(measureList) && measureList.length > 0) {
                    vm.tabSelected = measureList[0];
                }
            });

            setTimeout(() => {
                this.loadingList = false;
            }, 200);
        },

        computed: {
            measureList() {
                return this.$store.getters['mp_layout/getMeasureList'](this.mpid);
            },
            activeTab() {
                return 'measureTab' + this.tabSelected.id
            }
        },
    }
</script>
<style lang="scss" rel="stylesheet/scss">
    .ui-tabs-nav {
        cursor: pointer;
    }

    .selectize-dropdown-content>div {
        background-color: #999999;
    }

    .selectize-input {

        background-color: #999999;
    }

    .selectize-input.full {
        background-color: #999999;
    }
</style>
