<template>
    <div class="jobStatus">
        <h4>
            <span class="filename">{{ status.input[0].original_filename}}</span>
            <span class="jobdate">Created: {{ getCreatedDate(status.created_at) }}</span>
        </h4>
        <p>Current Status: {{ getStatusText(status.status) }}</p>
        <div class="messages" v-if="status.output">
            <collapse-list
                    v-if="status.output.error && status.output.error.length > 0"
                    :list-items="status.output.error"
            >
                <h5 slot="header">Error Messages</h5>
            </collapse-list>
            <collapse-list
                    v-if="status.output.info && status.output.info.length > 0"
                    :list-items="status.output.info"
            >
                <h5 slot="header">Information Messages</h5>
            </collapse-list>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { ucfirst } from 'etap/utilities/utils';
    export default {
        props: ['status'],

        methods: {
            getCreatedDate(created_at) {
                return moment.utc(created_at).local().format('h:mm A MMM Do YY');
            },
            getStatusText(status) {
                return ucfirst(status);
            },
        }
    }
</script>
