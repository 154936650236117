<template>
    <div class="project-leads-container">
        <div v-if="! loading && project_lead">
            <vue-form
                :form-config="leadFormConfig"
                :form-data="project_lead"
                :auto-save="false"
                :form-submit-url="'/api/projects/' + projectId + '/leads'"
                :actions="formActions"
                @share-lead="shareLead"
            ></vue-form>
            <modal
                    v-if="showConfirmModal"
                    :is-confirm="true"
                    modal-width="500px"
                    @close="showConfirmModal = false"
                    @confirmed="runSubmission"
                    confirm-text="Send"
            >

                <h4 slot="header">
                    {{ modalHeader}}
                </h4>

                <div slot="body" v-html="modalBody"></div>

            </modal>

            <div v-if="invited_leads">
                <hr />
                <div class="customerreporth3wrapper">
                    <h3>Invited Leads</h3>
                </div>
                <table>
                    <thead>
                        <th>Company</th>
                        <th>User</th>
                        <th>Sent</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        <tr v-for="lead in invited_leads">
                            <td>{{ lead.lead_company }}</td>
                            <td>{{ lead.lead_user }}</td>
                            <td>{{ lead.created_at}}</td>
                            <td>{{ lead.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <span class="fa fa-spinner fa-spin"></span>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState} from 'vuex';

export default {

    props: {
        projectId: {
            required: true,
            type: Number,
        },
        leadFormConfig: {
            required: true,
            type: Object
        },
        modalHeader: {
            required: true,
            type: String,
        },
        modalBody: {
            required: true,
            type: String,
        }
    },

    data() {
        return {
            loading: false,
            showConfirmModal: false,
            formActions: [
                {
                    name: 'share',
                    label: 'Share',
                    action: 'share-lead'
                }
            ],
            project_lead: {
                program_year: null
            },
            invited_leads: null
        }
    },

    created() {
        let project = this.$store.getters['mp_layout/getProject'](this.$store.state.mp_layout.assessmentMPID);
        this.project_lead.program_year = project.program_year_id;

        axios.get(
            '/api/projects/' + this.projectId + '/leads'
        ).then( response => {
            this.loading = false;
            this.showConfirmModal = false;

            this.invited_leads = response.data;
        }).catch( error => {
            window.notify.apiError(error);
            this.loading = false;
            this.showConfirmModal = false;
        })
    },

    computed: {},

    methods: {
        shareLead(leadData) {
            this.showConfirmModal= true;
            this.project_lead = leadData;
        },
        runSubmission() {
            this.loading = true; // not really loading data, but will allow the overlay to pop up.

            axios.post(
                '/api/projects/' + this.projectId + '/leads',
                this.project_lead
            ).then( response => {
                this.loading = false;
                this.showConfirmModal = false;

                //Zero out form after success
                this.project_lead.lead_company_id = null;
                this.project_lead.scenario_id = null;
                this.project_lead.notes = '';
                this.project_lead.sp_type = null;

                this.invited_leads = response.data.data;

                window.notify.message(response.data.message, 'success');

            }).catch( error => {
                window.notify.apiError(error);
                this.loading = false;
                this.showConfirmModal = false;
            })
        }
    }

}
</script>