<template>

    <div id="universal-page-selector" class="vue-form">
        <div class="form-group">
            <label>Project Phase:</label>
                <multi-select
                    :options="phases"
                    track-by="name"
                    label="title"
                    placeholder="Select a project phase"
                    v-model="phase"
                    select-label=""
                    deselect-label=""
                    class="form-control"
                ></multi-select>
        </div>

        <div class="form-group">
            <label>Project Scenario:</label>
                <multi-select
                    :options="scenarios"
                    track-by="id"
                    label="scenario_nickname"
                    placeholder="Select a project scenario"
                    v-model="scenario"
                    select-label=""
                    deselect-label=""
                    class="form-control"
                >
                    <template slot="option" slot-scope="props">
                        <span v-if="props.option.selected">{{ props.option.scenario_nickname }} (*)</span>
                        <span v-else>{{ props.option.scenario_nickname }}</span>
                    </template>

                </multi-select>
        </div>


        <div class="form-group">
            <form method="get">
                <input type="hidden" :value="phaseId" name="phase" />
                <input type="hidden" :value="scenarioId" name="scenario" />
                <input type="submit" class="inputbutton1" value="View" />
            </form>
        </div>
    </div>

</template>
<script>
    import MultiSelect from 'vue-multiselect';

    export default {

        components: {
            MultiSelect
        },

        props: {
            scenarios: {
                required: true,
                type: Array,
            },
            phases: {
                required: true,
                type: Array,
            },
            currentScenario: {
                required: true,
                type: Object,
            },
            currentPhase: {
                required: true,
            }
        },

        data() {

            return {
                scenario: this.currentScenario,
                phase: this.currentPhase
            }
        },

        computed: {
            phaseId() {

                if(this.phase.name) {
                    return this.phase.name
                }

                return null;
            },
            scenarioId() {

                if(this.scenario && this.scenario.id) {
                    return this.scenario.id
                }

                return null;
            },
        }


    }

</script>
