<template>
    <div class="equipment-widget">
        <div class="form-group"
             :id="fieldName + '-select-field'"
             :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }">
            <div class="flex items-end justify-between" style="width:95%">
                <form-autocomplete
                    class="w-full pb-1"
                    :find-in-form="false"
                    :label="fieldConfig.label"
                    :field-name="fieldName + 'equipment-autocomplete-selector'"
                    :value="value"
                    :options-url="currentOptionsURL"
                    :option-label-field="fieldConfig.optionLabelField"
                    :option-value-field="fieldConfig.optionValueField"
                    :placeholder="fieldConfig.label"
                    @input="updateValue"
                    :disabled="fieldConfig.disabled === 1 ? 1 : 0"
                    :use-json-api="true"
                    :json-api-includes="['equipment_measures', 'equipment_measures.measure', 'files']"
                    @options-updated="setEquipmentOptions"
                />
                <button class="p-3"
                        @click="showMeasureEquipmentLibrary"
                        :disabled="!equipmentFormConfiguration || fieldConfig.disabled === 1"
                >
                    <span class="fa fa-book"></span>
                </button>
            </div>
            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
        </span>
            <div v-if="hasHelpText">
                <span v-html="fieldConfig.field_extra.helpText"></span>
            </div>
        </div>
        <div v-can="'equipment_approve'">
            <div v-if="needsApproval" class="flex items-center justify-around">
                <form-select
                    class="w-1/2"
                    :find-in-form="false"
                    label="Equipment Approval Status"
                    :show-label="false"
                    :field-name="form.data.id + '-equipment-approval-status'"
                    v-model="currentEquipmentApprovalStatusId"
                    :options="approvalStatuses"
                    :allow-empty="false"
                    :disabled="runningAction ? 1 : 0"
                />
                <div>
                    <button class="inputbutton1 mx-2" @click="() => updateApprovalStatus()" :disabled="runningAction">
                        <span v-if="runningAction" class="fa fa-spinner fa-spin"></span>
                        Save
                    </button>
                    <button
                        class="inputbutton1"
                        v-if="hasNewerVersion"
                        @click="() => updateApprovalStatus(form.data.equipment_version_id)"
                        :disabled="runningAction"
                    >
                        <span v-if="runningAction" class="fa fa-spinner fa-spin"></span>
                        Update for version
                    </button>
                </div>
            </div>
        </div>
        <div v-if="displays && version && optionValue" class="equipment-version-information">
            <div v-if="equipmentApprovalEnabled" class="mb-2 border-b">
                <label>Equipment Approval Status:</label> {{ approvalStatus?.name }}
            </div>
            <div class="flex">
                <div class="w-half">
                    <div v-for="displayItem in displays">
                        <div
                            v-if="displayItem.model && displayItem.field_type && displayItem.field_type === 'lookup' && displayOptions[displayItem.model]">
                            <label>{{ displayItem.label }}</label><br/>
                            <span>{{ displayOptions[displayItem.model][version.attributes[displayItem.field]] }}</span>
                        </div>
                        <div
                            v-else-if="displayItem.path && displayItem.field_type && displayItem.field_type === 'path_lookup' && displayOptions[displayItem.path]">
                            <label>{{ displayItem.label }}</label><br/>
                            <span>{{ displayOptions[displayItem.path][version.attributes[displayItem.field]] }}</span>
                        </div>
                        <div v-else>
                            <label>{{ displayItem.label }}</label><br/>
                            <span>{{ version.attributes[displayItem.field] }}</span>
                        </div>
                    </div>
                </div>
                <div class="w-half" v-if="displayFiles">
                    <div v-for="displayFile in displayFiles">
                        <label>{{ displayFile.label }}</label>
                        <div v-for="file in version.relations.files">
                            <a v-if="file.meta_type === displayFile.name" @click="() => downloadFile(file.id)"
                               target="_blank" class="cursor-pointer">{{ file.original_filename }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="hasNewerVersion" class="text-right">
                There is a new version of this equipment record. Would you like to update to it?
                <button class="inputbutton1" @click="setEquipmentVersionId(optionValue)">Update</button>
            </div>
        </div>
        <transition name="fade">
            <measure-picker-equipment-library-container
                v-if="showingMeasureEquipmentLibrary"
                :measure="form.data.measure"
                @close="showingMeasureEquipmentLibrary = false"
            />
        </transition>
    </div>
</template>
<script>
import MultiSelect from 'vue-multiselect';
import {byString, FormFieldMixin} from 'laravel-vue-forms';
import {HasOptionsMixin} from 'laravel-vue-forms';
import axios from 'axios';
import Parser from "laravel-vue-forms/src/classes/jsonapi_parser";
import {mapState} from "vuex";
import isObject from "lodash/isObject";
import equipmentMeasure from "../../../admin/classes/models/equipmentMeasure";

export default {

    inject: {
        mpid: {
            default() {
                return this.$store.state.mp_layout.assessmentMPID
            }
        },
        apiClient: {
            default() {
                return axios;
            }
        }
    },


    components: {
        MultiSelect
    },

    mixins: [
        FormFieldMixin,
        HasOptionsMixin
    ],

    data() {
        return {
            watcher: null,
            runningAction: false,
            showingMeasureEquipmentLibrary: false,
            equipmentData: null,
            displayOptions: {},
            useBaseUpdateFormData: false,
            currentEquipmentApprovalStatusId: null,
        }
    },

    async created() {
        if (this.form && this.form.formConfig && Array.isArray(this.form.formConfig.fields)) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name === this.fieldName) {

                    var fieldExtra = this.getFormFieldFieldExtra(field);
                    if (typeof fieldExtra.required === 'undefined') {
                        fieldExtra.required = false;
                    }

                    if (fieldExtra.updates && Array.isArray(fieldExtra.updates)) {
                        this.$set(this.fieldConfig, 'updates', fieldExtra.updates);
                    }

                    if (fieldExtra.displays && Array.isArray(fieldExtra.displays)) {
                        this.$set(this.fieldConfig, 'displays', fieldExtra.displays);
                        this.initLookupDisplays();
                    }
                }
            });

            if (!this.equipmentFormConfiguration) {
                await this.$store.dispatch('form_configurations/getFormConfigurationsForMeasureAndType', {
                    measureId: this.measureId,
                    formType: 'equipment',
                    programTrackId: this.form.data.program_track_id,
                    programYearId: this.form.data.program_year_id
                })
            }

        }

        if(this.equipmentApprovalEnabled && this.approvalStatuses.length === 0) {
            await this.$store.dispatch(
                'lookup/getLookupForModel',
                {
                    model: this.equipmentLibraryConfiguration.approval.approvalStatuses
                }
            );
        }

        this.setupWatcher();
    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
            'selectedEquipmentForEquipmentLibraryMeasure'
        ]),
        equipmentApprovalEnabled() {
            if (
                !this.equipmentLibraryConfiguration.approval ||
                !this.equipmentLibraryConfiguration.approval.enabled
            ) {
                return false;
            }

            return true;
        },
        approvalStatuses() {
            if (
                !this.equipmentApprovalEnabled
            ) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](
                this.equipmentLibraryConfiguration.approval.approvalStatuses
            );
        },
        project() {
            if (!this.mpid) {
                return;
            }

            return this.$store.getters['mp_layout/getProject'](this.mpid);
        },
        measureId() {
            if (!this.form) {
                return;
            }

            return this.form.data.measure.id;
        },
        equipmentFormConfiguration() {

            if (!this.form) {
                return;
            }

            const equipmentMeasureFormConfiguration = this.$store.state.form_configurations
                .formConfigurationMeasures.find(formConfigurationMeasure => {
                    if (formConfigurationMeasure.measure_id === this.measureId) {
                        return true;
                    }
                });

            return equipmentMeasureFormConfiguration?.form_configuration;
        },
        displays() {

            if (!this.fieldConfig.field_extra.displays) {
                return null;
            }

            return this.fieldConfig.field_extra.displays.filter(displayItem => {
                if (displayItem.field === 'files') {
                    return false;
                }
                return true;
            });
        },
        displayFiles() {
            if (!this.fieldConfig.field_extra.displays || !this.equipmentFormConfiguration) {
                return [];
            }

            return this.fieldConfig.field_extra.displays.filter(displayItem => {
                return displayItem.field === 'files';
            }).map(displayItem => {
                return this.equipmentFormConfiguration.fields.find(ff => {
                    if (displayItem.name) {
                        return ff.value_field === displayItem.field && ff.name === displayItem.name;
                    }
                    return ff.value_field === displayItem.field;
                })
            })
        },
        version() {

            if (
                !this.optionValue
            ) {
                return {
                    attributes: {},
                    relations: {},
                };
            } else if (!this.form.data.equipment_version_id) {
                return {
                    attributes: this.optionValue,
                    relations: {
                        files: this.optionValue.files
                    },
                };
            }

            let selectedVersion = this.versionsForSelectedEquipment.find(version => {
                return version.id === this.form.data.equipment_version_id;
            });

            if (!selectedVersion) {
                return {
                    attributes: this.optionValue,
                    relations: {
                        files: this.optionValue.files
                    },
                };
            }
            return selectedVersion.version;
        },
        versionIndex() {

            if (
                !this.optionValue ||
                !this.versionsForSelectedEquipment ||
                !Array.isArray(this.versionsForSelectedEquipment) ||
                this.versionsForSelectedEquipment.length === 0 ||
                !this.form.data.equipment_version_id
            ) {
                return null;
            }

            return this.versionsForSelectedEquipment.findIndex(version => {
                return version.id === this.form.data.equipment_version_id;
            });
        },
        hasNewerVersion() {

            if (
                !this.optionValue ||
                !this.versionsForSelectedEquipment ||
                !Array.isArray(this.versionsForSelectedEquipment) ||
                this.versionsForSelectedEquipment.length === 0 ||
                !this.form.data.equipment_version_id ||
                !this.versionIndex
            ) {
                return false;
            }

            if (this.form.data.equipment_version_id !== null && !this.versionIndex) {
                // assume that the currently set version is not part of the most recent updates so display message to update
                // this should be fixed when we include the current version as part of the audits array returned from the server
                return true;
            }

            if (this.versionIndex !== 0) {
                return true;
            }

            return false;
        },
        versionsForSelectedEquipment() {
            if (!this.value) {
                return [];
            }

            return this.$store.getters['mp_equipment/getEquipmentVersionsForId'](this.value);
        },
        currentEquipmentMeasure() {
            if(!this.optionValue || !this.measureId) {
                return null;
            }

            let equipmentMeasures = this.optionValue.equipment_measures;
            if(typeof equipmentMeasures.getModels === 'function') {
                equipmentMeasures = equipmentMeasures.getModels();
            }

            return equipmentMeasures.find(em => {
                return em.measure_id === this.measureId;
            });
        },
        approvalStatus() {
            if(
                !this.equipmentApprovalEnabled ||
                !this.currentEquipmentMeasure
            ) {
                return null;
            }

            return this.approvalStatuses.find(status => {
                return status.id === byString(
                    this.version,
                    'attributes.approval_statuses.' + this.measureId
                );
            })
        },
        needsApproval() {
            if(
                !this.equipmentApprovalEnabled ||
                !this.currentEquipmentMeasure
            ) {
                return false;
            }

            const approvedSharedLookupId = this.equipmentLibraryConfiguration.approval.statusStates.approved;

            if(!this.approvalStatus || this.approvalStatus.shared_lookup_id !== approvedSharedLookupId) {
                return true;
            }

            return false;
        }
    },

    watch: {
        selectedEquipmentForEquipmentLibraryMeasure(newSelectedEquipment) {
            this.$store.commit('equipment_library/selectEquipmentForEquipmentLibraryMeasure', null);
            this.showingMeasureEquipmentLibrary = false;
            if(newSelectedEquipment) {
                this.updateValue(newSelectedEquipment);
            }
        },
        approvalStatus(newApprovalStatus) {
            if(!newApprovalStatus) {
                this.currentEquipmentApprovalStatusId = null;
                return;
            }

            this.currentEquipmentApprovalStatusId = newApprovalStatus.id;
        }
    },

    methods: {
        setEquipmentOptions(equipmentOptions) {
            equipmentOptions.forEach(equipment => {

                const existingIndex = this.fieldConfig.options.findIndex(option => {
                    return option.id === equipment.id;
                });

                if(existingIndex === -1) {
                    this.fieldConfig.options.push(equipment);
                } else {
                    this.fieldConfig.options.splice(existingIndex, 1, equipment);
                }
            })
        },

        // OVERRIDE to not get options for this field
        getOptions() {

        },

        async updateValue(value) {

            this.watcher();
            this.form.errors.clear(this.fieldConfig.value_field);
            if (value) {
                let valueId = value;
                if(isObject(value)) {
                    valueId = value[this.fieldConfig.optionValueField];
                    this.fieldConfig.options.push(value)
                } else {
                    value = this.fieldConfig.options.find(option => {
                        return option[this.fieldConfig.optionValueField] === value;
                    })

                    if(!value) {
                        window.notify.error("Unable to find equipment");
                        return;
                    }
                }

                if(!value.equipment_measures || !this.measureId) {
                    window.notify.error("Unable to find equipment measures");
                    return;
                }

                this.$emit('input', valueId);

                try {
                    await this.$store.dispatch('mp_equipment/getEquipmentVersions', {
                        equipmentId: valueId
                    });
                } catch (err) {
                    console.error(err);
                    window.notify.message("Unable to get versions for the equipment you selected", 'error');
                }

                this.setEquipmentVersionId(value);

                if (this.fieldConfig.updates) {
                    this.updateFieldsOnForm(value);
                }

            } else {
                this.setEquipmentVersionId(null);
                this.$emit('input', null)
            }
            this.setupWatcher();
        },
        setupWatcher() {
            this.watcher = this.$watch("optionValue", async function (newOptionValue) {
                if (
                    !newOptionValue ||
                    !newOptionValue.id ||
                    (
                        Array.isArray(this.versionsForSelectedEquipment) &&
                        this.versionsForSelectedEquipment.length > 0
                    )
                ) {
                    return;
                }

                try {
                    await this.$store.dispatch('mp_equipment/getEquipmentVersions', {
                        equipmentId: newOptionValue.id
                    });
                } catch (err) {
                    console.error(err);
                    window.notify.message("Unable to get versions for the equipment you selected", 'error');
                }
            });
        },
        updateFieldsOnForm(equipment) {

            if (!this.fieldConfig.updates) {
                return;
            }

            this.fieldConfig.updates.forEach(updateConfig => {
                if (updateConfig.errorWhenEmpty && updateConfig.errorMessage) {
                    var notEmpty = true;
                    // one potential option for now, later we could add more, but probably would then want to split this out
                    if (updateConfig.hasOptionsFromStore) {
                        var params = null;
                        if (Array.isArray(updateConfig.hasOptionsFromStore.params)) {
                            if (updateConfig.hasOptionsFromStore.params.length === 1) {
                                params = Object.getFormValueByString(this.form.data, updateConfig.hasOptionsFromStore.params[0]);
                            } else {
                                updateConfig.hasOptionsFromStore.params.forEach(paramString => {
                                    params[paramString] = Object.getFormValueByString(this.form.data, paramString);
                                });
                            }
                        }
                        const configOptions = this.$store.getters[updateConfig.hasOptionsFromStore.path](params);
                        const selectedOption = configOptions.find(cO => cO.id === equipment[updateConfig.from]);
                        // undefined cast to boolean is false
                        notEmpty = Boolean(selectedOption);

                    } else if (!equipment[updateConfig.from]) {
                        notEmpty = false;
                    }

                    if (notEmpty === false) {
                        window.notify.message(updateConfig.errorMessage, 'error');
                    } else {
                        this.form.data[updateConfig.to] = equipment[updateConfig.from];
                    }
                } else {
                    this.form.data[updateConfig.to] = equipment[updateConfig.from];
                }
            });
        },
        setEquipmentVersionId(measureEquipment) {

            if (
                !measureEquipment ||
                ! measureEquipment.equipment_measures ||
                (
                    typeof measureEquipment.equipment_measures.getModels !== 'function' &&
                    !Array.isArray(measureEquipment.equipment_measures)
                ) ||
                !this.versionsForSelectedEquipment ||
                !Array.isArray(this.versionsForSelectedEquipment) ||
                this.versionsForSelectedEquipment.length === 0
            ) {
                console.log(
                    'not setting equipment version id',
                    measureEquipment,
                    measureEquipment?.equipment_measures,
                    this.versionsForSelectedEquipment,
                    !Array.isArray(this.versionsForSelectedEquipment)
                )
                this.form.data.equipment_version_id = null;
                return;
            }

            let equipmentMeasures = measureEquipment.equipment_measures;
            if(typeof measureEquipment.equipment_measures.getModels === 'function') {
                equipmentMeasures = measureEquipment.equipment_measures.getModels();
            }

            const equipmentVersionId = equipmentMeasures.find(em => {
                return em.measure_id === this.measureId;
            })?.current_equipment_version_id;

            if(!equipmentVersionId) {
                this.form.data.equipment_version_id = null;
                return;
            }

            this.form.data.equipment_version_id = equipmentVersionId;
        },
        showMeasureEquipmentLibrary() {

            if (!this.equipmentFormConfiguration) {
                window.notify.message('Your measure does not have an equipment form');
                return;
            }

            this.equipmentData = {
                measure_id: this.measureId,
                company_id: this.project.company_id,
                active: 1
            };
            this.showingMeasureEquipmentLibrary = true;
        },
        initLookupDisplays() {
            // get simple model lookups

            let modelLookups = this.fieldConfig.displays.filter(display => {
                return display.field_type === 'lookup' && display.model;
            });

            if (modelLookups && modelLookups.length > 0) {
                this.apiClient.get('/api/projects/' + this.project.id + '/lookups', {
                    params: {
                        model: modelLookups.map(mLookup => mLookup.model)
                    },
                }).then(response => {

                    if (!response.data) {
                        window.notify.message('Unable to retrieve lookups for equipment display', 'error');
                        return;
                    }

                    for (var modelString in response.data) {
                        if (response.data.hasOwnProperty(modelString)) {
                            this.addModelToDisplayOptions(modelString, response.data[modelString]);
                        }
                    }

                }).catch(error => {
                    window.notify.apiError(error);
                    console.log(error);
                });
            }


            // Get path lookups

            let pathLookups = this.fieldConfig.displays.filter(display => {
                return display.field_type === 'path_lookup' && display.path;
            });

            if (pathLookups && pathLookups.length > 0) {
                pathLookups.forEach(pLookup => {
                    let api = pLookup.api_url ? pLookup.api_url : '/api/projects/' + this.project.id + '/' + pLookup.path;
                    this.apiClient.get(api)
                        .then(response => {

                            if (!response.data) {
                                window.notify.message('Unable to retrieve lookups for equipment display', 'error');
                            }
                            this.addModelToDisplayOptions(pLookup.path, response.data, pLookup.optionLabelField);

                        }).catch(error => {
                        window.notify.apiError(error);
                        console.log(error);
                    });
                });
            }
        },
        addModelToDisplayOptions(modelString, options, labelField, valueField) {

            if (!labelField) {
                labelField = 'name';
            }

            if (!valueField) {
                valueField = 'id';
            }

            var optionsObj = {};
            options.forEach(option => {
                optionsObj[option[valueField]] = option[labelField];
            });

            this.displayOptions[modelString] = optionsObj;
        },
        async downloadFile(fileId) {

            const urlResponse = await axios.get('/api/signed-download-url/' + fileId);

            if (!urlResponse.data.meta.url) {
                throw new Error("Invalid url to open");
            }

            window.open(urlResponse.data.meta.url);
        },
        async updateApprovalStatus(equipmentVersionId) {
            this.runningAction = true;
            await this.$store.dispatch('equipment_library/updateApprovalStatusForEquipment', {
                equipmentId: this.value,
                equipmentVersionId,
                approvalStatusId: this.currentEquipmentApprovalStatusId,
                measureId: this.measureId
            })

            // re-pull versions to update the version view on the equipment display
            await this.$store.dispatch('mp_equipment/getEquipmentVersions', {
                equipmentId: this.optionValue.id
            });
            this.runningAction = false;
        }
    }

}
</script>
<style lang="scss">
.form-control {
    &.mutliselect {
        border: none;
    }
}

.equipment-version-information {

    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid;
    background: #d0d0d0;
    width: 94%;
    min-height: 10px;

}
</style>
