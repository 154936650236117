<script>
import {mapMutations, mapState} from "vuex";
import Equipment from "etap/admin/classes/models/equipment";
import Collection from "etap/admin/classes/collection";
import Measure from "etap/admin/classes/models/measure";
import Parser from "etap/admin/classes/jsonapi_parser";
import EquipmentMeasure from "../../../admin/classes/models/equipmentMeasure";

export default {

    data() {
        return {
            loading: false,
            selectedViewType: '',
            showCreateModal: false,
            newEquipment: {
                name: '',
                measureId: null
            },
            measuresForCreate: [],
            recordSearchParams: {},
            jsonApiParser: Parser
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
            'equipmentLibraryMeasure',
        ]),
        equipmentSearchForm() {
            let formName = this.equipmentLibraryConfiguration.equipment_search_form;
            if(this.equipmentLibraryMeasure) {
                formName = this.equipmentLibraryConfiguration.equipment_quick_search_form;
            }

            if (!formName) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName'](
                formName
            );
        },
        displayHeaders() {
            if (
                !this.equipmentLibraryConfiguration.results_display_columns_by_view ||
                !this.equipmentLibraryConfiguration.results_display_columns_by_view[this.selectedViewType] ||
                !Array.isArray(this.equipmentLibraryConfiguration.results_display_columns_by_view[this.selectedViewType])
            ) {
                return [
                    'Name',
                    ''
                ]
            }

            let configuredHeaders = this.equipmentLibraryConfiguration.results_display_columns_by_view[this.selectedViewType].map(column => {
                return column.label;
            })

            configuredHeaders.push('');
            return configuredHeaders;
        }
    },

    async created() {
        this.loading = true;

        let formName = this.equipmentLibraryConfiguration.equipment_search_form;
        if(this.equipmentLibraryMeasure) {
            formName = this.equipmentLibraryConfiguration.equipment_quick_search_form;
        }

        if (
            !this.equipmentSearchForm &&
            formName
        ) {
            await this.$store.dispatch('form_configurations/getFormConfiguration',
                formName
            );
        }

        if(this.equipmentLibraryMeasure) {
            this.selectedViewType = 'equipment';
        }else if(this.equipmentLibraryConfiguration.default_results_view) {
            this.selectedViewType = this.equipmentLibraryConfiguration.default_results_view;
        } else {
            this.selectedViewType = 'measures';
        }

        if(this.equipmentLibraryMeasure) {
            this.recordSearchParams.measure = this.equipmentLibraryMeasure.id;
            this.newEquipment.measureId = this.equipmentLibraryMeasure.id;
            this.recordSearchParams.include = [
                'equipment_measures',
                'equipment_measures.measure',
                'files'
            ];
        }

        this.loading = false;
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView',
            'setEditingEquipmentRecord',
            'associateMeasureWithEditingEquipment'
        ]),
        updateSelectedViewType(selectedViewType) {
            this.selectedViewType = null;
            this.$nextTick(() => {
                this.selectedViewType = selectedViewType;
            })
        },
        searchForEquipment(equipmentSearchParams) {
            if(this.equipmentLibraryMeasure) {
                this.recordSearchParams = Object.assign({}, {
                    measure: this.equipmentLibraryMeasure.id
                }, equipmentSearchParams);

                return;
            }

            this.recordSearchParams = equipmentSearchParams
        },
        showCreateEquipmentModal() {
            this.showCreateModal = true;
        },
        storeMeasuresForCreate(measures) {
            this.measuresForCreate = measures;
        },
        goToEquipmentFormForCreate() {

            let measureSelectedForNewEquipment = this.measuresForCreate.find(measure => {
                return measure.id === this.newEquipment.measureId;
            });


            if (!measureSelectedForNewEquipment) {
                window.notify.error('Unable to find measure to associate');
                return;
            }

            this.setMainView({
                mainView: 'equipment-form',
                mainViewArgs: {
                    equipmentId: null,
                    defaultAttributes: {
                        name: this.newEquipment.name,
                        measure: measureSelectedForNewEquipment
                    }
                }
            })

            this.newEquipment = {
                name: '',
                measureId: this.equipmentLibraryMeasure ? this.equipmentLibraryMeasure.id : null
            };
        }
    }
}
</script>
<template>
    <div class="my-4">
        <div class="p-2 border rounded">
            <form-select
                class="w-1/4"
                field-name="equipment-listing-view-by-selector"
                label="View By"
                :options="[
                    {name: 'Equipment', value: 'equipment'},
                    {name: 'Measures', value: 'measures'}
                ]"
                :value="selectedViewType"
                @input="updateSelectedViewType"
                option-label="name"
                option-value-field="value"
                :allow-empty="false"
                :show-multiselect-labels="false"
                :find-in-form="false"
            />

            <div class="text-center flex justify-center mb-4" v-if="loading">
                <div class="loading-bar w-1/3 text-white rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
                &nbsp;
            </div>

            <hr/>

            <div>
                <vue-form
                    v-show="selectedViewType === 'equipment'"
                    v-if="equipmentSearchForm"
                    :form-config="equipmentSearchForm"
                    :form-data="{}"
                    :pass-thru="true"
                    @updated="searchForEquipment"
                    @createEquipment="showCreateEquipmentModal"
                    :use-json-api="true"
                    :actions="[
                        {name: 'search', label: 'Search', action: 'submitForm'},
                        {name: 'create', label: 'Create Equipment', action: 'createEquipment'},
                    ]"
                />
                <div v-show="selectedViewType === 'measures'">
                    <vue-form
                        :form-config="{
                            name: 'equipment-library-measure-search-form',
                            fields: [
                                {
                                    name: 'name',
                                    label: 'Measure name',
                                    value_field: 'name',
                                    widget: 'text',
                                    visible: true,
                                    disabled: false,
                                    is_eav: false,
                                    field_extra: { required: false }
                                }
                            ]
                        }"
                        :form-data="{}"
                        :pass-thru="true"
                        @updated="searchForEquipment"
                        @createEquipment="showCreateEquipmentModal"
                        :use-json-api="true"
                        :actions="[
                            {name: 'search', label: 'Search', action: 'submitForm'},
                            {name: 'create', label: 'Create Equipment', action: 'createEquipment'}
                        ]"
                    />
                </div>
            </div>
        </div>
        <div class="measure-equipment my-4 mx-1">
            <div v-if="selectedViewType === 'equipment'">
                <v-grid
                    :headers="displayHeaders"
                    :use-json-api="true"
                    record-url="/api/equipment"
                    :record-url-params="recordSearchParams"
                    record-type="equipment-item-row"
                    :json-api-parser="jsonApiParser"
                ></v-grid>
            </div>
            <div v-else-if="selectedViewType === 'measures'">
                <v-grid
                    grid-type="divRow"
                    record-type="equipment-library-measure-row"
                    :use-json-api="true"
                    record-url="/api/measures"
                    :record-url-params="recordSearchParams"
                    :json-api-parser="jsonApiParser"
                ></v-grid>
            </div>
        </div>
        <modal
            v-if="showCreateModal"
            @close="showCreateModal = false"
            modal-width="60%"
            :close-on-click-away="false"
        >
            <template #header>
                Create Equipment
            </template>
            <template #body>
                <div class="min-h-35vh">
                    <form-text
                        field-name="name"
                        label="Name"
                        v-model="newEquipment.name"
                        :find-in-form="false"
                    />
                    <form-autocomplete
                        field-name="newEquipmentMeasureId"
                        label="Measure"
                        option-label-field="name"
                        option-value-field="id"
                        options-url="/api/measures"
                        :use-json-api="true"
                        v-model="newEquipment.measureId"
                        @optionsUpdated="storeMeasuresForCreate"
                        :find-in-form="false"
                    />
                    <button class="button mt-2" @click="goToEquipmentFormForCreate">
                        Create
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>
