import { FormErrors } from "../classes/FormErrors";
import axios from "axios";
import { byString, assignOnObject } from "../utilities/utils";
import Collection from "../classes/collection";

export default {
    inject: {
        form: {
            default() {
                return {
                    disabled: false,
                    errors: new FormErrors()
                };
            }
        },
        apiClient: {
            default() {
                return axios;
            }
        },
        formHasJsonApi: {
            default: false
        }
    },

    props: {
        label: {
            type: String
        },
        fieldName: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Number,
            default: 0
        },
        findInForm: {
            type: Boolean,
            default: true
        },
        useJsonApi: {
            // allow for single component use with jsonApi
            type: Boolean,
            default: false
        },
        jsonApiIncludes: {
            type: Array|Object,
            default: () => {
                return [];
            }
        },
        children: {
            type: Array|Object,
            default: () => {
                return [];
            },
        },
    },

    data() {
        return {
            fieldConfig: {},
            useBaseUpdateFormData: true
        };
    },

    computed: {
        withHelpIcon() {
            if (this.fieldConfig.field_extra.withIcon) {
                return true;
            } else return false;
        },

        hasHelpText() {
            if (
                this.fieldConfig.field_extra.helpText &&
                !this.fieldConfig.field_extra.withIcon
            ) {
                return true;
            } else return false;
        },
        jsonApi() {
            if (this.formHasJsonApi || this.useJsonApi) {
                return true;
            }

            return false;
        }
    },

    watch: {
        "form.disabled": function(disabled) {
            this.fieldConfig.disabled = disabled ? 1 : 0;
        },
        disabled: function(disabled) {
            this.fieldConfig.disabled = disabled ? 1 : 0;
        },
        value: function(newValue) {
            this.updateFormDataFromValueChange(newValue);
        }
    },

    created() {
        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                    "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name === this.fieldName) {
                    this.$set(this.fieldConfig, "fieldName", this.fieldName);

                    var fieldExtra = this.getFormFieldFieldExtra(field);
                    if (typeof fieldExtra.required === "undefined") {
                        fieldExtra.required = false;
                    }

                    if (this.form.disabled) {
                        this.$set(this.fieldConfig, "disabled", 1);
                    } else {
                        this.$set(this.fieldConfig, "disabled", field.disabled);
                    }

                    if (
                        typeof fieldExtra.default !== "undefined" &&
                        (this.value === null ||
                            typeof this.value === "undefined")
                    ) {
                        this.$emit("input", fieldExtra.default);
                    }

                    if (fieldExtra.default) {
                        this.$set(
                            this.fieldConfig,
                            "default",
                            fieldExtra.default
                        );
                    }

                    this.$set(this.fieldConfig, "field_extra", fieldExtra);
                    this.$set(this.fieldConfig, "label", field.label);
                    this.$set(
                        this.fieldConfig,
                        "value_field",
                        field.value_field
                    );
                }
            });
        } else {
            this.$set(this.fieldConfig, "fieldName", this.fieldName);
            this.$set(this.fieldConfig, "field_extra", {
                required: this.required
            });
            this.$set(this.fieldConfig, "label", this.label);
            this.$set(this.fieldConfig, "value_field", this.fieldName);
            this.$set(this.fieldConfig, "disabled", this.disabled ? 1 : 0);
        }
    },

    methods: {
        getFormFieldFieldExtra(field) {
            var fieldExtra = field.field_extra;
            if (!fieldExtra) {
                fieldExtra = {};
            }
            return fieldExtra;
        },
        updateFormDataFromValueChange(newValue) {
            if(
                !this.fieldConfig.field_extra.updates ||
                !Array.isArray(this.fieldConfig.field_extra.updates) ||
                this.useBaseUpdateFormData === false
            ) {
                return;
            }

            const formConfig = this.form.formConfig;
            let fields = formConfig.fields;

            if(formConfig.fields instanceof Collection) {
                fields = formConfig.fields.toJSON();
            }

            let currentFormFieldRecord = fields.find(field => {
                return field.name === this.fieldName;
            });

            if(!currentFormFieldRecord) {
                throw new Error("Invalid form field record for updating form data");
            }

            if(
                currentFormFieldRecord.field_extra.updates.length > 0 &&
                currentFormFieldRecord.field_extra.updates[0].condition
            ) { // we have a conditional update, so update by condition
                this.updateFormDataByCondition(currentFormFieldRecord, newValue);
                return;
            }

            let currentOption = null;
            if(this.getConditionOptions(currentFormFieldRecord.value_field)) {
                // is a 'has options field' so get the current option

                if (
                    !currentFormFieldRecord.field_extra.options_config
                        .optionLabelField
                ) {
                    currentFormFieldRecord.field_extra.options_config.optionLabelField =
                        "name";
                }

                // Default the option value field to id, as this is the default from FormSelect
                if (
                    !currentFormFieldRecord.field_extra.options_config
                        .optionValueField
                ) {
                    currentFormFieldRecord.field_extra.options_config.optionValueField =
                        "id";
                }

                if(newValue != null) {
                    currentOption = this.getConditionOptions(
                        currentFormFieldRecord.value_field
                    ).find(option => {
                        return option[currentFormFieldRecord.field_extra.options_config.optionValueField] == newValue;
                    });

                    if(!currentOption) {
                        return;
                    }
                }
            }
            this.updateFormData(currentFormFieldRecord.field_extra.updates, currentOption);
        },
        updateFormDataByCondition(currentFormFieldRecord, newValue) {
            let validConditionOption = null;
            let updateConfig = currentFormFieldRecord.field_extra.updates.find(updateConfig => {
                // if we have condition options, search by text of option vs raw value
                if(this.getConditionOptions(currentFormFieldRecord.value_field)) {

                    if (
                        !currentFormFieldRecord.field_extra.options_config
                            .optionLabelField
                    ) {
                        currentFormFieldRecord.field_extra.options_config.optionLabelField =
                            "name";
                    }

                    // Default the option value field to id, as this is the default from FormSelect
                    if (
                        !currentFormFieldRecord.field_extra.options_config
                            .optionValueField
                    ) {
                        currentFormFieldRecord.field_extra.options_config.optionValueField =
                            "id";
                    }

                    validConditionOption = this.getConditionOptions(
                        currentFormFieldRecord.value_field
                    ).filter(option => {
                        return option[currentFormFieldRecord.field_extra.options_config.optionLabelField] == updateConfig.condition
                    }).find(option => {
                        return option[currentFormFieldRecord.field_extra.options_config.optionValueField] == newValue;
                    });

                    if(validConditionOption) {
                        return true;
                    }

                }else if(
                    updateConfig.condition === newValue
                ) {
                    return true;
                }

                return false;
            });

            if(!updateConfig || !updateConfig.change || !Array.isArray(updateConfig.change)) {
                return;
            }
            this.updateFormData(updateConfig.change, validConditionOption);
        },
        updateFormData(updateFields, validConditionOption) {

            updateFields.forEach(fieldUpdate => {
                switch(fieldUpdate.type) {
                    case 'static':
                        assignOnObject(this.form.data, fieldUpdate.field, fieldUpdate.value)
                        break;
                    case 'fromValue':
                        assignOnObject(
                            this.form.data,
                            fieldUpdate.field,
                            byString(validConditionOption, fieldUpdate.valueKey)
                        )

                        break;
                    default:
                        throw new Error("No valid field update type");
                        break;
                }

            })

        },
        getConditionOptions(valueField) {
            return byString(this.form.formFieldOptions, valueField);
        },
    }
};
