import Model from 'etap/admin/classes/models/model';
import Address from 'etap/admin/classes/models/address';
import Audit from 'etap/admin/classes/models/audit';
import User from 'etap/admin/classes/models/user';
import ProgramTrack from 'etap/admin/classes/models/programTrack';
import moment from 'moment';

class Company extends Model {


    defaults() {
        return {
            id: null,
            name: null,
            website: null,
            adobesign_username: null,
            tableau_username: null,
            project_coordinator_id: null,
            contact_id: null,
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {
            company_address: Address,
            audits: Audit,
            users: User,
            valid_program_tracks: ProgramTrack
        }
    }

    routes() {
        return {

        }
    }

}

export default Company;
