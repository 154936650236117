<template>
    <div class="measure flex"
         :class="{'ui-widget ui-widget-content': isDraggable}"
         :id="'measureSelectionItem' + measure_selection_item.id"
         :data-measureselectionitemid="measure_selection_item.id"
         :data-measureid="measure_selection_item.measure.id"
         :data-measureselectionitemdisplayname="'[' + measure_selection_item.measure.shared_measure_id + '] ' + measure_selection_item.name"
         @click="itemSelected"
    >
        <div class="handle flex items-center mr-2" v-if="isDraggable">
            <i class="fa fa-ellipsis-v"></i>&nbsp;
            <i class="fa fa-ellipsis-v"></i>
        </div>
        <div>
            {{ measure_selection_item.name }}
        </div>
    </div>
</template>
<script>
  import $ from 'jquery';

    export default {
        props: {
            measure_selection_item: {
                type: Object,
                required: true
            },
            isDraggable: {
                type: Boolean,
                default: true
            }
        },

        mounted() {

            if(!this.isDraggable) {
                return;
            }

            //Implementing 'snapped' Events http://jsfiddle.net/fhamidi/pjmje/1/
            $("#measureSelectionItem" + this.measure_selection_item.id).draggable({

                // removing containment as window snapping prevents good measure picker height
                //containment: '#measurepicker',
                appendTo: "body",
                helper: "clone",
                snap: ".emptymeasureslot",
                snapMode: "inner",
                handle: ".handle",
                drag: function (event, ui) {

                    $(".emptymeasureslot").addClass("droptargethighlight");
                    var draggable = $(this).data("ui-draggable");
                    $.each(draggable.snapElements, function (index, element) {
                        ui = $.extend({}, ui, {
                            snapElement: $(element.item),
                            snapping: element.snapping
                        });
                        if (element.snapping) {
                            if (!element.snappingKnown) {
                                element.snappingKnown = true;
                                draggable._trigger("snapped", event, ui);
                            }
                        } else if (element.snappingKnown) {
                            element.snappingKnown = false;
                            draggable._trigger("snapped", event, ui);
                        }
                    });
                },
                stop: function (event, ui) {
                    $(".emptymeasureslot").removeClass("droptargethighlight");
                },
                snapped: function (event, ui) {
                    ui.snapElement.toggleClass("droptargetgood");
                }
            });


        },


        methods: {
            getRequired(stringReq) {
                if(stringReq.indexOf('R') != -1) {
                    return 'true'
                }

                return 'false'
            },
            itemSelected() {
                if(this.isDraggable) {
                    return; // selection happens with drag
                }

                this.$emit('measureSelected', this.measure_selection_item);
            }
        }
    }
</script>
