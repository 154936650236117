<script>
import {mapState} from "vuex";
import {byString} from "laravel-vue-forms";

export default {

    data() {
        return {
            selectedQplType: null,
            loading: false,
            searchParams: {},
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration'
        ]),
        qplTypes() {
            return this.$store.getters['lookup/getLookupsForModel']('measure_equipment.qpl_types');
        },
        selectedQplTypeConfig() {
            const qplConfigs = byString(this.equipmentLibraryConfiguration, 'qpl.qpls');
            if(!qplConfigs || !Array.isArray(qplConfigs)) {
                return null;
            }

            return qplConfigs.find(qplConfig => {
                return qplConfig.name === this.selectedQplType;
            });
        },
        qplTypeSearchForm() {
            const formName = this.selectedQplTypeConfig?.searchForm

            if(!formName) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName'](
                formName
            );
        },
        qplUrl() {
            if(!this.selectedQplType) {
                return null;
            }
            return '/api/qpl_search/' + this.selectedQplType;
        },
        headersForTable() {
            if(
                !this.selectedQplTypeConfig ||
                !this.selectedQplTypeConfig.searchDisplayFields
            ) {
                return [];
            }

            let headers = this.selectedQplTypeConfig.searchDisplayFields.map(displayField => {
                return displayField.label;
            })

            headers.push('');

            return headers;
        }
    },

    watch: {
        qplTypes() {
            if(this.qplTypes.length > 0) {
                this.selectedQplType = this.qplTypes[0]['shared_lookup_id'];
            }
        },
        async selectedQplType() {
            const formName = this.selectedQplTypeConfig?.searchForm
            this.loading = true;

            try {
                if(!this.qplTypeSearchForm && formName) {
                    await this.$store.dispatch('form_configurations/getFormConfiguration', formName);
                }
            }catch(err) {
                console.log(err);
                window.notify.apiError("Unable to get form");
            }
            this.loading = false;
        }
    },

    created() {

        this.$store.dispatch('lookup/getLookupForModel', {
            model: 'measure_equipment.qpl_types',
            date_flag_filter: true
        });
    },

    methods: {
        searchForQplItems(searchParams) {
            this.searchParams = searchParams;
        },
        updateSelectedQPLType(selectedQplType) {
            this.loading = true;
            this.$nextTick(() => {
                this.selectedQplType = selectedQplType;
            })
        },
    }

}
</script>
<template>
    <div>
        <div class="p-2 border rounded">
            <form-select
                class="w-1/4"
                field-name="qpl-type-selector"
                label="QPL Type"
                :options="qplTypes"
                :value="selectedQplType"
                @input="updateSelectedQPLType"
                option-label="name"
                option-value-field="shared_lookup_id"
                :allow-empty="false"
                :show-multiselect-labels="false"
                :find-in-form="false"
            />

            <div class="text-center flex justify-center mb-4" v-if="loading">
                <div class="loading-bar w-1/3 text-white rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
                &nbsp;
            </div>

            <hr />

            <div>
                <vue-form
                    v-if="qplTypeSearchForm && !loading"
                    :form-config="qplTypeSearchForm"
                    :form-data="{}"
                    :pass-thru="true"
                    @updated="searchForQplItems"
                    :actions="[{name: 'search', label: 'Search', action: 'submitForm'}]"
                />
            </div>


        </div>
        <div class="bg-lightest-gray p-2">
            <v-grid
                class="w-full"
                v-if="qplUrl && !loading"
                :record-url="qplUrl"
                :record-url-params="searchParams"
                record-type="qpl-result-row"
                :use-json-api="true"
                :headers="headersForTable"
                :grid-args="{selectedQplType: selectedQplType}"
            />
        </div>
    </div>
</template>
