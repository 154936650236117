import { FormErrors } from "./FormErrors";
import { assignOnObject, byString } from "./../utilities/utils";
import Collection from "./collection";

export class Form {
    constructor(data, config, disabled) {
        this.initialData = data;
        this.fields = [];
        this.disabled = false;
        this.formFieldOptions = {};
        this.data = {};

        for (let field in data) {
            if (Object.prototype.hasOwnProperty.call(data, field)) {

                const formField = config.fields.find(f => f.value_field === field);
                if(formField) {
                    this.fields.push(formField);

                    assignOnObject(
                        this.data,
                        formField.value_field,
                        data[formField.value_field]
                    );
                } else {
                    this.fields.push(field);

                    assignOnObject(
                        this.data,
                        field,
                        data[field]
                    );
                }
            }
        }

        if (data.id) {
            this.id = data.id;
        } else {
            this.id = null;
        }

        if (config) {
            this.formConfig = config;
        }

        if (disabled) {
            this.disabled = true;
        }

        this.errors = new FormErrors();
    }

    /** Get the data for the form based on initial data fields **/
    getData() {
        let data = {};

        this.fields.forEach(field => {

            if(typeof field === "object") {

                if(field.widget === 'files') {
                    const potentialFiles = byString(this.data, field.value_field);
                    let value = [];
                    if(Array.isArray(potentialFiles)) {
                        value = potentialFiles
                    }
                    data[field.value_field] = value;
                }else {
                    assignOnObject(
                        data,
                        field.value_field,
                        this.data[field.value_field]
                    );
                }

            }else {
                assignOnObject(
                    data,
                    field,
                    this.data[field]
                );
            }
        });
        return data;
    }

    /** Set the updated data for the form **/
    updateData(newFormData, force) {
        if (typeof force !== "boolean") {
            force = false;
        }
        this.formConfig.fields.forEach((formField) => {
            if (!formField.value_field) {
                return;
            }

            let newFormDataValue;
            if(formField.widget === "files" && newFormData.files) {
                newFormDataValue = newFormData.files;
            } else {
                newFormDataValue = byString(newFormData, formField.value_field);
            }

            if (!newFormDataValue) {
                return;
            }

            let updateValue = false;
            if (!force) {
                let initialDataValue;
                let currentDataValue;
                if (formField.widget === "files") {
                    updateValue = true; // always update files
                } else {
                    initialDataValue = byString(
                        this.initialData,
                        formField.value_field
                    );

                    currentDataValue = byString(this.data, formField.value_field);

                    if (initialDataValue == currentDataValue) {
                        updateValue = true;
                    }
                }


            } else if (force) {
                updateValue = true;
            }

            if (!updateValue) {
                return;
            }

            if (
                formField.widget === "files" &&
                typeof newFormData.files !== "undefined"
            ) {
                if (newFormData.files && newFormData.files instanceof Collection) {
                    assignOnObject(this.data, formField.value_field, newFormData.files);
                } else if (newFormData.files && Array.isArray(newFormData.files)) {
                    assignOnObject(this.data, formField.value_field, newFormData.files);
                } else {
                    assignOnObject(this.data, formField.value_field, []);
                }
            } else {
                assignOnObject(this.data, formField.value_field, newFormDataValue);
            }
        });

        if (this.id === null && newFormData.id) {
            this.id = newFormData.id;
            this.data.id = newFormData.id;
        }

        this.wasRecentlyUpdated = true;
        setTimeout(() => {
            this.wasRecentlyUpdated = false;
        }, 500);
        // if (typeof force !== "boolean") {
        //     force = false;
        // }
        //
        // this.formConfig.fields.forEach(formField => {
        //     if (!formField.value_field) {
        //         return;
        //     }
        //
        //     let newFormDataValue = byString(newFormData, formField.value_field);
        //     if (!newFormDataValue) {
        //         return;
        //     }
        //
        //     if (!force) {
        //         let initialDataValue = byString(
        //             this.initialData,
        //             formField.value_field
        //         );
        //         let currentDataValue = byString(
        //             this.data,
        //             formField.value_field
        //         );
        //
        //         if (initialDataValue == currentDataValue) {
        //             assignOnObject(
        //                 this.data,
        //                 formField.value_field,
        //                 newFormDataValue
        //             );
        //             assignOnObject(
        //                 this.initialData,
        //                 formField.value_field,
        //                 newFormDataValue
        //             );
        //         }
        //     } else if (force) {
        //         assignOnObject(
        //             this.data,
        //             formField.value_field,
        //             newFormDataValue
        //         );
        //         assignOnObject(
        //             this.initialData,
        //             formField.value_field,
        //             newFormDataValue
        //         );
        //     }
        // });
        //
        // if (this.id === null && newFormData.id) {
        //     this.id = newFormData.id;
        //     this.data.id = newFormData.id;
        // }
    }

    /** Reset the form to the initial data **/
    reset() {
        this.formConfig.fields.forEach(field => {
            assignOnObject(
                this,
                field,
                this.initialData[field]
            );
        });
    }

    clearFields() {
        this.formConfig.fields.forEach(field => {
            assignOnObject(
                this.data,
                field.value_field,
                null
            );
        });
    }
}
