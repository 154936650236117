<script>
import Task from "../../admin/classes/models/task";

export default {

    data() {
        return {
            initializing: false,
            taskableEntityTypeName: 'project',
            taskableId: null,
            newTask: null,
        }
    },

    async created() {
        if(!this.tasksEnabled) {
            return;
        }

        try {
            this.initializing = true;

            if(!this.taskFormConfiguration && this.taskFeature.options?.tasks_form_configuration) {
                await this.$store.dispatch('form_configurations/getFormConfigurationsByName', [this.taskFeature.options?.tasks_form_configuration]);
            }

            if(!this.taskableEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', this.taskableEntityTypeName);
            }
            if(!this.taskEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', 'task');
            }

        }catch(err) {
            window.notify.apiError(err);
        }finally {
            this.initializing = false;
        }
    },

    computed: {
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        tasksEnabled() {
            return this.taskFeature?.enabled;
        },
        taskFormConfiguration() {
            if(
                !this.taskFeature?.options?.tasks_form_configuration
            ) {
                return null;
            }


            return this.$store.getters['form_configurations/getFormConfigurationByName']
            (this.taskFeature.options.tasks_form_configuration);
        },
        taskableEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName'](this.taskableEntityTypeName);
        },
        formSubmitUrl() {
            if(!this.taskableEntityType) {
                return null;
            }

            switch(this.taskableEntityType.name) {
                case 'project':
                    return '/api/projects/' + this.taskableId + '/tasks';
                default:
                    throw new Error("invalid taskable entity type");
            }
        }
    },

    methods: {
        openNewTaskForm(newTaskableId) {
            this.taskableId = newTaskableId
            if(!newTaskableId) {
                this.newTask = null;
                return;
            }

            this.newTask = new Task({
                taskable_type: this.taskableEntityType.built_in_type,
                taskable_id: newTaskableId,
            });
        },
        taskCreated(newTask) {
            window.notify.success('Task created successfully');
            setTimeout(() => {
                this.$emit('task-created', newTask);
                this.newTask = null;
            }, 3000);
        }
    }
}
</script>
<template>
    <div class="mx-2 mt-4 min-h-50vh">
        <div v-if="!initializing">
            <p class="mb-2">
                Please select a project for this task to then show the task form.
            </p>
            <form-autocomplete
                field-name="project-taskable-search-field"
                label="Project for task"
                options-url="/api/projects"
                :value="taskableId"
                @input="openNewTaskForm"
                option-label-field="name"
                option-value-field="id"
                :use-json-api="true"
            />


            <div v-if="newTask && taskFormConfiguration" class="bg-white p-2 rounded">
                <vue-form
                    :form-config="taskFormConfiguration"
                    :form-data="newTask"
                    :use-json-api="true"
                    :form-submit-url="formSubmitUrl"
                    :actions="[{label: 'Create', action: 'submitForm', name: 'create'}]"
                    @created="taskCreated"
                />
            </div>

        </div>
        <div
            v-else
            style="min-height: 10vh; display: flex; align-items: center; justify-content: center;"
        >
            <div class="text-center flex justify-center">
                <div class="loading-bar w-1/2 text-white p-12 rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
            </div>
        </div>

    </div>
</template>
