<template>
    <div class="schedule-builder form-group" :class="{ 'has-error': form.errors.has(fieldConfig.value_field) }">
        <label class="form-control-label" v-if="!asFloatLabelText"><span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
            <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
        </label>
        <div class="flex schedule-builder-input" v-if="!asFloatLabelText">
            <input
                :disabled="inputDisabled"
                type="text" :name="fieldName"
                class="form-control"
                @focus="showBuilder = true"
                autocomplete="no"
                v-model="operating_hours"
            />
        </div>
        <div v-else>
            <div class="float-input-wrapper" :class="{'hasValue': hasValue}">
                <input
                    autocomplete="off"
                    type="text"
                    v-model="operating_hours"
                    :name="fieldConfig.fieldName"
                    :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
                    @focus="showBuilder = true"
                    ref="floatLabelInput"
                />

                <div
                    class="field-placeholder"
                    @click="focusInput"
                >
                    <span v-html="fieldConfig.label"></span>
                    <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
                    <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
                </div>
            </div>
        </div>
        <div class="required-box" v-if="isEmpty">
            <i class="fa fa-exclamation-triangle"></i>
        </div>
        <span class="help-block" v-if="form.errors.has(fieldConfig.value_field)">
                {{ form.errors.get(fieldConfig.value_field, true) }}
            </span>
        <div class="dropdown-menu schedule-form-container" :class="{'show': showBuilder}">
            <div class="flex justify-space-between">
                <div class="builder-area">

                    <transition
                        enter-active-class="animated faster fadeIn"
                        leave-active-class="animated faster fadeOut"
                        mode="out-in"
                    >
                        <schedule-selector
                            v-if="selectOrCreate"
                            :project-id="projectId"
                            @create="selectOrCreate = false"
                            @useSchedule="useSchedule"
                        ></schedule-selector>
                        <schedule-builder-form v-else
                           :current-schedule="currentSchedule"
                           :project-id="projectId"
                            @input="updateOperatingHours"
                            :disabled="inputDisabled"
                        ></schedule-builder-form>
                    </transition>

                </div>
                <div class="pointer close-icon" @click="closeBuilder"><i class="fa fa-times"></i></div>
            </div>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>

    </div>
</template>
<script>
    import { FormFieldMixin } from 'laravel-vue-forms';
    import { mapActions } from 'vuex';
    export default {

        props: {
            projectId: {
                type: Number,
                default() {

                    if(this.form.data.project_id) {
                        return this.form.data.project_id;
                    }
                    if(this.form.data.projectId) {
                        return this.form.data.projectId
                    }

                    // use the id that is in the form.  Note that if this defaults from a project measure id it will likely fail
                    if(this.form.data.id) {
                        return this.form.data.id;
                    }

                    console.error('Unable to find default for projectId, did you configure the schedule builder component correctly?');
                    return null;
                }
            },
            asFloatLabelText: {
                type: Boolean,
                default: false
            }
        },

        mixins: [FormFieldMixin],

        data() {
            return {
                operating_hours: null,
                operatingHoursWather: null,
                showBuilder: false,
                selectOrCreate: true,
                currentSchedule: null,
            }
        },

        computed: {
            isEmpty() {
                if(this.required && !this.operating_hours) {
                    return true;
                }

                return false;
            },
            schedules() {
                return this.$store.getters['project_operating_schedule/getSchedulesForProject'](this.projectId);
            },
            inputDisabled() {
                return (this.fieldConfig.disabled === 1 || this.disabled === 1);
            },
            hasValue() {
                return this.operating_hours !== "" || this.operating_hours !== null;
            }
        },

        created() {
            this.operating_hours = this.value;
            this.getOperatingSchedulesForProject(this.projectId);
            this.operatingHoursWather = this.$watch('operating_hours', (updatedOperatingHours) => {
                this.form.errors.clear(this.fieldConfig.value_field);
                this.$emit('input', this.operating_hours);
            });
        },

        methods: {
            ...mapActions('project_operating_schedule', [
                'getOperatingSchedulesForProject'
            ]),
            updateOperatingHours(operatingHours) {
                this.selectOrCreate = true;
                this.showBuilder = false;
                this.operating_hours = operatingHours;
            },
            closeBuilder() {
                this.selectOrCreate = true;
                this.showBuilder = false;
            },
            useSchedule(schedule) {
                this.currentSchedule=JSON.parse(JSON.stringify(schedule));
                this.selectOrCreate = false;
            },
            focusInput() {
                this.$refs.floatLabelInput.focus();
            }
        }

    }
</script>
<style lang="scss">

   .schedule-builder {
       position: relative;

        .justify-space-between {
            justify-content: space-between;
        }

       .align-items-center {
           align-items: center;
       }

       .close-icon {
           margin: 5px;
       }

       .pointer {
           cursor: pointer;
       }

       .required-box {
           padding: 4px;
           color: #636b6f;
       }

       .builder-area{
           width: 90%;
           padding: 5px;

           .inputbutton1 {
               margin: 10px;
               height: 35px;
           }
       }

       .schedule-form {

            .inputbutton1 {
                height: 22px;
                font-size: 15px;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 12px;
                padding-right: 12px;
            }

           .flex{
               align-items: center;
           }

           .weekly-schedule-form {
               width: 50%;
               padding-left: 5px;

               h4 {
                   padding: 5px 0px;
               }

               .form-group {
                   margin-bottom: 5px;
               }

               .form-control {
                   width: 30px;
                   margin: 0;
               }
           }
       }


       .dropdown-menu {
           position: absolute;
           transform: translate3d(1%, 45px, 0px);
           top: 0px;
           left: 0px;
           width: 98%;

           &.show {
               display: block;
           }
       }



   }


</style>
