import Generic from 'etap/admin/classes/models/generic';
import Ability from 'etap/admin/classes/models/ability';
import Address from 'etap/admin/classes/models/address';
import Audit from 'etap/admin/classes/models/audit';
import Company from 'etap/admin/classes/models/company';
import Feature from 'etap/admin/classes/models/feature';
import File from 'etap/admin/classes/models/file';
import FormConfiguration from 'etap/admin/classes/models/formConfiguration';
import FormField from 'etap/admin/classes/models/formField';
import Role from 'etap/admin/classes/models/role';
import User from 'etap/admin/classes/models/user';
import EntityType from "etap/admin/classes/models/entity_type";
import Appointment from "etap/admin/classes/models/appointment";
import AppointmentOption from "etap/admin/classes/models/appointmentOption";
import UserSchedule from "etap/admin/classes/models/user_schedule";
import Lookup from "etap/admin/classes/models/lookup";
import Measure from "etap/admin/classes/models/measure";
import Equipment from "etap/admin/classes/models/equipment";
import EquipmentMeasure from "etap/admin/classes/models/equipmentMeasure";
import FormConfigurationMeasure from "etap/admin/classes/models/formConfigurationMeasure";
import MeasureTab from "etap/admin/classes/models/measureTab";
import Task from "etap/admin/classes/models/task";
import Note from "etap/admin/classes/models/note";

export default {
    generic: Generic,
    ability: Ability,
    address: Address,
    audit: Audit,
    company: Company,
    entity_type: EntityType,
    feature: Feature,
    file: File,
    form_configuration: FormConfiguration,
    form_field: FormField,
    role: Role,
    user: User,
    lookup: Lookup,
    user_schedule: UserSchedule,
    appointment: Appointment,
    appointment_option: AppointmentOption,
    measure: Measure,
    equipment: Equipment,
    equipment_measure: EquipmentMeasure,
    form_configuration_measure: FormConfigurationMeasure,
    measure_tab: MeasureTab,
    task: Task,
    note: Note,
}
