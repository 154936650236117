import Parser from 'etap/admin/classes/jsonapi_parser';
import apiClient from 'etap/admin/apiClient';
import Collection from 'etap/admin/classes/collection';

export default {
    namespaced: true,
    state: {
        visionPrograms: new Collection([]),
    },
    actions: {
        getVisionPrograms(context) {
           return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/vision/programs').then(response => {
                    var visionPrograms = Parser.parseJSONAPIResponse(response.data)
                    context.commit('setVisionPrograms', visionPrograms);
                    resolve(visionPrograms);
                });
           });
        },
        getVisionProgramLogsForid(context, visionProgramId)  {
            return new Promise((resolve, reject) => {
                apiClient.get('/api/admin/vision/programs/' + visionProgramId + '/logs').then(response => {
                    var visionProgramLogs = Parser.parseJSONAPIResponse(response.data);
                    context.commit('addLogsToVisionProgram', {visionProgramId, logs: visionProgramLogs});
                    resolve(visionProgramLogs);
                }).catch(error => {
                    console.log(error);
                });
            });
        }
    },
    mutations: {
        setVisionPrograms(state, programs){
            programs.forEach(program => {
                program.ensureRelationshipIsSet('logs');
            });
            state.visionPrograms = programs;
        },
        addLogsToVisionProgram(state, {visionProgramId, logs}) {

            var visionProgram = state.visionPrograms.filter({id: visionProgramId}).first();

            if(!visionProgram) {
                console.error('Unable to find vision program');
                return;
            }

            visionProgram.setRelationship('logs', logs);
        }
    }
}
