import Vue from "vue";


export const defaultMPScenarios = (state, mpid) => {
    Vue.set(state.measure_picker_scenarios, mpid, state.default_scenario_configuration);
}

export const updateCurrentScenario = (state, {mpid, scenario}) => {
    state.measure_picker_scenarios[mpid].currentScenario = scenario;
}

export const loadScenarios = (state, {mpid, scenarios}) => {

    state.measure_picker_scenarios[mpid].scenarios = scenarios;
}

export const addScenario = (state, {mpid, scenario}) => {
    state.measure_picker_scenarios[mpid].scenarios.push(scenario);
}




export const updateScenarioNickname = (state, {mpid, newNickname, scenarioId }) => {

    if(state.measure_picker_scenarios[mpid].currentScenario.id === scenarioId) {
        state.measure_picker_scenarios[mpid].currentScenario.scenario_nickname = newNickname;
    }

    state.measure_picker_scenarios[mpid].scenarios.forEach(scenario => {
        if(scenario.id === scenarioId) {
            scenario.scenario_nickname = newNickname;
        }
    })

}

export const updateScenario = (state, {mpid, newScenario}) => {

    state.measure_picker_scenarios[mpid].scenarios.forEach( (scenario, index) => {
        if(scenario.id === newScenario.id) {
            state.scenarios.splice(index, 1, newScenario);
        }
    });

}

