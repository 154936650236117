import Model from 'etap/admin/classes/models/model';
import UserSchedule from "./user_schedule";
import User from "./user";
import Lookup from "./lookup";

class Appointment extends Model {

    defaults() {
        return {
            id: null,
            appointmentable_type: null,
            appointmentable_id: null,
            shared_appointment_id: null,
            user_id: null,
            title: null,
            appointment_type_id: null,
            appointment_schedule_id: null,
            scheduled_appointment_at: null,
            appointment_first_available: null,
            appointment_duration: null,
        }
    }

    dateTimeFormat() {
        return 'MM/DD/YYYY hh:mm a';
    }

    casts() {
        return {
            created_at: this.parseDateTime,
            updated_at: this.parseDateTime
        }
    }


    relationships() {
        return {
            appointment_type: Lookup,
            appointment_schedule: UserSchedule,
            user: User,
        }
    }
}

export default Appointment;
