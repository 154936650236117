<template>
    <div
        class="form-group"
        :id="fieldName + '-text-field'"
    >
        <label class="form-control-label">
            <span v-html="fieldConfig.label"></span>
        </label>
        <div>
            <input
                class="form-control"
                ref="autocomplete"
                key="autocomplete"
                :id="id"
                placeholder="Enter an address"
                type="text"
                :disabled="fieldDisabled"
            />
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
<script>
    import {FormFieldMixin, assignOnObject} from "laravel-vue-forms";
    import { guid } from "../../utilities/utils";

    export default {

        mixins: [
            FormFieldMixin
        ],

        data() {
            const ffId = guid() + '-autocomplete-google';
            return {
                autocomplete: null,
                id: ffId
            }
        },

        computed: {
            fieldDisabled() {
                return Boolean(this.fieldConfig.disabled);
            }
        },

        mounted() {
            const options = {
                types: [
                    'address'
                ],
                componentRestrictions: {
                    country: ['us']
                    //administrative_area_level_1: ['Illinois'] //Not supported
                },
                offset: 3 //Don't query until this many characters are entered
            }

            this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id),
                options
            )

            this.autocomplete.setFields(
                [
                    'address_components', //Discrete address components
                    'adr_address', //Address formatted for display
                    'formatted_address', //Address string as it appears for user selection
                    'geometry', //Contains lat/lon and viewport for maps
                    'place_id' //Google's ID for place
                ]
            )

            this.autocomplete.addListener('place_changed', this.onPlaceChanged)
        },

        methods: {
            onPlaceChanged() {
                let place = this.autocomplete.getPlace();
                //console.log(place);

                if (!place.geometry) {
                  //User did not select a prediction; reset the input field
                  return;
                }

                if(typeof place.address_components === "undefined") {
                    return;
                }

                const parsedResponse = this.parseResponse(place)
                //console.log(place, parsedResponse);
                this.updateFieldsOnForm(parsedResponse);
            },

            parseResponse(place) {

                 const ADDRESS_COMPONENTS = {
                    subpremise : 'short_name',
                    street_number: 'short_name', //Street Number
                    route: 'long_name', //Road/Address
                    locality: 'long_name', //City
                    administrative_area_level_1: 'short_name', //State
                    administrative_area_level_2: 'long_name', //County
                    // country: 'long_name',
                    postal_code: 'short_name', //5 digit zip code
                    postal_code_suffix: 'short_name' //4 digit zip code suffix
                };

                let returnData = {};
                // Get each component of the address from the place details,
                // and then fill-in the corresponding field on the form.
                // place.address_components are google.maps.GeocoderAddressComponent objects
                // which are documented at http://goo.gle/3l5i5Mr
                for(const component of place.address_components) {
                    let componentType = component.types[0];
                    if (ADDRESS_COMPONENTS[componentType]) {
                        returnData[componentType] = component[ADDRESS_COMPONENTS[componentType]];
                    }
                }

                //Handle some address data formatting
                if(returnData['street_number']) {
                    returnData['address_1'] = returnData['street_number'] + ' ' + returnData['route'];
                } else {
                    returnData['address_1'] = returnData['route'];
                }

                //Extra info outside address_components array
                // todo: disable for now until we determine requirements for lat/long
                // returnData['latitude'] = place.geometry.location.lat();
                // returnData['longitude'] = place.geometry.location.lng();

                return returnData
            },
            updateFieldsOnForm(address) {
                if(!this.fieldConfig?.field_extra?.updates) {
                    return;
                }

                this.fieldConfig.field_extra.updates.forEach(function(updateConfig) {
                    assignOnObject(this.form.data, updateConfig.to, address[updateConfig.from]);
                }.bind(this));
            },
        }

    }
</script>
<style>
    .pac-container {
        z-index: 10000 !important; /* Needed to show autocomplete dropdown in modal */
    }
</style>
