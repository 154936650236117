import Model from 'etap/admin/classes/models/model';
import Measure from "etap/admin/classes/models/measure";

class EquipmentMeasure extends Model {


    defaults() {
        return {
            equipment_id: '',
            measure_id: '',
            approval_status_id: null,
            current_equipment_version_id: null,
            equipment_measure_form_configuration_id: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
        }
    }

    relationships() {
        return {
            measure: Measure,
        }
    }

    routes() {
        return {

        }
    }

}

export default EquipmentMeasure;
