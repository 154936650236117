<template>
    <div>
        <equipment-library-measure v-for="measure in measures" :key="measure.id" :measure="measure"></equipment-library-measure>
        <div v-if="measures.length === 0 && !isLoading">
            There were no measures that matched your query
        </div>
        <div v-else-if="isLoading" class="loading-icon">
            <span class="fa fa-spinner fa-spin fa-2x"></span>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    export default {

        computed: {
            ...mapGetters('equipment_library', [
                'measures',
            ]),
            ...mapState('equipment_library', [
                'isLoading'
            ])
        }
    }
</script>
