

var defaults = {
    defaultLocale: 'en' /** The default locale if not set. */
};

var Lang = function(options) {
    options = options || {};
    this.defaultLocale = options.defaultLocale || defaults.defaultLocale;
};

// Methods //

/**
 * Set messages source.
 *
 * @param messages {object} The messages source.
 *
 * @return void
 */
Lang.prototype.setMessages = function(messages) {
    this.messages = messages;
};

/**
 * Returns a translation message.
 *
 * @param key {string} The key of the message.
 * @param replacements {object} The replacements to be done in the message.
 *
 * @return {string} The translation message, if not found the given key.
 */
Lang.prototype.get = function(key, replacements) {
    if (!this.has(key)) {
        return key;
    }

    var message = this._getMessage(key, replacements);
    if (message === null) {
        return key;
    }

    if (replacements) {
        message = this._applyReplacements(message, replacements);
    }

    return message;
};

/**
 * Returns true if the key is defined on the messages source.
 *
 * @param key {string} The key of the message.
 *
 * @return {boolean} true if the given key is defined on the messages source, otherwise false.
 */
Lang.prototype.has = function(key) {
    if (typeof key !== 'string' || !this.messages) {
        return false;
    }
    return this._getMessage(key) !== null;
};

/**
 * Gets the plural or singular form of the message specified based on an integer value.
 *
 * @param key {string} The key of the message.
 * @param count {integer} The number of elements.
 * @param replacements {object} The replacements to be done in the message.
 *
 * @return {string} The translation message according to an integer value.
 */
Lang.prototype.choice = function(key, count, replacements) {
    // Set default values for parameters replace and locale
    replacements = typeof replacements !== 'undefined' ? replacements : {};

    // The count must be replaced if found in the message
    replacements['count'] = count;

    // Message to get the plural or singular
    var message = this.get(key, replacements);

    // Check if message is not null or undefined
    if (message === null || message === undefined) {
        return message;
    }

    // Separate the plural from the singular, if any
    var messageParts = message.split('|');

    // Get the explicit rules, If any
    var explicitRules = [];
    var regex = /{\d+}\s(.+)|\[\d+,\d+\]\s(.+)|\[\d+,Inf\]\s(.+)/;

    for (var i = 0; i < messageParts.length; i++) {
        messageParts[i] = messageParts[i].trim();

        if (regex.test(messageParts[i])) {
            var messageSpaceSplit = messageParts[i].split(/\s/);
            explicitRules.push(messageSpaceSplit.shift());
            messageParts[i] = messageSpaceSplit.join(' ');
        }
    }

    // Check if there's only one message
    if (messageParts.length === 1) {
        // Nothing to do here
        return message;
    }

    // Check the explicit rules
    for (var i = 0; i < explicitRules.length; i++) {
        if (this._testInterval(count, explicitRules[i])) {
            return messageParts[i];
        }
    }

    // Standard rules
    if (count > 1) {
        return messageParts[1];
    } else {
        return messageParts[0];
    }
};

/**
 * Set the current locale.
 *
 * @param locale {string} The locale to set.
 *
 * @return void
 */
Lang.prototype.setLocale = function(locale) {
    this.locale = locale;
};

/**
 * Get the current locale.
 *
 * @return {string} The current locale.
 */
Lang.prototype.getLocale = function() {
    return this.locale || this.defaultLocale;
};


/**
 * Parse a message key into components.
 *
 * @param key {string} The message key to parse.
 *
 * @return {object} A key object with source and entries properties.
 */
Lang.prototype._parseKey = function(key) {
    if (typeof key !== 'string') {
        return null;
    }
    var segments = key.split('.');
    return {
        source: this.getLocale(),
        entries: segments
    };
};

/**
 * Returns a translation message. Use `Lang.get()` method instead, this methods assumes the key exists.
 *
 * @param key {string} The key of the message.
 *
 * @return {string} The translation message for the given key.
 */
Lang.prototype._getMessage = function(key) {

    key = this._parseKey(key);

    // Ensure message source exists.
    if (this.messages[key.source] === undefined) {
        return null;
    }

    // Get message text.
    var message = this.messages[key.source];
    while (key.entries.length && (message = message[key.entries.shift()]));

    if (typeof message !== 'string') {
        return null;
    }

    return message;
};

/**
 * Apply replacements to a string message containing placeholders.
 *
 * @param message {string} The text message.
 * @param replacements {object} The replacements to be done in the message.
 *
 * @return {string} The string message with replacements applied.
 */
Lang.prototype._applyReplacements = function(message, replacements) {
    for (var replace in replacements) {
        message = message.split(':' + replace).join(replacements[replace]);
    }
    return message;
};

/**
 * Checks if the given `count` is within the interval defined by the {string} `interval`
 *
 * @param  count {int}  The amount of items.
 * @param  interval {string}    The interval to be compared with the count.
 * @return {boolean}    Returns true if count is within interval; false otherwise.
 */
Lang.prototype._testInterval = function(count, interval) {
    /**
     * From the Symfony\Component\Translation\Interval Docs
     *
     * Tests if a given number belongs to a given math interval.
     * An interval can represent a finite set of numbers: {1,2,3,4}
     * An interval can represent numbers between two numbers: [1, +Inf] ]-1,2[
     * The left delimiter can be [ (inclusive) or ] (exclusive).
     * The right delimiter can be [ (exclusive) or ] (inclusive).
     * Beside numbers, you can use -Inf and +Inf for the infinite.
     */

    return false;
};



/* Export: */

export default Lang;

