<template>
    <div v-if="! loading && inspection">
        <vue-form
            :form-config="inspectionFormConfig"
            :form-data="inspectionData"
            :auto-save="false"
            :disabled="form_disabled"
            :form-submit-url="'/api/projects/' + project.id + '/inspections/' + inspection.id"
            :actions="formActions"
            @changed="checkForChecklistUpdates"
            @updated="updateInspection"
            @waive-inspection="waiveInspection"
        ></vue-form>
        <span v-if="inspection.waived">Inspection waived at: {{ inspectionUpdatedDate }}</span><br>
        <span v-if="inspection.inspector && inspection.inspector.user_select_title">Inspection done by: {{ inspection.inspector.user_select_title }}</span>
    </div>
    <span v-else class="fa fa-spinner fa-spin"></span>
</template>
<script>
import { mapMutations, mapState} from 'vuex';

export default {

    props: {
        project: {
            required: true,
            type: Object,
        },
        phaseId: {
            required: true,
            type: Number,
        },
        phaseAvailable: {
            required: true,
            type: Number
        },
        inspectionFormConfig: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            loading: false,
            formActions: [
                {
                    name: 'submit',
                    label: 'Submit',
                    action: 'submitForm'
                },
                {
                    name: 'waive_inspection',
                    label: 'Waive Inspection',
                    action: 'waive-inspection'
                },
            ]
        }
    },

    created() {

        this.loading = true;

        this.$store.dispatch('inspections/getProjectInspection', {
            project_id: this.project.id,
            phase_id: this.phaseId
        }).then(inspection => {
            this.loading = false;
        });

        this.$store.commit('inspections/setInspectionFormConfigId', this.inspectionFormConfig.id);
    },

    computed: {
        inspection() {
            return this.$store.getters['inspections/getInspectionForProjectAndPhase'](this.project.id, this.phaseId);
        },
        inspectionUpdatedDate() {
            if(!this.inspection) { return; }

            return moment.utc(this.inspection.updated_at).local().format('h:mm A MMM Do YYYY');
        },
        form_disabled() {
            return Boolean(this.inspection.waived) || Boolean(this.inspection.status_name);
        },
        currentPhase() {
            return this.$store.getters.getProjectPhaseForId(this.phaseId);
        },
        projectProgramTrack() {
            return this.$store.getters.getProgramTrackForId(this.project.program_track_id);
        },
        projectProgramYear() {
            return this.$store.getters.getProgramYearForId(this.project.program_year_id);
        },
        checklistConfigurationType(){

            if(!this.currentPhase) {
                return null;
            }

            switch(this.currentPhase.name) {
                case 'pre_inspection':
                    return 'pre_installation_inspection';
                case 'post_inspection':
                    return 'post_installation_inspection';
            }

            return null;
        },
        checklistConfiguration() {

            if(!this.projectProgramTrack || !this.projectProgramYear || !this.checklistConfigurationType) {
                return null;
            }

            return this.$store.getters['checklists/getChecklistConfigurationForType'](
                this.projectProgramTrack,
                this.projectProgramYear,
                this.checklistConfigurationType
            )
        },
        currentChecklistItems() {

            if(!this.checklistConfigurationType) {
                return [];
            }

            return this.$store.getters['checklists/checklistItemsForModel'](this.inspection, 'project_inspection');
        },
        inspectionData() {

            var inspectionData = JSON.parse(JSON.stringify(this.inspection));

            if(this.checklistConfiguration && this.currentChecklistItems) {
                this.inspectionFormConfig.fields.forEach(formField => {

                    let checklistConfigItem = this.checklistConfiguration.checklist_configuration_items.find(checklistConfigurationItem => {
                        return checklistConfigurationItem.name === formField.value_field;
                    });

                    if(!checklistConfigItem) { return; }
                    let currentChecklistItem = this.currentChecklistItems.find(item => {
                        return item.checklist_configuration_item_id === checklistConfigItem.id
                    });

                    if(currentChecklistItem && Boolean(currentChecklistItem.checked)) {
                        inspectionData[formField.value_field] = true;
                    }
                });
            }

            return inspectionData;
        }
    },

    methods: {
        updateInspection(inspection) {
            window.notify.message('Successfully saved inspection', 'success');
            this.$store.commit('inspections/updateInspection', inspection);
        },
        waiveInspection(inspection) {
            this.$store.dispatch('inspections/waiveInspection', {
                projectId: this.project.id,
                inspection: inspection
            });
        },
        checkForChecklistUpdates(changedInfo) {

            if(!this.checklistConfiguration) {
                return;
            }

            var checklistItemValues = [];
            // pull out checklist items from the form
            this.checklistConfiguration.checklist_configuration_items.forEach(checklistConfigItem => {
                if(changedInfo[checklistConfigItem.name]) {
                    checklistItemValues.push({
                        checklistConfigItem,
                        checked: true
                    });
                }else if(changedInfo.hasOwnProperty(checklistConfigItem.name)) {
                    checklistItemValues.push({
                        checklistConfigItem,
                        checked: false
                    });
                }
            });

            // next pull only the checklist items that actually changed
            var changedChecklistItems = [];
            checklistItemValues.forEach(checklistItemValue => {
                let currentChecklistItem = this.currentChecklistItems.find(item => {
                    return item.checklist_configuration_item_id === checklistItemValue.checklistConfigItem.id
                });

                if(currentChecklistItem && Boolean(currentChecklistItem.checked) !== Boolean(checklistItemValue.checked)) {
                    changedChecklistItems.push(checklistItemValue);
                } else if(checklistItemValue.checked && !currentChecklistItem) {
                    changedChecklistItems.push(checklistItemValue);
                }
            });

            // update the changed checklist items (generally this will really only ever be 1 in practice)
            changedChecklistItems.forEach(changedChecklistItem => {
                this.$store.dispatch('checklists/setRelatedProjectChecklistForType', {
                    projectId: this.project.id,
                    model: this.inspection,
                    modelType: 'inspections',
                    checklistItemName: changedChecklistItem.checklistConfigItem.name,
                    checked: changedChecklistItem.checked
                }).then(response => {

                });
            });


            if(window.Bus && changedChecklistItems.length > 0) {
                setTimeout(() => {
                    window.Bus.$emit('updateChecklist');
                }, 500);
            }
        }
    }

}
</script>
