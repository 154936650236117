

export const format_dollars = value => {
    if (value == undefined || value == null || isNaN(value)) {
        return 'TBD';
    } else {
        var formatted = parseFloat(value).toFixed(2)
        formatted = formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$" + formatted;
    }
}
