<template>
    <div class="flex justify-center mb-4" :class="{'h-screen w-full': !hasImage}">
        <div class="card-2" :class="[hasImage ? 'w-3/4 flex' : 'w-full md:w-1/2 lg:w-1/3']">
            <div class="text-center flex flex-col justify-center" :class="{'w-1/2': hasImage}">
                <div class="" v-if="currentForm === 'login'">

                    <h2 class="my-8">Login</h2>
                    <p class="text-red" v-if="errorMessage">
                        {{ errorMessage }}
                    </p>
                    <p class="text-green mx-4" v-if="message">
                        {{ message }}
                    </p>
                    <div class="flex justify-center mb-4">
                        <form class="w-2/3 text-left" @submit.prevent="submitUserForm">
                            <div class="form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    class="block rounded w-full"
                                    style="border: 1px solid; padding:10px;"
                                    v-model="username"
                                />
                            </div>
                            <div class="form-group" v-show="accountType === 'local'">
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    class="block rounded w-full"
                                    style="border: 1px solid; padding:10px;"
                                    v-model="password"
                                />
                            </div>
                            <button
                                class="w-full inputbutton1 rounded"
                                @click="determineAccountType"
                                v-if="accountType === null"
                                :disabled="submittingForm"
                            >
                                <i class="fa fa-spinner fa-spin" v-if="submittingForm"></i>
                                Continue
                            </button>
                            <button
                                class="w-full inputbutton1 rounded"
                                @click="attemptLogin"
                                v-if="accountType === 'local'"
                                :disabled="submittingForm"
                            >
                                <i class="fa fa-spinner fa-spin" v-if="submittingForm"></i>
                                Login
                            </button>
                        </form>
                    </div>
                    <span class="text-sm smallnotloggedin mt-4" v-if="hasOtherLoginOptions">OR</span>
                    <div class="flex justify-center my-4">
                        <div class="w-2/3">
                            <button class="inputbutton1 rounded" v-if="usesOneLogin" @click="goToOneLogin">
                                {{ oneLoginButtonLabel }}
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-center mt-4 mb-2" v-if="hasResetPassword || hasSignup">
                        <hr class="w-2/3" />
                    </div>
                    <div class="flex justify-center">
                        <div class="w-2/3 flex justify-around">
                            <a class="block text-color1" href="" @click.prevent="showResetPasswordForm"
                               v-if="hasResetPassword">Reset Password</a>
                            <a class="block text-color1" href="" @click.prevent="showSignUpForm" v-if="hasSignup">Sign
                                up</a>
                        </div>
                    </div>

                </div>

                <div v-else-if="currentForm === 'resetForm'">
                    <div class="smallnotloggedin text-left flex justify-center">
                        <div class="w-2/3 cursor-pointer mb-4" @click="resetCurrentForm">
                            <i class="fa fa-arrow-left"></i>&nbsp; Go Back
                        </div>
                    </div>
                    <p class="text-red mx-4" v-if="errorMessage">
                        {{ errorMessage }}
                    </p>
                    <p class="text-green mx-4" v-if="message">
                        {{ message }}
                    </p>
                    <h2 class="my-8">Reset Password</h2>
                    <div class="flex justify-center mb-4">
                        <div class="w-2/3 text-left">
                            <div class="form-group">
                                <label>Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="rpw-username"
                                    class="block rounded w-full"
                                    style="border: 1px solid; padding:10px;"
                                    v-model="username"
                                />
                            </div>
                            <button class="w-full inputbutton1 rounded" @click="resetPassword">Reset Password</button>
                        </div>
                    </div>

                </div>

                <div v-else-if="currentForm === 'signUpForm'">
                    <sign-up-form
                        @resetCurrentForm="resetCurrentForm"
                        :company-sign-up-form="companySignUpForm"
                        :user-sign-up-form="userSignUpForm"
                    ></sign-up-form>
                </div>

                <div v-else-if="currentForm === 'twoFactorForm'">
                    <h2 class="my-8">Login</h2>
                    <p class="text-red" v-if="errorMessage">
                        {{ errorMessage }}
                    </p>
                    <p class="text-green mx-4" v-if="message">
                        {{ message }}
                    </p>
                    <div class="flex justify-center mb-4">
                        <form class="w-2/3 text-left" @submit.prevent="verifyTwoFactorCode">
                            <div class="form-group">
                                <label v-if="!usingTwoFactorRecoveryCode">Two Factor Code</label>
                                <label v-else>Recovery Code</label>
                                <input
                                    type="text"
                                    name="twoFactorCode"
                                    id="twoFactorCode"
                                    class="block rounded w-full"
                                    style="border: 1px solid; padding:10px;"
                                    v-model="twoFactorCode"
                                />
                            </div>
                            <button
                                class="w-full inputbutton1 rounded"
                                @click="verifyTwoFactorCode"
                                v-if="accountType === 'local'"
                                :disabled="submittingForm"
                            >
                                <i class="fa fa-spinner fa-spin" v-if="submittingForm"></i>
                                Verify Code
                            </button>
                        </form>
                    </div>
                    <span class="text-sm smallnotloggedin mt-4">OR</span>
                    <div class="flex justify-center my-4">
                        <div class="w-2/3">
                            <button class="inputbutton1 rounded" @click="useRecoveryTwoFactorCode"
                                    v-if="!usingTwoFactorRecoveryCode">Use Recovery Code
                            </button>
                            <button class="inputbutton1 rounded" @click="useNormalTwoFactorCode" v-else>Use Normal
                                Code
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="w-1/2 my-2" v-if="hasImage">
                <img :src="assetBaseUrl + loginImage" class="w-full" />
            </div>

        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Parser from 'etap/admin/classes/jsonapi_parser';

export default {

    props: {
        usesOneLogin: {
            type: Boolean,
            default: false
        },
        oneLoginButtonLabel: {
            type: String,
            default: 'Login with OneLogin'
        },
        usesTwoFactor: {
            type: Boolean,
            default: false
        },
        companySignUpForm: {
            type: Object
        },
        userSignUpForm: {
            type: Object,
        },
        hasSignup: {
            type: Boolean,
            default: false
        },
        hasResetPassword: {
            type: Boolean,
            default: false
        },
        loginImage: {
            type: String,
            default: ''
        }
    },



    data() {
        // todo deal with invitations / other form setups

        return {
            username: '',
            password: '',
            accountType: null,
            errorMessage: '',
            message: '',
            currentForm: 'login',
            submittingForm: false,
            assetBaseUrl: window.MAT.asset_base,
            twoFactorCode: '',
            usingTwoFactorRecoveryCode: false,
        }
    },

    computed: {
        hasOtherLoginOptions() {
            return this.usesOneLogin;
        },
        hasImage() {
            return this.loginImage.length !== 0;
        }
    },

    methods: {
        async determineAccountType() {
            this.errorMessage = '';
            try {
                this.submittingForm = true;
                const accountTypeResponse = await axios.get('/api/account_type', {
                    params: {
                        username: this.username
                    }
                });

                const data = Parser.parseJSONAPIResponse(accountTypeResponse.data);

                if (data.type === 'onelogin') {
                    this.goToOneLogin();
                } else {
                    this.accountType = data.type
                }

            } catch (err) {
                if (err.toString() === 'Error: Request failed with status code 422') {
                    this.errorMessage = 'Invalid information';
                } else {
                    this.errorMessage = 'Unknown Error';
                }
                console.log(err.toString());
                console.log(err);
            }
            this.submittingForm = false;
        },
        async attemptLogin() {
            this.errorMessage = '';
            this.submittingForm = true;
            try {
                const loginResponse = await axios.post('/api/auth/login', {
                    username: this.username,
                    password: this.password
                });

                if (loginResponse.data.two_factor === true) {
                    this.currentForm = "twoFactorForm";
                } else {
                    window.location = '/home';
                }

            } catch (err) {
                this.errorMessage = 'Invalid Login Credentials';
            }
            this.submittingForm = false;
        },
        async verifyTwoFactorCode() {

            this.errorMessage = '';
            this.submittingForm = true;
            try {
                let request = {};
                if (this.usingTwoFactorRecoveryCode) {
                    request = {
                        recovery_code: this.twoFactorCode
                    }
                } else {
                    request = {
                        code: this.twoFactorCode
                    }
                }

                const verifyResponse = await axios.post('/api/auth/two-factor-challenge', request);

                if (verifyResponse.status === 204) {
                    window.location = '/home';
                } else {
                    this.errorMessage = 'Unable to verify 2 factor authentication';
                }
            } catch (err) {
                this.errorMessage = 'Unable to verify 2 factor authentication';
            }
            this.submittingForm = false;
        },
        submitUserForm() {
            if (this.accountType === null) {
                this.determineAccountType()
            } else if (this.accountType === 'local') {
                this.attemptLogin()
            }
        },
        async resetPassword() {
            this.errorMessage = '';
            try {
                const resetPasswordResponse = await axios.post('/api/auth/forgot-password', {
                    email: this.username
                });

                this.message = resetPasswordResponse.data.message;
            } catch (err) {
                this.message = 'If we found a matching email, you should receive a reset link.';
            }
        },
        goToOneLogin() {
            window.location = '/saml2/login';
        },
        showLocalForm() {
            this.showOneLoginForm = false;
        },
        resetCurrentForm() {
            this.currentForm = 'login';
        },
        showResetPasswordForm() {
            this.currentForm = 'resetForm';
        },
        showSignUpForm() {
            this.currentForm = 'signUpForm';
        },
        useRecoveryTwoFactorCode() {
            this.usingTwoFactorRecoveryCode = true;
        },
        useNormalTwoFactorCode() {
            this.usingTwoFactorRecoveryCode = false;
        }
    }

}
</script>
