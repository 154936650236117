

export const getFeatureForName = state => {
    return function(name){
        return state.features.find(feature  => {
            return feature.name === name;
        })
    }
}

export const hasBannerMessage = (state, getters) => {

    if(!state.features) {
        return;
    }

    var feature = state.features.filter({name: "banner_message"}).first();

    if(!feature || !feature.enabled || !feature.options.message) {
        return false;
    }

    return true;
}

export const bannerMessage = (state, getters) => {

    if(!state.features) {
        return;
    }

    var feature = state.features.filter({name: "banner_message"}).first();

    if(!feature || !feature.enabled || !feature.options.message) {
        return null;
    }

    return feature.options.message;
}
