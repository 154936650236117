import api from 'axios';
import Parser from 'etap/admin/classes/jsonapi_parser'

export const getFormConfiguration = (context, formName) => {

    api.get('/api/forms/configuration', {
        params: {
            formConfigName: formName
        }
    }).then(response => {
        let formConfiguration = Parser.parseJSONAPIResponse(response.data);
        context.commit('setFormConfiguration', formConfiguration);
    }).catch(error => {
        window.notify.apiError(error);
    });

}

export const getFormConfigurationsByName = (context, forms) => {

    api.get('/api/forms/configuration', {
        params: {
            formConfigNames: forms
        }
    }).then(response => {
        let formConfigurations = Parser.parseJSONAPIResponse(response.data);
        formConfigurations.getModels().forEach(formConfiguration => {
            context.commit('setFormConfiguration', formConfiguration);
        });
    }).catch( error => {
        window.notify.apiError(error);
    });

}

export const getFormConfigurationById = async (context, formConfigurationId) => {

    const formConfigResponse = await api.get('/api/forms/configuration/' + formConfigurationId, {
        params: {
            include: ['fields'],
        }
    });

    let formConfiguration = Parser.parseJSONAPIResponse(
        formConfigResponse.data
    );

    context.commit('setFormConfiguration', formConfiguration);

    return formConfiguration;
}

export const getFormConfigurationForType = (context, {formType, programTrackId, programYearId}) => {


    api.get('/api/program_tracks/' + programTrackId + '/program_year/' + programYearId + '/forms', {
        params: {
            formType: formType
        }
    }).then(response => {
        context.commit('setFormConfigurationForType', {
            formConfig: response.data,
            formType,
            programTrackId,
            programYearId
        })
    }).catch( error => {
        window.notify.apiError(error);
    })

}


export const getFormConfigurationsForTypes = (context, {formTypes, programTrackId, programYearId} ) => {


    api.get('/api/program_tracks/' + programTrackId + '/program_year/' + programYearId + '/forms', {
        params: {
            formTypes
        }
    }).then(response => {

        for(var formType in response.data) {

            if (response.data.hasOwnProperty(formType)) {
                context.commit('setFormConfigurationForType', {
                    formConfig: response.data[formType],
                    formType,
                    programTrackId,
                    programYearId
                })
            }
        }

    }).catch( error => {
        window.notify.apiError(error);
    })
}


export const getFormConfigurationsForMeasureAndType = async (context, {measureId, formType, programYearId, programTrackId}) => {

    try {
        const response = await api.get('/api/measures/' + measureId + '/forms', {
            params: {
                formType,
                programYearId,
                programTrackId
            }
        });

        const formConfigurationMeasures = Parser.parseJSONAPIResponse(response.data);
        context.commit('upsertFormConfigurationMeasures', formConfigurationMeasures);
        return formConfigurationMeasures;
    }catch(err) {
        window.notify.apiError(err);
    }

}

export const getFormConfigurationsForMeasuresAndType = async (context, {measureIds, formType, programYearId, programTrackId, onlyLatestFormConfiguration}) => {

    try {
        const response = await api.get('/api/measures/forms', {
            params: {
                measureIds,
                formType,
                programYearId,
                programTrackId,
                onlyLatestFormConfiguration
            }
        });

        const formConfigurationMeasures = Parser.parseJSONAPIResponse(response.data);
        context.commit('upsertFormConfigurationMeasures', formConfigurationMeasures);
        return formConfigurationMeasures;

    }catch(err) {
        window.notify.apiError(err);
    }
}

export const getFormConfigurationsForMeasureAndTypes = (context, {measureId, formTypes, projectId}) => {




}

