<template>
    <div>
        <bill-accounts-container
            v-if="billAccountsFormConfiguration"
            :project-bill-accounts="billAccounts"
            :bill-account-form-configuration="billAccountsFormConfiguration"
            :project-id="form.data.id"
            :max-bill-accounts="maxBillAccounts"
            :disabled="formDisabled"
            @savedAccount="emit"
        ></bill-accounts-container>
    </div>
</template>
<script>

    import { FormFieldMixin } from 'laravel-vue-forms';

    export default {

        mixins: [
            FormFieldMixin
        ],


        data() {
            return {
                billAccounts: JSON.parse(JSON.stringify(this.value))
            }
        },

        created() {
            if(!this.billAccountsFormConfiguration) {
                this.$store.dispatch('form_configurations/getFormConfigurationsByName', [
                    this.fieldConfig.field_extra.bill_accounts_form_name
                ]);
            }
        },

        computed: {
            billAccountsFormConfiguration() {
                return this.$store.getters["form_configurations/getFormConfigurationByName"](this.fieldConfig.field_extra.bill_accounts_form_name);
            },
            maxBillAccounts() {
                if(this.fieldConfig.field_extra.hasOwnProperty('maxBillAccounts')) {
                    return this.fieldConfig.field_extra.maxBillAccounts;
                }

                return 0;
            },
            formDisabled() {
                return this.fieldConfig.disabled === 1;
            }
        },

        methods: {
            emit(bill_accounts) {
                this.$store.dispatch('customer_information/getProjectData', this.form.id);
            }
        }

    }
</script>
