import Model from 'etap/admin/classes/models/model';
import File from 'etap/admin/classes/models/file';
import moment from 'moment';


class VisionProgram extends Model {


    defaults() {
        return {
            id: null,
            name: null,
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
        }
    }

    relationships() {
        return {
            logs: File,
            files: File
        }
    }

}

export default VisionProgram;
