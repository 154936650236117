import axios from 'axios';
import Parser from 'etap/admin/classes/jsonapi_parser'
import MeasureTab from "../../admin/classes/models/measureTab";

let apiPrefix = '/api/equipment_pricing/';

export const getDefaultAttributesForQPLItem = async (context, {qplItemId, qplType, equipmentName, measureId}) => {

    try {

        const response = await axios.post(
            '/api/qpl_search/' + qplType, {
                qpl_item_id: qplItemId,
                equipment_name: equipmentName,
                measure_id: measureId
            }
        )
        return  Parser.parseJSONAPIResponse(response.data);
    }catch(err) {
        window.notify.apiError(err);
        return null;
    }
}

export const getEquipmentForId = async (context, {equipmentId, include}) => {
    if(!include) {
        include = [];
    }

    try {

        const equipmentResponse = await axios.get('/api/equipment/' + equipmentId, {
            params: {
                include
            }
        });

        const equipment = Parser.parseJSONAPIResponse(equipmentResponse.data);
        context.commit('upsertEquipmentRecord', equipment);
    }catch(err) {
        window.notify.apiError(err)
        throw err;
    }
}

// for now putting these here since we're modifying the equipment library, but getting measure eligibility information
// should likely be in a "measure store"
export const getMeasureEligibility = async (context, {measureId, projectId}) => {

    try {

        if(context.state.measureEquipmentEligibilityItemsLoaded.includes(measureId)) {
            return;
        }

        let params = {};
        if(projectId) {
            params.project_id = projectId;
        }

        const measureEquipmentEligibilityItemsResponse = await axios.get(
            '/api/measures/' + measureId + '/measure_equipment_eligibility_items',
            {
                params
            }
        );

        const measureEquipmentEligibilityItems = Parser.parseJSONAPIResponse(
            measureEquipmentEligibilityItemsResponse.data
        );

        context.commit('upsertMeasureEquipmentEligibilityItems', measureEquipmentEligibilityItems);
        context.commit('measureEligibilityItemsLoadedForMeasure', measureId);

    }catch(err) {
        window.notify.apiError(err);
        return null;
    }
}

export const removeCurrentEquipmentFromMeasure = async (context, {equipmentId, equipmentMeasure}) => {

    const existingEquipment = context.state.equipment.find( (existingEquipment) => {
        return existingEquipment.id === equipmentId;
    });

    if(!existingEquipment) {
        throw new Error("Could not find equipment with id " + equipmentId);
    }

    try{
        if(equipmentMeasure.id) {
            const response = await axios.delete(
                '/api/measures/' + equipmentMeasure.measure_id + '/equipment/' + existingEquipment.id
            );
        }

        context.commit('removeEquipmentMeasureFromEditingEquipment', equipmentMeasure);
    }catch(err) {
        window.notify.apiError(err);
        return false;
    }
}

export const deleteEquipment = async (context, equipmentId) => {
    const existingEquipment = context.state.equipment.find( existingEquipment => {
        return existingEquipment.id === equipmentId;
    });

    if(!existingEquipment) {
        throw new Error("Could not find equipment with id " + equipmentId);
    }

    try {
        const response = await axios.delete('/api/equipment/' + equipmentId);
        const deletedEquipment = Parser.parseJSONAPIResponse(response.data);
        context.commit('upsertEquipmentRecord', deletedEquipment);
        return deletedEquipment;
    }catch(err) {
        window.notify.apiError(err);
        return false;
    }
}

export const restoreEquipment = async (context, equipmentId) => {
    const existingEquipment = context.state.equipment.find( existingEquipment => {
        return existingEquipment.id === equipmentId;
    });

    if(!existingEquipment) {
        throw new Error("Could not find equipment with id " + equipmentId);
    }

    try {
        const response = await axios.patch('/api/equipment/' + equipmentId + '/restore');
        const restoredEquipment = Parser.parseJSONAPIResponse(response.data);
        context.commit('upsertEquipmentRecord', restoredEquipment);
        return restoredEquipment;
    }catch(err) {
        window.notify.apiError(err);
        return false;
    }
}



export const createAndDownloadBulkImportTemplate = async(context, measureTabIds) => {

    const response = await axios.get('/api/equipment/bulk_import/template', {
        params: {
            measureTabIds
        }
    });

    if(!response.data.meta.templateLink) {
        throw new Error("Unable to generate template");
    }

    return response.data.meta.templateLink;
}

export const createBulkImportJobForUpload = async(context, importFiles) => {

    const response = await axios.post('/api/equipment/bulk_import', {
        importFiles
    });


    console.log(response);

}


export const getMeasureTabs = async (context) => {

    try {
        const measureTabResponse = await axios.get('/api/measure_tabs');
        const measureTabs = Parser.parseJSONAPIResponse(
            measureTabResponse.data
        );

        context.commit('setMeasureTabs', measureTabs.getModels());

    }catch(err) {
        window.notify.apiError(err);
        console.log(err);
        return []
    }
}


export const updateApprovalStatusForEquipment = async (context, {equipmentId, measureId, approvalStatusId, equipmentVersionId}) => {

        try {
            const response = await axios.patch('/api/equipment/' + equipmentId + '/approval', {
                approvalStatus: approvalStatusId,
                measureId: measureId,
                equipmentVersionId: equipmentVersionId
            });

            const equipmentMeasure = Parser.parseJSONAPIResponse(response.data);
            // context.commit('upsertEquipmentRecord', equipment);
            return equipmentMeasure;
        }catch(err) {
            window.notify.apiError(err);
            return false;
        }
}
