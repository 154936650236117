<script>
export default {

    props: {
        lines: {
            type: Number,
            default: 3
        },
    }
}
</script>
<template>
    <div class="mx-1 py-1">
        <div class="skeleton-text" v-for="i in lines">
            <div class="skeleton skeleton-input-label"></div>
        </div>
    </div>
</template>
