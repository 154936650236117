<template>
    <vue-form
        :form-config="billAccountFormConfiguration"
        :form-data="billAccount"
        @cancel-form="closeForm"
        @close-form="closeForm"
        @created="updateOrAddBillAccount"
        @updated="updateOrAddBillAccount"
        :form-submit-url="'/api/bill_accounts/project/' + projectId"
        :disabled="disabled"
        :close-on-save="false"
        :actions="formActions"
    ></vue-form>
</template>
<script>
    export default {

        props: {
            billAccount: {
                required: true,
                type: Object
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        inject: {
            projectId: {
                default() {
                    return null;
                }
            },
            billAccountFormConfiguration: {
                default() {
                    console.error('Invalid Form Configuration provided');
                    return null;
                }
            }
        },

        data() {
            return {
                currentAccount: {},
                formActions: [
                    {
                        label: 'Save',
                        name: 'submit',
                        action: 'submitForm'
                    },
                    {
                        label: 'Cancel',
                        name: 'cancel',
                        action: 'cancelForm'
                    }
                ]
            }
        },

        created() {
            this.currentAccount = this.billAccount;

            if(!this.currentAccount.address) {
                this.currentAccount.address = {};
            }
        },

        methods: {
            closeForm() {
                this.$emit('close');
            },
            updateOrAddBillAccount(billAccount) {
                this.$emit('savedBillAccount', billAccount);
            }
        }
    }
</script>
