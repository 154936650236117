<template>
    <div class="measureslot filledmeasureslot">
        <div class="measuredetailstablediv">
            <table class="measuredetailstable">
                <tbody>
                <tr class="measuredatarow">
                    <td class="measurenamecolumn">
                        <span v-html="record.name"></span>
                    </td>
                    <td class="hourscolumn">
                        <span class="SettingsWidget"
                              :id="'show-equipment-toggle-' + record.id"
                              title="Click on eye symbol to show all equipment for this measure"
                              @click="toggleMeasureEquipment">
                            <i aria-label="Show/Hide Equipment for this measure" class="fa fa-eye" :class="{ 'fa-eye-slash': (showMeasureEquipment) }"></i>
                        </span>
                    </td>
                    <td v-can="{ permission: 'create', entity_type: getModelStringForType('equipment') }" class="hourscolumn">
                        <span class="SettingsWidget"
                            :id="'add-equipment-' + record.id"
                            title="Click on plus symbol to add equipment to measure"
                              @click="getEquipmentNameForEquipment"
                        ><i aria-label="Add equipment to this measure" class="fa fa-plus"></i></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="showMeasureEquipment" class="px-4 mb-4">
            <hr />
            <v-grid
                :headers="equipmentDisplayHeaders"
                :use-json-api="true"
                record-url="/api/equipment"
                :record-url-params="{ measure: record.id}"
                record-type="equipment-item-row"
                :json-api-parser="jsonApiParser"
            ></v-grid>
        </div>
        <modal
            v-if="showCreateModal"
            @close="showCreateModal = false"
            modal-width="60%"
            :close-on-click-away="false"
        >
            <template #header>
                Create Equipment
            </template>
            <template #body>
                <div class="min-h-35vh">
                    <form-text
                        field-name="name"
                        label="Name"
                        v-model="newEquipment.name"
                    />
                    <button class="button" @click="goToEquipmentFormForCreate">
                        Create
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex';
    import Parser from "etap/admin/classes/jsonapi_parser";
    import Equipment from "../../../admin/classes/models/equipment";
    import Collection from "../../../admin/classes/collection";
    import Measure from "../../../admin/classes/models/measure";
    export default {

        props: {
            record: {
                required: true,
                type: Object
            }
        },

        data() {
            return {
                jsonApiParser: Parser,
                showMeasureEquipment: false,
                showCreateModal: false,
                newEquipment: {
                    name: '',
                }
            }
        },

        computed: {
            ...mapState('equipment_library', [
                'equipmentLibraryConfiguration'
            ]),
            equipmentDisplayHeaders() {
                if(
                    !this.equipmentLibraryConfiguration.results_display_columns_by_view ||
                    !this.equipmentLibraryConfiguration.results_display_columns_by_view.equipment ||
                    !Array.isArray(this.equipmentLibraryConfiguration.results_display_columns_by_view.equipment)
                ) {
                    return [
                        'Name',
                        ''
                    ]
                }

                let configuredHeaders =  this.equipmentLibraryConfiguration.results_display_columns_by_view.equipment.map(column => {
                    return column.label;
                })

                configuredHeaders.push('');
                return configuredHeaders;
            }
        },

        methods: {
            ...mapMutations('equipment_library', [
                'setMainView',
                'setEditingEquipmentRecord'
            ]),
            toggleMeasureEquipment() {
                this.showMeasureEquipment = !this.showMeasureEquipment;
            },
            getEquipmentNameForEquipment() {
                this.showCreateModal = true;
            },
            goToEquipmentFormForCreate() {
                const newEquipmentRecord = new Equipment({
                    name: this.newEquipment.name
                })

                newEquipmentRecord.measures = new Collection([
                    this.record
                ], { model: Measure})

                this.setEditingEquipmentRecord(newEquipmentRecord);
                this.setMainView({
                    mainView: 'equipment-form',
                    mainViewArgs: {
                        equipmentId: null,
                        defaultAttributes: {
                            name: this.newEquipment.name,
                            measure: this.record,
                        }
                    }
                });

                this.newEquipment = {
                    name: '',
                };
            }
        }
    }
</script>
