<script>
import { parseQueryString } from "etap/utilities/parseQueryString";

export default {
    props: {
        formConfig: {
            required: true,
            type: Object
        },
        project: {
            type: Object,
        }
    },

    data() {
        return {
            searchParams: {},
            // this parameter is to essentially keep the table from immediately showing and searching
            // instead waiting for he first time the user clicks search on the eligibility form
            hasSearched: false
        }
    },

    created() {
        if(this.project) {
            this.searchParams = {
                program_track_id: this.project.program_track_id,
                program_year_id: this.project.program_year_id
            }
        } else {
            this.searchParams = Object.assign(
                {},
                parseQueryString(window.location.search)
            );
        }
    },

    computed: {
        eligibilityProgramTrackId() {
            return this.programTrackId || this.searchParams.program_track_id;
        },
        eligibilityProgramYearId() {
            return this.programYearId || this.searchParams.program_year_id;
        },
        programTrackEligibilityConfig() {
            if(
                !this.eligibilityFeature ||
                !this.eligibilityFeature.enabled ||
                !this.eligibilityFeature.options.valid_program_track ||
                !Array.isArray(this.eligibilityFeature.options.valid_program_track) ||
                !this.$store.getters.validTracksForUser ||
                !this.eligibilityProgramTrackId ||
                !this.eligibilityProgramYearId
            ) {
                return null
            }

            return this.eligibilityFeature.options.valid_program_track.find(vPT => {
                return this.$store.getters.validTracksForUser.find(programTrack => {
                    return Number(programTrack.id) === Number(this.eligibilityProgramTrackId) &&
                            Number(programTrack.pivot.program_year_id) === Number(this.eligibilityProgramYearId) &&
                            vPT.program_track === programTrack.name;
                }) !== undefined;
            });
        },
        eligibilityFeature() {
            return window.MAT.features.find(feature => {
                return feature.name === 'eligibility_check';
            });
        },
    },

    methods: {
        handleFilterList(data) {
            this.hasSearched = true;

            // ensure page isn't passed to the table, the table will handle what page we're on
            delete data.page;
            this.searchParams = data;
        },
    }
}
</script>
<template>
    <div class="w-full">
        <div v-if="programTrackEligibilityConfig">
            <eligibility-form
                :form-config="formConfig"
                :search-params="searchParams"
                :eligibility-config="programTrackEligibilityConfig"
                @filter-list="handleFilterList"
            ></eligibility-form>
            <eligibility-list
                v-if="hasSearched"
                :search-params="searchParams"
                :eligibility-config="programTrackEligibilityConfig"
                :project="project"
            ></eligibility-list>
        </div>
        <div v-else class="warning text-red p-4 text-left">
            The selected program track and program year are not valid for looking up eligible customers.
        </div>
    </div>
</template>
