import Model from 'etap/admin/classes/models/model';
import moment from 'moment';

class File extends Model {


    defaults() {
        return {
            id: null,
            fileable_type: null,
            fileable_id: null,
            meta_type: null,
            phase_id: null,
            disk: null,
            file_path: null,
            original_filename: null,
            thumbnail: null,
            created_by: null,
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }


}

export default File;
