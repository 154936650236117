import Vue from 'vue';

export const updateNeedsConfigurationUpdate = (state, {projectId, needsConfigurationUpdate}) => {

    if(!needsConfigurationUpdate) {
        Vue.set(state.projectsNeedingConfigurationUpdate, projectId, {needsUpdate: false});
    }

    Vue.set(state.projectsNeedingConfigurationUpdate, projectId, needsConfigurationUpdate);

}

export const setProjectForConfigurationStatusChecking = (state, project) => {
    state.configurationStatusProject = project;
}

export const updateProjectConfigurationUpdateStatus = (state, currentStatus) => {
    state.currentProjectConfigurationUpdateStatus = currentStatus;
}

export const projectConfigurationUpdateIsRunning = (state, running) => {
    state.projectConfigurationUpdateIsRunning = running;
}

export const setProjectConfigurationStatusUpdater = (state, statusUpdater) => {
    state.projectConfigurationStatusUpdater = statusUpdater;
}
