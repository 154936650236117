import axios from 'axios';

export const getOperatingSchedulesForProject = ({commit}, projectId) => {
    axios.get('/api/projects/' + projectId + '/schedules').then(response => {
        commit('setSchedulesForProject', {
            projectId,
            schedules: response.data,
        });
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const createOperatingScheduleForProject = ({commit}, {projectId, schedule}) => {
    axios.post('/api/projects/' + projectId + '/schedules', schedule).then(response => {
        commit('addScheduleForProject', {
            projectId,
            schedule: response.data
        });
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const updateOperatingScheduleForProject = ({commit}, {projectId, schedule}) => {
    axios.patch('/api/projects/' + projectId + '/schedules/' + schedule.id, schedule).then(response => {
        commit('updateScheduleForProject', {
            projectId,
            schedule: response.data
        });
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const deleteOperatingScheduleForProject = ({commit}, {projectId, scheduleId}) => {
    axios.delete('/api/projects/' + projectid + '/schedules/' + scheduleId).then(response => {
        commit('deleteScheduleForProject', {
            projectId,
            scheduleId
        });
    });
}
