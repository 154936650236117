<template>
    <div class="w-half vue-form">
        Generate Report
        <form-select
            field-name="report-type"
            :options="reportOptions"
            option-label-field="label"
            option-value-field="report_type"
            v-model="formData.selectedReportOption"
            :disabled="reportFormDisabled ? 1 : 0"
        ></form-select>
        <vue-form
            v-if="reportOptionFormConfig && formData.selectedReportOption"
            :form-config="reportOptionFormConfig"
            :form-data="formData"
            :actions="formActions"
            :disabled="reportFormDisabled"
            :form-submit-url="'/api/reports/' + formData.selectedReportOption + '/generate'"
            @created="showMessage"
            @updated="showMessage"
            @generate-report="generateReport"
        ></vue-form>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            reportOptions: {
                required: true,
                type: Array,
            }
        },


        data() {
            return {
                formData: {
                    selectedReportOption: null,
                },
                reportOptionFormConfig: null,
                formActions: [
                    {
                        name: 'submit',
                        label: 'Generate Report',
                        action: 'generate-report'
                    }
                ],
                reportFormDisabled:false,
                reportTypeFormConfig: {
                    'name': 'selectedReportOption',
                    'label': '',
                    'widget': 'text',
                    'hidden': 1,
                    'disabled': 0,
                    'is_eav': 0,
                    'field_extra': {},
                    'value_field': 'selectedReportOption'
                }
            }
        },


        watch: {
            'formData.selectedReportOption'() {
                this.getReportOptionConfig();
            }
        },

        methods :{
            getReportOptionConfig() {

                if(!this.formData.selectedReportOption) {
                    return;
                }

                axios.get('/api/reports/options', {
                    params: {
                        report_type: this.formData.selectedReportOption
                    }
                }).then(response => {
                    let formConfig = response.data
                    formConfig.fields.push(this.reportTypeFormConfig)
                    this.reportOptionFormConfig = formConfig;
                }).catch(error => {
                    window.notify.apiError(error);
                });
            },
            generateReport(formData) {
                this.reportFormDisabled = true;
                axios.post('/api/reports/' + formData.selectedReportOption + '/generate', {
                    formConfigurationId: this.reportOptionFormConfig.id,
                    data: formData
                }).then(response => {
                    this.reportFormDisabled = false;
                    this.showMessage(response.data);
                }).catch(error => {
                    this.reportFormDisabled = false;
                    window.notify.apiError(error);
                })
            },
            showMessage(response) {
                window.notify.message('Report Successfully queued.  You will receive an email when the report is finished', 'success');
            }
        }


    }
</script>
