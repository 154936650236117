<script>
export default {

    props: {
        bannerNotification: {
            type: Object,
            required: true
        }
    }

}
</script>
<template>
    <div
        class="p-2 m-1"
        :style="{'background-color': bannerNotification.background_color, 'color': bannerNotification.text_color}"
        v-html="bannerNotification.notification">
    </div>
</template>
