<script>
import {
    faChevronRight,
    faChevronDown,
    faEdit
} from "@fortawesome/free-solid-svg-icons";
export default {
    model: {
        prop: 'showPanel',
        event: 'change'
    },
    props: {
        showPanel: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            faChevronDown,
            faChevronRight,
            faEdit
        }
    }
}
</script>
<template>
    <div ref="formFieldElement" class="border-2 w-full rounded">
        <div class="flex justify-between items-center p-2 shadow-md" @click="() => $emit('change', !showPanel)">
            <div
                class="
                  cursor-pointer
                  mx-2
                  md:ml-8
                "
            >
                <font-awesome-icon
                    v-if="showPanel"
                    :icon="faChevronDown"
                ></font-awesome-icon>
                <font-awesome-icon
                    v-else
                    :icon="faChevronRight"
                ></font-awesome-icon>
            </div>
            <slot name="header-title"></slot>
            <slot name="header-controls"></slot>
        </div>
        <transition name="slide-fade">
            <div v-if="showPanel" class="shadow-inner w-full p-4">
                <slot>
                    Panel
                </slot>
            </div>
        </transition>
    </div>
</template>
<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>
