<script>

export default {

    props: {
        mpid: {
            type: String,
            required: true
        },
        projectMeasure: {
            type: Object,
            required: true
        },
        allowMeasureDetailsForm: {
            type: Boolean,
            default: false
        },
        showMeasureCalcDetails: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            showProjectMeasureForm: false,
            selected: false,
        }
    },

    async created() {

        const existingSelectionIndex = this.$store.getters['measure_selection/projectMeasureSelections'](this.mpid).findIndex(pm => {
            return pm.shared_uuid === this.projectMeasure.shared_uuid;
        })

        if(existingSelectionIndex !== -1) {
            this.selected = true;
        }

    },


    computed: {
        project() {
            return this.$store.getters['mp_layout/getProject'](this.mpid);
        },
        formConfig() {
            return this.$store.getters['mp_layout/formConfigurationForProjectMeasure'](
                this.projectMeasure,
                this.project
            );
        },
        displaySharedUUid() {
            const sharedParts = this.projectMeasure.shared_uuid?.split('-');

            if(sharedParts) {
                return sharedParts[sharedParts.length - 1]
            }

            return null;
        },

    },

    methods: {
        toggleMeasureSelection(){
            if(this.selected) {
                this.$store.commit('measure_selection/removeProjectMeasureSelection', {projectMeasure: this.projectMeasure, mpid: this.mpid});
                this.selected = false;
            } else {
                this.$store.commit('measure_selection/addProjectMeasureSelection', {projectMeasure: this.projectMeasure, mpid: this.mpid});
                this.selected = true;
            }
        },

        toggleMeasureForm() {
            this.showProjectMeasureForm = !this.showProjectMeasureForm;
        },
    }
}
</script>
<style scoped>
    .measureselectform >>> .multiselect input {
        display: none;
    }
</style>
<template>
    <div class="my-4">

        <div class="measureslot filledmeasureslot" :class=" {'selectedmeasure' : selected}" @click="toggleMeasureSelection" :id="'project-measure-' + projectMeasure.id" style="cursor:default;">

            <div class="measuredetailstablediv">
                <table class="measuredetailstable">
                    <tbody>
                        <tr class="measureheaderrow">
                            <td class="measurenamecolumn">
                                <span v-if="projectMeasure.measure.incentivized === 1">Energy-Saving Improvement</span>
                                <span v-else>Additional Cost / Additional Opportunity</span>
                                <span v-if="displaySharedUUid"> - {{ displaySharedUUid }}</span>
                            </td>
                            <td></td>
                            <td v-if="allowMeasureDetailsForm" class="hourscolumn">
                                <span>Form</span>
                            </td>

                        </tr>
                        <tr class="measuredatarow">
                            <td class="measurenamecolumn measurenametr">
                                <span v-html="projectMeasure.measure.name"></span>
                            </td>
                            <td >
                                <button class="button mx-2" style="min-width: 120px;">{{selected ? 'Unselect' : 'Select'}}</button>
                            </td>

                            <td v-if="allowMeasureDetailsForm" class="hourscolumn m-4">
                                <div class="fa fa-bars" style="cursor:pointer;"
                                    @click="toggleMeasureForm" v-on:click.stop></div>
                            </td>

                        </tr>

                        <tr v-if="formConfig && showMeasureCalcDetails" class="savingsdata">
                            <td class="littlenote">
                                <measure-selection-field-measure-details
                                    :mpid="mpid"
                                    :project-measure="projectMeasure"
                                    :form-config = "formConfig"
                                    />
                            </td>
                        </tr>

                        <tr class="text-left measureselectform"
                            v-if="allowMeasureDetailsForm"
                            v-show="showProjectMeasureForm"
                        >
                            <td>
                                <vue-form
                                    v-if="formConfig"
                                    :form-config="formConfig"
                                    :form-data="projectMeasure"
                                    :disabled="true"
                                />
                                <p v-else>No form configuration found for this measure.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
</template>
