
export const parseQueryString = (queryString) => {
    var query = {};

    if(queryString[0] !== '?') { // if there is no query string return empty object
        return {};
    }

    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');

        var decodedFieldName = decodeURIComponent(pair[0]);
        var decodedValue = decodeURIComponent(pair[1] || '')

        // if we have more than one get param for the dedcoded field name, we need to pas it as an array
        if(decodedFieldName in query) {
            if(!Array.isArray(query[decodedFieldName])) {
                let curValue = query[decodedFieldName];
                query[decodedFieldName] = [];
                query[decodedFieldName].push(curValue);
            }

            query[decodedFieldName].push(decodedValue);
        }else {
            query[decodedFieldName] = decodedValue;
        }
    }
    return query;
}
