<script>

import {byString} from "../../../utilities/utils";
import {mapMutations, mapState} from "vuex";

export default {
    props: {
        measure: {
            required: true,
            type: Object
        }
    },

    emits: ['close'],

    data() {
        return {
            showConfirmViewChangeModal: false,
            confirmingViewToChangeTo: null,
        }
    },

    computed: {
        ...mapState('equipment_library', [
            'mainView',
            'equipmentLibraryConfiguration',
            'equipmentFormDirty',
        ]),
        qplEnabled() {
            return byString(
                this.equipmentLibraryConfiguration,
                'qpl.enabled',
            ) || false
        }
    },

    created() {
        this.setEquipmentLibraryMeasure(this.measure);
        this.setMainView({
            mainView: 'equipment-library-items'
        })
    },

    methods: {
        ...mapMutations('equipment_library', [
            'setMainView',
            'setEquipmentLibraryMeasure',
        ]),
        goToView(view) {
            if(this.mainView === 'equipment-form' && this.equipmentFormDirty) {
                this.showConfirmViewChangeModal = true;
                this.confirmingViewToChangeTo = view;
                return;
            }

            this.setMainView({mainView: view});
        },
        viewChangeConfirmed() {
            if(
                !this.confirmingViewToChangeTo
            ) {
                window.notify.error('There is no view to change to');
                return
            }

            this.setMainView({
                mainView: this.confirmingViewToChangeTo
            });
            this.showConfirmViewChangeModal = false;
        },
        hideLibrary() {
            this.setEquipmentLibraryMeasure(null);
            this.$emit('close');
        }
    }
}

</script>
<template>
    <right-panel
        @close="hideLibrary"
        width="90%"
    >
        <template #header>
            <div class="my-4 mx-1 bg-white p-4 rounded shadow w-full">
                <div class="flex">
                    <button
                        class="w-1/3 p-4 cursor-pointer m-1 rounded"
                        :class="{
                                    'bg-primary text-white': mainView === 'equipment-library-items',
                                    ' bg-lighter-gray hover:bg-lightest-gray text-gray-900': mainView !== 'equipment-library-items'
                                }"
                        @click="goToView('equipment-library-items')"
                    >
                        {{ measure.name }} Equipment Library
                    </button>
                    <button
                        v-if="qplEnabled"
                        class="w-1/3 p-4 cursor-pointer m-1 rounded"
                        :class="{
                                    'bg-primary text-white': mainView === 'qpl-lookup' ,
                                    ' bg-lighter-gray hover:bg-lightest-gray text-gray-900': mainView !== 'qpl-lookup'
                                }"
                        @click="goToView('qpl-lookup')"
                    >
                        QPL Lookup
                    </button>
                </div>
            </div>
        </template>

        <template #default>
            <div class="mx-4 p-1 bg-white">
                <component :is="mainView"></component>
                <modal
                    v-if="showConfirmViewChangeModal"
                    :is-confirm="true"
                    @confirmed="viewChangeConfirmed"
                    @close="showConfirmViewChangeModal = false"
                >
                    <template #header>
                        <h3>Are you sure you want to leave this page?</h3>
                    </template>
                    <template #body>
                        <p>
                            You have unsaved changes. If you leave this page, your changes will be lost.
                        </p>
                    </template>
                </modal>
            </div>
        </template>
    </right-panel>
</template>
