import Vue from 'vue';
import apiClient from 'etap/admin/apiClient';

import Parser from 'etap/admin/classes/jsonapi_parser';

import Collection from 'etap/admin/classes/collection';
import Role from 'etap/admin/classes/models/role';

export const getRoles = (context, nameQuery) => {
    context.commit('resetCurrentRoles');
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/roles', {
            params: {
                name: nameQuery
            }
        }).then(response => {
            var roleCollection = Parser.parseJSONAPIResponse(response.data);
            context.commit('setCurrentRoles', roleCollection);
            resolve(roleCollection);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const getRole = (context, roleId) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/roles/' + roleId).then(response => {
            var role = Parser.parseJSONAPIResponse(response.data);
            if(!context.state.current_roles) {
                let roleCollection = new Collection([role], {
                    model: Role
                });
                context.commit('setCurrentRoles', roleCollection);
                resolve(roleCollection);
                return;
            }
            context.commit('addRole', role);
            resolve(role)
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}

export const updateOrCreateRole = (context, {record, eagerLoads}) => {

    var method = 'post';
    var url = '/api/admin/roles';
    if(record.id) {
        method = 'patch';
        url += '/' + record.id;
    }

    return new Promise((resolve, reject) => {
        apiClient[method](url, record).then(response => {
            var role = Parser.parseJSONAPIResponse(response.data);
            switch(method) {
                case 'patch':
                    context.commit('updateRole', {
                        roleId: record.id,
                        updatedRole: role
                    });
                    break;
                default:
                    if(!context.state.current_roles) {
                        let roleCollection = new Collection([role], {
                            model: Role
                        });
                        context.commit('setCurrentRoles', roleCollection);
                        resolve(roleCollection);
                        return;
                    }
                    context.commit('addRole', role);
            }
            resolve(role);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}

export const deleteRole = (context, role) => {
    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/roles/' + role.id).then(response => {
            context.commit('removeRole', role);
            resolve(role);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const getAbilitiesForRole = (context, role) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/roles/' + role.id + '/abilities').then(response => {
            let abilities = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateAbilitiesOnRole', {
                role,
                abilities: abilities
            });
            resolve(abilities);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const getAbilitiesForRoleId = (context, roleId) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/abilities', {
            params: {
                roleId
            }
        }).then(response => {
            let abilities = Parser.parseJSONAPIResponse(response.data);
            context.commit('setAbilityOptions', abilities);
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const addAbilityToRole = (context, {roleId, ability}) => {
    return new Promise((resolve, reject) => {
        apiClient.post('/api/admin/roles/' + roleId + '/abilities', {
            abilityId: ability.abilityId,
            roleId: ability.roleId
        }).then(response => {
            let newAbility = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateAbility', {
                ability,
                data: newAbility
            });
            resolve(newAbility);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });
    });
}

export const removeAbilityFromRole = (context, {roleId, ability}) => {
    if(!ability.id) {
        // not persisted, just remove from collection
        context.commit('removeAbilityFromRole', {roleId, ability});
        return;
    }

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/roles/' + roleId + '/abilities/' + ability.id).then(response => {
            context.commit('removeAbilityFromRole', {roleId, ability});
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });
    });
}

