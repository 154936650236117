<template>
    <div class="form-group"
         :id="fieldName + '-text-field'"
         :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
    >
        <label class="form-control-label"><span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
            <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
        </label>
        <div class="">
            <input type="text" class="form-control" :name="fieldConfig.fieldName" ref="formText" :value="value"
                   @change="updateValue($event.target.value)"
                   :disabled="fieldConfig.disabled === 1"
            >
            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
            </span>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
<script>
    import Inputmask from "inputmask";
    import { FormFieldMixin } from 'laravel-vue-forms';
    export default {
        mixins: [FormFieldMixin],

        name: 'input-mask',

        mounted() {
            var selector = document.getElementsByName(this.fieldName);

            if(selector) {
                if(this.fieldConfig.field_extra.mask === undefined) {
                    console.error('InputMask field extra mask is not defined');
                    return;
                }
                let mask = this.fieldConfig.field_extra.mask;
                if(this.isJson(this.fieldConfig.field_extra.mask )) {
                    mask = JSON.parse(this.fieldConfig.field_extra.mask);
                }

                var im = new Inputmask(mask);
                im.mask(selector[0]);
            }
        },

        methods: {
            isJson(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            updateValue(value) {
                this.form.errors.clear(this.fieldConfig.value_field);
                this.$emit('input', value);
            }
        }

    }
</script>
