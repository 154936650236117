import Model from 'etap/admin/classes/models/model';

class Lookup extends Model {

    defaults() {
        return {
            id: null,
            shared_lookup_id: null,
            model: null,
            name: '',
            description: '',
            order: 0,
            parent_id: null,
            date_flag_id: null,
            attributes: {}
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default Lookup;
