<template>
    <div class="fullreportqczerowarning" v-show="zeroCount['zeroMeasuresCount']">
        NOTE - There are {{ zeroCount['zeroMeasuresCount'] }} measure(s) with {{ zeroCount['zeroValuesCount'] }} zero savings/cost values.
        Please ensure the values are correct and not a calculation error.
    </div>
</template>
<script>

    import { mapGetters } from 'vuex';
    import Measure_picker_utils from "../../mixins/measure_picker/measure_picker_utils";
    export default {

        mixins: [Measure_picker_utils],

        computed: {
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            phase() {
                return this.$store.getters['mp_layout/getPhase'](this.mpid);
            },
            zeroCount() {
                return this.$store.getters['mp_project_measures/zeroCount'](this.currentScenario, this.phase)
            }

            // ...mapGetters('mp_project_measures',[
            //     'zeroCount'
            // ])
        },

    }
</script>
<style>

    .fullreportqczerowarning {
        margin: 10px 0px;
    }

</style>
