import Vue from 'vue';
import axios from 'axios';
import { startCase } from 'lodash';
import Parser from "etap/admin/classes/jsonapi_parser";

export default {
    namespaced: true,

    state: {

        measureEquipment: {},
        equipmentVersion: {},

    },

    getters: {
        measureEquipmentForMeasure(state) {
            return (measureId) => {

                if(state.measureEquipment[measureId]) {
                    return state.measureEquipment[measureId];
                }

                return null;
            }
        },
        getEquipmentVersionsForId(state) {
            return(equipmentId) => {
                if(state.equipmentVersion[equipmentId]) {
                    return state.equipmentVersion[equipmentId];
                }

                return [];
            }
        }
    },

    mutations: {
        addEquipmentForMeasure(state, measureEquipment) {
            // if measureId doesn't exist in the state or if no equipment exist for the measureId, add the new equipment to the measureId
            if(!state.measureEquipment[measureEquipment.measureId] || !Array.isArray(state.measureEquipment[measureEquipment.measureId])) {
                Vue.set(state.measureEquipment, measureEquipment.measureId, measureEquipment.equipment);

                return;
            }

            for(let i = 0; i < measureEquipment.equipment.length; i++) {
                let index = state.measureEquipment[measureEquipment.measureId].findIndex(equipment => {
                    return measureEquipment.equipment[i].id === equipment.id;
                })

                //Update existing equipment
                if(index !== -1) {
                    state.measureEquipment[measureEquipment.measureId].splice(index, 1, measureEquipment.equipment[i]);
                } else { //Add new equipment to existing measure
                    state.measureEquipment[measureEquipment.measureId].push(measureEquipment.equipment[i]);
                }
            }

        },
        addOrUpdateEquipmentForMeasure(state, {measureId, equipment}) {
            //Add new equipment to new measure
            if(!state.measureEquipment[measureId] || !Array.isArray(state.measureEquipment[measureId])) {
                // create a new key and add the equipment
                Vue.set(state.measureEquipment, measureId, []);
                state.measureEquipment[measureId].push(equipment);
                return;
            }

            //Update existing equipment
            let index = state.measureEquipment[measureId].findIndex(equip => equip.id === equipment.id);
            if(index !== -1) {
                state.measureEquipment[measureId].splice(index, 1, equipment);
                return;
            }

            //Add new equipment to existing measure
            state.measureEquipment[measureId].push(equipment);
        },
        addOrUpdateEquipmentVersionsForEquipmentId(state, {equipmentId, equipmentVersions}) {

                if(!state.equipmentVersion[equipmentId] || !Array.isArray(state.equipmentVersion[equipmentId])) {
                    Vue.set(state.equipmentVersion, equipmentId, []);
                }

                state.equipmentVersion[equipmentId] = equipmentVersions;
        }
    },

    actions: {
        updateProjectMeasure(context, projectMeasure) {

            context.commit('measurepicker/updateProjectMeasure', projectMeasure, { root: true });
            context.dispatch('measurepicker/getMeasureSummary', null, { root: true });

        },
        getMeasureEquipmentForMeasureIds(context, {measureIds, project}) {

            axios.get('/api/measures/equipment', {
                params: {
                    measures: measureIds,
                    active: 1,
                    project_id: project.id,
                }
            }).then(response => {


                const equipment = Parser.parseJSONAPIResponse(
                    response.data
                );
                measureIds.forEach(measureId => {

                    const equipmentForMeasure = equipment.getModels().filter(equip => {
                        if(typeof equip.equipment_measures.getModels !== 'function') {
                            return false;
                        }
                        return equip.equipment_measures.getModels().some(em => em.measure_id === measureId);
                    })

                    context.commit('addEquipmentForMeasure', {
                        measureId,
                        equipment: equipmentForMeasure
                    })
                })

            }).catch(error => {
                console.log(error);
                window.notify.apiError(error);
            })
        },
        getMeasureEquipmentForEquipmentIds(context, {measureIds, equipmentIds}) {

            if (
                !Array.isArray(equipmentIds) ||
                equipmentIds.length === 0
            ) {
                return;
            }

            axios.get('/api/measures/equipment', {
                params: {
                    id: equipmentIds,
                    active: 1,
                    pp: 0
                }
            }).then(response => {

                const equipment = Parser.parseJSONAPIResponse(
                    response.data
                );
                measureIds.forEach(measureId => {

                    const equipmentForMeasure = equipment.getModels().filter(equip => {
                        if(typeof equip.equipment_measures.getModels !== 'function') {
                            return false;
                        }
                        return equip.equipment_measures.getModels().some(em => em.measure_id === measureId);
                    })

                    context.commit('addEquipmentForMeasure', {
                        measureId,
                        equipment: equipmentForMeasure
                    })
                })

            }).catch(error => {
                console.log(error);
                window.notify.apiError(error);
            })
        },
        getEquipmentVersions(context, {equipmentId}) {


                return new Promise((resolve, reject) => {
                    axios.get('/api/equipment/' + equipmentId + '/versions').then(response => {
                            context.commit('addOrUpdateEquipmentVersionsForEquipmentId', {
                                equipmentId,
                                equipmentVersions: response.data
                            });
                            resolve(response);
                    }).catch(error => {
                        reject(error);
                    })
                });

        }
    },
}
