<template>
    <div id="importEquipment">
        <div>
            <div class="inline-children">
                <div class="btn" @click="goBack"><i aria-label="Go Back" class="fa fa-arrow-circle-left"></i>&nbsp;Go Back</div>
                <h3>Import Equipment Pricing and Specs</h3>
            </div>

            <div id="importJobs">
                <div class="inline-children">
                    <h3>Current and Finished Import Jobs</h3>
                    <i aria-label="Loading" class="fa fa-spin fa-spinner" v-show="loadingJobs"></i>
                </div>
                <div class="help-text" @click="reloadJobs">
                    <p>
                        <i aria-label="Refresh" class="fa fa-refresh"></i> Refresh import jobs
                    </p>
                </div>

                <collapse-list :initially-open="true">
                    <h4 slot="header">Active Jobs</h4>
                    <div slot="items">
                        <equipment-upload-job-status
                                v-for="job in active_jobs"
                                :key="job.id"
                                :status="job"></equipment-upload-job-status>
                    </div>
                </collapse-list>

                <collapse-list>
                    <h4 slot="header">Finished Jobs</h4>
                    <div slot="items">
                            <equipment-upload-job-status
                                v-for="job in finished_jobs"
                                :key="job.id"
                                :status="job"></equipment-upload-job-status>
                    </div>
                </collapse-list>

            </div>
            <div class="inline-children">
                <h3>Upload new Import File</h3>
                <span class="text-small">(e.g. .xlsx, .xls, .csv)</span>
                <div class="btn" @click="downloadSample">
                    <i aria-label="Download" class="fa fa-download"></i>&nbsp;Download Sample CSV File
                </div>
            </div>
            <upload-container
                upload-url="/api/equipment_library/upload"
                :max-files="1"
                @uploadSuccess="jobAdded"
                ></upload-container>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapActions, mapGetters } from 'vuex';
    import EquipmentUploadJobStatus from 'etap/components/EquipmentLibrary/EquipmentUploadJobStatus.vue';
    export default {

        components: {
            EquipmentUploadJobStatus
        },

        data() {
            return {
                loadingJobs: false
            }
        },

        created() {
            this.loadingJobs = true;
            this.getEquipmentUploadJobs().then( resp => {
                this.loadingJobs = false;
            }).catch( error => {
                this.loadingJobs = false;
            });
        },

        computed: {
            ...mapGetters('equipment_library', [
                'active_jobs',
                'finished_jobs'
            ])
        },

        methods: {
            ...mapMutations('equipment_library', [
                'setMainView',
                'addEquipmentPricingJob',
            ]),
            ...mapActions('equipment_library', [
                'getEquipmentUploadJobs',
            ]),
            goBack() {
                this.setMainView('equipment-library-measure-list');
            },
            jobAdded(response) {
                this.addEquipmentPricingJob(response.status);
            },
            reloadJobs() {
                this.loadingJobs = true;
                this.getEquipmentUploadJobs().then( resp => {
                    this.loadingJobs = false;
                }).catch( error => {
                    this.loadingJobs = false;
                });
            },
            downloadSample() {
                window.open(window.MAT.asset_base + '/samples/equipment_upload_example.csv')
            }
        }
    }
</script>
