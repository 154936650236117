<template>
    <div class="existing-files row">
        <div class="file" v-for="file in files">
            <div class="file-icon">
                <div v-if="file.thumbnail && thumbnails[file.id] !== false" class="thumbnail">
                    <div
                        v-if="thumbnails[file.id] === 'error'"
                        style="width: 80px; height: 100px; border: 1px solid;"
                    >
                        <i
                            class="fa fa-times fa-3x"
                        ></i>
                    </div>
                    <span
                        class="fa fa-spinner fa-spin"
                        v-else-if="thumbnails[file.id] === null"
                    ></span>
                    <img v-else-if="thumbnails[file.id]" :src="thumbnails[file.id]" width="80px" />
                </div>
                <span v-else class="thumbnail fa fa-file-o fa-4x"></span>
            </div>
            <div class="action-row">
                <span class="fa fa-download" @click="downloadFile(file)"></span>
                <span v-if="disabled === false" class="fa fa-close" @click="selectForDeletion(file)"></span>
            </div>
            <div class="file-name">
                {{ file.original_filename }}
            </div>
        </div>
        <modal
                v-if="showFileDeleteModal"
                :isConfirm="true"
                confirmText="Confirm Delete"
                modalWidth="400px"
                @close="showFileDeleteModal = false"
                @confirmed="deleteSelectedFile">

            <span slot="header">Delete File</span>
            <div slot="body" id="measure-delete-dialog-text">Are you sure you want to delete this file?</div>
        </modal>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            files: {
                required: true
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                showFileDeleteModal: false,
                deleteFile: {},
                thumbnails: {}
            }
        },

        watch: {
            files(newFiles) {
                this.getThumbnailsForFiles()
            }
        },

        methods: {
            async downloadFile(file) {
                const urlResponse = await axios.get('/api/signed-download-url/' + file.id);

                if(!urlResponse.data.meta.url) {
                    throw new Error("Invalid url to open");
                }

                window.open(urlResponse.data.meta.url);
            },
            getThumbnailsForFiles() {
                this.files.forEach(file => {

                    if(!file.thumbnail) {
                        this.$set(this.thumbnails, file.id, false);
                        // this.thumbnails[file.id] = false;
                        return;
                    } else if(typeof this.thumbnails[file.id] === 'string') {
                        return; // don't update for files we've already loaded
                    }

                    this.$set(this.thumbnails, file.id, null);
                    axios.get('/api/files/' + file.id + '/thumbnail').then(response => {
                        let thumbnailUrl;
                        if(this.formHasJsonApi) {
                            thumbnailUrl = response.data.meta.url;
                        } else {
                            thumbnailUrl = response.data;
                        }

                        this.$set(this.thumbnails, file.id, thumbnailUrl);
                    }).catch(error => {
                        console.log(error);
                        this.$set(this.thumbnails, file.id, 'error');
                    });
                });
            },
            selectForDeletion(file) {
                this.deleteFile = file;
                this.showFileDeleteModal = true;
            },
            deleteSelectedFile() {
                this.showFileDeleteModal = false;
                axios.delete('/api/files/' + this.deleteFile.id).then( response => {
                    this.$emit('deletedFile', this.deleteFile);
                    this.deleteFile = {};
                    window.notify.message('Successfully deleted file', 'success');
                }).catch( error => {
                    window.notify.apiError(error);
                });
            }
        }
    }
</script>
<style lang="scss" rel="stylesheet/scss">

    .existing-files {
        min-height: 75px;
        border: 1px dotted;

        .file {
            padding: 10px 5px;
            text-align: center;
            cursor: pointer;
            width: 120px;
            display: inline-block;
            border: 1px dashed;
            margin: 5px;
        }
        .file-icon {
            width: 100px;
            margin: auto;
        }
        .file-name {
            margin-bottom: 10px;
            word-wrap: break-word;
        }
        .thumbnail {
            margin-bottom: 10px;
        }
    }

</style>
