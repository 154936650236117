import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faSpinner,
    faSearch,
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faPlusCircle,
    faMinusCircle,
    faPlus,
    faEdit,
    faTimes,
    faEye,
    faCheck,
    faQuestionCircle,
    faSync,
    faDownload,
    faFile,
    faFolder,
    faFolderOpen,
    faFileArchive,
    faPaperPlane,
    faSave
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faSpinner);
library.add(faSearch);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleDown);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faPlus);
library.add(faEdit);
library.add(faTimes);
library.add(faEye);
library.add(faCheck);
library.add(faQuestionCircle);
library.add(faSync);
library.add(faDownload);
library.add(faFile);
library.add(faFolder);
library.add(faFolderOpen);
library.add(faFileArchive);
library.add(faPaperPlane);
library.add(faSave);

Vue.component('fa-icon', FontAwesomeIcon);
