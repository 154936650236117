import axios from 'axios';

export const getChecklistConfigurationForProgramTrackAndYear = (context, {programTrack, programYear, type}) => {

    let configurationsForProgramTrackAndYear = context.getters.getChecklistConfigurationForProgramTrackAndYear(programTrack, programYear);
    if(configurationsForProgramTrackAndYear && configurationsForProgramTrackAndYear.length > 0) {
        return;
    }

    return new Promise((resolve, reject) => {
        axios.get('/api/program_tracks/' + programTrack.id + '/program_year/' + programYear.id + '/checklist_configuration').then(response => {
            context.commit('setChecklistConfigurationForProgramTrackAndYear', {
                programTrack,
                programYear,
                configurations: response.data
            });
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });

}

export const getChecklistConfigurationForProject = (context, {project, type}) => {

    let programTrack = context.rootState.programTracks.find(pTrack => {
        return project.program_track_id === pTrack.id;
    });

    let programYear = context.rootState.programYears.find(pYear => {
        return project.program_year_id === pYear.id;
    });

    return context.dispatch('getChecklistConfigurationForProgramTrackAndYear', {
        programTrack,
        programYear,
        type
    });
}

export const getChecklistItems = (context, {model, modelType}) => {

    return new Promise((resolve, reject) => {
        let baseURL = context.getters.getBaseURLForModel(modelType, model);
        axios.get(baseURL).then(response => {
            context.commit('setChecklist', {
                type: modelType,
                model,
                checklist: response.data
            });
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}


export const setRelatedProjectChecklistForType = (context, {projectId, model, modelType, checklistItemName, checked}) => {

    return axios.post('/api/projects/' + projectId + '/' + modelType + '/' + model.id + '/checklist', {
        checked,
        type: checklistItemName
    });
}
