<template>
    <div class="tabs-component">
        <div v-if="isMultiStep">
            <div class="multistep-tabs flex mb-4">
                <button
                    v-for="(tab, index) in tabs"
                    :key="tab.hash"
                    :class="{ 'is-active': tab.isActive }"
                    @click="selectTab(tab.hash)"
                    class="multistep-tab flex-1 border-0 p-2 bg-none text-left"
                    style="margin-right: 1px;"
                >

                    <span class="block font-hairline text-sm">Step {{ index+1 }}</span>
                    <span v-html="tab.header" class="block"></span>
                </button>
            </div>
        </div>
        <ul role="tablist" class="tabs-component-tabs" v-else>
            <li
                v-for="tab in tabs"
                :key="tab.hash"
                :class="{ 'is-active': tab.isActive }"
                class="tabs-component-tab"
                role="presentation"
            >
                <a
                    v-html="tab.header"
                    :aria-controls="tab.hash"
                    :aria-selected="tab.isActive"
                    @click="selectTab(tab.hash)"
                    :href="tab.hash"
                    class="tabs-component-tab-a"
                    role="tab"
                ></a>
            </li>
        </ul>
        <div class="tabs-component-panels">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "form-tabs",

    props: {
        isMultiStep: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        tabs: [],
        activeTabHash: ""
    }),

    created() {
        this.tabs = this.$children;
    },

    mounted() {
        window.addEventListener("hashchange", () =>
            this.selectTab(window.location.hash)
        );

        if (this.findTab(window.location.hash)) {
            this.selectTab(window.location.hash);
            return;
        }

        if (this.tabs.length) {
            this.selectTab(this.tabs[0].hash);
        }
    },

    methods: {
        findTab(hash) {
            return this.tabs.find(tab => tab.hash === hash);
        },

        selectTab(selectedTabHash) {
            const selectedTab = this.findTab(selectedTabHash);

            if (!selectedTab) {
                return;
            }

            this.tabs.forEach(tab => {
                tab.isActive = tab.hash === selectedTab.hash;
            });

            this.$emit("changed", {tab: selectedTab});

            this.activeTabHash = selectedTab.hash;
        },
        selectTabIndex(index) {
            this.selectTab(this.tabs[index].hash);
        }
    }
};
</script>
