import { isEmpty } from 'etap/utilities/utils';
export default {

    computed: {
        inspectionFormConfig() {

            if(this.formConfig) {
                return this.formConfig
            }

            if(this.editFormConfig) {
                return this.editFormConfig;
            }
        },
        inspectionFieldsToWatch() {

            if(!this.inspectionFormConfig) {
                return [];
            }

            return this.inspectionFormConfig.fields.filter(field => {
                return field.visible === 1;
            });
        },
        inspectionAudits() {
            if(!this.projectMeasure.audits) {
                return {};
            }
            var messages = {};
            this.projectMeasure.audits.forEach(audit => {
                if(audit.event === 'updated') {
                    this.inspectionFieldsToWatch.forEach(field => {
                        if(audit.new_values[field.value_field] &&
                            (
                                audit.old_values[field.value_field] &&
                                audit.new_values[field.value_field] !== audit.old_values[field.value_field]
                            )
                        ){
                            this.$set(messages, field.value_field, 'The ' + field.label + ' has changed during inspection to: ' + audit.new_values[field.value_field])
                        }
                    })
                }
            });
            return messages;
        },
        hasInspectionAudits() {

            if(!this.showAudits) {
                return false;
            }


            return !isEmpty(this.inspectionAudits)
        },
        showAudits() {
            return this.$store.getters['mp_layout/measurePickerCan']('show_pm_audits', this.mpid);
        }
    }

}
