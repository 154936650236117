<template>
    <div class="rewind-project-button">
        <button class="inputbutton1" :disabled="!canRewind" @click.prevent="showRewindConfirmModel = true">{{ label }}</button>
        <modal
            v-if="showRewindConfirmModel"
            :is-confirm="true"
            modal-width="500px"
            @close="showRewindConfirmModel = false"
            @confirmed="rewind"
            :confirm-text="modalConfirmText"
        >

            <h4 slot="header">
                {{ modalHeader}}
            </h4>

            <div slot="body" v-html="modalBody"></div>

        </modal>
    </div>
</template>
<script>
    import RewindsProjects from "../../mixins/rewindsProjects";

    export default {

        mixins: [RewindsProjects],

        props: {
            project: {
                required: true,
                type: Object,
            },
            phase: {
                required: true,
                type: Object
            },
            label: {
                required: true,
                type: String
            },
            modalConfirmText: {
                required: false,
                type: String,
                default: 'Re-wind Project'
            },
            modalHeader: {
                required: true,
                type: String,
            },
            modalBody: {
                required: true,
                type: String
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            passThru: {
                type: Boolean,
                default: false
            },
            confirmModalIsShowing: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            showConfirmModal(isShowing) {
                this.$emit("update:confirmModalIsShowing", isShowing);
            },
            confirmModalIsShowing(isModalShowing) {
                this.showConfirmModal = isModalShowing
            }
        },

        methods: {
            rewind() {
                this.runRewind(this.project.id, this.phase.id);
            }
        }

    }
</script>
<style lang="scss">


</style>
