import Vue from 'vue';
import { format_decimals } from 'etap/filters/format_decimals'
import { format_dollars } from 'etap/filters/format_dollars'

export const guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}



export const getKeyForType = (type, uniqueId) => {
    return type + '_' + uniqueId
}

export const addItemToStateArray = (state, type, key, item, idElement) => {

    if(typeof idElement === 'undefined') {
        idElement = 'id';
    }
    Vue.set(state[type], key, item);
    state[type + 'List'].push(item[idElement]);
}

export const updateItemValueOnStateArray = (state, type, id, objKey, value) => {

    let key = getKeyForType(type, id);
    Vue.set(state[type][key], objKey, value);
}

export const removeItemFromStateArray = (state, type, id) => {
    let key = getKeyForType(type, id);

    delete state[type][key];
    state[type + 'List'].splice(state[type + 'List'].indexOf(id), 1);
}

export const ucfirst = (str) => {
    str += ''
    var f = str.charAt(0)
        .toUpperCase()
    return f + str.substr(1)
}

export const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];

        if (o === null) {
            // if the object itself is null, there is no key to get
            return;
        }

        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

export const assignOnObject = (obj, prop, value) => {
    if (typeof prop === "string") prop = prop.split(".");

    if (prop.length > 1) {
        var e = prop.shift();
        assignOnObject(
            (obj[e] =
                Object.prototype.toString.call(obj[e]) === "[object Object]"
                    ? obj[e]
                    : {}),
            prop,
            value
        );
    } else obj[prop[0]] = value;
};

export const formatValue = (value, format, rounding) => {
    switch (format) {
        case 'money':
            return format_dollars(value);
        case 'numeric':
            return format_decimals(value, rounding);
        case "percent":
            return format_decimals(value, rounding) === 'TBD' ? 'TBD' : format_decimals(value, rounding).toString().concat('%');
        default:
            return value;
    }
}
