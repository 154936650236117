<template>
    <div>
        <div id="roomlistwrapper" class="relative" :style="{overflow: showingProjectMeasuresDetailForm ? 'hidden': 'scroll'}">
            <div v-if="mp_show_review_filters" class="m-4 flex justify-around items-center" >
                <form-autocomplete
                    class="w-1/3"
                    field-name="measure-filter"
                    v-model="measureFilter"
                    options-url="/api/measures"
                    :use-json-api="true"
                    label="Measure"
                />
                <form-select
                    class="w-1/3"
                    v-if="equipmentLibraryConfiguration?.approval?.enabled ?? false"
                    field-name="approval-status-selector"
                    v-model="approvalStateFilter"
                    option-label-field="name"
                    option-value-field="value"
                    :options="[{name: 'All', value: null}, {name: 'Approved', value: 'approved'}, {name: 'Pending', value: 'pending'}, {name: 'Rejected', value: 'rejected'}]"
                    label="Equipment Approval State"
                />
            </div>
            <div id="roomlist" v-if="!loadingRooms">
                <room
                    v-for="room in scenarioRooms"
                    :key="room.id"
                    :room="room"
                ></room>
            </div>
            <table-skeleton
                v-else
                class="mx-2"
                :rows="3"
                grid-type="div"
            />
            <br /><br />
            <div id="buttonwrapper" style="display: inline-block" v-if="!loadingRooms">
                <button
                    v-if="add_measures"
                    class="inputbutton1"
                    @click="addRoom({mpid})"
                    style="margin-left: 20px"
                >
                    <span class="fa fa-plus"></span>&nbsp; Add Room
                </button>
                <button
                    class="inputbutton1"
                    v-if="projectMeasuresToDelete.length !== 0"
                    @click="showDeletePMMeasuresModal"
                    value="- Delete Selected Measures"
                    style="margin-left: 20px"
                >
                    <span class="fa fa-minus"></span>&nbsp; Delete Selected Measures
                </button>
            </div>
            <transition name="fade">
                <div>
                    <transition name="slide">
                        <div v-if="showingProjectMeasuresDetailForm" class="right-drawer" :style="{width: showingProjectMeasuresDetailForm ? '95%' : '0', paddingLeft: showingProjectMeasuresDetailForm ? '10px' : '0'}">
                            <project-measure-details></project-measure-details>
                        </div>
                    </transition>

                    <div class="drawer-mask" :style="{width: showingProjectMeasuresDetailForm ? '120vh' : '0'}" @click="closeProjectMeasureDetailsForm"></div>
                </div>
            </transition>
        </div>
        <modal
            v-if="showPMDeleteModal"
            :isConfirm="true"
            confirmText="Confirm Delete"
            modal-width="400px"
            @close="showPMDeleteModal = false"
            @confirmed="deleteSelectedMeasures">

            <span slot="header">Delete Measures</span>
            <div slot="body" id="measure-delete-dialog-text">Are you sure you want to delete a total of {{ projectMeasuresToDelete.length }} selected measures?</div>
        </modal>
    </div>
</template>
<script>
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    import {mapState, mapActions} from 'vuex';

    export default {

        mixins: [MeasurePickerUtils],

        async created() {
            if(this.scenarioRooms.length === 0) {
                this.loadingRooms = true;
                await this.getScenarioRooms(this.mpid);
            }
            setTimeout(() => {
                this.loadingRooms = false;
            }, 200)
        },

        data() {
            return {
                loadingRooms: true,
                showPMDeleteModal: false,
            }
        },

        computed: {
            showingProjectMeasuresDetailForm() {
                return this.$store.getters['mp_layout/getShowingProjectMeasuresDetailForm'](this.mpid);
            },
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            phase() {
                return this.$store.getters['mp_layout/getPhase'](this.mpid);
            },
            scenarioRooms() {
                return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.phase)
            },
            projectMeasuresToDelete() {
                return this.$store.getters['mp_project_measures/projectMeasuresToDelete'](this.mpid);
            },
            measureFilter: {
                get() {
                    const filters = this.$store.getters['mp_layout/getRoomListFilters'](this.mpid);

                    return filters?.measureId;
                },
                set(measureId) {
                    this.$store.commit(
                        'mp_layout/updateRoomListFilter',
                        {mpid: this.mpid, filterName: 'measureId', filterValue: measureId}
                    )
                }
            },
            approvalStateFilter: {
                get() {
                    const filters = this.$store.getters['mp_layout/getRoomListFilters'](this.mpid);

                    return filters?.approvalState;
                },
                set(approvalStatus) {
                    this.$store.commit(
                        'mp_layout/updateRoomListFilter',
                        {mpid: this.mpid, filterName: 'approvalState', filterValue: approvalStatus}
                    )
                }
            }
        },

        methods: {
            ...mapActions('mp_project_measures', [
                'addRoom',
                'getScenarioRooms',
                'canUpdateCosts'
            ]),
            showDeletePMMeasuresModal() {
                this.showPMDeleteModal = true;
            },
            deleteSelectedMeasures() {
                this.showPMDeleteModal = false;
                this.$store.dispatch('mp_project_measures/deleteSelectedMeasures', this.mpid);
            },
            closeProjectMeasureDetailsForm() {
                this.$store.dispatch('mp_layout/hideProjectMeasureDetails', this.mpid);
            },
        }
    }
</script>
<style scoped>
.right-drawer {
    position: absolute;
    top: 0;
    right: 0;
    overflow: scroll;
    width: 0;
    height: 100%;
    padding-left: 0;
    border-left: 2px solid whitesmoke;
    background: white;
    z-index: 29;
    transition: all 0.5s;
}

.drawer-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 0; /* initially */
    height: 100%;
    background: #000;
    opacity: 0.6;
    z-index: 28;
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>
