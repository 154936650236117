<template>
    <div>
        <input type="checkbox" class="projectcheckbox"
               :name="this.checkboxName + '-checkbox'"
               v-model="checkboxValue"
               :checked="checkboxValue"
               :disabled="disabled"/>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {

        props: {
            checkboxName: {
                required: true,
                type: String,
            },
            phase: {
                required: true,
                type: Object
            },
            project: {
                required: true,
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        created()  {
            this.$store.commit('qc_progress/setProject', this.project);
            this.$store.commit('qc_progress/setPhase', this.phase);
            this.getQCProgress();
        },

        computed: {
            checkboxValue: {
                get() {
                    return this.$store.getters['qc_progress/qcProgressForType'](this.checkboxName);
                },
                set(newVal) {

                    this.setQCProgress({
                        type: this.checkboxName,
                        progress: newVal
                    });
                }
            }
        },

        methods: {
            ...mapActions('qc_progress', [
                'getQCProgress',
                'setQCProgress'
            ]),
        }

    }
</script>
