
export default {
    install(Vue, options) {

        Vue.directive('can', {
            bind(el, binding, vnode, oldVnode) {
                var permission = null;
                var hasPermission = false;
                var entityType = null;
                if(binding.value instanceof Object) {

                    if(binding.value.entity_type) {
                        entityType = binding.value.entity_type;
                    }

                    if(binding.value.auth_id == window.MAT.user.id) {
                        return true;
                    }

                    permission = binding.value.permission;
                }else {
                    permission = binding.value;
                }

                hasPermission = window.MAT.permissions.some((perm) => {
                    if(perm.name === permission && perm.entity_type === entityType) {
                        return true;
                    }

                    return false;
                });

                if(!hasPermission) {
                    el.style.display = 'none';
                }
            }
        });

        Vue.prototype.$can = function(permission, entity_type = null, auth_id = false) {

            if(window.MAT.user.id == auth_id) {
                return true;
            }

            return window.MAT.permissions.some((perm) => {
                if(perm.name === permission && perm.entity_type === entity_type) {
                    return true;
                } else if(entity_type === null && perm.name === permission) {
                    return true;
                }

                return false;
            });
        }
    }
}
