<script>

export default {
    props: {
        fields: {
            type: Number,
            default: 3
        },
        columns: {
            type: Number,
            default: 1
        }
    },

    computed: {
        columnClass() {
            if(this.columns === 1) {
                return 'w-full';
            }

            return 'w-1/' + this.columns;
        }
    }
}


</script>
<template>
    <div class="md:flex justify-between">
        <div :class="'mx-1 ' + columnClass" v-for="i in columns">
            <div class="skeleton-input" v-for="i in fields">
                <div class="skeleton skeleton-input-label"></div>
                <div class="skeleton-border skeleton-input-field"></div>
            </div>

        </div>
    </div>
</template>
