

export const formConfigurationForProjectMeasure = (state, getters, rootState) => (projectMeasure, project) => {



    if(state.cachedFormConfigs[project.program_year_id] && state.cachedFormConfigs[project.program_year_id][projectMeasure.measure.id]) {
        return state.cachedFormConfigs[project.program_year_id][projectMeasure.measure.id]
    }

    return null;
}


export const measurePickerCan = (state) => (feature, mpid) => {
    return state.measure_pickers[mpid].features[feature];
}

export const getProject = (state) => (mpid) => {
    return state.measure_pickers[mpid].project;
}

export const getPhase = (state) => (mpid) => {
    return state.measure_pickers[mpid].phase;
}

export const getMeasureList = (state) => (mpid) => {
    return state.measure_pickers[mpid].measureList;
}

export const getMeasureSummary = (state) => (mpid) => {
    return state.measure_pickers[mpid].measureSummary
}

export const showProjectMeasureDetails = (state) => (mpid) => {
    return state.measure_pickers[mpid].showProjectMeasureDetails
}

export const getMainView = (state) => (mpid) => {
    return state.measure_pickers[mpid].mainView
}

export const getFullPicker = (state) => (mpid) => {
    return state.measure_pickers[mpid].fullPicker
}

export const getShowingProjectMeasuresDetailForm = (state) => (mpid) => {
    return state.measure_pickers[mpid].showingProjectMeasureDetailsForm;
}

export const getCurrentProjectMeasure = state => mpid => {
    return state.measure_pickers[mpid].currentProjectMeasure;
}

export const getEditFormConfig = state => mpid => {
    return state.measure_pickers[mpid].editFormConfig;
}

export const getRoomListFilters = state => mpid => {
    return state.measure_pickers[mpid].roomListFilters;
}

export const getPickerType = state => mpid => {
    return state.measure_pickers[mpid].pickerType;
}

export const getRoomsExpanded = (state) => (mpid) => {

    if(!state.measure_pickers[mpid] || !state.measure_pickers[mpid].roomsExpanded) {
        return null
    }

    for (let [roomId, currentExpandedValue] of Object.entries(state.measure_pickers[mpid].roomsExpanded)) {
        if (!currentExpandedValue) {
            // at least one room is collapsed
            return false;
        }
    }

    return true;
}

export const getRoomExpanded = (state) => (room, mpid) => {

    if(!state.measure_pickers[mpid] || !state.measure_pickers[mpid].roomsExpanded || !state.measure_pickers[mpid].roomsExpanded[room.id]) {
        return null
    }

    return state.measure_pickers[mpid].roomsExpanded[room.id];
}
