import Collection from 'etap/admin/classes/collection';
import Company from 'etap/admin/classes/models/company';

export default {

    companies: new Collection([], {model: Company}),
    companySearchResults: new Collection([], {model: Company}),


}
