<template>
    <div>
        <div>
            <button @click="sendNotification" :disabled="buttonDisabled" class="inputbutton1">
                <span class="fa fa-spinner fa-spin" v-if="busy"></span>&nbsp;&nbsp;
                {{ buttonText }}&nbsp;&nbsp;</button>
        </div>
        <modal
            v-show="hasValidationError"
            modal-width="550px"
            @close="hasValidationError = false"
            class="send_notification_error"
        >

            <h4 slot="header">
                Notification Validation Error
            </h4>

            <div slot="body" v-html="validationError"></div>
        </modal>
    </div>
</template>
<script>
    export default {

        props: {
            notification: {
                required: true,
                type: String,
            },
            buttonText: {
                required: true,
                type: String,
            },
            projectId: {
                required: true,
                type: Number
            },
            phaseId: {
                required: true,
                type: Number
            },
            disabled: {
                default: false,
            },
        },

        data() {
            return {
                hasValidationError: false,
                validationError: '',
                buttonDisabled: this.disabled,
                busy: false,
            }
        },

        methods: {
            sendNotification(){

                this.buttonDisabled = true;
                this.busy = true;

                axios.post('/api/notifications/' + this.projectId + '/send', {
                    notification: this.notification,
                    phase: this.phaseId
                }).then(response => {

                    this.busy = false;
                    if(!this.disabled) {
                        this.buttonDisabled = false;
                    }
                    this.$emit('updated')

                }).catch( error => {

                    this.busy = false;
                    if(!this.disabled) {
                        this.buttonDisabled = false;
                    }

                    if(error.response.status === 422) {
                        this.validationError = error.response.data.message;
                        this.hasValidationError = true;
                        console.log(error.response.data);

                    }else {
                        window.notify.apiError(error);
                    }
                })

            }
        }

    }
</script>
