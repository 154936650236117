<script>
import { faClipboard, faEdit, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { DateTime } from 'luxon'

export default {

    props: {
        record: {
            required: true,
            type: Object,
        },
        args: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            icons: {
                faClipboard,
                faEdit,
                faTimes,
                faSpinner
            },
            editing: false,
            confirmDelete: false,
            showingAudits: false,
            deleting: false,
        }
    },

    computed: {
        created_at() {
            return DateTime.fromJSDate(new Date(this.record.created_at), { zone: "UTC" })
                .setZone("local")
                .toLocaleString(DateTime.DATETIME_FULL);
        },
        updated_at() {
            return DateTime.fromJSDate(new Date(this.record.updated_at), { zone: "UTC" })
                .setZone("local")
                .toLocaleString(DateTime.DATETIME_FULL);
        },
    },

    methods: {
        showDeleteModal() {
            this.confirmDelete = true;
        },
        noteUpdated(updatedNote) {
            this.$store.commit('notes/upsertNote', updatedNote);
        },
        closeNoteForm(formActionInfo) {
            this.editing = false;
        },
        async runDeleteNote() {
            try {
                if(!this.args.baseUrl) {
                    throw new Error("Invalid url for notes");
                }

                this.deleting = true;
                await this.$store.dispatch(
                    'notes/deleteNoteForEntity', {
                        baseUrl: this.args.baseUrl,
                        note: this.record
                    }
                );

                window.notify.success('Successfully deleted note', 'Deleted');

            }catch(err) {
                window.notify.apiError(err);
            }finally{
                this.deleting = false;
            }
        }
    }
}
</script>
<template>
    <div class="border-t border-b p-2 md:p-4 my-2">
        <div v-if="!editing" class="flex">
            <div class="w-3/4 p-2">
                <div class="flex my-2 shadow p-2">
                    <font-awesome-icon
                        size="lg"
                        :icon="icons.faClipboard"
                        class="mr-2 md:mr-4"
                        @click="showingAudits = !showingAudits"
                    ></font-awesome-icon>
                    <font-awesome-icon
                        size="lg"
                        :icon="icons.faEdit"
                        class="mr-2 md:mr-4"
                        @click="editing = true"
                    ></font-awesome-icon>
                    <font-awesome-icon
                        size="lg"
                        :icon="icons.faTimes"
                        @click="showDeleteModal"
                    ></font-awesome-icon>
                </div>
                <div class="p-2 md:p-4" v-html="record.note"></div>
            </div>
            <div class="w-1/4">
                <div v-if="record.created_by">
                    <label class="block font-bold">Created By</label>
                    {{ record.created_by.user_select_title }}
                </div>
                <div>
                    <label class="block font-bold">Created At</label>
                    {{ created_at }}
                </div>
                <div>
                    <label class="block font-bold">Last Updated At</label>
                    {{ updated_at }}
                </div>
            </div>
        </div>
        <div v-else-if="editing">
            <vue-form
                :form-data="record"
                :form-config="args.noteForm"
                :use-json-api="true"
                :form-submit-url="args.baseUrl + '/notes/' + record.id"
                :actions="[
                    {
                        name: 'submit',
                        label: 'Save Note',
                        action: 'submitForm',
                    },
                    { name: 'cancel', label: 'Close Note', action: 'cancel' },
                ]"
                @cancel="closeNoteForm"
                @updated="noteUpdated"
            ></vue-form>
        </div>

        <div
            v-if="showingAudits"
            class="mt-2 md:mt-4mx-4 md:mx-8 p-2 md:p-4 border-l-2 border-r-2"
        >
            <div class="flex justify-end mb-2">
                <button class="button" @click="showingAudits = false">
                    Close Audits
                </button>
            </div>
            <audits-sub-view
                :auditable="record"
                :auditable-entity-type="args.noteEntityTypeRecord"
                :allow-refresh="false"
            ></audits-sub-view>
        </div>

        <modal
            v-if="confirmDelete"
            width="35%"
            @close="confirmDelete = false"
        >
            <template #header>
                <h3 class="text-white">Are you sure?</h3>
            </template>
            <template #body>
                <div class="flex justify-around">
                    <button
                        class="button"
                        :disabled="deleting"
                        @click="runDeleteNote"
                    >
                        <span v-if="deleting">
                            <font-awesome-icon
                                :icon="icons.faSpinner"
                                :spin="true"
                            ></font-awesome-icon>
                        </span>
                        Delete note
                    </button>
                    <button
                        class="button"
                        :disabled="deleting"
                        @click="confirmDelete = false"
                    >
                        Cancel
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>
