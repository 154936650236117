<script>
export default {

    data() {
      return {
          password: '',
          password_confirm: ''
      }
    },

    computed: {
        has10chars() {
            return this.password.length >=10
        },
        hasLowercase() {
            return /[a-z]/.test(this.password);
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasDigit() {
            return /[0-9]/.test(this.password);
        },
        hasSpecialChar() {
            return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(this.password);
        },
        passwordsMatch() {
            return this.password === this.password_confirm && this.password.length !== 0;
        },
        csrfToken() {
            return window.MAT.csrfToken;
        }
    },

    props: {
        resetPasswordUrl: {
            type: String,
            default: '/api/auth/reset-password'
        },
        urlMethod: {
            type: String,
            default: 'post'
        },
        email: {
            required: true,
            type: String,
        },
        token: {
            type: String,
        }
    }
}
</script>
<template>
    <form method="post" :action="resetPasswordUrl">


        <div class="text-center flex flex-col justify-center">
            <div class="">

                <h2 class="my-8">Reset Password</h2>
                <div class="flex justify-center mb-4">
                    <div class="w-2/3 text-left">
                        <div class="form-group">
                            <label>Email</label>
                            <input
                                type="text"
                                readonly="readonly"
                                disabled="disabled"
                                name="email"
                                id="email"
                                class="block rounded w-full"
                                style="border: 1px solid; padding:10px;"
                                v-model="email"
                            />
                            <input
                                type="hidden"
                                readonly="readonly"
                                name="email"
                                id="email"
                                class="block rounded w-full"
                                style="border: 1px solid; padding:10px;"
                                v-model="email"
                            />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                id="username"
                                class="block rounded w-full"
                                style="border: 1px solid; padding:10px;"
                                v-model="password"
                            />
                        </div>
                        <div>
                            <ul style="list-style: none" class="mb-2 ml-2">
                                <li :class="{'bg-green': has10chars}" class="p-1">
                                    <i class="fa fa-check" v-if="has10chars"></i>
                                    Has a minimum of 10 characters
                                </li>
                                <li :class="{'bg-green': hasLowercase}" class="p-1">
                                    <i class="fa fa-check" v-if="hasLowercase"></i>
                                    Has at least 1 lowercase letter
                                </li>
                                <li :class="{'bg-green': hasUppercase}" class="p-1">
                                    <i class="fa fa-check" v-if="hasUppercase"></i>
                                    Has at least 1 uppercase letter
                                </li>
                                <li :class="{'bg-green': hasDigit}" class="p-1">
                                    <i class="fa fa-check" v-if="hasDigit"></i>
                                    Has at least 1 number
                                </li>
                                <li :class="{'bg-green': hasSpecialChar}" class="p-1">
                                    <i class="fa fa-check" v-if="hasSpecialChar"></i>
                                    Has at least 1 special character
                                </li>
                            </ul>
                        </div>
                        <div class="form-group mt-2">
                            <label>Password Confirmation</label>
                            <input
                                type="password"
                                name="password_confirmation"
                                id="password"
                                class="block rounded w-full"
                                style="border: 1px solid; padding:10px;"
                                v-model="password_confirm"
                            />
                        </div>
                        <ul style="list-style: none" class="mb-2 ml-2">
                            <li :class="{'bg-green': passwordsMatch}" class="p-1">
                                <i class="fa fa-check" v-if="passwordsMatch"></i>
                                Passwords Match
                            </li>
                        </ul>
                        <input
                            type="hidden"
                            name="token"
                            id="token"
                            v-if="token"
                            v-model="token"
                        />
                        <input
                            type="hidden"
                            name="_token"
                            id="_token"
                            v-model="csrfToken"
                        />

                        <input
                            v-if="urlMethod === 'PATCH'"
                            type="hidden"
                            name="_method"
                            value="PATCH"
                        />
                        <input type="submit" class="w-full inputbutton1 rounded" value="Reset Password">
                    </div>
                </div>
            </div>
        </div>

    </form>
</template>
<style>
.bg-green {
    background-color: rgba(96, 212, 96, 0.98);
}
</style>
