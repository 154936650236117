import Model from 'etap/admin/classes/models/model';
import User from 'etap/admin/classes/models/user';
import moment from 'moment';

class Audit extends Model {

    defaults() {
        return {
            id: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDateTime,
            updated_at: this.parseDateTime
        }
    }

    relationships() {
        return {
            user: User
        }
    }

}

export default Audit;
