<template>
    <div class="flex align-items-center">
        <div class="form-group" style="width: 50%">
            <label>Existing Schedules</label>
            <multi-select
                :options="schedules"
                track-by="id"
                label="name"
                :show-labels="false"
                :select-label="''"
                :deselect-label="''"
                @input="selectSchedule">
            </multi-select>
        </div>
        <button class="inputbutton1" @click.prevent="create">Create New</button>
    </div>
</template>
<script>
    import MultiSelect from 'vue-multiselect';
    export default {

        components:{
            MultiSelect
        },

        props: {
            projectId: {
                required: true,
                type: Number
            }
        },

        computed: {
            schedules() {
                return this.$store.getters['project_operating_schedule/getSchedulesForProject'](this.projectId);
            }
        },

        methods: {
            create() {
                this.$emit('create');
            },
            selectSchedule(schedule) {
                this.$emit('useSchedule', schedule);
            }
        }
    }
</script>
