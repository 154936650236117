<template>
    <div class="energy-dashboard">
        <div class="w-full flex justify-between items-center">
            <div>
                <h1>{{ title }}</h1>
                <h3>{{ titleSecondary }}</h3>
            </div>
            <div class="w-1/4 flex items-center">
                <span class="littlenote mr-1 w-1/4">Program Year</span>
                <form-select
                    class="w-3/4"
                    field-name="valid-years"
                    :allow-empty="false"
                    :show-multiselect-labels="false"
                    :options="validYears"
                    option-value-field="program_year"
                    option-label-field="program_year"
                    v-model="selectedYear"
                />
            </div>
        </div>

        <div v-if="!selectedYear && !this.loadingTab">
            Please select a program year's dashboard that you would like to view.
        </div>

        <div class="tab-container">
            <button v-for="tabName in dashboardTabs" :key="tabName" class="button mx-1" :class="{'selected': tabName === currentTab}" @click="currentTab = tabName">{{ tabName }}</button>
        </div>
        <div class="w-full min-h-50vh text-center flex flex-col justify-around" v-show="loadingTab">
            <span class="fa fa-spinner fa-spin fa-4x"></span>
        </div>
        <div class="iframeContainer" v-show="!loadingTab">
            <iframe
                style="display: block; width: 100%; height:800px"
                class="my-1"
                v-for="iframeKey in iframeKeys"
                :key="iframeKey"
                :ref="'view-' + iframeKey"
            ></iframe>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        dashboardFeatureName: {
            type: String,
            default: 'dashboard'
        },
        currentYear: String,
    },
    data() {
        return {
            selectedYear: null,
            currentTab: null,
            loadingTab: false,
        }
    },
    async created() { // compute what iframe keys are needed for rendering
        try {
            this.loadingTab = true;
            await this.$store.dispatch('energy_dashboard/parseValidYears', this.dashboardFeatureName);
            let hasValidYear = this.validYears.findIndex(validYear => {
                return validYear.program_year === this.currentYear;
            });
            if(this.currentYear && hasValidYear !== -1) {
                this.selectedYear = this.currentYear;
            }
        }catch(error) {
            console.error(error);
            window.notify.apiError(error)
        }
        this.loadingTab = false;
    },
    watch: {
        currentTab(newTab) {
            this.loadingTab = true;
            this.updateTab(newTab).then(() => {
                this.loadingTab = false;
            }).catch(error => {
                window.notify.apiError(error);
            });
        },
        viewUrls(updatedUrls) {
            var url, iframeRef;
            var urlIndex = 0;
            for (var key in this.$refs) {
                iframeRef = this.$refs[key][0];
                url = updatedUrls[urlIndex];
                if (!url) {
                    iframeRef.src = '';
                    iframeRef.style.display = 'none';
                } else {
                    iframeRef.style.display = 'block';
                    iframeRef.src = url;
                    iframeRef.src += '';
                }
                urlIndex++
            }
        },
        selectedYear(updatedYear) {
            this.updateYear(updatedYear);
        }
    },
    computed: {
        dashboardFeature() {
            return this.$store.getters['system/getFeatureForName'](this.dashboardFeatureName);
        },
        title() {
            if(!this.dashboardFeature || !this.dashboardFeature.options.viewsByYear || !this.selectedYear) {
                return '';
            }
            let year = this.dashboardFeature.options.viewsByYear.find(year => year.program_year === this.selectedYear);
            if(year.program_year == this.selectedYear) {
                return year.title;
            }
            return '';
        },
        titleSecondary(){
            if(!this.dashboardFeature || !this.dashboardFeature.options.viewsByYear || !this.selectedYear) {
                return '';
            }
            let year = this.dashboardFeature.options.viewsByYear.find(year => year.program_year === this.selectedYear);
            if(year.program_year == this.selectedYear) {
                return year.title_secondary;
            }
            return '';
        },
        dashboardTabs(){
            return this.$store.getters['energy_dashboard/tabsForDashboard'](this.dashboardFeatureName, this.selectedYear);
        },
        validYears() {
            return this.$store.getters['energy_dashboard/validYears'];
        },
        iframeKeys() {
            return this.$store.state.energy_dashboard.currentIframeKeys;
        },
        viewUrls() {
            return this.$store.state.energy_dashboard.currentViewUrls;
        },
    },
    methods: {
        async updateTab(tabName) {
            await this.$store.dispatch('energy_dashboard/getTableauTicket');
            await this.$store.dispatch('energy_dashboard/updateCurrentViewUrlsForFeatureAndTab', {
                featureName: this.dashboardFeatureName,
                tabName
            });
            return true;
        },
        async updateYear(year) {
            await this.$store.dispatch('energy_dashboard/getTableauTicket');
            await this.$store.dispatch('energy_dashboard/updateCurrentYear', year);
            await this.$store.dispatch('energy_dashboard/createIframeKeysForFeature', this.dashboardFeatureName);
             if(this.dashboardTabs && this.dashboardTabs[0]) {
                this.currentTab = this.dashboardTabs[0];
            }
            await this.$store.dispatch('energy_dashboard/updateCurrentViewUrlsForFeatureAndTab', {
                featureName: this.dashboardFeatureName,
                tabName: this.currentTab
            });
        }
    }
}
</script>