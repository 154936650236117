<template>
    <div class="m-4">
        <h3>Force Re-calculate for Project</h3>

        <form-checkbox
            field-name="all-phase-checkbox"
            label="Recalculate All Phases"
            v-model="allPhases"
        ></form-checkbox>

        <multiselect
            v-if="allPhases === false"
            v-model="selectedPhases"
            :options="projectPhaseOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :preserve-search="true"
            label="title"
            track-by="id"
            :preselect-first="false"
            class="my-2">
            <template slot="tag" slot-scope="props">
                <span class="inline p-2 mr-2 mb-2 bg-primary text-white rounded">
                    <span>{{ props.option['title'] }}</span>
                    <span class="text-base ml-1 font-black cursor-pointer" @click="props.remove(props.option)">x</span>
                </span>
            </template>
        </multiselect>

        <form-checkbox
            field-name="all-calculations-checkbox"
            label="Recalculate All Calculations"
            v-model="allCalculations"
        ></form-checkbox>

        <multiselect
            v-if="allCalculations === false"
            v-model="selectedCalculations"
            :options="calculationTypeOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :preserve-search="true"
            label="label"
            track-by="type"
            :preselect-first="false"
            class="my-2">
            <template slot="tag" slot-scope="props">
                <span class="inline p-2 mr-2 mb-2 bg-primary text-white rounded">
                    <span>{{ props.option['label'] }}</span>
                    <span class="text-base ml-1 font-black cursor-pointer" @click="props.remove(props.option)">x</span>
                </span>
            </template>
        </multiselect>

        <form-radio
            field-name="scenario-type-selection"
            :options="scenarioOptions"
            v-model="selectedScenarioOption"
            option-label-field="label"
            option-value-field="value"
        ></form-radio>

        <button class="inputbutton1" :disabled="recalculating" @click="recalculate">
            <span v-if="recalculating">
                <i class="fa fa-spinner fa-spin"></i>&nbsp;Re-calculating...
            </span>
            <span v-else>
                Re-calculate Project
            </span>
        </button>

    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import { filter } from 'lodash';
    import Parser from 'etap/admin/classes/jsonapi_parser';
    import axios from 'axios';
    export default {

        components: {
            Multiselect
        },

        props: {
            project: {
                required: true,
            },
            validPhaseConfigsForRecalc: {
                required: true,
            }
        },


        data() {
            return {
                allPhases: true,
                selectedPhases: [],
                allCalculations: true,
                selectedCalculations: [],
                scenarioOptions: [
                    {
                        value: 'selected',
                        label: 'Customer Selected Scenario'
                    },
                    {
                        value: 'all',
                        label: 'All Scenarios'
                    }
                ],
                selectedScenarioOption: 'selected',
                recalculating: false,
                jobStatus: {},
                currentIntervalCheck: null
            }
        },

        created() {
            this.checkInitialJobStatus();
            this.selectAllPhases();
            this.selectAllCalcTypes();
        },

        computed: {
            projectPhaseOptions() {
                return this.validPhaseConfigsForRecalc.map(config => {
                    return config.projectPhase;
                })
            },
            selectedPhaseIds(){
                if(!this.selectedPhases) {
                    return [];
                }

                return this.selectedPhases.map(phase => {
                    return phase.id;
                });
            },
            calculationTypeOptions() {

                var calcTypeOptions = [];
                this.validPhaseConfigsForRecalc.forEach(config => {
                    config.configuration.measure_configuration.calc_types.forEach(calcType => {
                        if(filter(calcTypeOptions, {type: calcType.type}).length === 0) {
                            calcTypeOptions.push({
                                type: calcType.type,
                                label: calcType.label
                            });
                        }
                    });
                });

                return calcTypeOptions;
            },
            selectedCalcTypes() {
                if(!this.selectedCalculations) {
                    return []
                }

                return this.selectedCalculations.map(calculation => {
                    return calculation.type;
                })
            }
        },

        watch: {
            allPhases(newAllPhases) {
                if(newAllPhases) {
                    this.selectAllPhases();
                }
            },
            allCalculations(newAllCalculations) {
                if(newAllCalculations) {
                    this.selectAllCalcTypes();
                }
            }
        },

        methods: {
            selectAllPhases() {
                this.selectedPhases = this.projectPhaseOptions;
            },
            selectAllCalcTypes() {
                this.selectedCalculations = this.calculationTypeOptions;
            },
            recalculate() {

                this.recalculating = true;

                this.$store.dispatch('projects/reCalculateProjectCalculations', {
                    projectId: this.project.id,
                    selectedPhaseIds: this.selectedPhaseIds,
                    selectedCalcTypes: this.selectedCalcTypes,
                    selectedScenarioOption: this.selectedScenarioOption
                }).then(response => {
                    this.startInterval()
                }).catch(error => {
                    this.clearIntervalCheck();
                    this.recalculating = false;
                    window.notify.apiError(error);
                })
            },
            async checkInitialJobStatus() {
                await this.getJobStatus()
                if(['queued', 'executing'].includes(this?.jobStatus?.status)) {
                    this.startInterval()
                    this.recalculating = true
                }
            },
            async checkJobStatusAndNotify() {
                await this.getJobStatus()

                if(['queued', 'executing'].includes(this?.jobStatus?.status)) {
                    return;
                }

                if(['failed', 'cancelled'].includes(this?.jobStatus?.status)) {
                    return window.notify.message('Error re-calculating.', 'error');
                }

                if(['finished'].includes(this?.jobStatus?.status)) {
                    return window.notify.message('Successfully re-calculated.', 'success');
                }
            },
            async getJobStatus() {
                try {
                    const response = await axios.get('/api/system/job_status_logs?type=Nexant\\AuditTool\\Jobs\\RecalculateProjectCalculationsJob&pp=1')
                    const jobStatuses = Parser.parseJSONAPIResponse(response.data)

                    if(jobStatuses.getModels().length !== 1) {
                        this.clearIntervalCheck()
                        this.recalculating = false
                        return;
                    }

                    this.jobStatus = jobStatuses.getModels()[0]
                    if(['failed', 'cancelled', 'finished'].includes(this?.jobStatus?.status)) {
                        this.clearIntervalCheck()
                        this.recalculating = false
                    }
                } catch (error) {
                    this.clearIntervalCheck()
                    this.recalculating = false
                    window.notify.apiError(error);
                }
            },
            startInterval() {
                if (this.currentIntervalCheck === null) {
                    this.currentIntervalCheck = setInterval(this.checkJobStatusAndNotify, 5000)
                }
            },
            clearIntervalCheck() {
                if (this.currentIntervalCheck !== null) {
                    clearInterval(this.currentIntervalCheck)
                    this.currentIntervalCheck = null
                }
            }
        }

    }

</script>
