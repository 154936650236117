<template>
    <div class="measuresContainer">
        <equipment-library-search></equipment-library-search>
        <component :is="mainView" ></component>
    </div>
</template>
<script>
    import { mapGetters, mapState, mapMutations } from 'vuex';
    export default {

        computed: {
            ...mapState('equipment_library', [
                'mainView',
            ])
        },

        methods: {
            ...mapMutations('equipment_library', [
                'setMainView'
            ]),
        }

    }
</script>
