<template>
    <div class="email-unsubscribe">
        <div v-if="autoOpen === false && showSuccessMessage === false">
            <!-- TODO: ADD BUTTON OPENER FOR ADMIN OR OTHER TYPES OF UNSUBSCRIPTION -->
        </div>
        <div v-if="showSuccessMessage" class="message-good">
            Successfully un-subscribed your email of: {{ email }}
        </div>
        <modal
            v-if="showConfirmModal"
            :is-confirm="true"
            modal-width="500px"
            @close="showConfirmModal = false"
            @confirmed="runUnsubscription"
            confirm-text="Yes"
        >

            <h4 slot="header">
                Unsubscribe email
            </h4>

            <div slot="body" class="text-center">
                <p>{{ trans('audit_tool.unsubscribe.modal_text') }} </p>
                <br />
                <p>Are you sure you want to unsubscribe?</p>
            </div>

        </modal>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            email: {
                required: true,
                type: String
            },
            autoOpen: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },

        data() {
            return {
                showConfirmModal: false,
                showSuccessMessage: false,
            };
        },

        created() {
            if(this.autoOpen) {
                this.showConfirmModal = true;
            }
        },

        methods: {
            runUnsubscription() {

                axios.post('/api/emails/unsubscribe', {
                    email: this.email,
                }).then(response => {

                    this.showConfirmModal = false;
                    this.showSuccessMessage = true;

                }).catch(error => {
                    window.notify.apiError(error);
                });

            }
        }
    }
</script>
