<template>
    <li>
        <span>
            <div class="form-check form-check-inline">
                <input class="form-check-input"
                       type="checkbox" id="checklistConfigurationItem.name + '-checkbox'"
                       v-model="checked"
                       :disabled="disabled"
                >
                <label class="form-check-label" for="checklistConfigurationItem.name + '-checkbox'">{{ checklistConfigurationItem.label }}</label>
            </div>
            <br>
            <expansion-panel v-model:show-panel="panelOpen" v-if="showPanel">
                <div class="list-items">
                    <ul>
                        <li v-for="(item, listKey) in messages" :key="listKey">
                            <span v-html="item"></span>
                        </li>
                    </ul>
                </div>
            </expansion-panel>
        </span>
    </li>
</template>
<script>
    export default {
        props: {
            checklistConfigurationItem: {
                type: Object,
                required: true,
            }
        },

        data() {
            return {
                panelOpen: true
            }
        },

        computed: {
            modelChecklistItem() {
                return this.$store.getters['checklists/checklistItemForModalAndConfigurationItem'](this.$parent.model, this.$parent.modelType, this.checklistConfigurationItem);
            },
            checked: {
                get() {

                    if(this.modelChecklistItem && this.modelChecklistItem.checked) {
                        return true;
                    }

                    return false;
                },
                set(isChecked) {
                    return isChecked;
                }
            },
            messages() {

                if(!this.modelChecklistItem || !this.modelChecklistItem.messages) {
                    return [];
                }

                return this.modelChecklistItem.messages;
            },
            disabled() {

                if(this.checklistConfigurationItem.checkable) {
                    return false;
                }

                return true;
            },
            displayMessage() {
                if(
                    this.checklistConfigurationItem.item_configuration !== null &&
                    this.checklistConfigurationItem.item_configuration.hasOwnProperty('displayMessage')
                ) {
                    return this.checklistConfigurationItem.item_configuration.displayMessage;
                }

                return false;
            },
            showPanel() {
                return this.messages.length > 0 && this.displayMessage;
            }
        }
    }
</script>
<style lang="scss">

    .form-check {
        display: inline-block;

        input[type=checkbox] + label {
            display: block;
            margin: 0.2em;
            cursor: pointer;
            padding: 0.2em;
        }

        input[type=checkbox] {
            display: none;
        }

        input[type=checkbox] + label:before {
            content: "\2714";
            border: 0.1em solid #000;
            border-radius: 0.2em;
            display: inline-block;
            padding-left: 0.2em;
            padding-right: 0.1em;
            margin-right: 0.2em;
            color: transparent;
            transition: .2s;
        }

        input[type=checkbox] + label:active:before {
            transform: scale(0);
        }

        input[type=checkbox]:checked + label:before {
            background: white;
            color: #788890;
        }

    }

</style>
