import axios from 'axios';

export const getProjectData = (context, projectId) => {

    axios.get('/api/projects/' + projectId + '/customer_information').then(response => {
        context.commit('setOriginalProjectData', response.data);
    }).catch(error => {
        window.notify.apiError(error);
    })

}
