<template>

    <div class="form-group project-date-field inline-children">

        <label class="date-title'>">
            {{ label }}
        </label>&nbsp;
        <div class="date-field">
            <input type="text" v-model="date" :disabled="disabled" class="form-control date-field-control" :id="projectDate" />
        </div>

    </div>
</template>
<script>

    export default {

        props: {
            label: {
                type: String,
                required: true,
            },
            projectDate: {
                type: String,
                required: true,
            },
            phase: {
                type: Object,
                required: true
            },
            project: {
                type: Object,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            apiUrl: {
                type: String,
                default() {
                    return '/api/projects/' + this.project.id + '/date';
                }
            }
        },

        data() {
            return {
                date: this.project[this.projectDate],
            }
        },

        watch: {
            date() {
                this.updateProjectDate()
            }
        },

        mounted() {
            var vm = this;
            $('input#' + this.projectDate).datepicker({
                onClose: function (date) {
                    vm.date = date;
                }
            });
        },

        methods: {
            updateProjectDate() {
                axios.patch(this.apiUrl, {
                    projectDateField: this.projectDate,
                    date: this.date,
                    phase: this.phase.id
                }).then( response => {
                    if(window.Bus) {
                        setTimeout(function() {
                            window.Bus.$emit('updateChecklist')
                        }, 2000);
                    }
                }).catch( error => {
                    window.notify.apiError(error);
                });
            }
        }

    }
</script>
