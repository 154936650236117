<script>
export default {
    props: {
        project: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            showConfirmUpdateModal: false,
            executingConfigurationUpdate: false,
        }
    },

    computed: {
        needsConfigurationUpdateConfig() {
            return this.$store.getters['projects/getNeedsConfigurationUpdateConfigForProjectId'](this.project.id);
        },
        needsUpdate() {
            if (!this.needsConfigurationUpdateConfig) {
                return false;
            }

            return this.needsConfigurationUpdateConfig.needsUpdate
        },
        projectConfigurationUpdateIsRunning() {
            return this.$store.state.projects.projectConfigurationUpdateIsRunning;
        },
        currentProjectConfigurationUpdateStatus() {
            return this.$store.state.projects.currentProjectConfigurationUpdateStatus;
        }
    },
    methods: {
        showUpdateConfirmationModal() {
            this.showConfirmUpdateModal = true;
        },
        async executeConfigurationUpdateForProject() {
            this.executingConfigurationUpdate = true;

            try {
                const responseData = await this.$store.dispatch('projects/updateProjectConfiguration', {
                    projectId: this.project.id
                });
                this.showConfirmUpdateModal = false;
                this.$store.commit('projects/updateProjectConfigurationUpdateStatus', responseData.jobStatus);
                this.$store.commit('projects/projectConfigurationUpdateIsRunning', true)
                await this.$store.dispatch('projects/checkForConfigurationUpdate');

            } catch (err) {
                console.log(err);
            }

            this.executingConfigurationUpdate = false;
        }
    }
}
</script>
<template>
    <div v-if="needsUpdate || (projectConfigurationUpdateIsRunning && currentProjectConfigurationUpdateStatus !== null)" class="flex justify-center">
        <div class="warning-message flex justify-center items-center w-1/3 py-2 px-4">
            <div
                class="text-left"
                v-if="needsUpdate"
                v-html="needsConfigurationUpdateConfig.bannerWarningMessage"
            ></div>
            <div class="text-sm">
                <button class="inputbutton1" @click="showUpdateConfirmationModal" :disabled="projectConfigurationUpdateIsRunning">
                    <span v-if="projectConfigurationUpdateIsRunning"><i class="fa fa-spinner fa-spin"></i></span>
                    <span v-if="needsConfigurationUpdateConfig">{{ needsConfigurationUpdateConfig.configurationUpdateButtonText }}</span>
                    <span v-else>Updating</span>
                </button>
            </div>
        </div>
        <modal
            v-if="showConfirmUpdateModal"
            modal-width="500px"
            @close="showConfirmUpdateModal = false"
        >
            <div slot="body">
                <div class="text-left" v-html="needsConfigurationUpdateConfig.configurationUpdateConfirmText"></div>
                <div class="text-sm mt-2">
                    <button
                        class="inputbutton1"
                        @click="executeConfigurationUpdateForProject"
                        :disabled="executingConfigurationUpdate"
                    >
                        <span v-if="executingConfigurationUpdate"><i class="fa fa-spinner fa-spin"></i></span>
                        <span>{{ needsConfigurationUpdateConfig.configurationUpdateButtonText }}</span>
                    </button>
                    <button
                        class="inputbutton1"
                        @click="showConfirmUpdateModal = false"
                        :disabled="executingConfigurationUpdate"
                    >
                        <span v-if="executingConfigurationUpdate"><i class="fa fa-spinner fa-spin"></i></span>
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
