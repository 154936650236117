<template>
    <section
        :aria-hidden="!isActive"
        class="tabs-component-panel"
        :id="hash"
        role="tabpanel"
    >
        <div v-show="isActive">
            <component
                v-for="field in visibleChildren"
                :key="field.id"
                :is="getFormFieldComponent(field.widget)"
                v-show="conditionValues[field.name]"
                :field-name="field.name"
                :value="getFieldValue(form.data, field)"
                @input="newVal => updateValueAndConditionals(newVal, field)"
                @options-updated="
          newOptions => updateOptionsForField(newOptions, field)
        "
                :children="field.children || null"
                :disabled="disabled ? 1 : 0"
            ></component>
            <div class="controls-row flex" v-if="disabled === false" >
                <button
                    class="button"
                    v-for="action in actions"
                    :key="action.action"
                    @click.prevent="runAction(action.action)"
                    v-html="action.label"
                    :disabled="showSaving && saving"
                    v-if="!isMultiStep && ( (action.action !== 'submitForm' && action.action !== 'cancelForm') || !autoSave )"
                ></button>
                <span v-if="saving && showSaving">
                    <font-awesome-icon :icon="spinner" :spin="true"></font-awesome-icon>
                    {{ savingText }}
                </span>


                <div  v-if="isMultiStep">
                    <button class="button" @click="goToPrevious" v-if="!isFirstTab" :disabled="showSaving && saving">
                        Go Back
                    </button>
                    <button
                        class="button"
                        v-for="action in actions"
                        :key="action.action"
                        @click.prevent="runAction(action.action)"
                        v-html="action.label"
                        :disabled="showSaving && saving"
                        v-if="( (action.action !== 'submitForm' && action.action !== 'cancelForm') || !autoSave )"
                    ></button>
                    <button class="button" @click="submitAndGoToNext" v-if="!isLastTab" :disabled="showSaving && saving">
                        {{ multiStepContinueLabel }}
                    </button>
                    <button class="button" @click="submitAndGoToNext" v-else-if="multiStepFinalButtonLabel && !autoSave" :disabled="showSaving && saving">
                        {{ multiStepFinalButtonLabel }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FormField from "../../mixins/FormField";
import FormConfig from "../../mixins/FormConfig";
import UpdatesValuesAndConditions from "../../mixins/UpdatesValuesAndConditions";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    mixins: [FormField, FormConfig, UpdatesValuesAndConditions],

    name: "form-tab",

    components: {
        FontAwesomeIcon
    },

    props: {
        children: {
            type: Array,
            default() {
                return [];
            }
        },
        id: {
            default: null
        },
        name: {
            required: true
        },
        value: {
            required: false
        },
        prefix: {
            default: ""
        },
        suffix: {
            default: ""
        },
        disabled: {
            required: true
        },
        autoSave: {
            required: true
        },
        actions: {
            required: true
        },
        saving: {
            required: true
        },
        showSaving: {
            required: true
        },
        savingText: {
            required: true
        },
        isMultiStep: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: 0
        },
        submitFormFunction:{
            type: Function,
            default: async () => {}
        },
        formOptions: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    created() {
        this.generateConditionValues();

        this.$watch("form.data", () => {
            this.generateConditionValues();
        }, {deep: true})
    },

    data: () => ({
        isActive: false,
        spinner: faSpinner
    }),

    computed: {
        header() {
            return this.prefix + this.name + this.suffix;
        },

        hash() {
            return this.id
                ? "#" + this.id
                : "#" + this.name.toLowerCase().replace(/ /g, "-");
        },
        visibleChildren() {
            return this.children.filter(child => {
                return Boolean(child.visible);
            });
        },
        isFirstTab() {
            return this.index === 0;
        },
        isLastTab() {
            return this.index === this.$parent.tabs.length - 1;
        },
        multiStepContinueLabel() {
            return this.formOptions?.multistepContinueLabel ?? "Save & Continue";
        },
        multiStepFinalButtonLabel() {
            return this.formOptions?.multistepFinalStepLabel;
        }
    },

    methods: {
        runAction(action) {
            this.$emit("runAction", action);
        },
        goToPrevious() {
            this.$parent.selectTabIndex(this.index - 1);
        },
        goToNext() { // for now, this will directly access the parent. in vue 3, we should use the tabs provider
            this.$parent.selectTabIndex(this.index + 1);
        },
        async submitAndGoToNext() {
            try {
                await this.submitFormFunction();
                this.goToNext()
            }catch(err) {
                // let the form error handler handle the rest
            }
        }
    }
};
</script>
