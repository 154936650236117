export default {


    methods: {
        getModelStringForType(type) {

            if (window.MAT.model_map[type]) {
                return window.MAT.model_map[type];
            }

            return null;
        }
    }

}
