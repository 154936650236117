
export default {
    install(Vue, options) {

        Vue.directive('uses-feature', {
            bind(el, binding, vnode, oldVnode) {
                var askedFeature = null;

                askedFeature = binding.value;
                var hasFeature = window.MAT.features.find(feature => {
                    return feature.name === askedFeature;
                });

                if(!hasFeature) {
                    el.style.display = 'none';
                }
            }
        });

        Vue.prototype.$usesFeature = function(feature) {

            if(window.MAT.features[feature]) {
                return true;
            }

            return false;
        }
    }
}
