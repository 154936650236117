<template>
    <div class="flex-grow">
        <measure-summary></measure-summary>
        <transition name="slide">
            <component :is="mainView" class="overflow-y-scroll h-60vh" :class="{'h-95vh': fullPicker }"></component>
        </transition>
    </div>
</template>
<script>
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    export default {

        mixins: [MeasurePickerUtils],

        computed: {
            mainView() {
                return this.$store.getters['mp_layout/getMainView'](this.mpid)
            },
            fullPicker() {
                return this.$store.getters['mp_layout/getFullPicker'](this.mpid)
            },
        }
    }
</script>
