import Vue from "vue";
import axios from "axios"

export default {
    namespaced: true,

    state: {
        jobs: {}
    },

    actions: {
        getJobForId(context, {jobId, projectId}) {

            if(context.state.jobs[jobId]) {
                return Promise.resolve(context.state.jobs[jobId]);
            }

            return new Promise((resolve, reject) => {
                axios.get('/api/projects/' + projectId + '/jobs/' + jobId).then(response => {
                    context.commit("setJobForId", {
                        jobId,
                        job: response.data
                    });
                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            });
        },
        cancelJob(context, {jobId, projectId}) {
            return new Promise((resolve, reject) => {
                axios.delete('/api/projects/' + projectId + '/jobs/' + jobId).then(response => {
                    context.commit("setJobForId", {
                        jobId,
                        job: response.data
                    })
                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            })
        },
        getJobForStatusable(context, {statusableType, projectId}) {

            return new Promise((resolve, reject) => {
                axios.get('/api/projects/' + projectId + '/jobs', {
                    params: {
                        statusable_type: statusableType,
                        order_by: 'updated_at',
                        order_direction: 'DESC'
                    }
                }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });

        }
    },

    mutations: {
        setJobForId(state, {jobId, job}) {
            Vue.set(state.jobs, jobId, job);
        }
    }

}
