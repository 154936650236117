export default {


    methods: {
        selfOrParentHasClass(element, className) {
            var regex = new RegExp('\\b' + className + '\\b');
            do {
                if (regex.exec(element.className)) {
                    return true;
                }
                element = element.parentNode;
            } while (element);
            return false;
        }
    }

}