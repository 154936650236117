export default {


    methods: {
        getSavingsSubDivClass(value) {

            if(value !== null && typeof value === "string" && value.substr(0, 1) === '$') {
                return '';
            }

            if ( (parseFloat(value) == 0 || isNaN(parseFloat(value)) ) &&
                ( value !== "" || value == null) ){
                return 'adminqczero';
            }
            return '';
        },
    }
}
