import {byString} from "laravel-vue-forms";


export default {
    computed: {
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        taskFormConfiguration() {
            if(
                !this.taskFeature?.options?.tasks_form_configuration
            ) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName']
            (this.taskFeature.options.tasks_form_configuration);
        },
        taskDisplayColumns() {
            if(
                !this.taskFeature ||
                !this.taskFeature.enabled ||
                !this.taskFeature.options?.tasks_home_display_fields
            ) {
                return [];
            }

            return this.taskFeature.options.tasks_home_display_fields;
        },
        taskStatues() {
            const statusesModel = this.taskFeature.options?.task_statuses;
            if(!statusesModel) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](statusesModel);
        },
        taskTypes() {
            const taskTypesModels = this.taskFeature.options?.task_types;
            if(!taskTypesModels) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](taskTypesModels);
        },
        taskDisplayValues() {
            if(!this.taskFormConfiguration) {
                return [];
            }

            return this.taskDisplayColumns.map(col => {
                const ff = this.taskFormConfiguration.fields.find(f => {
                    return f.value_field === col;
                });

                let value = null;
                let label = null;
                switch(ff?.value_field) {
                    case 'task_status_id':
                        value = this.taskStatues.find(s => {
                            return s.id === this.record.task_status_id;
                        })?.name;
                        label = ff.label;
                        break;
                    case 'task_type_id':
                        value = this.taskTypes.find(t => {
                            return t.id === this.record.task_type_id;
                        })?.name;
                        label = ff.label;
                        break;
                    case 'assignable_id':
                        value = this.record.assignable?.user_select_title;
                        if(!value) {
                            value = 'Unassigned'
                        }
                        label = ff.label;
                        break;
                    default:
                        switch(col) {
                            case 'created_at':
                                label = 'Created';
                                value = this.record.created_at;
                                break;
                            case 'updated_at':
                                label = 'Last Updated';
                                value = this.record.updated_at;
                                break;
                            default:
                                label = ff?.label;
                                value = byString(this.record, ff.value_field);
                        }
                }

                return {
                    label: label,
                    value: value
                }
            })
        },
        taskableModelType() {
            return Object.keys(window.MAT.model_map).find(key => window.MAT.model_map[key] === this.record.taskable_type);
        },
        taskableEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName'](this.taskableModelType);
        },
        taskableDisplayComponent() {
            switch(this.taskableEntityType?.name) {
                case 'project':
                    return 'project-taskable-view';
                default:
                    return null;
            }
        },
        formSubmitUrl() {
            switch(this.taskableEntityType?.name) {
                case 'project':

                    if(!this.record.id) {
                        return '/api/projects/' + this.record.taskable_id + '/tasks';
                    }

                    return '/api/projects/' + this.record.taskable_id + '/tasks/' + this.record.id;

                    break;
                default:
                    return null;
            }
        },
        formActions() {
            if(!this.record.id) {
                return [
                    {
                        label: 'Create Task',
                        name: 'create',
                        action: 'submitForm',
                    }
                ]
            }

            return [
                {
                    label: 'Update Task',
                    name: 'update',
                    action: 'submitForm',
                }
            ]
        },
        taskEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('task');
        },
    }
}
