<script>
import axios from 'axios';
import {isArray} from "lodash";
export default {

    data() {
        return {
            errorMessage: '',
            message: '',
            formData: {
                company: {},
                user: {}
            }
        }
    },


    methods: {
        resetCurrentForm() {
            this.$emit('resetCurrentForm')
        },
        logCompanyData(newData) {
            this.formData.company = newData;
        },
        logUserData(userData) {
            this.formData.user = userData;
        },
        async register() {
            try {
                await axios.post('/api/auth/register', this.formData);
                window.location = '/home';
            }catch(err) {

                console.log(err);
                if(err.response && err.response.status === 422) {
                    let errorMessage = '<p>There was an error validating your submission:</p>';
                    // build error message list
                    if(typeof err.response.data.errors === 'object') {
                        errorMessage += '<ul>';
                        for (var key in err.response.data.errors) {
                            if (isArray(err.response.data.errors[key]) && err.response.data.errors.hasOwnProperty(key)) {
                                errorMessage += '<li>';
                                err.response.data.errors[key].forEach(message => {
                                    errorMessage += message;
                                })
                                errorMessage += '</li>';
                            } else {
                                errorMessage += '<li>' + err.response.data.errors[key] + '</li>';
                            }
                        }
                        errorMessage += '</ul>';
                    }

                    this.errorMessage = errorMessage;
                } else {
                    this.errorMessage = 'An unknown error occurred while trying to sign up.';
                }
            }
        },
    },

    props: {
        companySignUpForm: {
            type: Object,
        },
        userSignUpForm: {
            type: Object
        }
    }

}
</script>
<template>
    <div class="mt-4">
        <div class="smallnotloggedin text-left flex justify-center">
            <div class="w-2/3 cursor-pointer mb-4" @click="resetCurrentForm">
                <i class="fa fa-arrow-left"></i>&nbsp; Go Back
            </div>
        </div>
        <div class="flex justify-center text-left">
            <p class="w-3/4 text-red mx-4" v-if="errorMessage" v-html="errorMessage"></p>
        </div>
        <p class="text-green mx-4" v-if="message">
            {{ message }}
        </p>

        <div class="text-left flex justify-center">
            <div class="w-2/3 cursor-pointer mb-4">
                <vue-form
                    v-if="companySignUpForm"
                    :form-config="companySignUpForm"
                    :form-data="{}"
                    :pass-thru="true"
                    @changed="logCompanyData"
                    :actions="[]"
                ></vue-form>

                <vue-form
                    v-if="userSignUpForm"
                    :form-config="userSignUpForm"
                    :form-data="{}"
                    :pass-thru="true"
                    @changed="logUserData"
                    :actions="[]"
                ></vue-form>

                <button class="w-full inputbutton1 rounded" @click="register">Sign-Up</button>
            </div>
        </div>


    </div>
</template>
