<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            actions: [
                {
                    value: 'checkMailedDate',
                    label: Lang.get('audit_tool.home_project_row.dates.checkMailedDate')
                },
                {
                    value: 'finalProjectApprovalDate',
                    label: Lang.get('audit_tool.home_project_row.dates.finalProjectApprovalDate')
                },
                {
                    value: 'submitInvoicesDate',
                    label: Lang.get('audit_tool.home_project_row.dates.submitInvoicesDate')
                },
                {
                    value: 'projectApprovalDate',
                    label: Lang.get('audit_tool.home_project_row.dates.projectApprovalDate')
                },
                {
                    value: 'preApprovalDate',
                    label: Lang.get('audit_tool.home_project_row.dates.preApprovalDate')
                },
                {
                    value: 'residentialApplicationSubmissionDate',
                    label: 'Application Received Date',
                },
                {
                    value: 'siteVisitDate',
                    label: Lang.get('audit_tool.home_project_row.dates.siteVisitDate')
                }
            ]
        }
    },

    computed: {
        programTrack() {
            return this.$store.state.programTracks.find(programTrack => {
                return programTrack.id === this.record.program_track_id;
            });
        },
        programYear() {
            return this.$store.state.programYears.find(programYear => {
                return programYear.id === this.record.program_year_id;
            });
        },
        usesDSMC() {
            return window.MAT.features.find(feature => {
                return feature.name === 'dsmc_api' && feature.enabled;
            }) !== undefined;
        },
        programName() {
            return this.programTrack.title + ' ' + this.programYear.program_year;
        },
        reservationId() {
            if(!this.usesDSMC || !this.record.dsmc_project) {
                return null;
            }

            return this.record.dsmc_project.project_name;
        },
        lastAction() {

            if(this.programTrack.name === 'residential') {
                return {
                    label: 'Application Received Date',
                    date: this.residentialApplicationSubmissionDate
                }
            }

            let latestAction = this.actions.find(action => {
                return this[action.value] !== null;
            });

            if(!latestAction) {
                return {
                    label: 'Site Visit Date',
                    date: null
                }
            }

            var label = latestAction.label;
            if(latestAction.value === 'siteVisitDate') {
                var date = this.siteVisitDate;
            }else {
                var date = moment(moment.utc(this[latestAction.value].created_at).toDate()).local().format('MM/DD/YYYY');
            }

            return {
                label,
                date
            };
        },
        residentialApplicationSubmissionDate() {
            if(!this.record.logs || !Array.isArray(this.record.logs) || this.record.logs.length === 0) {
                return null;
            }
            return this.getLastItemFor(this.record.logs, 'residential_application_completed');
        },
        checkMailedDate() {
            if(!this.record.payments || !Array.isArray(this.record.payments) || this.record.payments.length === 0) {
                return null;
            }

            return this.getLastItemFor(this.record.payments);
        },
        finalProjectApprovalDate() {
            if(!this.record.logs || !Array.isArray(this.record.logs) || this.record.logs.length === 0) {
                return null;
            }
            return this.getLastItemFor(this.record.logs, 'final_project_approval_completed');
        },
        submitInvoicesDate() {
            if(!this.record.logs || !Array.isArray(this.record.logs) || this.record.logs.length === 0) {
                return null;
            }
            return this.getLastItemFor(this.record.logs, 'submit_invoices_completed');
        },
        projectApprovalDate() {
            if(!this.record.logs || !Array.isArray(this.record.logs) || this.record.logs.length === 0) {
                return null;
            }
            return this.getLastItemFor(this.record.logs, 'project_approval_completed');
        },
        preApprovalDate() {
            if(!this.record.logs || !Array.isArray(this.record.logs) || this.record.logs.length === 0) {
                return null;
            }
            return this.getLastItemFor(this.record.logs, 'pre_approval_completed');
        },
        siteVisitDate() {
            return this.record.site_visit_date;
        },
    },

    methods: {
        getLastItemFor(items, action) {
            var actionItems;
            if(action) {
                actionItems = items.filter(log => {
                    return log.action === action;
                });
            }else {
                actionItems = items
            }

            if(actionItems.length === 0) {
                return null;
            }
            return actionItems.slice(-1)[0];
        },
    }
}
</script>
<template>
    <div class="flex items-center w-3/4">
        <div class="col-sm-4 project-id text-sm flex flex-col w-half">
            <span>Tool ID: {{ record.shared_project_id }}&nbsp;</span>
            <span v-if="usesDSMC">Reservation ID: {{ reservationId }}</span>
        </div>
        <div class="flex flex-col project-id text-sm text-center w-half">
            <span>{{ lastAction.label }}: {{ lastAction.date }}</span>
            <span>Program Name: {{ programName }}</span>
        </div>
    </div>
</template>
