<template>
    <div class="upgrade-project-program-year-button">
        <div v-if="currentStatus === null">
            <button class="inputbutton1" v-show="canUpgrade" @click.prevent="showConfirmModal = true">{{ label }}</button>
            <modal
                v-if="showConfirmModal"
                :is-confirm="true"
                modal-width="500px"
                @close="showConfirmModal = false"
                @confirmed="runUpgrade"
                confirm-text="Upgrade"
            >

                <h4 slot="header">
                    {{ modalHeader}}
                </h4>

                <div slot="body" v-html="modalBody"></div>

            </modal>
        </div>
        <div v-else-if="in_progress">
            <span class="fa fa-spin fa-spinner"></span>Upgrading Program Year on Project
        </div>
        <div v-else-if="failed">
            <p>Your recent job has failed: {{ currentStatus.output.message }}</p>
            <button @click="runUpgrade" class="inputbutton1">Re-upgrade Program Year</button>
        </div>
        <div v-else-if="finished">
            <p>Program year successfully upgraded.</p>
        </div>
        <div v-else>
            <p>Unknown program year upgrade status</p>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {


        props: {
            project: {
                required: true,
                type: Object,
            },
            label: {
                required: true,
                type: String
            },
            modalHeader: {
                required: true,
                type: String,
            },
            modalBody: {
                required: true,
                type: String
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                canUpgrade: false,
                showConfirmModal: false,
                currentStatus: null,
                intervalHandle: null,
            }
        },

        created() {
            this.validateUpgrade();
        },

        computed: {
            in_progress() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'queued' || this.currentStatus.status === 'executing') {
                        return true;
                    }
                }
                return false
            },
            failed() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'failed') {
                        return true;
                    }
                }
                return false;
            },
            finished() {
                if(this.currentStatus) {
                    if(this.currentStatus.status === 'finished') {
                        return true;
                    }
                }
                return false;
            }
        },

        methods: {
            validateUpgrade() {

                if(this.disabled) {
                    return;
                }

                axios.get('/api/projects/' + this.project.id + '/is_upgradable').then( response => {

                    if(response.data.is_upgradable) {
                        this.canUpgrade = true;
                    } else {
                        this.canUpgrade = false;
                    }

                }).catch( error => {
                    window.notify.apiError(error);
                });

            },
            runUpgrade() {
                if(this.canUpgrade) {

                    axios.post('/api/projects/' + this.project.id + '/upgrade').then( response => {
                        this.startInterval();
                        this.currentStatus = response.data;
                        this.showConfirmModal = false;

                    }).catch(error => {
                        window.notify.apiError(error);
                        this.showConfirmModal = false;
                    });
                }
            },
            getUpgradeStatus() {
                if(!this.currentStatus || !this.currentStatus.id) {
                    window.notify.message('Invalid Status ID to get status', 'error');
                    return;
                }

                axios.get('/api/projects/' + this.project.id + '/upgrade/status', {
                    params: {
                        'job_status_id': this.currentStatus.id
                    }
                }).then( response => {
                    if(response.data) {
                        this.currentStatus = response.data;

                        if(response.data.status !== 'finished' && this.intervalHandle !== null) {
                            this.startInterval()
                        }else if( response.data.status === 'finished') {
                            clearInterval(this.intervalHandle);

                            setTimeout(function() {
                                window.location.reload();
                            }, 3000)
                        }
                    }
                }).catch(error => {
                    window.notify.apiError(error);
                });
            },
            startInterval() {
                if(this.intervalHandle === null) {
                    this.intervalHandle = setInterval(this.getUpgradeStatus, 5000);
                }
            },
        }

    }
</script>
<style lang="scss">

    .upgrade-project-program-year-button {

        margin: 20px;

    }

</style>
