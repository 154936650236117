<script>
import axios from "axios";

export default {

    data() {
        return {
            verificationLinkSent: false,
        }
    },

    methods: {
        async resendNotification() {
            try {
                await axios.post('/api/auth/email/verification-notification')
                this.verificationLinkSent = true;
                setTimeout(() => {
                    this.verificationLinkSent = false;
                }, 5000)
            }catch(err) {
                console.log(err);
            }
        },
        async logout() {
            await axios.post('/api/auth/logout');
            window.location.href = '/login';
        }
    }

}
</script>
<template>
    <div class="text-left flex justify-center">
        <div class="w-2/3 mb-4">
            <div class="mb-4 text-gray-600">
                Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.
            </div>

            <div class="mb-4 font-medium text-sm text-green-600" v-if="verificationLinkSent" >
                A new verification link has been sent to the email address you provided during registration.
            </div>

            <form @submit.prevent="resendNotification">
                <div class="mt-4 flex items-center justify-between">
                    <button class="inputbutton1 rounded" >
                        Resend Verification Email
                    </button>
                    <a href="" class="littlenote text-underline" @click.prevent="logout">
                        Logout
                    </a>
                </div>
            </form>
        </div>
    </div>
</template>
