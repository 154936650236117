import Vue from 'vue';

import 'etap/admin/fa-boostrap';

// regiser vision program model
import Parser from 'etap/admin/classes/jsonapi_parser';
import VisionProgram from 'vision/classes/models/visionProgram';
Parser.registerModel('vision_program', VisionProgram);


import { store } from 'app/store/mat'
import vision from 'vision/store/vision'
store.registerModule("vision", vision);


import ProjectVisionLogs from 'vision/components/Universal/ProjectVisionLogs.vue';
import VisionLogFileRow from 'vision/components/GridRows/VisionLogFileRow.vue';
Vue.component('project-vision-logs', ProjectVisionLogs);
Vue.component('vision-log-file-row', VisionLogFileRow);
