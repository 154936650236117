
import Model from "./model";
import moment from "moment/moment";


class Note extends Model {

    defaults() {
        return {
            id: null,
            notable_id: null,
            notable_type: null,
            created_by_id: null,
            external: false,
            note: null,
        }
    }

    parseDate(value) {

        if(!value) {
            return;
        }

        return moment(value).format(this.dateTimeFormat());
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {

        }
    }



}

export default Note;
