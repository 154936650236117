export const getFormConfigurationByName = state => formName => {

    let filtered = ObjectUtils.filter(state.formConfigurations, formConfig => {
        return formConfig.name === formName;
    });
    if (ObjectUtils.size(filtered) > 0) {
        return ObjectUtils.first(filtered);
    }

    return null;
}

export const getFormConfigurationById = state => formId => {
    let filtered = ObjectUtils.filter(state.formConfigurations, formConfig => {
        return formConfig.id === formId
    });

    if(ObjectUtils.size(filtered) > 0) {
        return ObjectUtils.first(filtered);
    }

    return null;
}

export const getFormConfigurationByType = state => (type, programYearId, programTrackId) => {

    if(state.formConfigurationsByType[programTrackId] &&
        state.formConfigurationsByType[programTrackId][programYearId] &&
        state.formConfigurationsByType[programTrackId][programYearId][type]) {
        return state.formConfigurationsByType[programTrackId][programYearId][type]
    }

    return null;
}

export const getMeasureFormConfigurationByType = state => (measureId, type, programTrackId, programYearId) => {

    if( state.measureFormConfigurationsByType[measureId] &&
        state.measureFormConfigurationsByType[measureId][programTrackId] &&
        state.measureFormConfigurationsByType[measureId][programTrackId][programYearId] &&
        state.measureFormConfigurationsByType[measureId][programTrackId][programYearId][type]) {
        return state.measureFormConfigurationsByType[measureId][programTrackId][programYearId][type]
    }

    return null;
}

