import axios from 'axios';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        projectMeasureSelections: {},
    },

    actions: {
        loadSelectedProjectMeasures(context, {mpid, selectedMeasures}) {

            return new Promise((resolve, reject) => {
                let project = context.rootGetters['mp_layout/getProject'](mpid);

                var params = {
                    ids: selectedMeasures.map(pm => pm.version)
                };

                axios.get(
                    context.rootState.apiPrefix + 'projects/' + project.id +
                    '/measures',
                    {
                        params
                    }
                ).then( response => {
                    context.commit('mp_project_measures/upsertProjectMeasures', {
                        projectMeasures: response.data,
                    }, {root: true});

                    response.data.forEach(projectMeasure => {
                        context.commit('addProjectMeasureSelection', {mpid, projectMeasure});
                    });

                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });

            });
        }
    },

    mutations: {
        defaultProjectMeasureSelections(state, mpid) {
            Vue.set(state.projectMeasureSelections, mpid, []);
        },
        addProjectMeasureSelection(state, {projectMeasure, mpid}) {
            const existingSelectionIndex = state.projectMeasureSelections[mpid].findIndex(pm => {
                return pm.shared_uuid === projectMeasure.shared_uuid;
            })

            if(existingSelectionIndex === -1) {
                state.projectMeasureSelections[mpid].push(projectMeasure);
            } else {
                state.projectMeasureSelections[mpid].splice(existingSelectionIndex, 1, projectMeasure);
            }
        },
        removeProjectMeasureSelection(state, {projectMeasure, mpid}) {
            let index = state.projectMeasureSelections[mpid].findIndex(pm => {
                return pm.shared_uuid === projectMeasure.shared_uuid;
            });
            state.projectMeasureSelections[mpid].splice(index, 1);
        }
    },

    getters: {
        projectMeasureSelections: state => (mpid) => {
            return state.projectMeasureSelections[mpid];
        }
    }
}
