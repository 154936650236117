<template>
    <div class="schedule-form">
        <div class="flex">
            <button class="inputbutton1" :disabled="disabled" @click.prevent="saveAndInsert">Save & Insert</button>
            <div class="form-check form-check-inline">
                <input class="form-check-input"
                       type="checkbox"
                       :id="'shared-checkbox'"
                       v-model="form.shared"
                       :disabled="disabled"
                >
                <label class="form-check-label" :for="'shared-checkbox'"><span v-html="'Shared'"></span></label>
            </div>
        </div>
        <div class="flex">
            <div style="width: 45%; padding: 10px; border-right: solid;">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.name">
                </div>
                <div class="form-group">
                    <label>Weeks in Use</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.weeks">
                </div>
                <div class="form-group">
                    <label>Holidays</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.holidays">
                </div>
                <div class="form-group">
                    <label>Calculated Hours</label>
                    <input type="text" :disabled="disabled" class="form-control" disabled="disabled" :value="calculatedHours">
                </div>
            </div>
            <div class="weekly-schedule-form">
                <h4>Schedule (hrs)</h4>
                <div class="form-group flex justify-space-between">
                    <label>Monday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.mon">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Tuesday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.tues">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Wednesday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.wed">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Thursday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.thurs">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Friday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.fri">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Saturday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.sat">
                </div>
                <div class="form-group flex justify-space-between">
                    <label>Sunday</label>
                    <input type="text" :disabled="disabled" class="form-control" v-model="form.schedule.sun">
                </div>
            </div>
        </div>
        <div v-if="this.formErrors" class="message-bad">
            <ul>
                <li v-for="error in formErrors">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {

        props: {
            projectId: {
                required: true,
                type: Number,
            },
            currentSchedule: {
                type: Object,
                default() {
                    return null;
                }
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                form: {
                    name: null,
                    weeks: null,
                    holidays: null,
                    shared: false,
                    schedule: {
                        mon: null,
                        tues: null,
                        wed: null,
                        thurs: null,
                        fri: null,
                        sat: null,
                        sun: null
                    }
                },

                calculatedHours: null,

                formErrors: {},
            }
        },

        watch: {
            'form': {
                handler(form) {
                    this.calculateSchedule();
                    //this.form.shared = form.shared;
                },
                deep: true,
            },
        },

        created() {
            if(this.currentSchedule) {
                this.form = this.currentSchedule;
                this.calculateSchedule();
            }
        },


        methods: {
            ...mapActions('project_operating_schedule', [
                'createOperatingScheduleForProject',
                'updateOperatingScheduleForProject'
            ]),
            calculateSchedule() {

                if(!this.isValidSchedule()) {
                    this.calculatedHours = null;
                    return;
                }

                var weeklyOperatingHours = 0;

                for(var day in this.form.schedule) {
                    if(this.form.schedule.hasOwnProperty(day)) {
                        weeklyOperatingHours += Number(this.form.schedule[day]);
                    }
                }

                if(isNaN(weeklyOperatingHours) || weeklyOperatingHours === 0 || parseFloat(weeklyOperatingHours) === 0.0) {
                    return;
                }

                var holidays = Number(this.form.holidays);
                var weeks = Number(this.form.weeks);
                if (weeks === 52) {
                    weeks = window.MAT.operatingHoursContinuousWeeks;
                }

                this.calculatedHours = Math.round(weeklyOperatingHours * weeks * ((365-this.form.holidays) / 365));

            },
            isValidSchedule() {

                this.formErrors = {};

                if(isNaN(this.form.weeks)) {
                    this.$set(this.formErrors, 'weeks', 'Your schedule\'s weeks must be a number.');
                    return false;
                } else if(this.form.weeks < 0 || this.form.weeks > 52) {
                    this.$set(this.formErrors, 'weeks', 'Your schedule\'s weeks must be 52 or less.');
                    return false;
                }

                if(isNaN(this.form.holidays)) {
                    this.$set(this.formErrors, 'holidays', 'Your schedule\'s holidays must be a number.')
                    return false;
                } else if(Number(this.form.holidays) < 0 || Number(this.form.holidays) > 365) {
                    this.$set(this.formErrors, 'holidays', 'Your schedule\'s holidays must be 365 or less.')
                    return false;
                }

                for(var day in this.form.schedule) {
                    if(this.form.schedule.hasOwnProperty(day)) {
                        if(isNaN(this.form.schedule[day])) {
                            this.$set(this.formErrors, 'schedule', 'Your schedule\'s hours must be a number.');
                            return false;
                        } else if(Number(this.form.schedule[day]) < 0 || Number(this.form.schedule[day]) > 24) {
                            this.$set(this.formErrors, 'schedule', 'Your schedule\'s hours must be 24 or less');
                            return false;
                        }
                    }
                }

                return true;
            },
            saveAndInsert() {
                if(!this.form.name) {
                    window.notify.message('Please add a form name to this schedule', 'error');
                    return;
                }
                if(!this.calculatedHours) {
                    window.notify.message('Please fix any errors so hours can calculate for this schedule', 'error');
                    return;
                }

                if(this.disabled) {
                    return;
                }

                if(!this.form.id) {
                    this.createOperatingScheduleForProject({
                        projectId: this.projectId,
                        schedule: this.form
                    });
                }else {
                    this.updateOperatingScheduleForProject({
                        projectId: this.projectId,
                        schedule: this.form
                    });
                }

                this.$emit('input', this.calculatedHours);
            }
        }

    }

</script>
