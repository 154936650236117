import state from 'etap/store/inspections/state';
import * as actions from 'etap/store/inspections/actions'
import * as mutations from 'etap/store/inspections/mutations';
import * as getters from 'etap/store/inspections/getters';
import axios from "axios";

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
