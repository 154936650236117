<template>
    <tr>
        <td>{{ record.original_filename }}</td>
        <td>{{ created_at }}</td>
        <td>
            <fa-icon @click.stop=showFile icon="eye"></fa-icon>
            <fa-icon @click="downloadFile" icon="download"></fa-icon>
            <modal
                v-if="showFileXml"
                @close="showFileXml = false"
                width="75%"
            >
                <h2 slot="header">Vision File</h2>
                <div slot="body" class="flex justify-between items-center">
                    <pre v-text="formattedXML">
                    </pre>
                </div>
            </modal>
        </td>
    </tr>
</template>
<script>
import axios from 'axios';
import formatter from 'xml-formatter';
import moment from 'moment';
export default {
    props: {
        record: {
            rquired: true,
            type: Object
        }
    },

    data() {
        return {
            showFileXml: false,
            formattedXML: null
        }
    },

    computed: {
        created_at() {
            return moment(moment.utc(this.record.created_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
    },

    methods: {
        showFile() {
            // getting file should eventually be moved to a files store module or something similar
            axios.get('/files/' + this.record.id + '/download').then(urlResponse => {
                if(!urlResponse.data.meta.url) {
                    throw new Error("Invalid url to open");
                }

                axios.get(urlResponse.data.meta.url).then(response => {
                    var formatted = formatter(response.data);
                    this.formattedXML = formatted;
                    this.showFileXml = true;
                })

            });
        },
        downloadFile() {
            window.open('/files/' + this.record.id + '/download');
        }
    }
}
</script>
