import { ucfirst } from "etap/utilities/utils";

export default {



    methods: {
        ucfirst(str) {
            return ucfirst(str);
        }
    }

}
