import Model from 'etap/admin/classes/models/model';
import moment from 'moment';

class Address extends Model {

    defaults() {
        return {
            id: null,
            addressable_id: null,
            addressable_type: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            additional_zip: null,
            latitude: null,
            longitude: null,
            phone: null,
            type: null,
            created_at: moment(),
            updated_at: moment()
        }
    }

    casts() {
        return {
            addressable_id: Number,
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default Address;
