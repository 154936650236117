<template>
    <div class="technology">
        <div class="form-group"
             :id="fieldName + '-technology-field'"
             :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
        >
            <label class="form-control-label"><span v-html="fieldConfig.label"></span>
                <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
                <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
            </label>
            <multi-select
                    :id="fieldConfig.fieldName"
                    class="form-control"
                    :value="optionValue"
                    :options="technologyOptions"
                    track-by="id"
                    label="technology"
                    :allow-empty="false"
                    placeholder="Select technology"
                    :show-labels="false"
                    :disabled="fieldConfig.disabled === 1"
                    @input="updateTechnology"></multi-select>
            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
            </span>
            <div v-if="hasHelpText">
                <span v-html="fieldConfig.field_extra.helpText"></span>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import MultiSelect from 'vue-multiselect'
    import { FormFieldMixin } from 'laravel-vue-forms';
    export default {

        inject: {
            mpid: {
                default() {
                    return this.$store.state.mp_layout.assessmentMPID
                }
            }
        },

        mixins: [
            FormFieldMixin
        ],

        components: {
            MultiSelect
        },

        data() {

            var specialTextObj = this.value;
            if(!this.value) {
                specialTextObj = {
                    technology: null,
                };
            }

            return {
                specialTextString: this.value,
            }
        },

        created() {
            this.$store.dispatch('mp_technology/getTechnologyForMeasureAndProject', {
                measureId: this.form.data.measure.id,
                project: this.project
            });

            if(this.form && this.form.formConfig && Array.isArray(this.form.formConfig.fields)) {
                this.form.formConfig.fields.forEach(field => {
                    if(field.name === this.fieldName) {
                        this.$set(this.fieldConfig, 'fieldName', this.fieldName);
                        this.$set(this.fieldConfig, 'storeAsJson', false);
                        var fieldExtra = this.getFormFieldFieldExtra(field);

                        if(typeof fieldExtra.technology_options !== 'undefined' && fieldExtra.technology_options.type) {
                             switch (fieldExtra.technology_options.type) {
                                 case 'baseline':
                                     this.fieldConfig.baseline = true;
                                     break;
                                 case 'proposed':
                                     this.fieldConfig.proposed = true;
                                     break;
                             }
                        }

                        if(typeof fieldExtra.technology_options !== 'undefined') {
                            if(fieldExtra.technology_options.store_as_json) {
                                // storeAsJson is for legacy usage of this field for eTAP v3
                                this.$set(this.fieldConfig, 'storeAsJson', true);
                            }
                        }

                    }
                });
            }else {
                this.$set(this.fieldConfig, 'fieldName', this.fieldName);
                this.$set(this.fieldConfig, 'field_extra', {
                    required: this.required
                });
                this.$set(this.fieldConfig, 'label', this.label);
                this.$set(this.fieldConfig, 'value_field', this.fieldName);
            }

        },

        computed: {
            project() {
                return this.$store.getters['mp_layout/getProject'](this.mpid);
            },
            technologyOptions() {
                var options = [];;
                if(this.fieldConfig.baseline) {
                    options =  this.$store.getters['mp_technology/getBaselineTechnologyForMeasure'](this.form.data.measure.id);
                }else if(this.fieldConfig.proposed) {
                    options =  this.$store.getters['mp_technology/getProposedTechnologyForMeasure'](this.form.data.measure.id);
                }else {
                    return [];
                }

                return options;
            },
            optionValue() {

                var technologyId = null;
                if(this.fieldConfig.storeAsJson && this.specialTextValue.technologyId) {
                    technologyId = parseInt(this.specialTextValue.technologyId);
                }else{
                    technologyId = parseInt(this.value);
                }

                if(!technologyId) {
                    return null;
                }

                return this.technologyOptions.find(option => {
                    return option.id === technologyId;
                });
            },
            specialTextValue() {
                if(this.value) {
                    return this.value;
                }else {
                    return {
                        technologyId: null,
                        wattage: null
                    };
                }
            }
        },

        watch: {
            technologyOptions(newOptions) {
                this.$emit("options-updated", newOptions);
            }
        },

        methods: {
            updateTechnology(technology) {

                if(this.fieldConfig.baseline) {
                    if(technology && technology.wattage) {
                        this.form.data.baseline_wattage = technology.wattage;
                    }
                } else if (this.fieldConfig.proposed) {
                    if(technology && technology.wattage) {
                        this.form.data.replacement_wattage = technology.wattage;
                    }
                }


                this.updateSpecialText(technology.id);
            },


            updateSpecialText(technologyId) {

                if(!this.fieldConfig.storeAsJson) {
                    this.$emit('input', technologyId);
                    return;
                }

                var specialText = {
                    technologyId,
                };
                this.$emit('input', specialText);

            }
        }

    }
</script>

<style>
    .multiselect input {
        border: 0;
        padding: 0;
    }
</style>
