export default {
    data() {
        return {
            internalPagination: false,
            internalCurrentPage: null,
            internalTotalPages: null
        };
    },

    computed: {
        canPaginate() {
            if (
                typeof this.gridData.current_page !== "undefined" &&
                this.gridData.current_page !== null
            ) {
                return true;
            } else if (this.pagination.per_page) {
                this.internalPagination = true;
                this.calculateInternalPage();
                return true;
            }
            return false;
        },
        currentPage() {
            if (this.gridData.current_page) {
                return this.gridData.current_page;
            } else if (this.internalPagination) {
                return this.internalCurrentPage;
            }

            return 0;
        },
        totalPages() {
            if (this.gridData.last_page) {
                return this.gridData.last_page;
            } else if (this.internalPagination) {
                return this.internalTotalPages;
            }

            return 0;
        },
        totalRecords() {
            if (this.gridData.total) {
                return this.gridData.total;
            } else if (this.internalPagination) {
                return this.data.length;
            }

            return 0;
        },
        from() {
            if (this.gridData.from) {
                return this.gridData.from;
            } else if (this.internalPagination) {
                return this.currentPage * this.pagination.per_page - this.pagination.per_page + 1;
            }

            return 0;
        },
        to() {
            if (this.gridData.to) {
                return this.gridData.to;
            } else if (this.internalPagination) {
                return this.currentPage * this.pagination.per_page;
            }

            return this.totalRecords;
        },
        internalPage() {
            let perPage = null;
            if (this.gridData.per_page) {
                perPage = this.gridData.per_page;
            } else if (this.pagination.per_page) {
                perPage = this.pagination.per_page;
            } else {
                return; // if we don't have a per page set, then we aren't paginating
            }

            var start;
            if (this.currentPage == 1) {
                start = 0;
            } else {
                start = this.currentPage * perPage - perPage;
            }

            var end = null;
            if (this.currentPage !== this.totalPages) {
                end = this.currentPage * perPage;
            }

            var data = [];
            if (this.gridData.data) {
                data = this.gridData.data;
            } else {
                data = this.gridData;
            }

            if (end) {
                return data.slice(start, end);
            } else {
                return data.slice(start);
            }
        }
    },

    methods: {
        setPagination() {
            var search = window.location.search;
            if (!search) {
                search = "#page";
            } else {
                search = "" + search + "#page=";
            }
        },
        calculateInternalPage() {
            this.internalTotalPages = Math.ceil(
                this.data.length / this.pagination.per_page
            );
            this.internalCurrentPage = 1;
        }
    }
};
