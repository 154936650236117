import apiClient from 'etap/admin/apiClient';
import Collection from 'etap/admin/classes/collection';
import Company from 'etap/admin/classes/models/company';
import Parser from 'etap/admin/classes/jsonapi_parser';

export const searchForCompanies = (context, searchParams) => {

    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/companies', {
            params: searchParams
        }).then( response => {
            let companyCollection = Parser.parseJSONAPIResponse(response.data);
            context.commit('setSearchResults', companyCollection);
            resolve(companyCollection);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}


export const getCompany = (context, companyId) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/companies/' + companyId).then(response => {
            var company = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddCompany', company);
            resolve(company);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const getCompanyWithRelationships = (context, {companyId, relationships}) => {
    return new Promise((resolve, reject) => {
        apiClient.get('/api/admin/companies/' + companyId, {
            params: {
                includes: relationships
            }
        }).then(response => {
            let company = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddCompany', company);
            resolve(company);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}


export const updateOrCreateCompany = (context, {record, eagerLoads}) => {

    var method = 'post';
    var url = '/api/admin/companies';
    if(record.id) {
        method = 'patch';
        url += '/' + record.id;
    }

    record.includes = [
        'company_address',
        'audits',
        'users'
    ]

    return new Promise((resolve, reject) => {
        apiClient[method](url, record).then(response => {
            var company = Parser.parseJSONAPIResponse(response.data);
            company.ensureRelationshipIsSet('company_address');
            company.ensureRelationshipIsSet('users');
            company.ensureRelationshipIsSet('audits');
            company.ensureRelationshipIsSet('valid_program_tracks');
            switch(method) {
                case 'patch':
                    context.commit('updateOrAddCompany', company);
                    break;
                default:
                    if(!context.state.companies) {
                        let companyCollection = new Collection([company], {
                            model: Company
                        });
                        context.commit('setCompanies', companyCollection);
                        resolve(companyCollection);
                        return;
                    }
                    context.commit('updateOrAddCompany', company);
            }
            resolve(company);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}
export const deleteCompany = (context, company) => {

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/companies/' + company.id).then(response => {
            context.commit('removeCompany', company);
            resolve(Parser.parseJSONAPIResponse(response.data));
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const restoreCompany = (context, {record, relationships}) => {

    var includes = [];
    if(Array.isArray(relationships) && relationships.length > 0) {
        includes = relationships;
    }

    return new Promise((resolve, reject) => {
        apiClient.patch('/api/admin/companies/' + record.id + '/restore', {includes}).then(response => {
            var restoredCompany = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddCompany', restoredCompany);
            resolve(restoredCompany);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const addUserToCompany = (context, {userId, owner, companyId}) => {

    return new Promise((resolve, reject) => {
        apiClient.post('/api/admin/companies/' + companyId + '/users', {
            userId,
            owner
        }).then(response => {
            var user = Parser.parseJSONAPIResponse(response.data);
            context.commit('updateOrAddUserToCompany', {user, companyId});
            resolve(user);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}

export const removeUserFromCompany = (context, {userId, companyId}) => {

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/companies/' + companyId + '/users/' + userId).then(response => {
            context.commit('removeUserFromCompany', {userId, companyId});
            resolve(true);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })
}


export const removeYearFromCompany = (context, validTrackYear) => {

    if(!validTrackYear.program_track_id || !validTrackYear.company_id) {
        console.error('Invalid program year to remove', programYear);
        return;
    }

    delete validTrackYear.created_at;
    delete validTrackYear.updated_at;

    return new Promise((resolve, reject) => {
        apiClient.delete('/api/admin/companies/' + validTrackYear.company_id + '/valid_program_tracks', {
            params: validTrackYear
        }).then(response => {
            // context.commit('removeYearFromCompany', validTrackYear);
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    })

}
