<script>
export default {
    props: {
        record: {
            required: true,
            type: Object
        }
    }
}
</script>
<template>
    <div class="p-1 rounded m-1" :class="{'bg-yellow': typeof record.success === 'undefined' ? true : record.success === false, 'bg-success text-white': record.success}">
        <div class="flex items-center">
            <div v-if="record.sheet" class="mr-1 w-1/5 break-words px-1 border-r">
                <span class="text-bold">Sheet:</span> <br />{{ record.sheet }}
            </div>
            <div class="w-4/5 px-1">
                <div v-if="record.message" class="text-bold">
                    {{ record.message }}
                </div>
                <div v-else class="text-bold">
                    Unknown Error
                </div>
                <div v-if="Array.isArray(record.error)" class="ml-4">
                    <ul v-for="error in record.error">
                        <li>
                            {{ error }}
                        </li>
                    </ul>
                </div>
                <div v-else-if="typeof record.error === 'object'" class="ml-4">
                    <div v-for="(error, key) in record.error">
                        <ul v-for="validationError in error" v-if="Array.isArray(error)">
                            <li>
                                {{ validationError }}
                            </li>
                        </ul>
                        <div class="ml-4" v-else>
                            {{ error }}
                        </div>
                    </div>
                </div>
                <div v-else class="ml-4">
                    {{ record.error }}
                </div>
            </div>
        </div>
    </div>
</template>
