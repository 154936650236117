<script>
import axios from 'axios';
import Parser from "etap/admin/classes/jsonapi_parser";
import BannerNotification from "./BannerNotification.vue";
export default {
    components: {BannerNotification},
    data() {
        return {
            bannerNotifications: []
        }
    },

    created() {
        this.getBannerNotifications();
    },

    methods: {
        getBannerNotifications() {
            axios.get('/api/user/banner_notifications').then(bannerNotificationsResponse => {
                if(!bannerNotificationsResponse.data.data || !Array.isArray(bannerNotificationsResponse.data.data)) {
                    throw new Error("Invalid response for banner notifications");
                }

                this.bannerNotifications = Parser.parseJSONAPIResponse(bannerNotificationsResponse.data).getModels();
            }).catch(error => {
                console.error(error);
                window.notify.apiError(error);
            });
        }
    }
}
</script>
<template>
    <div class="flex justify-center" v-if="bannerNotifications.length > 0">

        <div class="w-2/3 m-4">
            <banner-notification v-for="bannerNotification in bannerNotifications" :key="bannerNotification.id"
                :banner-notification="bannerNotification"
            ></banner-notification>
        </div>

    </div>
</template>
