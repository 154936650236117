
export const setSearchResults = (state, users) => {
    state.userSearchResults =  users;
}

export const setUsers = (state, users) => {

    if(!users._uid) {
        console.error('Users must be an instance of collection');
        return;
    }

    state.users = users;
}

export const updateOrAddUser = (state, user) => {

    var existingUser = state.users.find({id: user.id});

    if(existingUser) {
        existingUser.assign(user);
    }else {
        state.users.add(user)
    }

}

export const removeUser = (state, user) => {

    var existingUser = state.users.filter({id: user.id}).first();
    var existingSearchUser = state.userSearchResults.filter({id: user.id}).first();


    if(!existingUser & !existingSearchUser) {
        console.error('Invalid user to remove: ' + user.id);
        return;
    }

    if(existingUser) {
        state.users.remove(existingUser);
    }

    if(existingSearchUser) {
        state.userSearchResults.remove(existingSearchUser);
    }
}


export const updateOrAddCompanyToUser = (state, {userId, company}) => {

    var user = state.users.filter({id: userId}).first();

    if(!user) {
        console.error('Invalid user to add company for: ' + userId);
        return;
    }

    user.ensureRelationshipIsSet('companies');
    var existingCompany = user.companies.filter({id: company.id}).first();

    if(existingCompany) {
        existingCompany.assign(company);
    }else {
        user.companies.add(company)
    }
}

export const removeCompanyFromUser = (state, {userId, companyId}) => {

    var user = state.users.filter({id: userId}).first();

    if(!user) {
        console.error('Invalid user to remove company from: ' + userId);
        return;
    }

    user.ensureRelationshipIsSet('companies');
    var existingCompany = user.companies.filter({id: companyId}).first();

    if(existingCompany) {
        user.companies.remove(existingCompany);
    }
}



export const addOrUpdateRoleToUser = (state, {userId, role}) => {

    var user = state.users.filter({id: userId}).first();

    if(!user) {
        console.error('Invalid user to add role for: ' + userId);
        return;
    }

    user.ensureRelationshipIsSet('roles');
    var existingRole = user.roles.filter({id: role.id}).first();

    if(existingRole) {
        existingRole.assign(role);
    }else {
        user.roles.add(role)
    }
}

export const removeRoleFromUser = (state, {userId, roleId}) => {

    var user = state.users.filter({id: userId}).first();

    if(!user) {
        console.error('Invalid user to remove role from: ' + userId);
        return;
    }

    user.ensureRelationshipIsSet('roles');
    var existingRole = user.roles.filter({id: roleId}).first();

    if(existingRole) {
        user.roles.remove(existingRole);
    }
}


export const removePwLink = (state, userId) => {

    var user = state.users.filter({id: userId}).first();

    if(!user) {
        console.error('Invalid User to remove pwLink');
        return;
    }
    user.pwLink = null;
}

