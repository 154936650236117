import Vue from 'vue';
import axios from 'axios';


export default {
    namespaced: true,


    state: {
        projectBillAccounts: [],
        defaultProjectBillAccount: {
            project_id: null,
            account_type: 'electric',
        }
    },

    mutations: {
        upsertProjectBillAccount(state, projectBillAccount) {

            let existingBillAccountIndex = state.projectBillAccounts.findIndex(ePBA => {
                return ePBA.id === projectBillAccount.id
            });

            if(existingBillAccountIndex === -1) {
                state.projectBillAccounts.push(projectBillAccount);
                return;
            }

            state.projectBillAccounts.splice(existingBillAccountIndex, 1, projectBillAccount);
        },
        removeProjectBillAccount(state, projectBillAccount) {

            let existingBillAccountIndex = state.projectBillAccounts.findIndex(ePBA => {
                return ePBA.id === projectBillAccount.id
            });

            if(existingBillAccountIndex === -1) {
                throw new Error("Invalid Project bill account")
            }

            state.projectBillAccounts.splice(existingBillAccountIndex, 1);

        },
        removeTempProjectBillAccountRow(state)
        {
            let tempBillAccountIndex = state.projectBillAccounts.findIndex(projectBillAccount => {
                return !projectBillAccount.hasOwnProperty('id');
            });

            if(tempBillAccountIndex === -1) {
                throw new Error("No new bill account found")
            }

            state.projectBillAccounts.splice(tempBillAccountIndex, 1);
        },
        makeOtherAccountNotPrimary(state, projectBillAccount)
        {
            state.projectBillAccounts.forEach(accountRecord => {
                if(accountRecord.id !== projectBillAccount.id) {
                    Vue.set(accountRecord, 'primary', 0);
                }
            });
        }
    },

    getters: {
        getProjectBillAccountsForProject(state) {
            return (projectId) => {
                return state.projectBillAccounts.filter(pBA => {
                    return pBA.project_id === projectId
                })
            }
        }
    },

    actions: {
        async addProjectBillAccount(context, projectBillAccountAttributes) {
            context.commit('upsertProjectBillAccount', Object.assign(
                JSON.parse(JSON.stringify(context.state.defaultProjectBillAccount)),
                projectBillAccountAttributes || {}
            ))
        },
        async copyProjectBillAccount(context, copyInfo) {

            if(!copyInfo.formConfig || !copyInfo.projectBillAccount) {
                throw new Error("Invalid copy information.  Please specify form config and project bill account")
            }

            try {
                const response = await axios.post("/api/bill_accounts/project/" + copyInfo.projectBillAccount.project_id, {
                    formConfigurationId: copyInfo.formConfig.id,
                    data: JSON.parse(JSON.stringify(copyInfo.projectBillAccount)),
                });

                context.commit('upsertProjectBillAccount', response.data);
                return response.data;
            }catch(err) {
                window.notify.apiError(err);
            }
        },
        updateAndSaveProjectBillAccount(context, projectBillAccount) {

            if(!projectBillAccount.project_id) {
                console.error('bill account does not have a projectId');
                return new Promise((resolve, reject) => reject('Bill account does not have a project Id'));
            }

            return new Promise((resolve, reject) => {
                axios.patch('/api/bill_accounts/project/' + projectBillAccount.project_id, projectBillAccount).then(response => {
                    context.commit('upsertProjectBillAccount', response.data);
                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });
            });
        },
        async deleteProjectBillAccount(context, projectBillAccount) {
            try {

                const response = await axios.delete('/api/bill_accounts/project/' + projectBillAccount.project_id, {
                    data: {
                        projectBillAccountId: projectBillAccount.id
                    }
                });
                context.commit('removeProjectBillAccount', projectBillAccount);

            } catch(err) {
                window.notify.apiError(err);
            }
        }
    }


}
