<template>
    <div class="form-group row py-4" style="width:95%;">
        <div class="col-md-12 panel" v-if="fieldsNeedingCompletion.length > 0">
            <div class="panel-body">
                For this {{ completionName}} to be considered complete:
                <ul>
                    <li v-for="field in fieldsNeedingCompletion" :key="field.id">
                        {{ field.label }} is required
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-12 panel" v-if="fieldsNeedingProjectSubmission.length > 0">
            <div class="panel-body">
                Required Fields for Project Submission:
                <ul>
                    <li v-for="field in fieldsNeedingProjectSubmission" :key="field.id">
                        {{ field.label }} is required
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { FormFieldMixin} from 'laravel-vue-forms';
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';

    export default {

        mixins: [
            FormFieldMixin,
            MeasurePickerUtils
        ],

        created() {

            if(this.form && this.form.formConfig && Array.isArray(this.form.formConfig.fields)) {
                this.form.formConfig.fields.forEach(field => {
                    if(field.name === this.fieldName) {

                        var fieldExtra = this.getFormFieldFieldExtra(field);
                        if(typeof fieldExtra.completionName !== 'undefined') {
                            this.$set(this.fieldConfig, 'completionName', fieldExtra.completionName);
                        }

                    }
                });

            }

        },


        computed: {
            fieldsNeedingCompletion() {
                var fields = [];
                if(!this.form) {
                    return;
                }

                let parent_ids = [... new Set(this.form.formConfig.fields.map(field => field.parent_id).filter(Boolean))];

                this.form.formConfig.fields.forEach(field => {
                    //Parents cannot affect completion state
                    if(parent_ids.indexOf(field.id) != -1) {
                        return;
                    }

                    var requireType = 'default';
                    if(field.field_extra.requiredForCompletion && field.field_extra.requiredForCompletion.type) {
                        requireType = field.field_extra.requiredForCompletion.type;
                    }

                    //if field is hidden, but it does have a parent, show the error for it as it's likely in a custom field
                    if(!field.visible && !field.parent_id) {
                        return;
                    }

                    switch(requireType) {
                        case 'phase':

                            if(!Array.isArray(field.field_extra.requiredForCompletion.phases)) {
                                console.error('invalid completion information');
                                return;
                            }

                            if(!field.field_extra.requiredForCompletion.phases.includes(this.mp_phase.name)) {
                                return;
                            }

                            var formValue = Object.getFormValueByString(this.form.data, field.value_field);

                            if(Array.isArray(formValue) && formValue.length === 0) { // useful for items like photos
                                fields.push(field);
                            } else if(!formValue) {
                                fields.push(field);
                            }

                            break;
                        default:

                            if(
                                Boolean(field.field_extra.requiredForCompletion) === true &&
                                Boolean(this.$parent.conditionValues[field.name]) === true &&
                                !Object.getFormValueByString(this.form.data, field.value_field)
                            ) {
                                fields.push(field);
                            }

                            break;
                    }
                });

                return fields;
            },
            fieldsNeedingProjectSubmission() {
                var fields = [];
                if(this.form) {
                    this.form.formConfig.fields.forEach(field => {
                        if(Boolean(field.field_extra.requiredForProjectSubmission) &&
                            Boolean(this.$parent.conditionValues[field.name]) &&
                            ( Object.getFormValueByString(this.form.data, field.value_field) === null || Object.getFormValueByString(this.form, field.value_field) === '') ) {
                            fields.push(field);
                        }
                    });
                }
                return fields;
            },
            completionName() {

                if(this.fieldConfig.completionName) {
                    return this.fieldConfig.completionName
                }

                return 'record';
            }
        }

    }
</script>
