<template>
    <div class="loading-overlay" v-show="loading">
        <div class="loading-spinner">
            <i aria-label="Loading" class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>
</template>
<script>
    export default {

        props: {
            loading: {
                required: true,
                type: Boolean
            }
        }

    }
</script>
<style lang="scss" scoped>

    .loading-overlay {
        background: #ffffff;
        color: #666666;
        opacity: 0.6;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 5000;
        top: 0;
        left: 0;
        float: left;
        text-align: center;

        .loading-spinner {
            position: relative;
            width: 100%;
            height: 100%;
            .fa-spinner {
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }
    }

</style>