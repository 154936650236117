import Model from "./model";
import FormConfiguration from "./formConfiguration";
import Measure from "./measure";
import ProgramTrack from "./programTrack";

class FormConfigurationMeasure extends Model {


    defaults() {
        return {
            measure_id: null,
            form_configuration_id: null,
            program_track_id: null,
            program_year_id: null,
            form_type: null,
        }
    }

    casts() {
        return {

        }
    }

    relationships() {
        return {
            form_configuration: FormConfiguration,
            measure: Measure,
            program_track: ProgramTrack,
        }
    }

}

export default FormConfigurationMeasure;
