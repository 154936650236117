
<template>
    <div class="mx-2">
        <h3 v-if="showTitle">Select Scenario</h3>
        <input v-for="scenario in scenarios"
               type="button"
               class="button mx-2"
               :class="{ selected: scenarioIsSelected(scenario) }"
               :value="scenario.scenario_nickname"
               @click="updateScenario(scenario)"
        />
    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex';
    import urlUtils from 'etap/mixins/url-utils';
    import MeasurePickerutils from 'etap/mixins/measure_picker/measure_picker_utils';
    export default {

        props: {
            showTitle: {
                type: Boolean,
                default: false,
            }
        },

        mixins: [urlUtils, MeasurePickerutils],

        computed: {
            scenarios() {
                return this.$store.getters['mp_scenarios/getScenarios'](this.mpid);
            },
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
        },

        created() {
            this.loadScenarios(this.mpid);

            // listen for popstate so that we can update scenario if the user hit the back button
            window.onpopstate = e => {
                var poppedScenarioID = this.getParameterByName('scenario', location.href);
                var newScenario = this.scenarios.find(scenario => {
                    return scenario.id == poppedScenarioID;
                });

                if(newScenario) {
                    this.updateScenario(newScenario);
                }
            }
        },

        methods: {
            ...mapActions('mp_scenarios',[
                'loadScenarios',
            ]),
            ...mapActions('mp_layout', [
                'getMeasureSummary',
            ]),
            updateScenario(scenario) {
                this.$store.commit('mp_scenarios/updateCurrentScenario', {
                    mpid: this.mpid,
                    scenario
                });
                let newURL = this.updateQueryString('scenario', scenario.id);
                window.history.pushState({'scenario': scenario.id}, "", newURL);
                this.$store.dispatch('mp_project_measures/getScenarioRooms', this.mpid);
                this.getMeasureSummary(this.mpid);
            },
            scenarioIsSelected(scenario) {
                if(this.currentScenario.id == scenario.id) {
                    return true;
                }

                return false;
            }
        }
    }
</script>
<style>
    .scenariowrapper input{
        margin-right: 10px;
        display: inline-block;
    }
</style>
