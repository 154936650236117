import Vue from 'vue';
import Ability from 'etap/admin/classes/models/ability';
import Collection from 'etap/admin/classes/collection';

export const setCurrentRoles = (state, roles) => {

    state.current_roles = roles;

}

export const resetCurrentRoles = state => {

    state.current_roles = null;
}

export const addRole = (state, role) => {

    state.current_roles.add(role);

}

export const updateRole = (state, {roleId, updatedRole}) => {

    let role = state.current_roles.find({id: roleId});

    if(!role) {
        console.error('Unable to find role', role, roleId, state.current_roles);
        return;
    }

    role.assign(updatedRole);
}

export const removeRole = (state, role) => {


    if(!state.current_roles) {
        return;
    }

    state.current_roles.remove(role);
}

export const updateAbilitiesOnRole = (state, {role, abilities}) => {

    role.setRelationship('abilities', abilities);
}


export const addNewAbilityRecordToRole = (state, role) => {

    let newAbility = new Ability({
        roleId: role.id,
        abilityId: null,
    });

    if(!role.abilities) {
        let abilitiesCollection = new Collection(newAbility, {
            model: Ability
        });
    }

    role.abilities.add(newAbility);
}

export const setAbilityIdOnAbility = (state, {ability, abilityId}) => {
    ability.abilityId = abilityId;
}

export const updateAbility = (state, {ability, data}) => {
    ability.assign(data);
}

export const removeAbilityFromRole = (state, {ability, roleId}) => {

    var role;
    if(!state.current_roles || !(role = state.current_roles.find({id: roleId})) ) {
        console.error('Unable to find role for specified Id', roleId);
        return;
    }

    role.abilities.remove(ability);
}

export const setAbilityOptions = (state, abilities) => {

    state.ability_options = abilities;
}
