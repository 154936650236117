<script>
export default {
    props: {
        project: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            showProjectSettings: false,
            initializing: false,
            entityFormData: JSON.parse(JSON.stringify(this.project))
        }
    },

    async created() {
        try {
            this.initializing = true;

            if(this.canQCProjects && !this.projectSettingsForm) {
                await this.$store.dispatch('form_configurations/getFormConfigurationsByName', ['project_settings_form']);
            }

        }catch(err) {
            window.notify.error(err);
        }finally {
            this.initializing = false;
        }
    },


    computed: {
        canQCProjects() {
            return this.$can('qc_projects');
        },
        projectSettingsForm() {
            return this.$store.getters['form_configurations/getFormConfigurationByName']('project_settings_form');
        }
    },

    methods: {
        updated(updatedProject) {
            window.notify.success('Project Updated!');

            if(typeof updatedProject.toJSON === 'function') {
                updatedProject = updatedProject.toJSON();
            }

            this.entityFormData = Object.assign(
                this.project,
                updatedProject
            );

            if (window.Bus) {
                setTimeout(() => {
                    window.Bus.$emit('updateChecklist');
                }, 2000);
            }
        }
    }
}
</script>
<template>
    <div>
        <button class="button-secondary-small" @click="showProjectSettings = true">
            <i class="fa fa-cog"></i>
            Project Settings
        </button>
        <right-panel
            v-if="showProjectSettings"
            :width="'80%'"
            :click-away="false"
            @close="showProjectSettings = false"
        >
            <template #header>
                <div class="m-4 p-3 shadow-md rounded w-full">
                    <h3>Project Settings</h3>
                </div>
            </template>
            <template>
                <div class="px-4">
                    <div class="flex justify-between bg-white p-2 rounded" v-if="!this.canQCProjects">
                        <form-readonly
                            field-name="project-configuration-date"
                            v-model="project.valid_pricing_as_of"
                            label="Configuration Date"
                            class="w-1/3"
                            :display-in-line="true"
                        />
                        <form-autocomplete
                            v-can="'qc_projects'"
                            class="w-1/2"
                            field-name="project-owner"
                            label="Project Owner / Project Coordinator"
                            :options-url="'/api/projects/' + project.id + '/owners'"
                            :value="project.project_owner_id"
                            option-label-field="user_select_title"
                            option-value-field="id"
                            :disabled="1"
                        />
                    </div>
                    <div v-else class="bg-white p-4 rounded">

                        <vue-form
                            v-if="projectSettingsForm && !initializing"
                            :form-config="projectSettingsForm"
                            :form-data="entityFormData"
                            :disabled="initializing"
                            :form-submit-url="'/api/projects/' + project.id + '/settings'"
                            :actions="[{name: 'save', label: 'Save Settings', action: 'submitForm'}]"
                            @updated="updated"
                            :use-json-api="true"
                        />
                        <div
                            v-else
                            style="min-height: 10vh; display: flex; align-items: center; justify-content: center;"
                        >
                            <div class="text-center flex justify-center">
                                <div class="loading-bar w-1/2 text-white p-12 rounded">
                                    <span class="ml-4">Loading ...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </right-panel>
    </div>
</template>
