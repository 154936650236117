<script>
import {byString} from "laravel-vue-forms";
export default {

    props: {
        args: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedProjectStatus: null,
            projectStatusReason: null
        }
    },

    computed: {
        lookupModel() {
            return byString(this.args.action, 'options.lookupModel');
        },
        statusOptions() {
            if(!this.lookupModel || !window.MAT.lookups[this.lookupModel]) {
                return [];
            }

            return window.MAT.lookups[this.lookupModel].filter(statusOption => {
                if(
                    statusOption.attributes &&
                    statusOption.attributes.permission &&
                    !this.$can(statusOption.attributes.permission)
                ) {
                    return false;
                }

                return true;
            });
        }
    },

    methods: {
        closeActionComponent() {
            this.$emit("closeActionComponent");
        },
        updateProjectStatus() {
                return new Promise((resolve, reject) => {
                    let selectedProjectIds = this.args.selectedRecords.map(project => {
                        return project.id;
                    });

                    axios.patch('/api/projects/status', {
                        projects: selectedProjectIds,
                        status: this.selectedProjectStatus,
                        reasons: this.projectStatusReason
                    }).then(response => {
                        window.notify.message(response.data.message, 'success');
                        this.$emit('bulkActionCompleted')
                        resolve(response);
                    }).catch(error => {
                        if(error.response.status === 403) {
                            window.notify.message('You are unable set these projects to this status', 'error', 'Unauthorized');
                            return;
                        }
                        window.notify.apiError(error);
                        reject(error);
                    });
                });
            },
    }
}
</script>
<template>
    <modal modal-width="30%" @close="closeActionComponent">
        <template #header>Update Project Status</template>
        <template #body>
            <div class="min-h-35vh text-center mt-3">
                <div class="flex justify-center">
                    <form-select
                        field-name="update-project-status-action-handler-select"
                        :options="statusOptions"
                        option-label-field="name"
                        option-value-field="name"
                        v-model="selectedProjectStatus"
                        label="New Project Status"
                        class="w-2/3"
                        ></form-select>
                </div>
                <div>
                    <base64-wysiwyg
                        field-name="update-project-status-action-handler-wysiwyg"
                        v-model="projectStatusReason"
                    ></base64-wysiwyg>
                </div>
                <div class="flex justify-center">

                    <button
                        class="inputbutton1"
                        @click="updateProjectStatus"
                        :disabled="selectedProjectStatus === null"
                    >
                        Update Selected Projects
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>
