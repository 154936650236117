

export const getSchedulesForProject = state => projectId => {
    
    if(state.projectSchedules[projectId]) {
        return state.projectSchedules[projectId];
    }
    
    return [];
}
