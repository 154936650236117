

export const getInspectionForProjectAndPhase = state => (projectId, phaseId) => {

    return state.inspections.find(inspection => {
        if(inspection.project_id === projectId && inspection.phase_id === phaseId) {
            return true;
        }
    });
}
