<template>
    <div class="file-upload-container">
        <files :files="files" @deletedFile="removeFile" :disabled="disabled"></files>
        <upload-container
                v-if="showUploadContainer"
                :max-files="maxFiles"
                @addFile="addFile"
        ></upload-container>
    </div>
</template>
<script>
    import axios from 'axios';
    import Parser from "etap/admin/classes/jsonapi_parser";
    export default {

        props: {
            phaseId: {
                type: Number,
                default: null,
            },
            metaType: {
                type: String,
            },
            maxFiles: {
                type: Number,
                default: 100,
            },
            disabled: {
                type:Boolean,
                default: false
            },
            fileableType: {
                required:true,
                type: String,
            },
            fileableId: {
                required: true,
                type: Number
            },
            getFilesUrl: {
                type: String,
                default: '/api/files'
            },
            postFilesUrl: {
                type: String,
                default: '/api/files',
            }
        },

        data() {
            return {
                files: [],
                showUploadContainer: !this.disabled,
            }
        },

        created() {
            // get the files for the specified stepID passed in
            this.getFiles();

            if(window.Bus) {
                window.Bus.$on('updateFiles', (metaType) => {

                    if(Array.isArray(metaType) && metaType.includes(this.metaType)) {
                            this.getFiles();
                    }else if(typeof metaType === 'string' && metaType === this.metaType) {
                        this.getFiles();
                    }
                });
            }
        },

        methods: {
            getFiles() {

                var fileRequest = {
                    metaType: this.metaType,
                    fileableType: this.fileableType,
                    fileableId: this.fileableId
                }
                if(this.phaseId !== null) {
                    fileRequest.phaseId = this.phaseId;
                }

                axios.get('/api/files', {
                    params: fileRequest
                }).then( response => {
                    const files = Parser.parseJSONAPIResponse(response.data);
                    this.files = files.getModels();
                    this.checkIfReachedMaxFiles();
                }).catch( error => {
                    window.notify.apiError(error);
                });
            },
            async addFile(file) {
                try {
                    const fileRecordResponse = await axios.post(this.postFilesUrl, {
                        fileableType: this.fileableType,
                        fileableId: this.fileableId,
                        metaType: this.metaType,
                        phaseId: this.phaseId,
                        file
                    });

                    const files = Parser.parseJSONAPIResponse(fileRecordResponse.data);

                    window.notify.message("Successfully uploaded file", "success");

                    files.getModels().forEach(fileRecord => {
                        this.files.push(fileRecord);
                    })

                    this.checkIfReachedMaxFiles();
                    this.updateCheckList();
                } catch(error) {
                    window.notify.apiError(error);
                }
            },
            removeFile(deleteFile) {
                this.files = this.files.filter( file => {
                    return file.id !== deleteFile.id
                });
                this.checkIfReachedMaxFiles();
                this.updateCheckList();
            },
            checkIfReachedMaxFiles() {

                if(this.disabled) {
                    return; // don't check for max files if this component is intentionally disabled
                }

                if(this.files.length == this.maxFiles) {
                    this.showUploadContainer = false;
                }else {
                    this.showUploadContainer = true;
                }
            },
            updateCheckList() {
                if(window.Bus) {
                    setTimeout(() => {
                        Bus.$emit('updateChecklist');
                    }, 3200);
                }
            }
        }
    }
</script>
<style>

    .file-upload-container {
        margin: 10px;
    }

</style>
