<template>
    <div class="qc_widget">
        <h3>
            <span v-if="title" v-html="title"></span>
            <span v-else>{{ currentScenario.scenario_nickname}} Details</span>
        </h3>
        <table class="nobordertableright maxwidth">
            <div style="display: table; width: 100%" v-for="(qcItem,index) in qcConfigurationList" :key="index" >
                <tr v-if="qcItem.static">
                    <td colspan="3" v-html="qcItem.static"></td>
                </tr>
                <tr v-else :class="{'projectqcbadrow' : measureSummary[qcItem.qc_check]}">
                    <td class="projectqccolqc">
                        <div class="projectqciconwrapper">
                            <div class="fa fa-warning projectqcbad" v-if="measureSummary[qcItem.qc_check]"></div>
                            <div v-else>
                                <span class="fa fa-check"></span>
                            </div>
                        </div>
                    </td>
                    <td class="projectqccol1">
                        {{ qcItem.label }}
                    </td>
                    <td class="projectqccol2">
                        {{ formatValue(measureSummary[qcItem.value], qcItem.format, qcItem.rounding) }}
                    </td>
                </tr>
            </div>
        </table>
    </div>
</template>
<script>
    import {mapState, mapActions, mapMutations} from 'vuex';
    import { guid, formatValue } from 'etap/utilities/utils';

    export default {
        props: {
            initialProject: {
                type: Object,
            },
            initialCurrentScenario: {
                type: Object,
            },
            currentPhase: {
                type: Object
            },
            qcConfigurationList: {
                type: Array,
            },
            title: {
                default: null,
            },
        },

        data() {
            return {
                mpid: null,
            }
        },

        created() {

            if(this.initialProject) { // only set up separately if we're passing information directly in

                this.mpid = guid();
                this.$store.commit('mp_layout/defaultMeasurePicker', this.mpid);
                this.$store.commit('mp_scenarios/defaultMPScenarios', this.mpid);
                this.$store.commit('mp_project_measures/defaultMPProjectMeasures', this.mpid);
                this.updateProject({mpid: this.mpid, project: this.initialProject});

                if(this.initialCurrentScenario) {
                    this.updateCurrentScenario({
                        mpid: this.mpid,
                        scenario: this.initialCurrentScenario
                    });
                }

                if(this.currentPhase) {
                    this.setPhase({mpid: this.mpid, phase: this.currentPhase});
                }
            } else {

                this.mpid = this.$store.state.mp_layout.assessmentMPID;

            }


            this.getMeasureSummary(this.mpid);
            this.loadScenarios(this.mpid);
        },

        computed: {

            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            project() {
                return this.$store.getters['mp_layout/getProject'](this.mpid);
            },
            measureSummary() {
                return this.$store.getters['mp_layout/getMeasureSummary'](this.mpid);
            },
        },

        methods: {
            ...mapActions('mp_layout', [
                'getMeasureSummary'
            ]),
            ...mapActions('mp_scenarios',[
                'loadScenarios',
            ]),
            ...mapMutations('mp_layout', [
                'updateProject',
                'setPhase',
            ]),
            ...mapMutations('mp_scenarios', [
                'updateCurrentScenario'
            ]),
            formatValue,
        }
    }
</script>
