<script>
export default {
    props: {
        auditable: {
            required: true,
            type: Object,
        },
        auditableEntityType: {
            required: true,
            type: Object,
        },
        allowRefresh: {
            type: Boolean,
            default: true,
        },
        refreshAudits: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        searchParams() {
            return {
                entityType: this.auditableEntityType.name,
                entityTypeId: this.auditable.id,
            }
        }
    },

    methods: {
        updateRefreshAuditsModelValue(updateValue) {
            this.$emit('update:refreshAudits', updateValue);
        }
    }
}
</script>
<template>
    <div>
        <v-grid
            record-url="/api/audits"
            :allow-refresh="allowRefresh"
            :use-json-api="true"
            :record-url-params="searchParams"
            grid-type="divRow"
            record-type="audit-row"
            :queue-refresh="refreshAudits"
            @update:queueRefresh="updateRefreshAuditsModelValue"
        ></v-grid>
    </div>
</template>
