<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {

    mixins: [ clickaway ],

    props: {
        header: {
            type: String,
            default: ""
        },
        clickAway: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: "80%",
        },
    },

    emits: ["close"],

    data() {
        return {
            showPanelContent: false,
        }
    },

    created() {
        setTimeout(() => {
            this.showPanelContent = true;
        }, 100);
    },

    computed: {
        modalStyle() {
            let style = '';
            if(window.screen.width < 720) {
                style += "width: 100%;";
            } else {
                style += "width: " + this.width + ";";
            }

            return style;
        }
    },

    methods: {
        closeFromClickAway(){
            if(!this.clickAway) {
                return;
            }

            this.closePanel(true);
        },
        closePanel() {

            this.showPanelContent = false;
            setTimeout(() => {
                this.$emit("close")
            }, 100);
        }
    },
}
</script>
<template>
    <Teleport to="body">
        <div
            ref="rightPanel"
            class="fixed z-40 w-full h-full top-0 left-0 overflow-x-hidden overflow-y-hidden text-gray-900"
            style="background-color: rgba(0, 0, 0, 0.32)"
        >

            <transition name="slide">
                <div
                    class="absolute inset-0 sm:left-auto z-60 shadow-xl h-full bg-gray-100 overflow-x-hidden overflow-y-auto no-scrollbar border-l border-slate-200" v-on-clickaway="closeFromClickAway" v-if="showPanelContent"
                    :style="modalStyle"
                >

                    <div class="top-0 dark:border-slate-700">

                        <div class="flex justify-between items-center">

                            <slot name="header">
                                <div class="py-8 px-4 lg:px-8 font-bold text-lg">
                                    {{ header}}
                                </div>
                            </slot>

                            <button ref="button-secondary" @click.stop="closePanel" class="m-4 p-2">
                                <svg class="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                                </svg>
                            </button>
                        </div>

                        <div class="relative">
                            <slot>
                                <div>
                                    Right panel body
                                </div>
                            </slot>
                        </div>
                    </div>

                </div>
            </transition>
        </div>
    </Teleport>
</template>
<style scoped>
.fixed {
    position: fixed;
}

.z-40 {
    z-index: 40;
}

.h-full {
    height: 100%;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.text-gray-900 {
    color: #1a202c;
}

.inset-0 {
    inset: 0;
}

.sm\:left-auto {
    left: auto;
}

.shadow-xl {
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.5);
}

.bg-gray-100 {
    background-color: #f7fafc;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.border-l {
    border-left-width: 1px;
}

.border-slate-200 {
    border-color: #e2e8f0;
}

.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.fill-slate-400 {
    fill: #a0aec0;
}

.group-hover\:fill-slate-600:hover {
    fill: #4a5568;
}

.pointer-events-none {
    pointer-events: none;
}

.m-6 {
    margin: 1.5rem;
}
</style>
