

export const getCurrentScenario = (state) => (mpid) => {

    if(!state.measure_picker_scenarios[mpid]) {
        return null;
    }

    return state.measure_picker_scenarios[mpid].currentScenario;
}

export const getScenarios = (state) => (mpid) => {

    if(!state.measure_picker_scenarios[mpid]) {
        return [];
    }

    return state.measure_picker_scenarios[mpid].scenarios;
}

