import Vue from 'vue';




// Login component
import Login from 'etap/components/Login.vue';
import ResetPassword from "etap/components/ResetPassword.vue";
import SignUpForm from "etap/components/SignUpForm.vue";
import PasswordWithRequirements from "etap/components/FormWidgets/PasswordWithRequirements.vue";
import VerifyEmailNotification from "etap/components/authentication/VerifyEmailNotification.vue";
import GridContainer from 'etap/components/AppGrid/GridContainer.vue';

Vue.component('login-form', Login);
Vue.component('reset-password-form', ResetPassword);
Vue.component('sign-up-form', SignUpForm);
Vue.component('password-with-requirements', PasswordWithRequirements);
Vue.component('verify-email-notification', VerifyEmailNotification);
Vue.component('app-grid-container', GridContainer);

// Global Component Imports;
import CollapseList from 'etap/components/CollapseList.vue';
import Modal from 'etap/components/Modal.vue';
import ProjectDateField from 'etap/components/ProjectDateField.vue';
import PreviewPdf from 'etap/components/ReportComponents/PreviewPdf.vue';
import GlobalReportGenerator from 'etap/components/ReportComponents/GlobalReportGenerator.vue';
import HeaderDropMenu from 'etap/components/HeaderDropMenu.vue';
import AdobeSignRequest from 'etap/components/ReportComponents/AdobeSignRequest.vue';
import InputMask from 'etap/components/FormWidgets/InputMask.vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ExpansionPanel from "etap/components/ExpansionPanel.vue";
import GoogleMapLink from "etap/components/FormComponents/GoogleMapLink.vue";
import FormSection from "etap/components/FormComponents/FormSection.vue";
import FormSkeleton from "etap/components/FormWidgets/FormSkeleton.vue";
import TableSkeleton from "etap/components/FormWidgets/TableSkeleton.vue";
import TextSkeleton from "etap/components/FormWidgets/TextSkeleton.vue";
import RightPanel from "etap/components/RightPanel.vue";
import AuditsSubView from "etap/components/SharedSubViews/AuditsSubView.vue";
import AuditRow from "etap/components/GridRows/AuditRow.vue";
import NotesSubView from "etap/components/SharedSubViews/NotesSubView.vue";
import NoteRow from "etap/components/GridRows/NoteRow.vue";
import ProjectSettings from "etap/components/Project/ProjectSettings.vue";
import Teleport from "etap/components/Teleport.vue";
import ExpansionDrawer from "etap/components/ExpansionDrawer.vue";

Vue.component('collapse-list', CollapseList);
Vue.component('modal', Modal);
Vue.component('project-date-field', ProjectDateField);
Vue.component('preview-pdf', PreviewPdf);
Vue.component('global-report-generator', GlobalReportGenerator);
Vue.component('header-drop-menu', HeaderDropMenu);
Vue.component('adobe-sign-request', AdobeSignRequest);
Vue.component('input-mask', InputMask);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('expansion-panel', ExpansionPanel);
Vue.component('google-map-link', GoogleMapLink);
Vue.component('form-section', FormSection);
Vue.component('form-skeleton', FormSkeleton);
Vue.component('table-skeleton', TableSkeleton);
Vue.component('text-skeleton', TextSkeleton);
Vue.component('right-panel', RightPanel);
Vue.component('Teleport', Teleport);
Vue.component('audits-sub-view', AuditsSubView);
Vue.component('audit-row', AuditRow);
Vue.component('notes-sub-view', NotesSubView);
Vue.component('note-row', NoteRow);
Vue.component('project-settings', ProjectSettings);
Vue.component('expansion-drawer', ExpansionDrawer);



/**
 * Require tabs components
 */
import Tabs from 'etap/components/Tabs/Tabs.vue';
import Tab from 'etap/components/Tabs/Tab.vue';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

/**
 * File upload
 */
import FileUploaderContainer from 'etap/components/FileUpload/FileUploaderContainer.vue';
import Files from 'etap/components/FileUpload/Files.vue';
import UploadContainer from 'etap/components/FileUpload/UploadContainer.vue';

Vue.component('file-upload-container', FileUploaderContainer);
Vue.component('files', Files);
Vue.component('upload-container', UploadContainer);

/**
 * Email components
 */
import UnsubscribeEmail from 'etap/components/Emails/UnsubscribeEmail.vue';
import SendNotification from 'etap/components/Emails/SendNotification.vue';

Vue.component('send-notification', SendNotification);
Vue.component('unsubscribe-email', UnsubscribeEmail);


/**
 * Home Page components
 */
import Home from 'etap/components/Home/Home.vue';
import Search from 'etap/components/Home/Search.vue';
import ProjectStats from 'etap/components/Home/ProjectStats.vue';
import HomeProjectGridRow from 'app/components/GridRows/HomeProjectGridRow.vue';
import HomeProjectGridRowDisplayRow1 from "app/components/GridRows/HomeProjectSubViews/HomeProjectGridRowDisplayRow1.vue";
import HomeProjectGridRowDisplayRow2 from "app/components/GridRows/HomeProjectSubViews/HomeProjectGridRowDisplayRow2.vue";
import CreateProjectModal from 'app/components/Home/CreateProjectModal.vue';
import DivRowPinned from 'etap/components/GridViews/Grid-DivRowPinned.vue'
import BannerNotificationContainer from "etap/components/BannerNotifications/BannerNotificationContainer.vue";
import UpdateProjectStatusActionTypeHandler from 'etap/components/Home/UpdateProjectStatusActionTypeHandler.vue';

Vue.component('home', Home);
Vue.component('search', Search);
Vue.component('project-stats', ProjectStats);
Vue.component('home-project-grid-row', HomeProjectGridRow);
Vue.component('home-project-grid-row-display-row1', HomeProjectGridRowDisplayRow1);
Vue.component('home-project-grid-row-display-row2', HomeProjectGridRowDisplayRow2);
Vue.component('create-project-modal', CreateProjectModal);
Vue.component('div-row-pinned', DivRowPinned)
Vue.component('banner-notification-container', BannerNotificationContainer);
Vue.component('update-project-status-action-type-handler', UpdateProjectStatusActionTypeHandler);

/**
 * Settings Page components
 */
import EquipmentLibraryContainer from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryContainer.vue';
import EquipmentLibraryRightContainer from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryRightContainer.vue';
import EquipmentLibraryMeasureGroupsContainer from 'etap/components/EquipmentLibrary/layout/MeasureGroupsContainer.vue';
import EquipmentLibrarySearch from 'etap/components/EquipmentLibrary/EquipmentLibrarySearch.vue';
import EquipmentLibraryMeasureList from 'etap/components/EquipmentLibrary/layout/EquipmentLibraryMeasureList.vue';
import EquipmentLibraryMeasureEquipment from 'etap/components/EquipmentLibrary/EquipmentLibraryMeasureEquipment.vue';
import EquipmentForm from 'etap/components/EquipmentLibrary/layout/EquipmentForm.vue';
import ImportEquipment from 'etap/components/EquipmentLibrary/layout/ImportEquipment.vue';
import QplLookup
    from "etap/components/EquipmentLibrary/layout/QplLookup.vue";
import EquipmentLibraryItems
    from "etap/components/EquipmentLibrary/layout/EquipmentLibraryItems.vue";
import EquipmentLibraryBulkImport
    from "etap/components/EquipmentLibrary/layout/EquipmentLibraryBulkImport.vue";
import EquipmentItemRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentItemRow.vue";
import EquipmentLibraryMeasureRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentLibraryMeasureRow.vue"
import QplResultRow
    from "etap/components/EquipmentLibrary/GridRows/QplResultRow.vue";
import EquipmentBulkImportResultRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentBulkImportResultRow.vue";
import EquipmentBulkImportOperationLogRow
    from "etap/components/EquipmentLibrary/GridRows/EquipmentBulkImportOperationLogRow.vue";
import QplLookupField from 'etap/components/EquipmentLibrary/QplLookupField.vue';

Vue.component('equipment-library-container', EquipmentLibraryContainer);
Vue.component('equipment-library-right-container', EquipmentLibraryRightContainer);
Vue.component('equipment-library-measure-groups-container', EquipmentLibraryMeasureGroupsContainer);
Vue.component('equipment-library-search', EquipmentLibrarySearch);
Vue.component('equipment-library-measure-list', EquipmentLibraryMeasureList);
Vue.component('equipment-library-measure-equipment', EquipmentLibraryMeasureEquipment);
Vue.component('equipment-form', EquipmentForm);
Vue.component('import-equipment', ImportEquipment);
Vue.component('qpl-lookup', QplLookup);
Vue.component('equipment-library-items', EquipmentLibraryItems);
Vue.component('equipment-library-bulk-import', EquipmentLibraryBulkImport);
Vue.component('equipment-item-row', EquipmentItemRow);
Vue.component('equipment-library-measure-row', EquipmentLibraryMeasureRow);
Vue.component('qpl-result-row', QplResultRow);
Vue.component('equipment-bulk-import-result-row', EquipmentBulkImportResultRow);
Vue.component('equipment-bulk-import-operation-log-row', EquipmentBulkImportOperationLogRow);
Vue.component('qpl-lookup-field', QplLookupField);

/**
 * Universal Page Vue Components boostrap
 */
import ReAssignProject from 'etap/components/Universal/ReAssignProject.vue';
import UniversalPageSelector from 'etap/components/Universal/UniversalPageSelector.vue';
import ProjectPaymentsContainer from 'etap/components/Universal/ProjectPaymentsContainer.vue';
import ProjectStatusUpdater from 'etap/components/QC/ProjectStatusUpdater.vue';
import RecalculateProjectCalculations from 'etap/components/Universal/RecalculateProjectCalculations.vue';
import JobStatuses from 'etap/components/Universal/JobStatuses.vue'
import JobStatusRow from 'etap/components/GridRows/JobStatusRow.vue';

Vue.component('re-assign-project', ReAssignProject);
Vue.component('universal-page-selector', UniversalPageSelector);
Vue.component('project-payments', ProjectPaymentsContainer);
Vue.component('project-status-updater', ProjectStatusUpdater);
Vue.component('recalculate-project-calculations', RecalculateProjectCalculations);
Vue.component('project-job-statuses', JobStatuses);
Vue.component('job-status-row', JobStatusRow);

/**
 * Customer Information Components
 */
import CustomerInformationForm from 'etap/components/Forms/CustomerInformationForm.vue';
import FacilityBillAccountsForm from 'etap/components/FormWidgets/FacilityBillAccountsForm.vue';
import BillAccountsContainer from 'etap/components/BillAccounts/BillAccountsContainer.vue';
import BillAccountLookup from './../components/BillAccounts/BillAccountLookup.vue';
import BillAccountForm from 'etap/components/BillAccounts/BillAccountForm.vue';
import BillAccount from './../components/BillAccounts/BillAccount.vue';
import FormAddressGoogleAutocomplete from 'etap/components/FormComponents/FormAddressGoogleAutocomplete.vue'

Vue.component('customer-information-form', CustomerInformationForm);
Vue.component('facility-bill-accounts-form', FacilityBillAccountsForm);
Vue.component('bill-accounts-container', BillAccountsContainer);
Vue.component('bill-account-lookup', BillAccountLookup);
Vue.component('bill-account-form', BillAccountForm);
Vue.component('bill-account', BillAccount);
Vue.component('form-address-google-auto-complete', FormAddressGoogleAutocomplete);



/**
 * Eligibility Check
 */

import EligibilityContainer from 'app/components/Eligibility/EligibilityContainer.vue';
import EligibilityForm from 'app/components/Eligibility/EligibilityForm.vue';
import EligibilityList from 'app/components/Eligibility/EligibilityList.vue';
import EligibleBillAccountRow from "app/components/Eligibility/EligibleBillAccountRow.vue";

Vue.component('eligibility-container', EligibilityContainer);
Vue.component('eligibility-form', EligibilityForm);
Vue.component('eligibility-list', EligibilityList);
Vue.component('eligible-bill-account-row', EligibleBillAccountRow);



/**
 * Measure Picker Components
 */

import MeasurePickerContainer from 'etap/components/MeasurePicker/MeasurePickerContainer.vue';
import ZeroInformationWarning from 'etap/components/MeasurePicker/ZeroInformationWarning.vue';
import ScenarioList from 'etap/components/MeasurePicker/ScenarioList.vue';
import MeasureList from 'etap/components/MeasurePicker/MeasureList.vue';
import MeasureTabAccordion from 'etap/components/MeasurePicker/Accordion/MeasureTabAccordion.vue';
import MeasureTabAccordionItem from 'etap/components/MeasurePicker/Accordion/MeasureTabAccordionItem.vue';
import MeasureSelectionItem from 'etap/components/MeasurePicker/Accordion/MeasureSelectionItem.vue';
import AddScenarioModal from 'etap/components/MeasurePicker/Modals/AddScenarioModal.vue';
import ProjectMeasureContainer from 'etap/components/MeasurePicker/ProjectMeasureContainer.vue';
import MeasureSummary from 'app/components/MeasurePicker/MeasureSummary.vue';
import RoomList from 'etap/components/MeasurePicker/RoomList.vue';
import Room from 'etap/components/MeasurePicker/Room.vue';
import ProjectMeasure from 'app/components/MeasurePicker/ProjectMeasure.vue';
import ProjectMeasureDetails from 'etap/components/MeasurePicker/ProjectMeasureDetails.vue';
import FBTechnology from 'etap/components/MeasurePicker/FormBuilderWidgets/Technology.vue';
import FBEquipment from 'etap/components/MeasurePicker/FormBuilderWidgets/Equipment.vue';
import FBFormCompletion from 'etap/components/MeasurePicker/FormBuilderWidgets/FormCompletion.vue';
import ExteriorHours from 'etap/components/MeasurePicker/FormBuilderWidgets/ExteriorHours.vue';
import MeasurePickerEquipmentLibraryContainer from "etap/components/MeasurePicker/MeasurePickerEquipmentLibrary/MeasurePickerEquipmentLibraryContainer.vue";
import FormSimpleMeasurePicker from "etap/components/MeasurePicker/FormSimpleMeasurePicker.vue";
import SimpleMeasurePickerAddMeasureModal from "etap/components/MeasurePicker/SimpleMeasurePicker/SimpleMeasurePickerAddMeasureModal.vue";
import SimpleProjectMeasureRow from "etap/components/MeasurePicker/SimpleMeasurePicker/SimpleProjectMeasureRow.vue";

Vue.component('measure-picker-container', MeasurePickerContainer);
Vue.component('zero-information-warning', ZeroInformationWarning);
Vue.component('scenario-list', ScenarioList);
Vue.component('measure-list', MeasureList);
Vue.component('tab-accordion', MeasureTabAccordion);
Vue.component('accordion-item', MeasureTabAccordionItem);
Vue.component('measure-selection-item', MeasureSelectionItem);
Vue.component('add-scenario-modal', AddScenarioModal);
Vue.component('project-measure-container', ProjectMeasureContainer);
Vue.component('measure-summary', MeasureSummary);
Vue.component('room-list', RoomList);
Vue.component('room', Room);
Vue.component('project-measure', ProjectMeasure);
Vue.component('project-measure-details', ProjectMeasureDetails);

Vue.component('technology', FBTechnology);
Vue.component('equipment', FBEquipment);
Vue.component('form-completion', FBFormCompletion);
Vue.component('exterior-hours', ExteriorHours);
Vue.component('measure-picker-equipment-library-container', MeasurePickerEquipmentLibraryContainer);
Vue.component('form-simple-measure-picker', FormSimpleMeasurePicker);
Vue.component('simple-measure-picker-add-measure-modal', SimpleMeasurePickerAddMeasureModal);
Vue.component('simple-project-measure-row', SimpleProjectMeasureRow);

/**
 * Report Page Components
 */
import GenerateAssessmentReport from 'etap/components/ReportComponents/GenerateAssessmentReport.vue';
import QCWidget from 'etap/components/ReportComponents/QCWidget.vue';
import SelectedScenarioSelector from 'etap/components/ReportComponents/SelectedScenarioSelector.vue';
import ProjectChecklist from 'etap/components/Checklist/ProjectChecklist.vue';
import ChecklistItem from 'etap/components/Checklist/ChecklistItem.vue';
import SubmitPhaseForCompletion from 'etap/components/ReportComponents/SubmitPhaseForCompletion.vue';
import UpgradeProjectProgramYear from 'etap/components/ReportComponents/UpgradeProjectProgramYear.vue';
import GenerateInvoices from 'etap/components/ReportComponents/GenerateInvoices.vue';
import GenerateMeasureListReport from 'etap/components/ReportComponents/GenerateMeasureList.vue';
import ProjectNeedsConfigurationUpdateBanner from "etap/components/ReportComponents/ProjectNeedsConfigurationUpdateBanner.vue";

Vue.component('generate-measure-list', GenerateMeasureListReport);
Vue.component('generate-assessment-report', GenerateAssessmentReport);
Vue.component('qc-widget', QCWidget);
Vue.component('selected-scenario-selector', SelectedScenarioSelector);
Vue.component('project-checklist', ProjectChecklist);
Vue.component('checklist-item', ChecklistItem);
Vue.component('submit-phase-for-completion', SubmitPhaseForCompletion);
Vue.component('upgrade-project-program-year', UpgradeProjectProgramYear);
Vue.component('generate-invoices', GenerateInvoices);
Vue.component('project-needs-configuration-update-banner', ProjectNeedsConfigurationUpdateBanner);


/**
 * QC Page Components
 */
import QCCheckbox from 'etap/components/QC/QCCheckbox.vue';
import QCCheckboxList from 'etap/components/QC/QCCheckboxList.vue';
import QCRollupContainer from 'etap/components/QC/QCRollup/QCRollupContainer.vue';
import GroupedProjectMeasure from 'etap/components/QC/QCRollup/GroupedProjectMeasure.vue';
import RewindProject from 'etap/components/QC/RewindProject.vue';
import ZipFilesCreator from 'etap/components/ProjectZipFiles/ZipFilesCreator.vue';
import QCInspectionManager from 'app/components/Inspections/QCInspectionManager.vue';
import ProjectApprovalForm from 'etap/components/Forms/ProjectApprovalForm.vue';
import BillAccountsQC from 'etap/components/QC/BillAccountsQC.vue';
import BillAccountQCRow from 'etap/components/GridRows/BillAccountQCRow.vue';

Vue.component('qc-checkbox', QCCheckbox);
Vue.component('qc-checkbox-list', QCCheckboxList);
Vue.component('qc-rollup-container', QCRollupContainer);
Vue.component('grouped-project-measure', GroupedProjectMeasure);
Vue.component('rewind-project', RewindProject);
Vue.component('zip-files-creator', ZipFilesCreator);
Vue.component('qc-inspection-manager', QCInspectionManager);
Vue.component('bill-accounts-qc', BillAccountsQC);
Vue.component('bill-account-qc-row', BillAccountQCRow);
Vue.component('project-approval-form', ProjectApprovalForm);



/**
 * Inspector Page
 */
import InspectionForm from 'etap/components/Inspections/Form.vue';
import FormBase64WYSIWYG from 'etap/components/FormWidgets/FormBase64WYSIWYG.vue';

Vue.component('inspection-form', InspectionForm);
Vue.component('base64-wysiwyg', FormBase64WYSIWYG);


/**
 * Schedule builder
 */
import ScheduleBuilder from 'etap/components/ScheduleBuilder/ScheduleBuilder.vue';
import ScheduleSelector from 'etap/components/ScheduleBuilder/ScheduleSelector.vue';
import ScheduleBuilderForm from 'etap/components/ScheduleBuilder/ScheduleBuilderForm.vue';

Vue.component('schedule-builder', ScheduleBuilder);
Vue.component('schedule-selector', ScheduleSelector);
Vue.component('schedule-builder-form', ScheduleBuilderForm);

/**
 * Leads Components
 */
import LeadForm from 'etap/components/Leads/Form.vue';
import ProjectLeadRow from 'etap/components/GridRows/ProjectLeadRow.vue';

Vue.component('lead-form', LeadForm);
Vue.component('project-lead-row', ProjectLeadRow);

//Vue.component('', require('./../../../../vendor/nexant/audittoolmaster/resources/assets/js/components/MeasurePicker/'));

/**
 * Form Widgets
 */
import ConditionedFormCheckbox from 'etap/components/FormWidgets/ConditionedFormCheckbox.vue';
import DropdownFlag from 'etap/components/FormComponents/DropdownFlag.vue';
import FormReadOnly from 'etap/components/FormComponents/FormReadOnly.vue'
import FormProjectSummary from 'etap/components/FormComponents/FormProjectSummary.vue';

Vue.component('conditioned-form-checkbox', ConditionedFormCheckbox);
Vue.component('dropdown-flag', DropdownFlag);
Vue.component('form-readonly', FormReadOnly);
Vue.component('form-project-summary', FormProjectSummary);


import EnergyDashboard from 'etap/components/Dashboard/EnergyDashboard.vue';

Vue.component('energy-dashboard', EnergyDashboard);

/**
 * Project Components
 */
import BypassProjectSubmissionValidation from 'etap/components/Project/BypassProjectSubmissionValidation.vue';
import ProjectForm from "etap/components/Project/ProjectForm.vue";
import FormProjectPayee from "etap/components/Project/FormProjectPayee.vue";

Vue.component('bypass-project-submission-validation', BypassProjectSubmissionValidation);
Vue.component('project-form', ProjectForm);
Vue.component('form-project-payee', FormProjectPayee);

/**
 * Project Tasks Components
 */
import ProjectTaskContainer from "etap/components/Tasks/ProjectTaskContainer.vue";
import ProjectTaskRow from "etap/components/GridRows/ProjectTaskRow.vue";
import TasksHomeContainer from "etap/components/Tasks/TasksHomeContainer.vue";
import TaskHomeSearchRow from "etap/components/GridRows/TaskHomeSearchRow.vue";
import ProjectTaskableView from "etap/components/Tasks/TaskableViews/ProjectTaskableView.vue";
import BulkUpdateTaskActionTypeHandler from "etap/components/Tasks/BulkUpdateTaskActionTypeHandler.vue";
import AddTaskForm from "etap/components/Tasks/AddTaskForm.vue";

Vue.component('project-task-container', ProjectTaskContainer);
Vue.component('project-task-row', ProjectTaskRow);
Vue.component('tasks-home-container', TasksHomeContainer);
Vue.component('task-home-search-row', TaskHomeSearchRow);
Vue.component('project-taskable-view', ProjectTaskableView);
Vue.component('bulk-update-task-action-type-handler', BulkUpdateTaskActionTypeHandler);
Vue.component('add-task-form', AddTaskForm);


/**
 * Residential/IQMF components
 */
import ResidentialApplication from "app/components/ResidentialApplication.vue";

Vue.component('residential-application', ResidentialApplication);

/**
 * Measure Selection Components
 */
import MeasureSelectionField from 'etap/components/MeasureSelection/MeasureSelectionField.vue';
import MeasureSelectionFieldRoom from 'etap/components/MeasureSelection/MeasureSelectionFieldRoom.vue';
import MeasureSelectionFieldRow from 'etap/components/MeasureSelection/MeasureSelectionFieldRow.vue';
import MeasureSelectionFieldMeasureDetails from 'etap/components/MeasureSelection/MeasureSelectionFieldMeasureDetails.vue';


Vue.component('measure-selection-field', MeasureSelectionField);
Vue.component('measure-selection-field-room', MeasureSelectionFieldRoom);
Vue.component('measure-selection-field-row', MeasureSelectionFieldRow);
Vue.component('measure-selection-field-measure-details', MeasureSelectionFieldMeasureDetails);
