<script>
import { FormFieldMixin } from 'laravel-vue-forms';
export default {

    mixins: [FormFieldMixin],

    data() {
        return {
            password: '',
            password_confirm: ''
        }
    },

    computed: {
        has10chars() {
            return this.password.length >=10
        },
        hasLowercase() {
            return /[a-z]/.test(this.password);
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasDigit() {
            return /[0-9]/.test(this.password);
        },
        hasSpecialChar() {
            return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(this.password);
        },
        passwordsMatch() {
            return this.password === this.password_confirm && this.password.length !== 0;
        },
        csrfToken() {
            return window.MAT.csrfToken;
        }
    },

    watch: {
        password(newPassword) {
            this.form.data.password = newPassword;
        },
        password_confirm(newPassword) {
            this.form.data.password_confirmation = newPassword;
        }
    },

    methods: {
        updateValue(value) {
            this.form.errors.clear(this.fieldConfig.value_field);
            this.$emit('input', value);
        }
    }

}
</script>
<template>

    <div>

        <div class="form-group">
            <label>Password</label>
            <input
                type="password"
                name="password"
                id="username"
                class="block rounded w-full"
                style="border: 1px solid; padding:10px;"
                v-model="password"
            />
        </div>
        <div>
            <ul style="list-style: none" class="mb-2 ml-2">
                <li :class="{'bg-green': has10chars}" class="p-1">
                    <i class="fa fa-check" v-if="has10chars"></i>
                    <i class="fa fa-times-circle text-red" v-else></i>
                    Has a minimum of 10 characters
                </li>
                <li :class="{'bg-green': hasLowercase}" class="p-1">
                    <i class="fa fa-check" v-if="hasLowercase"></i>
                    <i class="fa fa-times-circle text-red" v-else></i>
                    Has at least 1 lowercase letter
                </li>
                <li :class="{'bg-green': hasUppercase}" class="p-1">
                    <i class="fa fa-check" v-if="hasUppercase"></i>
                    <i class="fa fa-times-circle text-red" v-else></i>
                    Has at least 1 uppercase letter
                </li>
                <li :class="{'bg-green': hasDigit}" class="p-1">
                    <i class="fa fa-check" v-if="hasDigit"></i>
                    <i class="fa fa-times-circle text-red" v-else></i>
                    Has at least 1 number
                </li>
                <li :class="{'bg-green': hasSpecialChar}" class="p-1">
                    <i class="fa fa-check" v-if="hasSpecialChar"></i>
                    <i class="fa fa-times-circle text-red" v-else></i>
                    Has at least 1 special character
                </li>
            </ul>
        </div>
        <div class="form-group mt-2">
            <label>Password Confirmation</label>
            <input
                type="password"
                name="password_confirmation"
                id="password"
                class="block rounded w-full"
                style="border: 1px solid; padding:10px;"
                v-model="password_confirm"
            />
        </div>
        <ul style="list-style: none" class="mb-2 ml-2">
            <li :class="{'bg-green': passwordsMatch}" class="p-1">
                <i class="fa fa-check" v-if="passwordsMatch"></i>
                <i class="fa fa-times-circle text-red" v-else></i>
                Passwords Match
            </li>
        </ul>

    </div>

</template>
