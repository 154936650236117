import Vue from 'vue';


export const setInspectors = (state, inspectors) => {
    state.inspectors = inspectors;
}

export const addInspection = (state, inspection) => {

    if(!state.inspections) {
        Vue.set(state, inspections, []);
    }


    let index = state.inspections.findIndex(insp => {
        if(insp.id === inspection.id) {
            return true;
        }
    });

    if(index) {
        state.inspections.splice(index, 1, inspection);
    }else {
        state.inspections.push(inspection);
    }
}

export const updateInspection = (state, inspection) => {

    let index = state.inspections.findIndex(insp => {
        if(insp.id === inspection.id) {
            return true;
        }
    });

    if(index !== -1) {
        state.inspections.splice(index, 1, inspection);
    }else {
        state.inspections.push(inspection);
    }

}


export const setInspection = (state, data) => {
    state.inspection = data;
};

export const setInspectionLogs = (state, logs) => {
    state.inspectionLogs = logs;
}

export const setProjectId = (state, value) => {
    state.inspection.project_id = value;
};
export const setPhaseId = (state, value) => {
    state.inspection.phase_id = value;
};

export const setInspectionWaived = (state, waived) => {
    if(typeof  waived == "undefined") {
        waived = 1;
    }

    state.inspection.waived = waived;
}

export const updateLoading = (state, value) => {
    state.loading = value;
};

export const setInspectionFormConfigId = (state, formConfigId) => {
    state.inspectionFormConfigId = formConfigId;
}
