<template>
    <div class="technology">
        <div class="form-group"
             :id="fieldName + '-exterior-hours-field'"
             :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
        >
            <label class="form-control-label">
                {{ fieldConfig.label }}
                <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
            </label>

            <multi-select
                    :value="optionValue"
                    :options="fieldConfig.options"
                    :track-by="fieldConfig.optionValueField"
                    :label="fieldConfig.optionLabelField"
                    :placeholder="fieldConfig.label"
                    @input="updateExteriorHours"
                    class="form-control"
                    :disabled="fieldConfig.disabled === 1"
            ></multi-select>

            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
            </span>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import MultiSelect from 'vue-multiselect'
    import { FormFieldMixin } from 'laravel-vue-forms';
    import { HasOptionsMixin } from 'laravel-vue-forms';
    export default {

        mixins: [
            FormFieldMixin,
            HasOptionsMixin
        ],

        components: {
            MultiSelect
        },

        computed: {
            ...mapState('mp_project_measures', [
                'scenarioRooms',
            ]),
            project_measure_room() {

                return this.scenarioRooms.filter(room => {
                    return room.id === this.form.room_id;
                }).shift();

            }
        },

        methods: {
            updateExteriorHours(value) {
                this.form.errors.clear(this.fieldConfig.value_field);
                if(value) {
                    this.updateFormOperatingHours(value);
                    this.$emit('input', value[this.fieldConfig.optionValueField]);
                } else {
                    this.$emit('input', null)
                }
            },
            updateFormOperatingHours(value) {
                // Only update the operating hours
                if(!this.form.data.operating_hours && value[this.fieldConfig.optionLabelField] === 'Dusk to Dawn') {
                    this.form.data.operating_hours = value[this.fieldConfig.optionValueField]
                } else if (!this.form.data.operating_hours && value[this.fieldConfig.optionLabelField] === 'Custom Schedule') {
                    this.form.data.operating_hours = value[this.fieldConfig.optionValueField]
                }
            }
        }

    }
</script>

<style>
    .multiselect input {
        border: 0;
        padding: 0;
    }
</style>
