import Model from 'etap/admin/classes/models/model';
import Audit from 'etap/admin/classes/models/audit';
import Measure from "etap/admin/classes/models/measure";
import EquipmentMeasure from "./equipmentMeasure";

class Equipment extends Model {

    constructor(attributes) {
        super(attributes);

        if(!attributes.equipment_measures) {
            this.set('equipment_measures', [])
        }
    }

    defaults() {
        return {
            name: '',
            make: '',
            model: '',
            unit_cost: '',
            equipment_wattage: '',
            notes: null,
            active: 1,
            approved: 0,
            deleted_at: null
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {
            equipment_measures: EquipmentMeasure,
            measures: Measure,
            audits: Audit,
        }
    }

    routes() {
        return {

        }
    }

}

export default Equipment;
