import axios from 'axios';
import { updateQueryString } from 'etap/utilities/url_utils';

export const loadScenarios = (context, mpid) => {

    return new Promise((resolve, reject) => {

        let project = context.rootGetters['mp_layout/getProject'](mpid)

        axios.get(
            context.rootState.apiPrefix + 'projects/' + project.id + '/scenarios'
        ).then( response => {
            context.commit('loadScenarios', {
                scenarios: response.data,
                mpid
            })

            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });

    });
}

export const createScenario = (context, {copyScenarioId, mpid}) => {

    let phase = context.rootGetters['mp_layout/getPhase'](mpid);
    let project = context.rootGetters['mp_layout/getProject'](mpid);

    var postParams = {
        phase: phase.id
    };

    if(copyScenarioId) {
        postParams.scenario = copyScenarioId
    }

    axios.post(context.rootState.apiPrefix + 'projects/' + project.id + '/scenarios', postParams).then( response => {

        var newScenario = response.data;

        let newURL = updateQueryString('scenario', newScenario.id);
        window.history.pushState({'scenario': newScenario.id}, "", newURL);

        context.commit('addScenario', {scenario: newScenario, mpid});
        context.commit('updateCurrentScenario', {scenario: newScenario, mpid});
        context.dispatch('mp_scenarios/loadScenarios', mpid, { root: true });
        context.dispatch('mp_project_measures/getScenarioRooms', mpid, { root: true });
        context.dispatch('mp_layout/getMeasureSummary', mpid, { root: true });
    }).catch( error => {
        window.notify.apiError(error);
    })
}

export const updateScenarioNickname = (context, {mpid, newNickname }) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid)
    let currentScenario = context.rootGetters['mp_scenarios/getCurrentScenario'](mpid);

    axios.patch(context.rootState.apiPrefix + 'projects/' + project.id + '/scenarios/' + currentScenario.id, {
        scenario_nickname: newNickname
    }).then(response => {
        context.commit('updateScenarioNickname', {
            mpid,
            newNickname,
            scenarioId: currentScenario.id
        });
    }).catch(error => {
        window.notify.apiError(error);
    });
}

export const updateScenarioSelection = (context, {mpid, scenario, selection}) => {

    let project = context.rootGetters['mp_layout/getProject'](mpid)

    axios.patch(context.rootState.apiPrefix + 'projects/' + project.id + '/scenarios/' + scenario.id, {
        selected: selection
    }).then(response => {

        context.dispatch('loadScenarios', mpid);
        if(window.Bus) {
            setTimeout(() => {
                window.Bus.$emit('updateChecklist');
            }, 2000);
        }

    }).catch(error => {
        window.notify.apiError(error);
    });
}
