<template>
    <div id="project-searchbox" class="panel-body text-center">
        <div id="projectsearchheader">
            <div class="projectsearchheaderelement">
                <div class="fa fa-search searchicon"></div>
            </div>
            <div class="projectsearchheaderelement"><h2 class="projectsearchtext">Project Search</h2></div>
        </div>
        <div class="vue-form">
            <form-select
                    field-name="searchBy"
                    v-model="searchBy"
                    label="Search By"
                    :show-label="false"
                    :options="options"
                    :show-multiselect-labels="false"
                    option-label-field="name"
                    option-value-field="value"
            ></form-select>
            <form-datepicker
                v-if="searchBy === 'site_visit_date'"
                field-name="searchByValue"
                label=""
                :show-label="false"
                v-model="query"
                ></form-datepicker>
            <form-text
                v-else
                label=""
                field-name="searchByValue"
                :show-label="false"
                v-model="query"
            ></form-text>
            <div class="control-row">
                <input type="button" value="Search" class="inputbutton1" @click="search"/>
                <input type="button" value="Reset Search" class="inputbutton1" @click="resetSearch"/>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                searchBy: null,
                options: {},
                query: null,
            }
        },

        created() {
            this.getSearchOptions();
        },

        methods: {
            getSearchOptions() {
                this.options = [
                    {
                        name: 'Project Name',
                        value: 'project_name'
                    },
                    {
                        name: 'Tool ID',
                        value: 'shared_project_id'
                    },
                    {
                        name: 'Visit Date',
                        value: 'site_visit_date'
                    },
                    {
                        name: 'Contact Name',
                        value: 'contact_name'
                    },
                    {
                        name: 'Project Address City',
                        value: 'project_address_city'
                    },
                    {
                        name: 'Project Address Zip',
                        value: 'project_address_zip',
                    },
                    {
                        name: 'Service Provider',
                        value: 'service_provider'
                    },
                    {
                        name: 'User Account',
                        value: 'user_account'
                    }
                ];


                let hasDMSCAPI = window.MAT.features.find(feature => {
                    return feature.name === 'dsmc_api' && feature.enabled;
                }) !== undefined;

                if (hasDMSCAPI) {
                    this.options.push({
                        name: 'Reservation ID',
                        value: 'reservation_id'
                    });
                }
            },
            search() {

                if(!this.query) {
                    window.notify.message('Please specify a search query', 'error');
                    return;
                }


                let search = {}
                search[this.searchBy] = this.query;
                this.$emit('search', search);
            },
            resetSearch() {
                this.searchBy = null;
                this.query = null;
                this.$emit('search', {});
            }
        }


    }
</script>
