<script>
export default {

    props: {
        applicationFormConfiguration: {
            required: true,
            type: Object
        },
        initialProjectData: {
            required: true,
            type: Object
        },
        projectId: {
            required: true,
            type: Number
        },
        phaseAvailable: {
            required: true,
            type: Boolean,
        },
        saveLabel: {
            type: String,
            default: 'Save Progress'
        },
        phase: {
            required: true,
            type: Object
        },
    },


    methods: {
        applicationSaved(updatedProject) {
            window.notify.success('Application progress was saved!', 'Saved');
            setTimeout(() => {
                if(window.Bus) {
                    window.Bus.$emit('updateChecklist');
                }
            }, 3000);
        }
    }

}
</script>
<template>
    <div class="residential-application">
        <div class="flex justify-between">
            <vue-form
                class="w-3/4 md:w-full"
                :form-config="applicationFormConfiguration"
                :form-submit-url="'/api/projects/' + projectId + '/residential_application'"
                :form-data="initialProjectData"
                :actions="[{
                    name: 'save',
                    label: applicationFormConfiguration.options?.multistepSaveLabel ?? 'Save Progress',
                    action: 'submitForm',
                }]"
                @updated="applicationSaved"
                :disabled="!phaseAvailable"
                :auto-save="true"
            ></vue-form>
            <div class="w-1/4">
                <div class="text-center">
                    <submit-phase-for-completion
                        :disabled="this.initialProjectData.phase_id !== this.phase.id"
                        :phase="phase"
                        :project="initialProjectData"
                        show-message-keyword="Inspection"
                        :label="trans('audit_tool.project_workflow.residential_application.submit')"
                        :modal-header="trans('audit_tool.project_workflow.customer_information.submit_customer_information')"
                        modal-body="If you are sure all of your information is correct please click the 'Submit' button to advance to the next phase, otherwise click 'Cancel'"
                    ></submit-phase-for-completion>
                </div>
                <div class="text-sm" v-html="trans('audit_tool.project_workflow.residential_application.cannot_submit_help_text')">

                </div>
                <div class="my-2 text-bold">
                    &nbsp;<sup><span class="text-red">(*)</span></sup> - Indicates required field
                </div>
                <project-checklist
                    :phase="phase"
                    :project="initialProjectData"
                    :model="initialProjectData"
                    model-type="project"
                    checklist-configuration-type="application_submission"

                ></project-checklist>
            </div>
        </div>
    </div>
</template>
