<template>
    <div>
        <div class="text-right">
            <font-awesome-icon :icon="icons.refresh" :spin="spinRefresh" @click="refreshRecords"></font-awesome-icon>
        </div>
        <v-grid
            :record-url="'/api/projects/' + projectId + '/jobs'"
            record-type="job-status-row"
            :headers="headers"
            :queue-refresh.sync="queueRefresh"
            :base-record-id="projectId"
        ></v-grid>
    </div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync"
export default {
    props: {
        projectId: {
            type: Number,
            required: true
        }
    },

    components: {
        FontAwesomeIcon
    },

    data() {
        return {
            icons: {
                refresh: faSync
            },
            headers :[
                'Job ID',
                'Job Type',
                'Job Queue',
                '# Attempts',
                'Status',
                'Job Created At',
                'Job Last Updated',
                'Job Started At',
                'Job Finished At',
                'Actions'
            ],
            queueRefresh: false
        }
    },

    computed: {
        spinRefresh() {
            return this.queueRefresh === true
        }
    },

    methods: {
        refreshRecords() {
            this.queueRefresh = true;
        }
    }
}
</script>
