<template>
    <div class="qc_checklist_items">
        <div v-if="loadingProgress" class="loading-icon">
            <i aria-label="Loading" class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
        <div class="row inline-children" v-else v-for="checklistItem in checkboxList" :key="checklistItem.value">
            <div class="approvalqcstepbox repreq compreq" :class="getChecklistState(checklistItem)"></div>
            <div class="">{{ checklistItem.text}}</div>
        </div>

    </div>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    export default {

        props: {
            checkboxList: {
                required: true,
                type: Array,
            }
        },

        computed: {
            ...mapState('qc_progress', [
                'loadingProgress',
                'qc_progress'
            ])
        },

        methods: {
            getChecklistState(checklistItem) {

                if(checklistItem.automatic) {
                    return 'approvalqcstepgood';
                } else if (this.qc_progress[checklistItem.value]) {
                    return 'approvalqcstepgood';
                }


                return 'approvalqcincomplete';
            }
        }

    }
</script>
<style lang="scss">
    .qc_checklist_items {
        font-size: 0.8em;
        margin-left: 30px;
        width: 100%;
        text-align: left;

        .row {
            padding: 2px 0;
        }
    }
</style>
