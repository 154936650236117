<template>
    <div :id="mpid" class="measure_picker">
        <div class="measuretopreference"></div>
        <div id="measurepickerwrapper">
            <measure-summary></measure-summary>
            <div class="room">
                <grouped-project-measure
                        v-for="groupedProjectMeasure in groupedProjectMeasures"
                        :key="groupedProjectMeasure.name"
                        :groupedProjectMeasure="groupedProjectMeasure"></grouped-project-measure>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions, mapMutations } from 'vuex';
    import MeasurePickerArea from 'etap/mixins/measure_picker/measure_picker_area'
    export default {

        props: {
            project: {
                required: true,
                type: Object,
            },
            currentScenario: {
                required: true,
                type: Object
            },
            phase: {
                required: true,
                type: Object
            }
        },

        mixins: [
            MeasurePickerArea
        ],

        created() {

            this.$store.commit('mp_layout/defaultMeasurePicker', this.mpid);
            this.$store.commit('mp_scenarios/defaultMPScenarios', this.mpid);
            this.$store.commit('mp_project_measures/defaultMPProjectMeasures', this.mpid);
            this.updateProject({mpid: this.mpid, project: this.project});
            this.setPhase({mpid: this.mpid, phase: this.phase});
            this.updateCurrentScenario({
                mpid: this.mpid,
                scenario: this.currentScenario
            });


            this.setFeature({
                mpid: this.mpid,
                feature: 'select_measures',
                value: false
            });

            this.getGroupedMeasures({
                project_id: this.project.id,
                phase: this.phase.id,
                scenario: this.currentScenario.id
            });
        },

        computed: {
            ...mapState('qc_rollup', [
                'groupedProjectMeasures'
            ])
        },

        methods: {
            ...mapActions('qc_rollup', [
                'getGroupedMeasures'
            ]),
            ...mapMutations('mp_layout', [
                'updateProject',
                'setPhase',
                'setFeature',
            ]),
            ...mapMutations('mp_scenarios', [
                'updateCurrentScenario'
            ]),
        },

        provide() {

            let provide = {};

            Object.defineProperty(provide, 'mpid', {
                enumerable: true,
                get: () => this.mpid
            });

            return provide;
        },

    }
</script>
