
export default {

    measure_picker_scenarios: {},

    default_scenario_configuration: {
        scenarios: [],
        currentScenario: {},
    }
}
