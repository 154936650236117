'use strict'

import toastr from 'toastr';
import { isArray, merge } from 'lodash';

export default class Notifications {

    // variable declarations
    toastrOptions = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-bottom-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

    environment = 'production';

    constructor() {
        if(window.MAT.environment) {
            this.environment = window.MAT.environment;
        }
    }

    apiError(error) {
        var errorMessage, title;
        ({ errorMessage, title } = this.getApiErrorMessage(error));

        this.showMessage(errorMessage, title, 'error', this.toastrOptions);
    }

    message(message, type, title, options) {
        let messageOptions = merge(this.toastrOptions, options);
        this.showMessage(message, title, type, messageOptions);
    }

    error(message, title, options) {
        let messageOptions = merge(this.toastrOptions, options);
        this.showMessage(message, title, 'error', messageOptions);
    }

    success(message, title, options) {
        let messageOptions = merge(this.toastrOptions, options);
        this.showMessage(message, title, 'success', messageOptions);
    }

    // simple pass through method in case we need to modify show message later
    showMessage(message, title, type, options) {
        toastr.options = options;
        toastr[type](message, title);
    }



    getApiErrorMessage(error) {

        var errorMessage = 'Sorry.  Something went wrong when trying to contact the server.';
        var title = 'Error';

        if(error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors[0].id) { // parse JSON API error

            if (this.environment === 'local') {
                console.error(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }

            var message;
            return this.parseJSONAPIError(error.response.data.errors, error.response.status);
            // error.response.data.errors.forEach(error => {
            //     message = this.parseJSONAPIError(error);
            // })
            // return message;
        }


        if(error.response && error.response.status === 422) {
            title = 'Validation Error';
            errorMessage = '<p>There was an error validating your request:</p>';
            // build error message list
            if(typeof error.response.data.errors === 'object') {
                errorMessage += '<ul>';
                for( var key in error.response.data.errors) {
                    if(isArray(error.response.data.errors[key]) && error.response.data.errors.hasOwnProperty(key)) {
                        errorMessage += '<li>';
                        error.response.data.errors[key].forEach(message => {
                            errorMessage += message;
                        })
                        errorMessage += '</li>';
                    }else {
                        errorMessage += '<li>' + error.response.data.errors[key] + '</li>';
                    }
                }
                errorMessage += '</ul>';
            }


        } else if (error.response && error.response.status === 401) {
            window.location = '/login';

        } else if (error.response && error.response.status === 403) {

            title = 'Unauthorized';
            errorMessage = 'You are not authorized to perform this action';

        } else if (error.response && error.response.status === 429) {

            title = '';
            errorMessage = 'Loading: Please allow the tool a minute to process your request before you continue.'

        } else if (error.response) {
            // The request was made and the server responded with a status code
            if(this.environment === 'local') {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                errorMessage = error.response.data.message;
            }
        } else if (error.request) {
            // The request was made but no response was received
            if(this.environment === 'local') {
                console.log(error.request);
            }
            errorMessage = 'Unable to make request due to no response';
        }else {
            if(this.environment === 'local') {
                console.error(error);
            }
        }
        return {
            errorMessage,
            title
        };
    }

    parseJSONAPIError(errors, statusCode) {

        var message = {
            title: 'Error',
            errorMessage: ''
        }


        switch(statusCode) {
            case 422:

                var title = 'Validation Error';
                var errorMessage = '<p>There was an error validating your request:</p>';
                // build error message list

                errorMessage += '<ul>';
                errors.forEach(error => {

                    error.source.messages.forEach(msg => {
                        errorMessage += '<li>' + msg + '</li>';
                    })

                });
                errorMessage += '</ul>';

                message = {
                    title,
                    errorMessage
                }

                break;
            case 403:
            case 401:

                message = {
                    title: 'Unauthorized',
                    errorMessage: ''
                }

                break;
            case 429:

                message = {
                    title: '',
                    errorMessage: error.detail
                }

                break;
            case 500:

                message = {
                    title: 'Server Error',
                    errorMessage: errors[0].title
                }

        }

        return message;
    }
}
