<template>
    <div>
        <preview-pdf
            :preview-url="assessmentUrl"
            :download-url="assessmentDownloadUrl"
            :open-label="trans('audit_tool.project_workflow.pre_approval.assessment_report_preview_button',  {scenario_name: currentScenario.scenario_nickname})"
            class="mb-4"
            :disabled="!canPreviewAndGenerate"
        ></preview-pdf>
        <adobe-sign-request
            v-if="canPreviewAndGenerate && useAdobeSign"
            model-type="project"
            :model-id="project.id"
            agreement-type="program-agreement"
            :additional-args="{phase: phase.id, scenario: currentScenario.id}"
            :generate-text="trans('audit_tool.project_workflow.pre_approval.assessment_report_generate_button', {scenario_name: currentScenario.scenario_nickname})"
            :regenerate-text="trans('audit_tool.project_workflow.pre_approval.assessment_report_re_generate_button',  {scenario_name: currentScenario.scenario_nickname})"
            :sign-text="trans('audit_tool.project_workflow.pre_approval.assessment_report_sign_button',  {scenario_name: currentScenario.scenario_nickname})"
        ></adobe-sign-request>

        <div v-if="!canPreviewAndGenerate">
            <span v-html="validationMessage"></span>
        </div>
    </div>
</template>
<script>
    import ValidateAssessmentReportGeneration from 'etap/mixins/validate_assessment_report_generation.js';

    export default {
        props: {
            reportPhase: {
                type: Object,
            },
            reportScenarioId: {
                type: Number,
            },
            project: {
                required: true,
            },
            useAdobeSign: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            download: {
                type: Boolean,
                default: false,
            }
        },

        mixins: [ValidateAssessmentReportGeneration],

        data() {
            return {
                canPreviewAndGenerate: false,
            }
        },

        async created() {

            if(!this.currentScenario.id) {
                return;
            }

            try {
                await this.validateAssessmentReportGeneration();
                this.canPreviewAndGenerate = true;
            } catch(error) {
                if(error !== 'apiError') {
                    this.canPreviewAndGenerate = false;
                }
            }

            if(window.Bus) {
                Bus.$on('updateChecklist', async () => {
                    try {
                        await this.validateAssessmentReportGeneration();
                        this.canPreviewAndGenerate = true;
                    } catch(error) {
                        if(error !== 'apiError') {
                            this.canPreviewAndGenerate = false;
                        }
                    }
                });
            }
        },

        computed: {
            reportMPID() {
                if(!this.$store.state.mp_layout.assessmentMPID) {
                    return null;
                }
                return this.$store.state.mp_layout.assessmentMPID;
            },
            currentScenario() {

                if(!this.reportMPID && this.reportScenarioId) {
                    let reportScenario = this.project.scenarios.find(scenario => {
                        return scenario.id === this.reportScenarioId;
                    });

                    if(reportScenario) {
                        return reportScenario
                    } else {
                        return {
                            id: '',
                            scenario_nickname: ''
                        }
                    }

                }else if(!this.reportMPID) {
                    return {
                        id: '',
                        scenario_nickname: ''
                    }
                }

                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.$store.state.mp_layout.assessmentMPID)
            },
            phase() {
                if(!this.reportMPID) {
                    return this.reportPhase;
                }
                return this.$store.getters['mp_layout/getPhase'](this.reportMPID);
            },
            scenarioRooms() {
                if(!this.reportMPID || !this.currentScenario) {
                    return [];
                }
                return this.$store.getters['mp_project_measures/getScenarioRoomsForPhase'](this.currentScenario, this.phase)
            },
            buttonText() {
                return this.trans('audit_tool.project_workflow.pre_approval.assessment_report_generation_button', {scenario_name: this.currentScenario.scenario_nickname});
            },
            assessmentUrl() {
                return '/project/' + this.project.id + '/assessment_report?phase=' +
                    this.phase.id + '&scenario=' + this.currentScenario.id;
            },
            assessmentDownloadUrl() {
                return '/project/' + this.project.id + '/assessment_report?phase=' +
                    this.phase.id + '&scenario=' + this.currentScenario.id + '&download=1';
            },
            programTrack() {
                return this.$store.state.programTracks.find(programTrack => {
                    return programTrack.id === this.project.program_track_id;
                });
            },
            validationMessage() {
                const programTrackValidationMessageKey = 'audit_tool.project_workflow.report_validation_messages.'+ this.programTrack.name +'.assessment_report';
                const programTrackValidationMessage = this.trans(programTrackValidationMessageKey);

                if(programTrackValidationMessage !== programTrackValidationMessageKey) {
                    return programTrackValidationMessage;
                }

                return this.trans('audit_tool.project_workflow.report_validation_messages.assessment_report');
            }
        },

        watch: {
            scenarioRooms: {
                handler(newRooms) {
                    this.validateAssessmentReportGeneration().then(success => {
                        this.canPreviewAndGenerate = true;
                    }).catch( error => {
                        if(error !== 'apiError') {
                            this.canPreviewAndGenerate = false;
                        }
                    });
                },
                deep: true,
            },
            currentScenario() {
                this.validateAssessmentReportGeneration().then(success => {
                    this.canPreviewAndGenerate = true;
                }).catch( error => {
                    if(error !== 'apiError') {
                        this.canPreviewAndGenerate = false;
                    }
                });
            }
        },

        methods: {

        }
    }
</script>
