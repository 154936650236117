<template>
    <div>
        <h2>Vision Project Logs</h2>
        <div class="flex">
            <button class="inputbutton1 m-4" :disabled="generatingProjectXML" @click="addProjectToQueue">Re-queue project</button>
            <button class="inputbutton1 m-4" @click="viewProjectXML" :disabled="generatingProjectXML">
                <span v-show="!generatingProjectXML">View Project XML</span>
                <span v-show="generatingProjectXML"><fa-icon icon="spinner" :spin="true"></fa-icon>&nbsp;Generating</span>
            </button>
        </div>
        <v-grid
            :record-url="'/api/projects/' + projectId + '/vision_logs'"
            :allow-refresh="true"
            record-type="vision-log-file-row"
            :headers="['Log File Name', 'Created At', '']"
            :use-json-api="true"
            :queue-refresh.sync="queueRefresh"
            ></v-grid>
    </div>
</template>
<script>
import formatter from 'xml-formatter';
export default {

    props: {
        projectId: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            queueRefresh: false,
            generatingProjectXML: false,
        };
    },

    methods: {
        viewProjectXML() {
            this.generatingProjectXML = true;
            this.$store.dispatch('vision/universal/generateXMLForProject', this.projectId).then(response => {
                this.queueRefresh = true;
                this.generatingProjectXML = false;
            }).catch(error => {
                console.error(error);
                this.generatingProjectXML = false;
            });
        },
        addProjectToQueue() {
            this.$store.dispatch('vision/universal/addProjectToQueue', this.projectId).then(response => {
                window.notify.message('Successfully queued project', 'success');
            });
        }
    }
}
</script>
