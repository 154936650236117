import Vue from 'vue';
import axios from 'axios';

export default {
    namespaced: true,

    state: {
        groupedProjectMeasures: [],
    },

    getters: {

    },

    mutations: {
        setGroupedProjectMeasures( state, groupedProjectMeasures ) {
            state.groupedProjectMeasures = groupedProjectMeasures;
        }
    },

    actions: {

        getGroupedMeasures(context, request) {
            request.grouped = 1;
            axios.get('/api/projects/' + request.project_id + '/measures', {
                params: request
            }).then( response => {

                context.commit('setGroupedProjectMeasures', response.data);

            }).catch( error => {
                window.notify.apiError(error);
            });
        }

    },
}

