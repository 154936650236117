import Collection from 'etap/admin/classes/collection';
import User from 'etap/admin/classes/models/user';

export default {

    users: new Collection([], {model: User}),
    userSearchResults: new Collection([], {model: User}),


}
