<script>
import { FormFieldMixin, byString } from "laravel-vue-forms";
import {
    faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons"
export default {
    mixins: [FormFieldMixin],
    name: "google-map-link",
    props: {
        searchBy: {
            type: String,
            default: "address"
        },
        addressLineConfiguration: {
            type: Array,
            default() {
                return [
                    [
                        { field: "address" },
                    ],
                    [
                        { field: "address2" }
                    ],
                    [
                        { field: "city"},
                        { type: "static", text: ", "},
                        { field: "state"},
                        { type: "static", text: " "},
                        { field: "zip"}
                    ]
                ]
            }
        }
    },
    data() {
        return {
            faMapMarkerAlt
        }
    },
    computed: {
        addressLines() {
            let addressLines = [];
            this.fieldConfig.addressLineConfiguration.forEach(addressLineConfig => {
                let lineText = '';
                let showLine = false;
                addressLineConfig.forEach(addressLineConfigItem => {
                    switch(addressLineConfigItem.type) {
                        case 'static':
                            if(addressLineConfigItem.showWithLine) {
                                showLine = true;
                            }
                            lineText += addressLineConfigItem.text
                            break;
                        case 'field':
                        default:
                            if(!this.value) {
                                break;
                            }
                            const fieldValue = byString(this.value, addressLineConfigItem.field) ?? '';
                            if(fieldValue.length > 0) {
                                showLine = true;
                            }
                            lineText += fieldValue;
                    }
                });
                if(lineText.length > 0 && showLine) {
                    addressLines.push(lineText);
                }
            });
            return addressLines;
        },
        mapQueryString() {
            let searchQuery = '';
            switch(this.fieldConfig.searchBy)
            {
                case 'geolocation':
                    let latitude = this.value.latitude ?? null;
                    let longitude = this.value.longitude ?? null;
                    // if both latitude and longitude are not null, set searchQuery
                    if(latitude !== null && longitude !== null) {
                        searchQuery = latitude + ', ' + longitude;
                    }
                    break;
                case 'address':
                    let addressFields = [];
                    if(this.value.address) addressFields.push(this.value.address);
                    if(this.value.address2) addressFields.push(this.value.address2);
                    if(this.value.city) addressFields.push(this.value.city);
                    if(this.value.state) addressFields.push(this.value.state);
                    if(this.value.zip) addressFields.push(this.value.zip);
                    // if any of the address fields are not null, set searchQuery
                    if(addressFields.length !== 0) {
                        searchQuery = addressFields.join(', ');
                    }
                    break;
            }
            return searchQuery;
        }
    },
    created() {
        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name === this.fieldName) {
                    const fieldExtra = this.getFormFieldFieldExtra(field);
                    this.$set(this.fieldConfig, "searchBy", fieldExtra.searchBy ?? "address");
                    this.$set(this.fieldConfig, "addressLineConfiguration", fieldExtra.addressLines ?? this.addressLineConfiguration)
                }
            });
        } else {
            this.$set(this.fieldConfig, "searchBy", this.searchBy);
            this.$set(this.fieldConfig, "addressLineConfiguration", this.addressLineConfiguration)
        }
    },
    methods: {
        openGoogleMapLink() {
            window.open('https://maps.google.com/?q=' + this.mapQueryString)
        }
    }
};
</script>
<template>
    <div class="form-group" :id="fieldName + '-map-link-field'">
        <label class="form-control-label">
            <span v-html="fieldConfig.label"></span>
            <span
                v-if="withHelpIcon"
                :class="fieldConfig.field_extra.withIcon"
                :title="fieldConfig.field_extra.helpText"
            ></span>
        </label>
        <div class="flex items-center ml-4" v-if="addressLines.length > 0">
            <div>
                <div v-for="(addressLine, key) in addressLines" :key="key">{{ addressLine }}</div>
            </div>
            <a v-if="mapQueryString" @click.stop="openGoogleMapLink" href="#" class="ml-4">
                <font-awesome-icon
                    :icon="faMapMarkerAlt"
                    size="lg"
                    ></font-awesome-icon>
            </a>
        </div>
        <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
            {{ form.errors.get(this.fieldConfig.value_field, true) }}
        </span>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
