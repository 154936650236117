

export default {


    measure_pickers: {},


    default_measure_picker_configuration: {
        mainView: 'room-list',
        currentProjectMeasure: {},
        showingProjectMeasureDetailsForm: false,

        pickerType: 'default',

        // TODO: Merge most of this config into an overall 'measure_configuration' as defined by the program_track_project_phase config
        calculationConfiguration: {},
        features: {
            add_measures: true,
            edit_measures: true,
            delete_measures: true,
            show_scenarios: true,
            select_and_create_scenarios: true,
            show_zero_warning: false,
            update_costs: true,
            select_measures: true,
            only_selected_measures: false,
            update_room_hours: false,
            show_pm_audits: false,
            show_review_filters: false,
        },


        project: {},
        currentPhase: {},
        showProjectMeasureDetails: false,
        fullPicker: false,

        measureList: [],
        measureSummary: {
            project_bonus: 0
        },
        roomsExpanded: {},
        roomListFilters: {
            measureId: null,
            approvalState: null,
        }
    },

    assessmentMPID: null,
    editFormConfig: {},
    cachedFormConfigs: {},

}
