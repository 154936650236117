<template>
    <div class="measure-equipment">
        <table v-if="equipment.length > 0">
            <thead>
                <th>Product Name</th>
                <th>Make</th>
                <th>Model</th>
                <th>Unit Cost</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="equip in equipment" :key="equip.id">
                    <td>{{ equip.name }}</td>
                    <td>{{ equip.make }}</td>
                    <td>{{ equip.model }}</td>
                    <td>{{ equip.unit_cost }}</td>
                    <td>
                        <span v-can="{ permission: 'update', entity_type: getModelStringForType('equipment') }"
                              :id="'edit-equipment-' + equip.id"
                              class="SettingsWidget"
                              @click="editEquipment(equip)">
                            <i aria-label="Edit equipment" class="fa fa-edit"></i>
                        </span>
                        <span v-can="{ permission: 'delete', entity_type: getModelStringForType('equipment') }"
                              :id="'delete-equipment-' + equip.id"
                              class="SettingsWidget"
                              @click="deleteEquipment(equip)">
                            <i aria-label="Delete equipment" class="fa fa-times"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            There are no products associated with this measure
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {

        props: {
            equipment: {
                required: true,
                type: Array
            }
        },

        methods: {
            editEquipment(equipment) {
                let measure = this.$parent.measure;
                if(!measure.equipment_form_configuration_id) {
                    window.notify.message('There is no form configuration for this measure', 'error');
                }else {
                    this.$store.dispatch('equipment_library/editMeasureEquipment', {
                        equipment,
                        measure
                    });
                }
            },
            deleteEquipment(equipment) {
                let measure = this.$parent.measure;
                this.$store.dispatch('equipment_library/deleteMeasureEquipment', {
                    equipment,
                    measure
                })
            }
        }

    }
</script>
