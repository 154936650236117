<script>
import {faChevronRight, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
export default {
    props: {
        defaultOpen: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'right'
        },
        openClass: {
            required: true,
            type: String,
        }
    },

    data() {
        return {
            icons: {
                left: faChevronLeft,
                right: faChevronRight
            },
            isOpen: this.defaultOpen
        }
    },

    methods: {
        toggleVisibility() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>
<template>
    <div class="flex min-h-50vh" :class="isOpen ? openClass : ''">
        <div
            @click="toggleVisibility"
            class="w-4 flex flex-col justify-center mr-1 p-1 shadow rounded hover:bg-lightest-gray cursor-pointer"
        >
            <font-awesome-icon
                v-if="isOpen"
                :icon="direction === 'right' ? icons.left: icons.right"
            ></font-awesome-icon>
            <font-awesome-icon
                v-else
                :icon="direction === 'right' ? icons.right: icons.left"
            ></font-awesome-icon>
        </div>
        <div v-show="isOpen" class="w-full">
            <slot></slot>
        </div>
    </div>
</template>
