import Model from 'etap/admin/classes/models/model';

class Generic extends Model {

    defaults() {
        return {
            id: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate
        }
    }

}

export default Generic;
