<template>
    <div class="ui-accordion ui-widget ui-helper-reset">
        <accordion-item
            v-for="(group, index) in groups"
            :group="group"
            :key="group.id"
            :auto-open="index === 0"
            v-on:content-visible="updateVisibles"
            v-if="groupHasMeasureSelectionItems(group)"
        ></accordion-item>
    </div>
</template>
<script>

    export default {

        props: {
            groups: {
                type: Array,
                required: true
            },
            accordionLike: {
                default: true
            }
        },

        mounted() {
//            this.$nextTick(() => {
//                console.log('Child Length: ' + this.$children.length);
//                console.log(this.$children)
//                if(this.$children.length > 0) {
//                    console.log('updating first element');
//                    this.$children[0].contentVisible = true;
//                    console.log(this.$children[0].contentVisible);
//                }
//            })
        },

        methods: {
            updateVisibles(item) {
                if(this.accordionLike) {
                    var items = this.$children;
                    for (var i = 0; i < items.length; i++) {
                        var tempItem = items[i];
                        if (tempItem !== item && tempItem.contentVisible) tempItem.contentVisible = false;
                    }
                }
            },
            groupHasMeasureSelectionItems(group) {
                if(group.measure_selection_items && group.measure_selection_items instanceof Array && group.measure_selection_items.length > 0) {
                    return true;
                }
                return false;
            }
        }

    }
</script>
