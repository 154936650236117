<template>
    <div>
        <div class="checklist" v-if="checklistConfiguration">
            <div v-if="showTitle">
                <span>{{ checklistConfiguration.label }}</span>&nbsp;&nbsp;
                <font-awesome-icon class="cursor-pointer" :icon="icons.refresh" :spin="refreshingChecklist" @click="refreshChecklist" />
            </div>
            <ol :style="{ 'list-style-type': listStyle }">
                <li is="checklist-item"
                    v-for="checklistConfigurationItem in checklistConfiguration.checklist_configuration_items"
                    :key="checklistConfigurationItem.id"
                    :checklist-configuration-item="checklistConfigurationItem">
                </li>
            </ol>
        </div>
        <div v-else class="text-center">
            <span class="fa fa-spinner fa-spin fa-3x"></span>
        </div>

    </div>
</template>
<script>
    import { faSync} from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters, mapState } from 'vuex';
    export default {

        props: {
            phase: {
                required: true,
                type: Object
            },
            project: {
                required: true,
                type: Object
            },
            model: {
                required: true,
                type: Object
            },
            modelType: {
                required: true,
                type: String,
            },
            submitConfig: {
                type: Object
            },
            checklistConfigurationType: {
                required: true,
                type: String
            },
            showTitle: {
                type: Boolean,
                default: true,
            },
            listStyle: {
                default: 'decimal'
            }
        },

        data() {
            return {
                checklist: [],
                refreshingChecklist: false,
                icons: {
                    refresh: faSync
                }
            }
        },

        created() {

            this.getChecklistConfigurationForProject({ project: this.project, type: this.checklistConfigurationType}).then(checklistConfiguration => {

                this.getChecklistItems({model: this.model, modelType: this.modelType}).then(response => {
                    if (window.Bus) {
                        window.Bus.$emit('checklistUpdated');
                    }
                });;
                if(window.Bus) {
                    Bus.$on('updateChecklist', () => {
                        this.refreshingChecklist = true;
                        this.getChecklistItems({model: this.model, modelType: this.modelType}).then(response => {

                            if (window.Bus) {
                                window.Bus.$emit('checklistUpdated');
                            }
                            this.refreshingChecklist = false;
                        }).catch(err => {
                            this.refreshingChecklist = false;
                        });
                    });
                }

                this.$store.commit('qc_progress/setProject', this.project);
                this.$store.commit('qc_progress/setPhase', this.phase);
            });

        },

        computed: {
            projectProgramTrack() {
                return this.$store.getters.getProgramTrackForId(this.project.program_track_id);
            },
            projectProgramYear() {
                return this.$store.getters.getProgramYearForId(this.project.program_year_id);
            },
            checklistConfiguration() {

                if(!this.projectProgramTrack || !this.projectProgramYear) {
                    return null;
                }

                let config = this.$store.getters['checklists/getChecklistConfigurationForType'](
                    this.projectProgramTrack,
                    this.projectProgramYear,
                    this.checklistConfigurationType
                )

                return config;
            }
        },

        methods: {
            ...mapActions('checklists', [
                'getChecklistConfigurationForProject',
                'getChecklistItems'
            ]),
            refreshChecklist() {
                this.refreshingChecklist = true;
                this.getChecklistItems({model: this.project, modelType: 'project'}).then(response => {

                    if (window.Bus) {
                        window.Bus.$emit('checklistUpdated');
                    }
                    this.refreshingChecklist = false;
                }).catch(err => {
                    this.refreshingChecklist = false;
                });
            },
            checkIfComplete() {



                var complete = true;
                if(Array.isArray(this.checklist)) {
                    let checklistItems = map(this.checklist, 'item');

                    this.items.forEach(item => {
                        if(!checklistItems.includes(item.value)) {
                            complete = false;
                        }
                    })

                }else {
                    complete = false;
                }
                if(complete && window.Bus) {
                    Bus.$emit('checklistComplete');
                }

                if(window.Bus) {
                    Bus.$emit('checklistUpdated')
                }

            }
        }

    }
</script>
<style lang="scss" rel="stylesheet/scss">
    .checklist {

        text-align: left;

        ol {
            padding-left: 10px;
            list-style-position: inside;

        }
    }
</style>
