<template>
    <div class="qpl-lookup-field">
        <div class="form-group"
             :id="fieldName + '-qpl-lookup-field'"
             :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }">
            <div class="flex items-end justify-between" style="width:95%">
                <form-text
                    class="w-3/4 pb-1"
                    :field-name="fieldName + 'qpl-lookup-text'"
                    :label="fieldConfig.label"
                    :value="value"
                    @input="updateValue"
                    :disabled="1"
                    :find-in-form="false"
                ></form-text>
                <button
                    class="w-1/4 p-2 mb-2"
                    @click="showQplSearch = true"
                    :disabled="!qplTypeSearchForm || fieldConfig.disabled"
                >
                    <i class="fa fa-book"></i>Search&nbsp;QPL
                </button>
            </div>
            <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
                {{ form.errors.get(this.fieldConfig.value_field, true) }}
            </span>
            <div v-if="hasHelpText">
                <span v-html="fieldConfig.field_extra.helpText"></span>
            </div>

            <right-panel
                v-if="showQplSearch"
                width="80%"
                @close="showQplSearch = false"
            >
                <template #header>
                    <div class="my-4 mx-4 bg-white p-4 rounded shadow w-full">
                    <h2>QPL Lookup</h2>
                </div>
                </template>
                <template #default>
                    <div class="mx-4 bg-white rounded shadow">
                        <div class="p-2 border rounded">
                            <div>
                                <vue-form
                                    v-if="qplTypeSearchForm"
                                    :form-config="qplTypeSearchForm"
                                    :form-data="{}"
                                    :pass-thru="true"
                                    @updated="searchForQplItems"
                                    :actions="[{name: 'search', label: 'Search', action: 'submitForm'}]"
                                />
                            </div>
                        </div>
                        <div class="bg-lightest-gray p-2">
                            <v-grid
                                class="w-full"
                                v-if="qplUrl"
                                :record-url="qplUrl"
                                :record-url-params="searchParams"
                                record-type="qpl-result-row"
                                :use-json-api="true"
                                :headers="headersForTable"
                                :grid-args="{selectedQplType: selectedQplType, selectableForField: true}"
                            />
                        </div>
                    </div>
                </template>
            </right-panel>
        </div>
    </div>
</template>

<script>
import {byString, FormFieldMixin} from 'laravel-vue-forms';
import axios from 'axios';
import {mapState} from "vuex";
import isObject from "lodash/isObject";

export default {

    inject: {
        apiClient: {
            default() {
                return axios;
            }
        }
    },

    mixins: [
        FormFieldMixin
    ],

    data() {
        return {
            showQplSearch: false,
            selectedQplType: null,
            searchFormName: null,
            displayOptions: {},
            searchParams: {},
            useBaseUpdateFormData: false
        }
    },

    async created() {
        if (this.form && this.form.formConfig && Array.isArray(this.form.formConfig.fields)) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name === this.fieldName) {

                    var fieldExtra = this.getFormFieldFieldExtra(field);
                    if (typeof fieldExtra.required === 'undefined') {
                        fieldExtra.required = false;
                    }

                    if (fieldExtra.qpl_config) {
                        this.$set(this.fieldConfig, 'qpl_config', fieldExtra.qpl_config);
                    }

                    if (fieldExtra.updates && Array.isArray(fieldExtra.updates)) {
                        this.$set(this.fieldConfig, 'updates', fieldExtra.updates);
                    }

                }
            });

            if(this.fieldConfig.qpl_config?.qpl_name){
                this.selectedQplType = this.fieldConfig.qpl_config.qpl_name;

                this.searchFormName = this.selectedQplTypeConfig?.searchForm;

                if (!this.qplTypeSearchForm) {
                    await this.$store.dispatch('form_configurations/getFormConfiguration', this.searchFormName);
                }
            }
        }


    },

    computed: {
        ...mapState('equipment_library', [
            'equipmentLibraryConfiguration',
            'selectedQplRecord'
        ]),

        selectedQplTypeConfig() {
            const qplConfigs = byString(this.equipmentLibraryConfiguration, 'qpl.qpls');
            if(!qplConfigs || !Array.isArray(qplConfigs)) {
                return null;
            }

            return qplConfigs.find(qplConfig => {
                return qplConfig.name === this.selectedQplType;
            });
        },
        qplTypeSearchForm() {
            if(!this.searchFormName) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName'](
                this.searchFormName
            );
        },
        qplUrl() {
            if(!this.selectedQplType) {
                return null;
            }
            return '/api/qpl_search/' + this.selectedQplType;
        },
        headersForTable() {
            if(
                !this.selectedQplTypeConfig ||
                !this.selectedQplTypeConfig.searchDisplayFields
            ) {
                return [];
            }

            let headers = this.selectedQplTypeConfig.searchDisplayFields.map(displayField => {
                return displayField.label;
            })

            headers.push('');

            return headers;
        }
    },

    watch: {
        selectedQplRecord(newSelectedRecord) {
            this.$store.commit('equipment_library/selectQplRecord', null);
            this.showQplSearch = false;
            if(newSelectedRecord) {
                this.updateValueFromQPL(newSelectedRecord);
            }
        },

    },

    methods: {
        searchForQplItems(searchParams) {
            this.searchParams = searchParams;
        },

        updateValue(value) {
            this.form.errors.clear(this.fieldConfig.value_field);
            this.$emit('input', value);
        },

        async updateValueFromQPL(value) {

            this.form.errors.clear(this.fieldConfig.value_field);
            if (value) {
                let fieldValue = null;
                if(
                    isObject(value) &&
                    this.fieldConfig.qpl_config.field_to_store &&
                    value.hasOwnProperty(this.fieldConfig.qpl_config.field_to_store)
                ) {
                    fieldValue = value[this.fieldConfig.qpl_config.field_to_store];
                } else {
                    window.notify.error("Error with this QPL item");
                    return;
                }

                this.$emit('input', fieldValue);

                if (this.fieldConfig.updates) {
                    this.updateFieldsOnForm(value);
                }

            } else {
                this.$emit('input', null)
            }
        },

        updateFieldsOnForm(record) {

            if (!this.fieldConfig.updates) {
                return;
            }

            this.fieldConfig.updates.forEach(updateConfig => {
                if (updateConfig.errorWhenEmpty && updateConfig.errorMessage) {
                    if (!record[updateConfig.from]) {
                        window.notify.message(updateConfig.errorMessage, 'error');
                    } else {
                        this.form.data[updateConfig.to] = record[updateConfig.from];
                    }
                } else {
                    this.form.data[updateConfig.to] = record[updateConfig.from];
                }
            });
        }
    }

}
</script>
