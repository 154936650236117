export default {


    methods: {
        trans(message, replacements) {
            if(typeof replacements === "undefined") {
                return window.Lang.get(message);
            }else {
                return window.Lang.get(message, replacements)
            }
        }
    }

}
