<template>
    <div>
        <div v-if="signingStatus == 'finished'">
            <button class="inputbutton1 grid-row-button my-2" @click.prevent="signDocument">{{ signText }}</button><br />
            <button class="inputbutton1 grid-row-button my-2" @click.prevent="generateSignAssessment">{{ regenerateText }}</button>
        </div>
        <div v-else>
            <button class="inputbutton1 my-2" @click.prevent="generateSignAssessment" :disabled="signingStatus == 'executing'">
                <span v-if="signingStatus == 'executing'"><span class="fa fa-spinner fa-spin"></span>&nbsp;{{ generatingText }}</span>
                <span v-else>{{ generateText }}</span>
            </button>
        </div>
    </div>
</template>
<script>

    import axios from 'axios';
    export default {


        props: {
            modelType: {
                required: true,
                type: String
            },
            modelId: {
                required: true,
                type: Number,
            },
            agreementType: {
                required: true,
                type: String,
            },
            additionalArgs: {
                type: Object,
                default() {
                    return {};
                }
            },
            generateText: {
                type: String,
                default: 'Generate Agreement',
            },
            generatingText: {
                type: String,
                default: 'Generating...'
            },
            signText: {
                type: String,
                default: 'Sign Agreement'
            },
            regenerateText: {
                type: String,
                default: 'Re-generate Agreement'
            },
            api: {
                default() {
                    return axios;
                }
            }
        },


        data() {
            return {
                signingStatus: 'not-started',
                signingURL: '',
                code: '',
                intervalHandle: null,
            }
        },
        created() {
            this.getSigningStatus();

            if(window.Bus) {
                Bus.$on('updateAdobeSignStatus', () => {
                    this.getSigningStatus()
                })
            }
        },

        methods: {
            getSigningStatus() {

                var queryParams = this.additionalArgs;
                queryParams.type = this.agreementType;

                this.api.get('/api/adobe_sign/' + this.modelType + '/' + this.modelId, {
                    params: queryParams
                }).then(response => {
                    this.signingStatus = response.data.status;
                    this.signingURL = response.data.signing_url;

                    if (this.signingStatus == 'failed' || this.signingStatus == 'cancelled') {
                        this.clearInterval()
                    } else if (this.signingStatus == 'signed' || this.signingStatus === 'complete' || this.signingStatus === 'finished') {
                        if (window.Bus) {
                            setTimeout(() => {
                                Bus.$emit('updateFiles', 'signed-program-form');
                                Bus.$emit('updateChecklist');
                            }, 4000);
                        }
                        this.clearInterval();
                    } else if (this.signingStatus !== 'not-started') {
                        this.startInterval();
                    }

                }).catch(error => {
                    window.notify.apiError(error);
                });
            },
            generateSignAssessment() {

                var params = this.additionalArgs;
                params.type = this.agreementType;
                this.signingStatus = 'executing';

                this.api.post('/api/adobe_sign/' + this.modelType + '/' + this.modelId, params).then(response => {
                    this.startInterval();
                }).catch(error => {
                    this.signingStatus = 'not-started';
                    window.notify.apiError(error);
                });

            },
            signDocument() {
                window.signingWindow = window.open(this.signingURL);
            },
            startInterval() {
                if (this.intervalHandle === null) {
                    this.intervalHandle = setInterval(this.getSigningStatus, 5000);
                }
            },
            clearInterval() {
                window.clearInterval(this.intervalHandle);
                this.intervalHandle = null;
            }
        }

    }

</script>
