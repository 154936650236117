<template>
    <div>
        <div class="form-check form-check-inline">
            <input class="form-check-input"
                   type="checkbox" :id="fieldConfig.fieldName + '-checkbox'"
                   v-model="checked"
                   :true-value="fieldConfig.trueValue"
                   :false-value="fieldConfig.falseValue"
                   :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
            >
            <label class="form-check-label" :for="fieldConfig.fieldName + '-checkbox'"><span v-html="fieldConfig.label"></span></label>
        </div>
    </div>
</template>
<script>
import { FormFieldMixin } from 'laravel-vue-forms';
export default {

    mixins: [FormFieldMixin],
    name: 'conditioned-form-checkbox',

    props: {
        trueValue: {
            default() {
                return true;
            }
        },
        falseValue: {
            default() {
                return false;
            }
        }
    },

    data() {
        return {
            watcher: null,
            checked: false,
            conditionedFields: {}
        }
    },

    watch: {
        value(newValue) {
            this.watcher();
            if(newValue === this.fieldConfig.trueValue) {
                this.checked = this.fieldConfig.trueValue;
            } else {
                this.checked = this.fieldConfig.falseValue;
            }
            this.setupWatcher();
        }
    },

    created() {

        if(this.form && this.form.formConfig &&
            (
                Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] === 'function'
            )
        ) {
            this.form.formConfig.fields.forEach(field => {
                if(field.name === this.fieldName) {
                    var fieldExtra = this.getFormFieldFieldExtra(field);

                    this.$set(this.fieldConfig, 'trueValue', true);
                    if(fieldExtra.trueValue) {
                        this.$set(this.fieldConfig, 'trueValue', fieldExtra.trueValue);
                    }

                    this.$set(this.fieldConfig, 'falseValue', false);
                    if(typeof fieldExtra.falseValue !== 'undefined') {
                        this.$set(this.fieldConfig, 'falseValue', fieldExtra.falseValue);
                    }
                }
            });

        }else {
            this.$set(this.fieldConfig, 'trueValue', this.trueValue);
            this.$set(this.fieldConfig, 'falseValue', this.falseValue);
        }

        if(typeof this.value !== 'undefined' && this.value !== null) {
            this.checked = this.value;
        } else {
            this.checked = this.fieldConfig.falseValue;
            this.$emit('input', this.fieldConfig.falseValue);
        }

        this.setupWatcher();
    },

    methods: {
        setupWatcher() {
            this.watcher = this.$watch('checked', (newChecked) => {

                var fieldsConditionedBy = this.form.formConfig.fields.filter(field => {
                    return Object.getFormValueByString(field.field_extra, 'condition.valueField') === this.fieldConfig.value_field;
                });

                if(newChecked === this.fieldConfig.trueValue) {
                    this.$emit('input', this.fieldConfig.trueValue);

                    // get old value and set form fields to null that have condition property
                    fieldsConditionedBy.forEach(field => {
                        Vue.set(this.conditionedFields, field.name, Object.getFormValueByString(this.form.data, field.value_field));
                        Object.assignFormValueByString(this.form.data, field.value_field, null);
                    });
                }else {
                    this.$emit('input', this.fieldConfig.falseValue);

                    // set form fields old value that have condition property
                    fieldsConditionedBy.forEach(field => {

                        if(!this.conditionedFields.hasOwnProperty(field.name)) {
                            return;
                        }

                        Object.assignFormValueByString(this.form.data, field.value_field, this.conditionedFields[field.name]);
                        Vue.delete(this.conditionedFields, field.name);
                    });
                }
            })
        }
    }
}
</script>
