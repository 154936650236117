<template>
    <div class="generate_invoices">
        <div>
            <button @click="openGenerationWindow" :disabled="disabled" class="inputbutton1">{{ buttonText }}</button>
            <p class="littlenote" v-if="underButtonText">{{ underButtonText }}</p>
        </div>

        <modal
            v-if="showInvoiceNumberModal"
            modal-width="500px"
            @close="showInvoiceNumberModal = false"
        >

            <h4 slot="header">
                Generate Invoice
            </h4>

            <div slot="body">

                <div class="form-group">
                    <label>Invoice Number</label>
                    <input type="text" class="form-control" v-model="invoice_number" />
                </div>
                <div class="form-group inline-children">
                    <button class="inputbutton1" @click="viewInvoice">View</button>&nbsp;&nbsp;
                    <button class="inputbutton1" @click="attachInvoice" v-if="!download">Attach</button>
                </div>
            </div>

        </modal>

    </div>
</template>
<script>
    import { snakeToTitleCase } from 'etap/filters/snake_to_title_case';
    export default {

        props: {
            phase: {
                required: true,
                type: Object,
            },
            project: {
                required: true,
                type: Object,
            },
            invoice_type: {
                required: true,
                type: String,
            },
            optional: {
                type: Boolean,
                default: true,
            },
            download: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
                invoice_number: this.project.shared_project_id,
                showInvoiceNumberModal: false,
            }
        },

        filters: {
            snakeToTitleCase
        },

        computed: {
            buttonText: function() {
                const key = 'audit_tool.project_workflow.'+ this.phase.name +'.'+ this.invoice_type +'_report_button_name'
                const buttonTextTrans = this.trans(key, {
                    'invoice_type': snakeToTitleCase(this.invoice_type)
                })

                if(buttonTextTrans !== key) {
                    return buttonTextTrans
                }

                return 'Generate ' + snakeToTitleCase(this.invoice_type) + (this.optional ? ' (Optional)' : '')
            },
            underButtonText: function() {
                const key = 'audit_tool.project_workflow.'+ this.phase.name +'.'+ this.invoice_type +'_under_button_text'
                const underButtonTextTrans = this.trans(key)

                if(underButtonTextTrans !== key) {
                    return underButtonTextTrans
                }

                return (this.optional ? 'May use your own template' : null)
            }
        },

        methods: {
            openGenerationWindow() {
                this.showInvoiceNumberModal = true;
            },
            viewInvoice() {

                if(this.validateInvoiceNumber()) {
                    this.showInvoiceNumberModal = false;

                    let url = '/project/' +
                        this.project.id +
                        '/generate_invoice?' +
                        'action=view' +
                        '&invoice_type=' + this.invoice_type +
                        '&invoice_number=' + this.invoice_number;

                    if(this.download) {
                        window.location = url + '&download=1';
                    }else {
                        window.open( url );
                    }
                    this.invoice_number = this.project.shared_project_id;
                }

            },
            attachInvoice() {
                var vm = this;
                if(!this.download && this.validateInvoiceNumber()) {

                    axios.post('/api/projects/' + this.project.id + '/generate_invoice', {
                        action: 'attach',
                        invoice_type: this.invoice_type,
                        invoice_number: this.invoice_number
                    }).then( response => {

                        this.showInvoiceNumberModal = false;
                        this.invoice_number = this.project.shared_project_id;

                        if(window.Bus) {
                            setTimeout(function() {
                                window.Bus.$emit('updateFiles', vm.invoice_type.replace(new RegExp('_', 'g'), '-'));
                                window.Bus.$emit('updateChecklist');
                            }, 1300)
                        }


                    }).catch( error => {
                        window.notify.apiError(error);
                    })

                }


            },
            validateInvoiceNumber() {

                if(this.invoice_number) {
                    return true;
                }

                window.notify.message('Please enter an invoice number to generate the ' + snakeToTitleCase(this.invoice_type) +'.', 'error');
                return false;
            }
        }

    }
</script>
