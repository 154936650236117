

export const getLeadForProject = state => (projectId) => {
    return state.project_lead;
}

export const getUnselectedScenariosForProject =  (state, getters, rootState, rootGetters)  => {
    let scenarios = rootGetters['mp_scenarios/getScenarios'](rootState.mp_layout.assessmentMPID);

    scenarios = scenarios.filter(function(scenario) {
        return !scenario.selected;
    }) ;

    return scenarios;
}

export const getAllScenariosForProject =  (state, getters, rootState, rootGetters)  => {
    let scenarios = rootGetters['mp_scenarios/getScenarios'](rootState.mp_layout.assessmentMPID);

    return scenarios;
}
