import apiClient from 'etap/admin/apiClient';

export default {
    namespaced: true,
    state: {},
    actions: {
        generateXMLForProject(context, projectId) {
            return new Promise((resolve, reject) => {
                apiClient.post('/api/projects/' + projectId + '/vision/generate').then(response => {
                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });
            });
        },
        addProjectToQueue(context, projectId) {
            return new Promise((resolve, reject) => {
                apiClient.post('/api/projects/' + projectId + '/vision/queue').then(response => {
                    resolve(true);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });
            });
        }
    },
    mutations: {}
}
