<template>
    <div class="flex">
        <vue-form
            class="col-md-9"
            :form-config="customerInformationFormConfiguration"
            :form-data="originalProjectData"
            :form-submit-url="'/api/projects/' + projectId + '/customer_information'"
            @updated="updateSuccess"
            @changed="updateCurrentProjectData"
            :actions="formActions"
            :disabled="!phaseAvailable"
        ></vue-form>


        <div class="col-md-3 mt-4">
            <div class="text-center">
                <submit-phase-for-completion
                    v-if="displaySubmitBtn"
                    :disabled="this.initialProjectData.phase_id !== this.customerInformationPhase.id"
                    :phase="customerInformationPhase"
                    :project="currentProjectData"
                    show-message-keyword="Inspection"
                    :label="trans('audit_tool.project_workflow.customer_information.submit_customer_information')"
                    :modal-header="trans('audit_tool.project_workflow.customer_information.submit_customer_information')"
                    modal-body="If you are sure all of your information is correct please click the 'Submit' button to advance to the next phase, otherwise click 'Cancel'"
                ></submit-phase-for-completion>
            </div>
            <div class="panel">
                <div id="customerinfo-directions" class="panel-body">
                    <div class="custinfocompletewrapper">
                        <div class="text-center">Step Progress</div>
                        <div class="maxwidth">
                            <div id="custinfocompletebarbg" class="maxwidth">
                                <div id="custinfocompletebar" class="custinfocompletebar" :style="{ width: stepProgress + '%'}"></div>
                            </div>
                            <div class="littlenote text-left my-2">
                                <p v-for="remainingField in remainingFieldsRequiredForCompletion" :key="remainingField.value_fields">
                                    {{ remainingField.label }} is required for completion
                                </p>
                            </div>

                            <hr class="mb-2" v-if="remainingFieldsRequiredForCompletion.length > 0 && remainingFieldsRequiredForProjectSubmission.length > 0" />

                            <div class="littlenote text-left mt-2">
                                <p v-for="remainingField in remainingFieldsRequiredForProjectSubmission" :key="remainingField.value_fields">
                                    {{ remainingField.label }} is required for project submission
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    export default {

        props: {
            customerInformationFormConfiguration: {
                required: true,
                type: Object
            },
            initialProjectData: {
                required: true,
                type: Object
            },
            projectId: {
                required: true,
                type: Number
            },
            phaseAvailable: {
                required: true,
                type: Boolean,
            },
            saveLabel: {
                type: String,
                default: 'Save Information'
            },
            displaySubmitBtn: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                formActions: [
                    {
                        label: this.saveLabel,
                        name: 'submit',
                        action: 'submitForm'
                    }
                ]
            }
        },

        created() {
            this.setOriginalProjectData(this.initialProjectData);
            this.setCurrentProjectData(this.initialProjectData);
        },

        computed: {
            ...mapState('customer_information', [
                'originalProjectData',
                'currentProjectData'
            ]),
            fieldsRequiredForCompletion() {
                return this.customerInformationFormConfiguration.fields.filter(field => {
                    return field.field_extra.requiredForCompletion;
                });
            },
            fieldsRequiredForProjectSubmission() {
                return this.customerInformationFormConfiguration.fields.filter(field => {
                    return field.field_extra.requiredForProjectSubmission;
                });
            },
            remainingFieldsRequiredForCompletion() {
                return this.fieldsRequiredForCompletion.filter(field => {

                    let fieldValue = Object.getFormValueByString(this.currentProjectData, field.value_field);
                    if(fieldValue === null) {
                        return true;
                    }

                    switch(typeof fieldValue) {
                        case "object":
                            if(Array.isArray(fieldValue)) {
                                return fieldValue.length === 0;
                            }

                            return false;
                            break;
                    }
                });
            },
            remainingFieldsRequiredForProjectSubmission() {
                return this.fieldsRequiredForProjectSubmission.filter(field => {

                    let fieldValue = Object.getFormValueByString(this.currentProjectData, field.value_field);
                    if(fieldValue === null || fieldValue === '') {
                        return true;
                    }

                    switch(typeof fieldValue) {
                        case "object":
                            if(Array.isArray(fieldValue)) {
                                return fieldValue.length === 0;
                            }

                            return false;
                            break;
                    }
                });
            },
            stepProgress() {
                return (1- this.remainingFieldsRequiredForCompletion.length/this.fieldsRequiredForCompletion.length) * 100;
            },
            customerInformationPhase() {
                return this.$store.getters.getProjectPhaseForName('customer_information');
            }
        },

        watch: {
            originalProjectData(newData, oldData) {
                if(newData !== null && oldData !== null && newData.phase_id !== oldData.phase_id) {
                    this.updateSuccess(newData);
                }
            }
        },

        methods: {
            ...mapMutations('customer_information', [
                'setOriginalProjectData',
                'setCurrentProjectData',
            ]),
            updateSuccess(updatedInformation) {
                this.setOriginalProjectData(updatedInformation);
                this.setCurrentProjectData(updatedInformation);

                if(this.initialProjectData.phase_id === this.customerInformationPhase.id && this.currentProjectData.phase_id !== this.customerInformationPhase.id) {
                    window.location.reload();
                } else if(
                    this.initialProjectData.phase_id === this.customerInformationPhase.id &&
                    this.displaySubmitBtn &&
                    this.stepProgress === 100
                ) {
                    if(window.Bus) {
                        window.Bus.$emit('checklistUpdated');
                    } else {
                        window.location.reload();
                    }
                }

                window.notify.message('Successfully saved customer information', 'success');
            },
            updateCurrentProjectData(updatedData) {
                // remove object reference so that vuex doesn't get in the way
                updatedData = JSON.parse(JSON.stringify(updatedData));
                this.setCurrentProjectData(updatedData);
            }
        }

    }

</script>
