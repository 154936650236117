<template>
    <div id="projectstatsbox">
        <div class="flex items-center">
            <div class="projectstatsheaderelement">
                <div class="statsicon"></div>
            </div>
            <div class="projectstatsheaderelement"><h2>Your {{ programTrack.title }} Projects</h2></div>
        </div>


        <div id="projectstatswrapper">
            <div>
                <form-select
                    label="Program Year"
                    :options="selectableProgramYearsForUser"
                    :field-name="'selectable-program-years-' + programTrack.name"
                    v-model="selectedProgramYearId"
                    option-label-field="program_year"
                    option-value-field="id"
                    :show-label="false"
                    :allow-empty="false"
                    :show-multiselect-labels="false"
                ></form-select>
            </div>
            <div id="projectcountrow" v-if="!loadingStats && project_stats">
                <h3>Number of Projects: {{ project_stats.project_count }}</h3>
            </div>

            <div v-if="!loadingStats && project_stats" class="flex flex-col">
                <div
                    class="statsbargraphtitlewrapper"
                    v-for="(projectPhase, index) in visibleProjectPhasesForProgramTrack"
                >
                    <div>
                        <div class="statsbackground">
                            {{ index+1}}
                        </div>
                        <div class="statsbargraphtitle">
                            {{ projectPhase.title }}
                        </div>
                    </div>
                    <div class="bargraphWrapper flex">
                        <div class="barform"
                             :style="'width: ' + (computedStatWidths[projectPhase.name].completed - 0.2) + '%'"
                             @click="searchBy(projectPhase, 'complete')"
                        >
                            <div class="bar colorcomplete"></div>
                        </div>
                        <div class="barform"
                             :style="'width: ' + (computedStatWidths[projectPhase.name].ready - 0.2) + '%'"
                             @click="searchBy(projectPhase, 'ready')"
                        >
                            <div class="bar colorready"></div>
                        </div>
                        <div class="barform"
                             :style="'width: ' + (computedStatWidths[projectPhase.name].locked - 0.2) + '%'"
                             @click="searchBy(projectPhase, 'locked')"
                        >
                            <div class="bar colorlocked"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="keyrow" v-if="!loadingStats && project_stats">
                <div class="keybox colorlocked">&nbsp;</div>
                <div class="keytext">Not Ready</div>
                <span class="keyspacer">&nbsp;</span>
                <div class="keybox colorready">&nbsp;</div>
                <div class="keytext">Ready</div>
                <span class="keyspacer">&nbsp;</span>
                <div class="keybox colorcomplete">&nbsp;</div>
                <div class="keytext">Complete</div>
            </div>


            <div class="project_stats_loading flex items-center justify-center text-dark" v-else-if="loadingStats">
                <span class="fa fa-spinner fa-spin fa-2x"></span>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            programTrack: {
                required: true,
                type: Object
            }
        },

        data() {
            return {
                loadingStats: false,
                project_stats: null,
                selectedProgramYearId: null,
            }
        },

        computed: {
            programYears() {
                return this.$store.getters.programYears;
            },
            validTracksForUser() {
                return this.$store.getters.validTracksForUser;
            },
            selectableProgramYearsForUser() {

                return this.validTracksForUser.filter(track => {
                    return track.name === this.programTrack.name
                }).map(track => {
                    return this.programYears.find(year => {
                        return year.id === track.pivot.program_year_id;
                    });
                })

            },
            projectPhasesForSelectedProgramYear() {
                return this.$store.state.projectPhases;
            },
            visibleProjectPhasesForProgramTrack() {
                return this.programTrack.project_phases.filter(projectPhase => {
                    return projectPhase.config.hidden === 0 && projectPhase.config.program_year_id === this.selectedProgramYearId;
                });
            },
            computedStatWidths() {
                var statWidths = {};
                this.visibleProjectPhasesForProgramTrack.forEach(visiblePhase => {

                    let visiblePhaseName = visiblePhase.name;

                    if(!this.project_stats[visiblePhaseName]) {
                        statWidths[visiblePhaseName] = {
                            locked: 100,
                            ready: 0,
                            complete: 0
                        };
                        return;
                    }

                    let phaseTotal = this.project_stats[visiblePhaseName].completed +
                        this.project_stats[visiblePhaseName].locked +
                        this.project_stats[visiblePhaseName].ready;

                    if(phaseTotal === 0) {
                        statWidths[visiblePhaseName] = {
                            locked: 100,
                            ready: 0,
                            complete: 0
                        };
                        return;
                    }
                    statWidths[visiblePhaseName] = {};
                    statWidths[visiblePhaseName].locked = this.project_stats[visiblePhaseName].locked / phaseTotal * 100;
                    statWidths[visiblePhaseName].ready = this.project_stats[visiblePhaseName].ready / phaseTotal * 100;
                    statWidths[visiblePhaseName].completed = this.project_stats[visiblePhaseName].completed / phaseTotal * 100;

                });
                return statWidths;
            }
        },

        created() {

            if(Array.isArray(this.selectableProgramYearsForUser) || this.selectableProgramYearsForUser.length > 0) {
                this.selectedProgramYearId = this.selectableProgramYearsForUser.sort((first, second) => {
                    return first.valid_to > second.valid_to;
                })[this.selectableProgramYearsForUser.length -1].id;
            }

            this.getProjectStats();
        },

        methods: {
            getProjectStats() {
                this.loadingStats = true;
                axios.get('/api/projects/stats', {
                    params: {
                        program_year_id: this.selectedProgramYearId,
                        program_track_id: this.programTrack.id
                    }
                }).then(response => {
                    this.loadingStats = false;
                    this.project_stats = response.data;
                }).catch(error => {
                    this.loadingSTats = false;
                    window.notify.apiError(error);
                });
            },
            searchBy(projectPhase, phaseStatus) {
                this.$emit('search-by', {
                    projectPhase: projectPhase.name,
                    projectPhaseStatus: phaseStatus,
                    program_track_id: this.programTrack.id
                });
            }
        },

        watch: {
            selectedProgramYearId(newProgramYearId) {
                this.getProjectStats();
            }
        }
    }
</script>
