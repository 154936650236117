<script>

export default {
    props: {
        gridType: {
            type: String,
            default: 'table'
        },
        rows: {
            type: Number,
            default: 3,
        },
        columns: {
            type: Number,
            default: 3
        },
        showButton: {
            type: Boolean,
            default: false
        }
    }
}

</script>
<template>
    <div>
        <div v-if="gridType === 'table'">
            <div class="flex justify-end" v-if="showButton">
                <div class="skeleton skeleton-table-item">&nbsp;</div>
            </div>
            <div class="skeleton-table-row flex flex-row  justify-between" v-for="i in rows">
                <div class="skeleton skeleton-table-item" :class="columns === 1 ? 'w-full' : 'w-1/' + columns" v-for="i in columns">&nbsp;</div>
            </div>
        </div>
        <div v-else-if="gridType == 'div'">
            <div class="flex justify-end mb-2" v-if="showButton">
                <div class="skeleton skeleton-table-item">&nbsp;</div>
            </div>
            <div class="skeleton-table-row flex justify-between h-32 w-full mb-4" v-for="i in rows">
                <div class="w-1/2 mx-2">
                    <div class="w-full my-1">
                        <div class="skeleton skeleton-table-item w-1/3 my-2">&nbsp;</div>
                        <div class="skeleton skeleton-table-item w-full my-2" >&nbsp;</div>
                    </div>
                    <div class="w-full my-1">
                        <div class="skeleton skeleton-table-item w-1/3">&nbsp;</div>
                        <div class="skeleton skeleton-table-item w-full my-2" >&nbsp;</div>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="w-full my-1">
                        <div class="skeleton skeleton-table-item w-1/3 my-2">&nbsp;</div>
                        <div class="skeleton skeleton-table-item w-full my-2" >&nbsp;</div>
                    </div>
                    <div class="w-full my-1">
                        <div class="skeleton skeleton-table-item w-1/3">&nbsp;</div>
                        <div class="skeleton skeleton-table-item w-full my-2" >&nbsp;</div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
