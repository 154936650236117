<template>
    <tr>
        <td>{{ record.job_id}}</td>
        <td>{{ type }}</td>
        <td>{{ record.queue }}</td>
        <td>{{ record.attempts }}</td>
        <td>{{ record.status }}</td>
        <td>{{ created }}</td>
        <td>{{ updated }}</td>
        <td>{{ started }}</td>
        <td>{{ finished }}</td>
        <td >
            <div class="flex justify-between" v-if="!loading">
                <font-awesome-icon :icon="icons.eye" class="mx-2" @click="getJobInformation" v-if="canViewDetails"></font-awesome-icon>
                <font-awesome-icon :icon="icons.delete" class="mx-2" v-if="canCancel" @click="cancelJob"></font-awesome-icon>
            </div>
            <div v-if="loading">
                <font-awesome-icon :icon="icons.spinner" class="mx-2" :spin="true"></font-awesome-icon>
            </div>
            <modal
                v-if="jobRecord"
                v-show="showDetails"
                @close="showDetails = false"
                modal-width="50%"
            >
                <h2 slot="header">Job Details</h2>
                <div slot="body">

                   <div class="flex justify-between">
                       <span ref="input">Input: <br/></span>
                       <span ref="output">Output: <br /></span>
                   </div>

                </div>
            ></modal>
        </td>
    </tr>
</template>
<script>
import moment from "moment";
import JSONFormatter from 'json-formatter-js';
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
export default {

    props: {
        record: {
            required: true,
            type: Object
        },
        baseRecordId: {
            required: true,
            type: Number,
        }
    },

    components: {
        FontAwesomeIcon
    },

    data() {
        return {
            icons: {
                eye: faEye,
                delete: faTimes,
                spinner: faSpinner
            },
            showDetails: false,
            loading: false,
        }
    },

    computed: {
        type() {
            let typeParts =  this.record.type.split('\\');
            return typeParts.pop();
        },
        created() {
            return moment(moment.utc(this.record.created_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
        updated() {
            return moment(moment.utc(this.record.updated_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
        started() {

            if(!this.record.started_at) {
                return null;
            }

            return moment(moment.utc(this.record.started_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
        finished() {

            if(!this.record.finished_at) {
                return null;
            }

            return moment(moment.utc(this.record.finished_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
        canViewDetails() {
            return window.MAT.permissions.findIndex(perm => perm.name === 'view_project_job_details') !== -1;
        },
        canCancel() {
            let hasPermission = window.MAT.permissions.findIndex(perm => perm.name === 'cancel_project_job') !== -1
            return hasPermission && (this.record.status === 'executing' || this.record.status === 'queued');
        },
        jobRecord() {
            return this.$store.state.jobs.jobs[this.record.id];
        }
    },

    watch: {
        jobRecord(newValue, oldValue) {
            if(newValue && !oldValue) {

                this.$nextTick(() => {
                    const inputFormatter = new JSONFormatter(newValue.input);
                    this.$refs['input'].appendChild(inputFormatter.render());

                    const outputFormatter = new JSONFormatter(newValue.output);
                    this.$refs['output'].appendChild(outputFormatter.render());
                })
            }
        }
    },

    methods: {
        getJobInformation() {
            this.loading = true;
            this.$store.dispatch("jobs/getJobForId", {
                projectId: this.baseRecordId,
                jobId: this.record.id
            }).then(jobRecord => {
                this.loading = false;
                let vm = this;
                setTimeout(() => {
                    vm.showDetails = true;
                }, 400)
            }).catch(error => {
                this.loading = false;
                throw new Error(error);
            });
        },
        cancelJob() {
            this.loading = true;
            this.$store.dispatch("jobs/cancelJob", {
                projectId: this.baseRecordId,
                jobId: this.record.id
            }).then(jobRecord => {
                this.loading = false;
                window.notify.message("Successfully cancelled job.", "success");
                let vm = this;
                setTimeout(() => {
                    this.$emit("refreshRecords");
                }, 200)
            }).catch(error => {
                this.loading = false;
                throw new Error(error);
            });
        }
    }
}
</script>
