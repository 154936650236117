<template>
    <div class="text-sm">
        <h3 class="ui-accordion-header ui-state-default ui-corner-top ui-accordion-icons"
            :class="{ 'ui-accordion-header-active ui-state-active' : contentVisible}"
            @click="toggleContent"
        >
            <span class="ui-accordion-header-icon ui-icon"
                  :class="{ 'ui-icon-triangle-1-s' : contentVisible, 'ui-icon-triangle-1-e' : contentVisible == false}"
            ></span>
            {{ group.name }}
        </h3>
        <transition
            name="slide-down"
        >
            <div class="ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom p-0 w-300"
                v-show="contentVisible"
            >
                <measure-selection-item
                    v-for="measure_selection_item in group.measure_selection_items"
                    :measure_selection_item="measure_selection_item"
                    :key="measure_selection_item.id"
                ></measure-selection-item>
            </div>
        </transition>
    </div>
</template>
<script>

    import {TweenMax} from "gsap";
    export default {

        props: ['group', 'autoOpen'],

        data() {
            return {
                contentVisible:false
            }
        },

        created() {
            if(this.autoOpen) {
                this.$nextTick( () => {
                    this.contentVisible = true;
                });
            }
        },

        mounted() {
            // stopgap for now
            $(".measurelistdiv").css("padding-right", "0px");
            $(".measurelistdiv").css("padding-left", "5px");
            $(".measurelistdiv").css("padding-top", "5px");
            $(".measurelistdiv").css("padding-bottom", "5px");
            $(".measurelist div").addClass("ui-widget ui-widget-content");

        },

        methods: {
            toggleContent() {
                this.contentVisible = !this.contentVisible;
                if (this.contentVisible) this.$emit('content-visible', this);
            },
        }
    }
</script>
<style>
    .slide-down-enter-active, .slide-down-leave-active {
        transition: max-height 0.5s;
    }
    .slide-down-enter, .slide-down-leave-to {
        max-height: 0;
    }

</style>
