<template>
    <div id="header-drop-menu" v-on-clickaway="closeProfile">
        <div class="user-settings relative outline-none">
            <button class="headerbutton shadow-inner flex align-center text-right items-center" :class="buttonColor" @click="profileOpen = !profileOpen">
                <div class="flex flex-col px-2">
                    <span class="text-sm">{{ firstName }}&nbsp;{{ lastName }}</span>
                    <span class="text-xs">{{ username }}</span>
                </div>

                <div class="ml-2 fa fa-caret-down"></div>
            </button>
            <transition
                enter-active-class="animated faster fadeInDown"
                leave-active-class="animated faster fadeOut"
            >
                <div class="rounded shadow-md absolute mt-16 pin-t pin-l min-w-full z-10" v-if="profileOpen" >
                    <ul class="list-reset">
                        <li><a href="/settings" class="nav-link-item">Settings</a></li>
                        <li><a href="/energy_dashboard" class="nav-link-item" v-if="dashboardEnabled">Energy Dashboard</a></li>
                        <li><a href="/esuite/dashboard" class="nav-link-item" v-if="canAccessAdmin">eSuite Dashboard</a></li>
                        <li class="bg-grey-lighter px-4"><hr class="border-t m-0 border-grey-light"></li>
                        <li>
                            <a href="#" class="nav-link-item" @click="logout">
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
        <div class="user-settings-compact box-shadow header-icon">
            <button
                class="w-full fa fa-user text-white items-center justify-center"
                :class="buttonColor"
                @click="profileOpen = !profileOpen"
            ></button>
            <transition
                enter-active-class="animated faster fadeInDown"
                leave-active-class="animated faster fadeOut"
            >
                <div class="user-settings-compact-menu rounded shadow-md absolute z-10" v-if="profileOpen">
                    <div class="flex flex-col px-2 nav-link-item nav-link-item-user">
                        <span>Hello, {{ firstName }}&nbsp;{{ lastName }}</span>
                    </div>
                    <hr class="border-t m-0 border-grey-light">
                    <ul class="list-reset">
                        <li><a href="/settings" class="nav-link-item">Settings</a></li>
                        <li><a href="/energy_dashboard" class="nav-link-item" v-if="dashboardEnabled">Energy Dashboard</a></li>
                        <li><a href="/esuite/dashboard" class="nav-link-item" v-if="canAccessAdmin">eSuite Dashboard</a></li>
                        <li><hr class="border-t m-0 border-grey-light"></li>
                        <li>
                            <a href="#" class="nav-link-item" @click="logout">
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
    import {mixin as clickaway} from 'vue-clickaway';
    import axios from 'axios';
    export default {

        mixins: [clickaway],

        props: {
            firstName: {
                required: true,
                type: String,
            },
            lastName: {
                required: true,
                type: String,
            },
            username: {
                required: true,
                type: String,
            },
            viewGlobalReports: {
                type: Boolean,
                default: false,
            }
        },


        data() {
            return {
                profileOpen: false,
            }
        },

        computed: {
            buttonColor() {
                if(this.profileOpen) {
                    return "nav-button-open";
                }

                return "nav-button";
            },
            dashboardEnabled() {
                let feature =  this.$store.getters['system/getFeatureForName']('dashboard');

                if(!feature) {
                    return false;
                }

                return Boolean(feature.enabled) && this.$can('access_energy_dashboard');
            },
          canAccessAdmin() {
              return window.MAT.permissions.filter(permission => {
                return permission.name === 'access_administration'
              }).length > 0;
          }
        },

        methods: {
            closeProfile() {
                this.profileOpen = false;
            },
            async logout() {
                await axios.post('/api/auth/logout');
                window.location.href = '/login';
            }
        }

    }


</script>
