export const format_decimals = (value, decimals) => {

    if (decimals === undefined || decimals === null || decimals ===false || isNaN(decimals) || decimals < 0) {
        decimals = 2;
    }

    if (value === undefined || value === null || value === false || isNaN(value)) {
        return "TBD";
    } else {
        var formatted = parseFloat(value).toFixed(decimals);
        formatted = formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formatted;
    }
}
