<script>
import {byString} from "laravel-vue-forms";
import {faChevronDown, faChevronRight, faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import taskDisplayRow from "etap/mixins/task_display_row";

export default {

    mixins: [taskDisplayRow],

    props: {
        record: {
            required: true,
            type: Object
        },
        args: {
            required: true,
            type: Object
        },
        selected: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            icons: {
                faChevronRight,
                faChevronDown,
                faEdit,
                faTimes
            },
            initializing: false,
            showForm: false,
        }
    },

    async created() {
        this.initializing = true;
        if(this.taskableModelType && !this.taskableEntityType) {
            await this.$store.dispatch('entity_types/getEntityTypeForName', this.taskableModelType);
        }

        if(this.taskableEntityType) {
            this.initializing = false;
        }
    },

    watch: {
        taskableEntityType() {
            if(this.taskableEntityType) {
                this.initializing = false;
            }
        }
    },

    computed: {
    },

    methods: {
        recordIsSelected(selected) {
            if(selected) {
                this.$emit('record-selected');
            } else {
                this.$emit('record-unselected');
            }
        },
        taskUpdated() {
            window.notify.success('Task updated successfully');
            setTimeout(() => {
                this.$emit('refreshRecords')
            }, 2500)
        }
    }
}
</script>
<template>
    <div>
        <div class="border w-full rounded my-1">
            <div
                class="p-2 shadow-md"
                v-if="!initializing"
                :class="{'bg-lightest-gray': selected}"
                @click="showForm = !showForm"
            >
                <div
                    class="cursor-pointer ml-2 md:ml-8 flex justify-between items-center w-full"
                >
                    <div class="flex justify-around"
                    >
                        <div class="mx-2">
                            <font-awesome-icon
                                v-if="showForm"
                                :icon="icons.faChevronDown"
                                size="lg"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else
                                :icon="icons.faChevronRight"
                                size="lg"
                            ></font-awesome-icon>
                        </div>
                    </div>
                    <form-checkbox
                        :value="false"
                        label="Select"
                        :field-name="'task-' + record.id + '-select-widget'"
                        @input="recordIsSelected"
                    />
                    <div class="w-3/4">
                        <div class="flex justify-between items-center">
                            <div class="mx-2 w-1/5" v-for="displayValue in taskDisplayValues">
                                <label>{{ displayValue.label }}</label>
                                <div>{{ displayValue.value }}&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="mx-2 border-0" @click.stop="showForm = true">
                            <font-awesome-icon :icon="icons.faEdit" size="lg"></font-awesome-icon>
                        </button>
                    </div>
                </div>
                <div v-if="taskableDisplayComponent" class="mx-16">
                    <component
                        :is="taskableDisplayComponent"
                        :taskable="record.taskable"
                    ></component>
                </div>
            </div>
            <div
                v-else
                style="min-height: 10vh; display: flex; align-items: center; justify-content: center;"
            >
                <div class="text-center flex justify-center">
                    <div class="loading-bar w-1/2 text-white p-12 rounded">
                        <span class="ml-4">Loading ...</span>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="showForm" class="shadow-inner w-full p-4">
                <div>
                    <tabs>
                        <tab name="Task Details">
                            <vue-form
                                :form-config="taskFormConfiguration"
                                :form-data="record"
                                :form-submit-url="formSubmitUrl"
                                :actions="formActions"
                                :use-json-api="true"
                                @updated="taskUpdated"
                            />
                        </tab>
                        <tab name="Notes & Comments">
                            <notes-sub-view
                                :base-url="'/api/tasks/' + record.id"
                                entity-type="task"
                                :notable="record"
                                title="Comment"
                            />
                        </tab>
                        <tab name="Audits">
                            <audits-sub-view
                                :auditable="record"
                                :auditable-entity-type="taskEntityType"
                            />
                        </tab>
                    </tabs>
                </div>
            </div>
        </transition>
    </div>
</template>
