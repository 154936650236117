<template>
    <div class="flex m-2 p-2 border projectlistdiv justify-between items-center">
        <div class="col-md-10">
            <home-project-grid-row-display-row1 :record="record" />
            <div class="flex justify-between">
                <home-project-grid-row-display-row2 :record="record" />
                <div class="text-right w-third statusboxes" v-can="'qc_projects'">
                    <a
                        class="projectstatusbackground mr-2"
                        :class="getProjectPhaseStatusClass(projectPhase)"
                        v-for="(projectPhase, index) in visibleProjectPhasesForProgramTrack"
                        v-if="projectPhase.hidden !== 1"
                        @click.stop="goToPhase(projectPhase)"
                        :key="index"
                    >
                        <span v-if="projectPhase.icon" class="fa" :class="'fa-' + projectPhase.icon" :title="projectPhase.title"></span>
                        <span v-else :title="projectPhase.title">{{ index + 1}}</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex actions">
            <button class="action-item cursor-pointer" v-can="'assess_projects'" v-if="canEdit" @click.stop="goToAction('edit')">
                <span class="fa fa-3x fa-pencil" title="Edit"></span>
            </button>
            <button class="action-item" v-can="'assess_projects'" v-else>
                <span class="fa fa-3x fa-check"></span>
            </button>
            <button class="action-item cursor-pointer" v-can="'view_assigned_projects_for_inspection'" v-if="hasPreInspection"  @click.stop="goToAction('pre_inspection')">
                <span class="fa fa-3x fa-search" title="Pre-Inspection"></span>
            </button>
            <button class="action-item cursor-pointer" v-can="'view_assigned_projects_for_inspection'" v-if="hasPostInspection" @click.stop="goToAction('post_inspection')">
                <span class="fa fa-3x fa-search" title="Post-Inspection"></span>
            </button>
            <button class="action-item cursor-pointer" v-can="'view_universal_page'" @click.stop="goToAction('universal')">
                <span class="fa fa-3x fa-globe" title="Universal Page"></span>
            </button>
            <button class="action-item" :class="{'cursor-pointer': projectApprovalReady}" v-can="'qc_projects'" v-if="hasProjectApproval" :disabled="!projectApprovalReady" @click.stop="goToAction('project_approval')">
                <span class="fa fa-3x fa-check-circle" title="Project Approval"></span>
            </button>
            <button class="action-item" v-can="'qc_projects'" :class="{'cursor-pointer': finalProjectApprovalReady}" v-if="hasFinalProjectApproval" :disabled="!finalProjectApprovalReady" @click.stop="goToAction('final_project_approval')">
                <span class="fa fa-3x fa-usd" title="Final Project Approval"></span>
            </button>
        </div>
    </div>
</template>
<script>
import { IsRow } from 'v-grid';

export default {

    mixins: [IsRow],


    computed: {
        projectPhases() {
            return this.$store.state.projectPhases
        },
        programTrack() {
            return this.$store.state.programTracks.find(programTrack => {
                return programTrack.id === this.record.program_track_id;
            });
        },
        programYear() {
            return this.$store.state.programYears.find(programYear => {
                return programYear.id === this.record.program_year_id;
            });
        },
        visibleProjectPhasesForProgramTrack() {
            return this.programTrack.project_phases.filter(projectPhase => {
                return projectPhase.config.hidden === 0 && projectPhase.config.program_year_id === this.record.program_year_id;
            });
        },
        currentPhaseOrder() {
            return this.programTrack.project_phases.find(phase => {
                return phase.id === this.record.phase_id;
            }).config.order;
        },
        canEdit() { // whether or not to show the edit button or 'checked' button
            let projectApprovalPhase = this.projectPhases.find(phase => phase.name === 'project_approval');
            let finalProjectApprovalPhase = this.projectPhases.find(phase => phase.name === 'final_project_approval');
            let preInspectionPhase = this.projectPhases.find(phase => phase.name === 'pre_inspection');
            let postInspectionPhase = this.projectPhases.find(phase => phase.name === 'post_inspection');
            if(
                this.record.phase_id === projectApprovalPhase.id ||
                this.record.phase_id === finalProjectApprovalPhase.id ||
                this.record.phase_id === preInspectionPhase?.id ||
                this.record.phase_id === postInspectionPhase?.id
            ) {
                return false;
            }

            return true;
        },
        hasPreInspection() {

            if(!this.projectPhases || !this.record.inspections) {
                return false;
            }

            let preInspectionPhase = this.projectPhases.find(phase => phase.name === 'pre_inspection');
            return this.record.inspections.find(inspection => inspection.phase_id === preInspectionPhase.id);
        },
        hasPostInspection() {

            if(!this.projectPhases || !this.record.inspections) {
                return false;
            }

            let postInspectionPhase = this.projectPhases.find(phase => phase.name === 'post_inspection');
            return this.record.inspections.find(inspection => inspection.phase_id === postInspectionPhase.id);
        },
        hasProjectApproval() {
            return this.visibleProjectPhasesForProgramTrack.find(phase => phase.name === 'project_approval');
        },
        hasFinalProjectApproval() {
            return this.visibleProjectPhasesForProgramTrack.find(phase => phase.name === 'final_project_approval');
        },
        projectApprovalReady() {
            let projectApprovalPhase = this.projectPhases.find(phase => phase.name === 'project_approval');
            return this.phaseIsAvailable(projectApprovalPhase);
        },
        finalProjectApprovalReady() {
            let finalProjectApprovalPhase = this.projectPhases.find(phase => phase.name === 'final_project_approval');
            return this.phaseIsAvailable(finalProjectApprovalPhase);
        }
    },


    methods: {
        getLastItemFor(items, action) {
            var actionItems;
            if(action) {
                actionItems = items.filter(log => {
                    return log.action === action;
                });
            }else {
                actionItems = items
            }

            if(actionItems.length === 0) {
                return null;
            }
            return actionItems.slice(-1)[0];
        },
        phaseIsAvailable(projectPhase) {

            let projectPhaseOrder = this.programTrack.project_phases.find(phase => {
                return phase.id === projectPhase.id;
            }).config.order;

            if(projectPhaseOrder > this.currentPhaseOrder) {
                return false
            }

            return true;
        },
        getProjectPhaseStatusClass(projectPhase) {
            if(projectPhase.config.order > this.currentPhaseOrder) {
                return 'projectstatusinactive'
            } else if(projectPhase.config.order < this.currentPhaseOrder) {
                return 'projectstatuscomplete';
            }

            return '';
        },
        goToPhase(projectPhase) {

            let projectApprovalPhase = this.projectPhases.find(phase => phase.name === 'project_approval');
            let finalProjectApprovalPhase = this.projectPhases.find(phase => phase.name === 'final_project_approval');


            if(!this.$can('qc_projects') && (projectPhase.id === projectApprovalPhase.id || projectPhase.id === finalProjectApprovalPhase.id)) {
                return;
            }

            window.location = '/project/' + this.record.id + '/' + projectPhase.name;
        },
        goToAction(action) {
            switch(action) {
                case 'universal':
                    window.location = '/project/' + this.record.id + '/universal';
                    return;
                case 'edit':
                    let currentPhase = this.projectPhases.find(phase => phase.id === this.record.phase_id);
                    window.location = '/project/' + this.record.id + '/' + currentPhase.name;
                    return;
                case 'pre_inspection':
                    window.location = '/project/' + this.record.id + '/pre_inspection';
                    return;
                    break;
                case 'post_inspection':
                    window.location = '/project/' + this.record.id + '/post_inspection';
                    break;
                case 'project_approval':
                    window.location = '/project/' + this.record.id + '/project_approval';
                    return;
                    break;
                case 'final_project_approval':
                    window.location = '/project/' + this.record.id + '/final_project_approval';
                    return;
                    break;
                default:
                    return false;
            }
        }
    }

}
</script>
