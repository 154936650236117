import axios from 'axios';
import {byString} from "laravel-vue-forms";

export default {
    namespaced: true,

    state: {
        cachedRemainingBudget: null,
    },

    mutations: {
        updateCachedRemainingBudget(state, budget) {
            state.cachedRemainingBudget = budget;
        }
    },

    actions: {
        getCachedRemainingBudget(context) {
            return new Promise((resolve, reject) => {
                axios.get('/api/incentive_widget/remaining_budget').then(response => {

                    const remainingBudget = byString(
                        response.data,
                        'meta.remaining_budget'
                    );

                    if(!remainingBudget) {
                        reject('No remaining budget found');
                        return;
                    }

                    context.commit('updateCachedRemainingBudget', remainingBudget);
                    resolve(remainingBudget);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });
            });
        }
    }
}
