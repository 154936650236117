<template>
    <div>
        <div class="flex">
            <div class="mr-2" :class="{ 'w-3/4': can_view_search_filters, 'w-full': !can_view_search_filters }">
                <div class="flex justify-end vue-form">
                        <form-select
                            :find-in-form="false"
                            label="Select Program Track"
                            :showLabel="false"
                            class="project-status-id-select"
                            field-name="program_track"
                            :options="programTrackOptions"
                            option-label-field="title"
                            option-value-field="id"
                            :show-multiselect-labels="false"
                            v-model="selectedProgramTrackId"
                        ></form-select>
                        <form-select
                            :find-in-form="false"
                            label="Select Project Status"
                            :showLabel="false"
                            class="project-status-id-select"
                            field-name="project_status"
                            :options="statusOptions"
                            option-label-field="name"
                            option-value-field="id"
                            :show-multiselect-labels="false"
                            v-model="selectedStatusId"
                        ></form-select>
                </div>
                <v-grid
                    grid-type="div-row-pinned"
                    record-type="home-project-grid-row"
                    record-url="/api/projects"
                    :record-url-params="searchParams"
                    :update-after-bulk-action="false"
                    :bulk-actions="bulkActions"
                    :sorts="sorts"
                    :filters="filters"
                    :grid-args="gridArgs"
                    @updateFilter="updateCurrentFilter"
                    @actionRun="notifyActionRun"
                    @runBulkAction="runBulkAction"
                    :use-json-api="true"
                    :clear-selected-records.sync="clearSelectedRecords"
                    :queue-refresh.sync="queueRefresh"
                ></v-grid>
                <component v-if="actionComponent" :is="actionComponent" :args="actionComponentArguments" @closeActionComponent="closeActionComponent" @bulkActionCompleted="bulkActionCompleted"></component>
            </div>
            <div style="width: 22%; padding-bottom: 100px;" v-if="can_view_search_filters">
                <search
                    @search="updateSearch"
                ></search>
                <hr class="m-4" />
                <project-stats
                    v-if="can_assess_projects"
                    @search-by="updateSearch"
                    v-for="programTrack in programTracks"
                    :key="programTrack.id"
                    :program-track="programTrack"
                ></project-stats>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import {byString} from "etap/utilities/utils";
    export default {

        data() {
            const vm = this;

            let defaultProjectStatus = this.getDefaultProjectStatusFromFeature();
            let filters = [];
            if(this.$can('project_view_search_filters')) {
                filters.push({
                    name: 'inspection',
                    params: { inspection: 1 },
                    title: 'Inspection'
                })
            }

            return {
                searchParams: {
                    project_status_id: defaultProjectStatus?.id,
                    program_track_id: '',
                },
                includes: [
                    'inspections',
                    'user',
                    'user.companies',
                    'logs',
                    'company',
                    'addresses',
                    'status',
                ],
                selectedStatusId: defaultProjectStatus?.id,
                selectedProgramTrackId: '',

                filters,
                currentFilter: null,
                pinnedRecords: [],
                actionComponent: null,
                actionComponentArguments: {},
                clearSelectedRecords: false,
                queueRefresh: false
            }
        },

        mounted() {
            axios.get(
                '/api/users/' + window.MAT.user.id + '/preferences/pinned_projects'
            ).then( response => {
                this.pinnedRecords = response.data;
            }).catch( error => {
                window.notify.apiError(error);
            });
        },

        created() {
            this.updateSearch(Object.assign(this.searchParams, this.parseQuery(window.location.search)));
        },

        watch: {
            selectedStatusId(newStatusId) {
                this.updateSearch(JSON.parse(JSON.stringify(this.searchParams)));
            },
            selectedProgramTrackId(newTrackId) {
                var searchParams = JSON.parse(JSON.stringify(this.searchParams));
                searchParams.program_track_id = newTrackId;
                this.updateSearch(searchParams)
            }
        },


        computed: {
            searchingFeature() {
                return window.MAT.features.find(f => f.name === 'searching') || null
            },
            can_assess_projects() {
                return this.$can('assess_projects');
            },
            can_view_search_filters() {
                return this.$can('project_view_search_filters');
            },
            bulkActions() {
                return this.getActions();
            },
            sorts() {

                if(!this.searchingFeature) {
                    return  [
                        {
                            name: 'customer_company_name',
                            by: 'customer_company_name.raw',
                            title: 'Project Name',
                        },
                        {
                            name: 'project_phase_order',
                            by: 'project_phase_order',
                            title: 'Project Phase'
                        }
                    ]
                }
                return this.searchingFeature.options.sorts;
            },
            statusOptions() {

                var options = JSON.parse(JSON.stringify(window.MAT.lookups['project.status']));
                options.push({
                    name: 'All',
                    id: '',
                });
                return options;
            },
            programTracks() {
                return this.$store.getters.uniqueValidTracksForUser;
            },
            programTrackOptions() {
                var options = JSON.parse(JSON.stringify(this.programTracks));
                options.push({
                    title: 'All',
                    id: '',
                });
                return options;
            },
            gridArgs() {


                let searchingFeature = window.MAT.features.find(f => f.name === 'searching');
                let noRecordsText = 'No records found';
                if(
                    searchingFeature &&
                    searchingFeature.enabled &&
                    searchingFeature.options &&
                    searchingFeature.options.no_records_text
                ) {
                    noRecordsText = searchingFeature.options.no_records_text;
                }

                return {
                    pinned: this.pinnedRecords,
                    noRecordsText: noRecordsText,
                }
            }
        },

        methods: {
            getDefaultProjectStatusFromFeature() {
                let searchingFeature = window.MAT.features.find(f => f.name === 'searching');
                const projectStatuses = window.MAT.lookups['project.status'];

                let activeProjectStatus = projectStatuses.find(status => {
                    return status.name === 'Active';
                }) ?? null;

                if(
                    !searchingFeature ||
                    !searchingFeature.enabled ||
                    !searchingFeature.options ||
                    !searchingFeature.options.default_project_status
                ) {
                    return activeProjectStatus;
                }

                let defaultProjectStatus = activeProjectStatus;
                const defaultProjectStatusConfig = byString(searchingFeature.options, 'default_project_status')
                if(typeof defaultProjectStatusConfig === 'string') {

                    defaultProjectStatus = projectStatuses.find(status => {
                        return status.name === defaultProjectStatusConfig;
                    }) ?? null;
                }else if(Array.isArray(defaultProjectStatusConfig)) {

                    const defaultStatusItem = defaultProjectStatusConfig.find(config => {
                        if(
                            !config.ability ||
                            !config.status ||
                            !this.$can(config.ability)
                        ) {
                            return false;
                        }

                        return true;
                    });

                    if(defaultStatusItem) {
                        defaultProjectStatus = projectStatuses.find(status => {
                            return status.name === defaultStatusItem.status;
                        }) ?? null;
                    }
                }

                return defaultProjectStatus;
            },
            updateSearch(searchParams) {

                this.$set(searchParams, 'project_status_id', this.selectedStatusId);

                this.updateQueryFromParams(searchParams);
                searchParams.include = this.includes;
                this.searchParams = searchParams;

            },
            parseQuery(queryString) {
                var query = {};

                if(queryString[0] !== '?') { // if there is no query string return empty object
                    return {};
                }

                var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
                for (var i = 0; i < pairs.length; i++) {
                    var pair = pairs[i].split('=');

                    var decodedFieldName = decodeURIComponent(pair[0]);
                    var decodedValue = decodeURIComponent(pair[1] || '')

                    // if we have more than one get param for the dedcoded field name, we need to pas it as an array
                    if(decodedFieldName in query) {
                        if(!Array.isArray(query[decodedFieldName])) {
                            let curValue = query[decodedFieldName];
                            query[decodedFieldName] = [];
                            query[decodedFieldName].push(curValue);
                        }

                        query[decodedFieldName].push(decodedValue);
                    }else {
                        query[decodedFieldName] = decodedValue;
                    }
                }
                return query;
            },
            updateQueryFromParams(params) {

                var queryString = '';
                for(var fieldName in params) {
                    if(params.hasOwnProperty(fieldName)) {
                        if(queryString.length > 0) {
                            queryString += '&';
                        }

                        if(Array.isArray(params[fieldName])) {
                            var arrQString = '';
                            params[fieldName].forEach(fValue => {
                                if(arrQString.length > 0) {
                                    arrQString += '&';
                                }

                                arrQString += encodeURIComponent(fieldName) + '[]=' + encodeURIComponent(fValue);
                            });

                            queryString += arrQString;
                        }else {
                            queryString += encodeURIComponent(fieldName) + '=' + encodeURIComponent(params[fieldName]);
                        }
                    }
                }

                let newUrl = window.location.pathname + '?' + queryString;
                if(window.location.hash) {
                    newUrl += '#' + window.location.hash;
                }

                history.pushState(null, '', newUrl);
            },
            updateCurrentFilter(filter) {
                this.currentFilter = filter;
            },
            notifyActionRun(action) {

                if(action.successNotification) {
                    window.notify.message('Successfully ' + action.title, 'success');
                }

                this.clearSelectedRecords = true
                if(action.refreshRecords) {
                    window.setTimeout(() => {
                        this.queueRefresh = true
                        this.closeActionComponent()
                    }, 2500);
                }
            },
            getActions() {
                return this.getActionsFromEntityType().map((action) => {
                    return action;
                })
            },
            async runBulkAction({action, selectedRecords}) {
                switch (action.type) {
                    case 'update_project_status':
                        this.actionComponent = action.options.component;
                        this.actionComponentArguments = {
                            action,
                            selectedRecords
                        }
                        break;
                    case 'pin_project':
                        await this.pinProject(selectedRecords)
                        this.notifyActionRun(action)
                        break;
                    case 'unpin_project':
                        await this.unpinProject(selectedRecords)
                        this.notifyActionRun(action)
                        break;
                    default:
                        return null;
                }
            },
            closeActionComponent() {
                this.actionComponent = null;
                this.actionComponentArguments = {}
            },
            bulkActionCompleted() {
                this.notifyActionRun(this.actionComponentArguments.action)
            },
            getActionsFromEntityType() {
                return [
                    {
                        name: 'update_project_status',
                        title: 'Update Project Status',
                        type: 'update_project_status',
                        refreshRecords: true,
                        options: {
                            lookupModel: 'project.status',
                            component: 'update-project-status-action-type-handler'
                        },
                    },
                    {
                        name: 'pin_project',
                        title: 'Pin Project',
                        type: 'pin_project',
                        options: {
                            component: null,
                        }
                    },
                    {
                        name: 'unpin_project',
                        title: 'Un-pin Project',
                        type: 'unpin_project',
                        options: {
                            component: null,
                        }
                    },
                ];
            },
            pinProject(selectedRecords) {
                const vm = this
                return new Promise((resolve, reject) => {
                    axios.patch('/api/users/' + window.MAT.user.id + '/preferences/pinned_projects', {
                        projects: selectedRecords.map(project => {
                            return project.id;
                        }),
                        pinned: 1,
                    }).then(response => {
                        if(!response.data || !Array.isArray(response.data.pinned)) {
                            return;
                        }

                        if(!vm.pinnedRecords || !Array.isArray(vm.pinnedRecords)) {
                            vm.pinnedRecords = [];
                        }

                        response.data.pinned.forEach(project => {
                            if(!vm.pinnedRecords.find(p => p.id === project.id)) {
                                vm.pinnedRecords.push(project);
                            }
                        });

                        window.notify.message('Successfully pinned projects', 'success');
                        resolve(response);
                    }).catch(error => {
                        window.notify.apiError(error);
                        reject(error);
                    });
                });
            },
            unpinProject(selectedRecords) {
                const vm = this
                return new Promise((resolve, reject) => {
                    let selectedProjectIds = selectedRecords.map(project => {
                        return project.id;
                    });

                    axios.patch('/api/users/' + window.MAT.user.id + '/preferences/pinned_projects', {
                        projects: selectedProjectIds,
                        pinned: 0,
                    }).then(response => {
                        if(!vm.pinnedRecords.length || !Array.isArray(vm.pinnedRecords)) {
                            return;
                        }

                        vm.pinnedRecords = vm.pinnedRecords.filter(project => {
                            return !selectedProjectIds.includes(project.id);
                        });
                        window.notify.message('Successfully unpinned projects', 'success');
                        resolve(response);
                    }).catch(error => {
                        window.notify.apiError(error);
                        reject(error);
                    });
                });
            }
        }

    }
</script>
