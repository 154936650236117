
export default {

    equipmentLibraryConfiguration: {},
    mainView: '',
    mainViewArgs: {},

    // for measure picker library, in order for components to react / "know" that we're in the context
    // of a specific measure
    equipmentLibraryMeasure: null,
    selectedEquipmentForEquipmentLibraryMeasure: null,

    equipment: [],
    equipmentFormDirty: false,


    measureEquipmentEligibilityItemsLoaded: [],
    measureEquipmentEligibilityItems: [],


    measureTabs: [],

    selectedQplRecord: null,

    // equipment upload
    importJobs: [],



}
