<script>

    export default {
        props: {
            permissions: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                bypassProjectSubmissionValue: false,
            }
        },
        computed: {
            hasPermission() {
                // loop through the abilities to check if user doesn't have permission for an ability
                // if the user doesn't have permission then return false, else return true
                if(this.permissions.length === 0) {
                    return false;
                }

                return this.permissions.every((permission) => {
                    return this.$can(permission);
                });
            }
        },
        methods: {
            updateBypassProjectSubmissionValidation(updatedValue) {
                this.bypassProjectSubmissionValue = updatedValue;
                this.$emit('bypass-validation-option-updated', updatedValue);
            }
        }
    }
</script>
<template>
    <div>
        <form-checkbox
            v-if="hasPermission"
            field-name="bypass_project_submission"
            label="Bypass Project Submission Validation"
            :value="bypassProjectSubmissionValue"
            @input="updateBypassProjectSubmissionValidation"
        ></form-checkbox>
    </div>
</template>
