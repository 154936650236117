<template>
    <div>

        <bill-account-form
            v-if="selectedBillAccount.account_number || selectedBillAccount.isNew"
            :bill-account="selectedBillAccount"
            :disabled="formDisabled"
            :form-configuration="billAccountFormConfiguration"
            @close="closeLookup"
            @savedBillAccount="addBillAccountToList"
        ></bill-account-form>

    </div>
</template>
<script>
    import { debounce } from 'lodash';
    import str_utils from 'etap/mixins/str_utils';
    export default {

        mixins: [str_utils],

        props: {
            billAccountFormConfiguration: {
                required: true,
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                selectedBillAccount: {
                    address: {}
                },
                billAccounts: [],
                isLoading: false,
                currentQuery: '',
            }
        },

        inject: {
            projectId: {
                default() {
                    return null;
                }
            }
        },

        created() {
            this.showNewAccountCreate();
        },

        computed: {
            canCreate() {
                return this.$can('create', this.getModelStringForType('project_bill_account'));
            },
            canUpdate() {
                return this.$can('update', this.getModelStringForType('project_bill_account'));
            },
            formDisabled() {

                if(this.disabled) {
                    return true;
                }

                if(this.selectedBillAccount.isNew && this.canCreate) {
                    return false;
                }else if(this.selectedBillAccount.account_number && this.canUpdate) {
                    return false;
                }

                return true;
            }
        },


        methods: {
            limitText(count) {
                return `and ${count} other bill accounts`;
            },
            findBillAccounts(query) {

                this.isLoading = true;
                this.currentQuery = query;
                this.search();

            },
            search: debounce(function() {

                axios.get('/api/bill_accounts', {
                    params: {
                        project_id: this.projectId,
                        account_number: this.currentQuery
                    }
                }).then(response => {

                    if(response.data) {
                        this.billAccounts = response.data;
                        this.isLoading = false;
                    } else {
                        window.notify.message('Invalid response from server in trying to get bill accounts', 'error');
                    }

                }).catch( error => {
                    window.notify.apiError(error);
                });

            }, 600),
            showBillAccountForm(billAccount) {

                delete billAccount.id;
                billAccount.project_id = this.projectId
                this.selectedBillAccount = billAccount;

            },
            showNewAccountCreate() {

                this.selectedBillAccount = {
                    isNew: true,
                    project_id: this.projectId,
                    account_number: '',
                    account_type: '',
                    energy_usage: '',
                    energy_cost: '',
                    peak_usage: '',
                    address: {
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                        additional_zip: '',
                    }
                }
            },
            reset() {
                this.selectedBillAccount = {
                    address: {}
                }
            },
            addBillAccountToList(billAccount) {
                this.$emit('addBillAccountToList', billAccount);
            },
            closeLookup() {
                this.$emit('close');
            }
        }

    }
</script>
