<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="ui-widget-content modal-container" v-on-clickaway="closeModal">
                    <div class="dialog-header ui-widget-header">
                        Add New Scenario
                        <button @click="closeModal"
                                type="button"
                                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close modal-close-button"
                        >
                            <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
                            <span class="ui-button-text">Close</span>
                        </button>
                    </div>
                    <div class="add-scenario-modal-body">

                        <div id="scenariodialogwrapper">
                            <div v-if="error != ''">
                                {{ error }}
                            </div>
                            <form-select
                                field-name="copy-scenario-selector"
                                v-model="copyScenarioID"
                                :show-multiselect-labels="false"
                                label=""
                                :options="scenarios"
                                option-label-field="scenario_nickname"
                                option-value-field="id"
                            />
                            <br/>
                            <br/>
                            <input type="submit" value="Copy From Scenario" class="inputbutton1" @click="copyScenario"/>
                            <br/>
                            <br />
                            -- OR --
                            <br/>
                            <br/>
                            <input type="submit" id="createscenario" value="Create New Scenario" class="inputbutton1" @click="createScenario"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </transition>
</template>
<script>
    import { mixin as clickaway } from 'vue-clickaway';
    import { mapState } from 'vuex';
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    export default {

        mixins: [ clickaway, MeasurePickerUtils ],

        data() {
            return {
                error: '',
                copyScenarioID: '',
            }
        },

        computed: {
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            scenarios() {
                return this.$store.getters['mp_scenarios/getScenarios'](this.mpid);
            },
        },

        methods: {
            closeModal() {
                this.error = '';
                this.$emit('close');
            },
            copyScenario() {
                this.error = '';
                if(this.copyScenarioID == '') {
                    this.error = 'Please select a scenario to copy';
                    return;
                }
                this.$store.dispatch('mp_scenarios/createScenario', {copyScenarioId: this.copyScenarioID, mpid: this.mpid});
                this.closeModal()
            },
            createScenario() {
                this.error = '';
                this.$store.dispatch('mp_scenarios/createScenario', {copyScenarioId: null, mpid: this.mpid});
                this.closeModal();
            }
        }

    }
</script>
<style>


    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 400px;
        margin: 0px auto;
        padding: 10px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
    }

    .dialog-header {
        text-align: center;
        padding: 5px;
    }

    .center-text {
        text-align:center;
    }

    .add-scenario-modal-body {
        color: #362b36;
        font-size: 1.1em;
        padding: 10px;
    }

    .action-buttons {
        padding: 5px 15px 5px 10px;
    }


</style>
