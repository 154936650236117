import axios from "axios";


export default {

    data() {
        return {
            canRewind: false,
            showRewindConfirmModel: false,
        }
    },

    created() {
        this.validateCanRewind();

        if(window.Bus) {

            window.Bus.$on('checklistUpdated', () => {
                this.validateCanRewind();
            });

        }
    },

    methods: {
        validateCanRewind() {

            if(this.disabled) {
                return;
            }

            axios.get('/api/projects/' + this.project.id + '/phase/' + this.phase.id + '/can_rewind').then( response => {

                if(response.data.can_rewind) {
                    this.canRewind = true;
                } else {
                    this.canRewind = false;
                }

            }).catch( error => {
                window.notify.apiError(error);
            });

        },
        runRewind(projectId, phaseId) {

            if(this.passThru) {
                this.$emit('rewindRan', this.canRewind)
                return;
            }

            if(this.canRewind) {

                axios.post('/api/projects/' + projectId + '/phase/' + phaseId + '/rewind').then( response => {

                    if(response.data.success === true) {
                        window.notify.message('Successfully re-wound project phase', 'success');
                        if(!this.$can('qc_projects')) {
                            setTimeout(function() {
                                window.location.href = '/home';
                            }, 2500)
                        } else {
                            setTimeout(function() {
                                window.location.reload();
                            }, 2500)
                        }
                    }else {
                        window.notify.message(response.data.message, 'error');
                    }

                    this.showRewindConfirmModel = false;

                }).catch(error => {
                    window.notify.apiError(error);
                    this.showRewindConfirmModel = false;
                });
            }
        }
    }
}
