import axios from'axios';
import Parser from 'etap/admin/classes/jsonapi_parser'
import Lookup from "../../admin/classes/models/lookup";

export default {
    namespaced: true,

    state: {
        lookups: [],
    },

    mutations: {
        upsertLookup(state, lookup) {
            let existingLookup = state.lookups.find(l => l.id === lookup.id);
            if(existingLookup) {
                Object.assign(existingLookup, lookup);
            } else {
                state.lookups.push(lookup);
            }
        }
    },

    actions: {
        async getLookupForModel({ commit }, params) {

            let requestParams = {};
            if(params.include) {
                requestParams.include = params.include;
            }

            if(params.date_flag_filter) {
                requestParams.filterByDate = true;
            }

            if(params.date_flag_date) {
                requestParams.configurationDate = params.date_flag_date;
            }

            const response = await axios.get('/api/lookups', {
                headers: {
                    Accept: 'application/vnd.api+json'
                },
                params: {
                    model: params.model,
                    ...requestParams
                }
            });


            const lookups = Parser.parseJSONAPIResponse(response.data);
            lookups.getModels().forEach(lookup => {
                commit('upsertLookup', lookup);
            })

            return lookups;
        }
    },

    getters: {
        getLookupsForModel(state) {
            return (model) => {
                return state.lookups.filter(lookup => {
                    return lookup.model === model;
                });
            }
        }
    }
}
