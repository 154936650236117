import axios from 'axios';

export const getProjectCompleteForPhase = (context, {projectId, phaseId}) => {

    return new Promise((resolve, reject) => {
        axios.get('/api/projects/' + projectId + '/phase/' + phaseId + '/is_complete').then(response => {
            context.commit('updateNeedsConfigurationUpdate', {
                projectId,
                needsConfigurationUpdate: response.data.needsConfigurationUpdate
            });
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        })
    });
}


export const reCalculateProjectCalculations = (context, {projectId, selectedPhaseIds, selectedCalcTypes, selectedScenarioOption}) => {

    return new Promise((resolve, reject) => {
        axios.patch('/api/projects/' + projectId + '/re-calculate', {
            selectedPhaseIds,
            selectedCalculationTypes: selectedCalcTypes,
            selectedScenario: selectedScenarioOption
        }).then(response => {
            resolve(response);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });
    });
}

export const updateProjectConfiguration = (context, {projectId}) => {

    return new Promise((resolve, reject) => {
        axios.patch('/api/projects/' + projectId + '/update_project_configuration')
        .then(response => {
            resolve(response.data);
        }).catch(error => {
            window.notify.apiError(error);
            reject(error);
        });
    });
}


export const checkForConfigurationUpdate = async (context) => {

    context.commit('projectConfigurationUpdateIsRunning', true);

    try {
        const jobStatusResponse = await context.dispatch('jobs/getJobForStatusable', {
            projectId: context.state.configurationStatusProject.id,
            statusableType: 'Nexant\\AuditTool\\Jobs\\RecalculateProjectAtRiskJob'
        }, {root: true});

        if (
            jobStatusResponse.data &&
            Array.isArray(jobStatusResponse.data) &&
            jobStatusResponse.data.length > 0
        ) {

            let newStatus = jobStatusResponse.data[0];
            await context.dispatch('notifyUserOfNewStatus', newStatus);
            context.commit('updateProjectConfigurationUpdateStatus', newStatus);

            await context.dispatch('setupOrCancelStatusUpdater');
            if (
                ['queued', 'executing'].includes(
                    context.state.currentProjectConfigurationUpdateStatus.status
                )
            ) {
                return; // return early so we don't cancel "projectConfigurationUpdateIsRunning"
            }
        }

    } catch (err) {
        window.notify.message('Unable to get job status', 'error');
        console.log(err);
    }

    context.commit('projectConfigurationUpdateIsRunning', false);
}

export const notifyUserOfNewStatus = (context, newStatus) => {

    /**
     * Logic
     * - if we have a current status and the new status's status is the same, return
     * - If the newStatus is queued / executing then we don't need to notify
     * - If we had a status that was running, and the new one is now "finished"
     *      We'll let the user know that it was finished, but we don't want to show
     *      this message every time we load the page
     * - If any new status is failed then we notify in a message below button
     */

    if (
        context.state.currentProjectConfigurationUpdateStatus &&
        (
            context.state.currentProjectConfigurationUpdateStatus.status === newStatus.status ||
            ['queued', 'executing'].includes(newStatus.status)
        )
    ) {
        return;
    } else if (
        context.state.currentProjectConfigurationUpdateStatus &&
        ['queued', 'executing'].includes(context.state.currentProjectConfigurationUpdateStatus.status) &&
        newStatus.status === 'finished'
    ) {
        window.notify.message('Successfully updated configuration.', 'success');
        if (window.Bus) {
            window.Bus.$emit('updateFiles', 'signed-program-form');
            window.Bus.$emit('updateFiles', 'utility-bill');
            window.Bus.$emit('updateFiles', 'pre-application-supporting-documents');
            window.Bus.$emit('updateChecklist');
            window.Bus.$emit('updateAdobeSignStatus');
        }

        Object.keys(context.rootState.mp_layout.measure_pickers).map(function(mpid) {
            context.dispatch('mp_project_measures/getProjectMeasuresForCurrentScenarioAndPhase', mpid, { root: true });
            context.dispatch('mp_layout/getMeasureSummary', mpid, { root: true });
        })
    } else if (newStatus.status === 'failed') {
        window.notify.message('We were recently unable to update configuration for project.  Please try again.', 'error');
    }
}

export const setupOrCancelStatusUpdater = (context) => {
    if (!context.state.currentProjectConfigurationUpdateStatus) {
        return;
    }
    if (
        !context.state.projectConfigurationStatusUpdater &&
        ['queued', 'executing'].includes(
            context.state.currentProjectConfigurationUpdateStatus.status
        )
    ) {
        context.commit(
            'setProjectConfigurationStatusUpdater',
            setInterval(async () => {
                await context.dispatch('checkForConfigurationUpdate')
            }, 5000)
        )
    } else if (
        ['finished', 'failed', 'canceled'].includes(
            context.state.currentProjectConfigurationUpdateStatus.status
        )
    ) {
        if (context.state.projectConfigurationStatusUpdater) {
            clearInterval(context.state.projectConfigurationStatusUpdater);
        }
        context.commit('projectConfigurationUpdateIsRunning', false);
    }
}
