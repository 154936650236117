<script>
import axios from 'axios';
import moment from 'moment';
import Parser from "etap/admin/classes/jsonapi_parser";

export default {
    props: {
        record: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            gettingLogs: false,
            showLogs: false,
            importLogs: []
        }
    },

    computed: {
        statusName() {
            if(!this.record.operation_status) {
                return null;
            }

            return this.record.operation_status.charAt(0).toUpperCase() + this.record.operation_status.slice(1);
        },
        lastUpdated() {
            if(!this.record.updated_at) {
                return null;
            }

            return moment(this.record.updated_at).format('MM/DD/YYYY h:mm A');
        }
    },

    methods: {
        async getImportLogs() {

            try {
                this.gettingLogs = true;
                const response = await axios.get(
                    `/api/equipment/bulk_import/logs/${this.record.shared_operation_id}`
                );

                const entityBulkOperation = Parser.parseJSONAPIResponse(response.data);

                let logContent = [];
                if (entityBulkOperation && entityBulkOperation.output_signed_url) {
                    // attempt to download the log file, but if the response gets too big or the browser can't parse it, at least
                    // give the user the option to download the file
                    try {
                        const logContentResponse = await axios.get(
                            entityBulkOperation.output_signed_url,
                            undefined,
                            { Accept: "application/json" }
                        );

                        logContent = logContentResponse?.data ?? [];
                    } catch (err) {
                        window.notify.error(
                            "Unable to download contents of log to display.  Please use the download button to download the log file.",
                            "Unable to get log contents"
                        );
                    }
                }

                this.importLogs = logContent;
                this.showLogs = true;
            }catch(err) {
                window.notify.apiError(err);
                console.log(err);
            }
            this.gettingLogs = false;
        }
    }
}
</script>
<template>
    <div class="m-3 shadow p-4">
        <div class="flex w-full items-center">
            <div class="w-1/2 text-center">
                <div class="mb-2 flex justify-between">
                    <div class="text-bold">Operation Id:</div>
                    <div class="w-1/2 break-words">
                        {{ record.shared_operation_id }}
                    </div>
                </div>
                <div class="flex justify-between">
                    <div class="text-bold">Import generated by:</div>
                    <div v-if="record.user" class="w-1/2 break-words">
                        {{ record.user.user_select_title }}
                    </div>
                </div>
            </div>
            <div class="w-1/4">
                <div class="mb-2 flex justify-between">
                    <div class="text-bold">Status:</div>
                    <div class="flex items-center justify-center">
                        <span class="mr-2">{{ statusName }}</span>
                        <div v-if="record.operation_status === 'finished'" class="led-box">
                            <div class="led-green"></div>
                        </div>
                        <div v-else-if="record.operation_status === 'failed'" class="led-box">
                            <div class="led-red"></div>
                        </div>
                        <div v-else-if="record.operation_status === 'queued'" class="led-box">
                            <div class="led-yellow"></div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between">
                    <div class="text-bold">Import Last Updated:</div>
                    <div>{{ lastUpdated }}</div>
                </div>
            </div>
            <div class="w-1/5 ml-4">
                <button class="inputbutton1" @click="getImportLogs" :disabled="gettingLogs">
                    <span class="fa fa-spinner fa-spin" v-if="gettingLogs" />
                    <span class="fa fa-eye" v-else />
                    &nbsp;View Import Logs
                </button>
            </div>
        </div>
        <div v-if="showLogs" class="mx-2 border-t p-2">
            <h4>Bulk Operation Logs:</h4>
            <v-grid
                :data="importLogs"
                grid-type="divRow"
                record-type="equipment-bulk-import-operation-log-row"
                :pagination="{ per_page: 20 }"
            />
        </div>
    </div>
</template>
