<template>
    <div class="selected_scenario_picker">
        <h4 v-if="showLabel">Project's Selected Scenario</h4>
        <div class="flex items-center">
            <multi-select
                :options="scenarios"
                track-by="id"
                label="scenario_nickname"
                v-model="selectedScenario"
                :disabled="!editing"
                select-label=""
                deselect-label=""
                class="form-control"
            ></multi-select>
            <button class="inputbutton1" @click="editing = true" v-if="editing === false">Edit</button>
            <div>
                <button class="inputbutton1" @click="editing = false" v-if="editing === true">Cancel</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import MultiSelect from 'vue-multiselect';

    export default {

        props: {
            showLabel: {
                type: Boolean,
                default: false
            }
        },

        components: {
            MultiSelect
        },

        data() {
            return {
                editing: false,
            }
        },

        computed: {
            mpid() {
                return this.$store.state.mp_layout.assessmentMPID;
            },
            scenarios() {

                if(!this.mpid) {
                    return [];
                }

                return this.$store.getters['mp_scenarios/getScenarios'](this.mpid);
            },
            selectedScenario: {
                get() {
                    var selectedScenario = null;
                    this.scenarios.forEach(scenario => {
                        if(scenario.selected) {
                            selectedScenario = scenario;
                        }
                    });

                    return selectedScenario;
                },
                set(scenario) {

                    this.$store.dispatch('mp_scenarios/updateScenarioSelection', {mpid: this.mpid, scenario, selection: 1} );

                    this.editing = false;
                }
            }
        }

    }
</script>
