<template>
    <div class="px-4 home">
        <v-grid
            v-if="hasEligibilityConfig"
            record-url="/api/eligibility/search"
            :record-url-params="searchParams"
            :headers="displayHeaders"
            :use-json-api="true"
            record-type="eligible-bill-account-row"
            :grid-args="{noRecordsText: noRecordsText, eligibilityConfig: eligibilityConfig, programTrackId: searchParams.program_track_id, programYearId: searchParams.program_year_id, project}"
        ></v-grid>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        searchParams: {
            required: true,
            type: Object
        },
        eligibilityConfig: {
            required: false,
            type: Object,
            default: function() {
                return {};
            }
        },
        project: {
            type: Object,
        }
    },

    data() {
        return {
            isDisabled: false,
        }
    },
    computed: {
        hasEligibilityConfig() {
            return Object.keys(this.eligibilityConfig) !== 0
        },
        displayHeaders() {
            let displayHeaders = [];

            if(!this.hasEligibilityConfig || !this.eligibilityConfig?.display_fields) {
                return displayHeaders;
            }

            displayHeaders = this.eligibilityConfig.display_fields.map(displayField => {
                return displayField.label;
            });

            // add action display
            displayHeaders.push('');

            return displayHeaders;
        },
        noRecordsText() {
            if(this.eligibilityConfig?.no_results_text) {
                return this.eligibilityConfig.no_results_text;
            }

            return 'No eligible accounts found';
        }
    },
    methods: {
        handleProjectCreation(billAccount) {

            if(!this.queryParamProgramTrackId) {
                window.notify.message('You must specify a program track to create this project.', 'error');
                return;
            }
            this.isDisabled = true;

            var request = {
                account_number: billAccount.account_number,
                program_track_id: this.queryParamProgramTrackId,
                program_year_id: this.queryParamProgramYearId,
            };

            axios.post('/api/projects/create', request).then((res) => {
                this.isDisabled = false;
                if(res.data.active_url) {
                   window.location.replace(res.data.active_url);
                }
            }).catch((error) => {
                window.notify.apiError(error);
            });
        }
    }
}
</script>
<style>
#eligibility-list-table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

#eligibility-list-table th {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid;
}

#eligibility-list-table td {
    padding: 0.75rem;
    vertical-align: top;
}
</style>
