import axios from "axios";

export default {
    namespaced: true,

    state: {
        tableauBaseUri: null,
        tableauTicket: null,
        currentIframeKeys: [],
        currentViewUrls: [],
        currentYear: null,
        validYears: [],
    },


    actions: {
        getTableauTicket(context) {
            return new Promise((resolve, reject) => {
                axios.get('/api/tableau_ticket').then(response => {
                    context.commit('setTableauBaseUri', response.data.base_uri)
                    context.commit('setTableauTicket', response.data.ticket);
                    resolve(response.data);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                })
            });
        },
        parseValidYears(context, featureName) {
            return new Promise((resolve, reject) => {
                axios.get('/api/program_years').then(response => {
                    if (!featureName) {
                        reject('Invalid Feature Name Specified.');
                    }

                    let feature = context.rootGetters['system/getFeatureForName'](featureName);
                    let years = [];

                    response.data.forEach(year => {
                        feature.options.viewsByYear.forEach(options_year => {
                            if (year.program_year == options_year.program_year) {
                                years.push(year);
                            }
                        });
                    });
                    context.commit('setValidYears', years);
                    resolve(years);
                }).catch(error => {
                    window.notify.apiError(error);
                    reject(error);
                });
            });
        },
        updateCurrentYear(context, currentYear) {
            return new Promise((resolve, reject) => {
                if (!currentYear) {
                    reject('Invalid Current Year Specified.');
                }

                context.commit('setCurrentYear', currentYear);
                resolve(currentYear);
            });
        },
        createIframeKeysForFeature(context, featureName) {
            return new Promise((resolve, reject) => {
                if (!featureName) {
                    reject('Invalid Feature Name Specified.');
                }

                let feature = context.rootGetters['system/getFeatureForName'](featureName);
                let year = feature.options.viewsByYear.find(year => year.program_year == context.state.currentYear);
                var iframeCount = 0;

                if (!feature || !year || !year.tabs || !Array.isArray(year.tabs)) {
                    return iframeCount;
                }

                year.tabs.forEach(tab => {
                    if (tab.viewUrls.length > iframeCount) {
                        iframeCount = tab.viewUrls.length;
                    }
                });

                var iframeKeys = [];
                for (var x = 0; x < iframeCount; x++) {
                    iframeKeys.push('key-' + x);
                }
                context.commit('setIframeKeys', iframeKeys);
                resolve(iframeKeys);
            });
        },
        updateCurrentViewUrlsForFeatureAndTab(context, { featureName, tabName }) {
            return new Promise((resolve, reject) => {
                let feature = context.rootGetters['system/getFeatureForName'](featureName);
                let year = feature.options.viewsByYear.find(year => year.program_year == context.state.currentYear);


                if (!feature || !year || !year.tabs || !Array.isArray(year.tabs)) {
                    reject('Invalid feature');
                    return;
                }

                let tab = year.tabs.find(t => t.tabName === tabName);
                if (!tab || !tab.viewUrls || !Array.isArray(tab.viewUrls)) {
                    reject('Invalid feature and tab: ' + tabName);
                }
                var urls = [];

                tab.viewUrls.forEach(vUrl => {
                    var url;
                    if (String(context.state.tableauTicket) !== "-1") {
                        url = context.state.tableauBaseUri +
                            '/trusted/' +
                            encodeURIComponent(context.state.tableauTicket) +
                            vUrl;
                    } else {
                        url = context.state.tableauBaseUri + vUrl;
                    }

                    urls.push(url);
                });

                context.commit('setCurrentViewUrls', urls);
                resolve(urls);
            });
        }
    },


    mutations: {
        setTableauTicket(state, ticket) {
            state.tableauTicket = ticket;
        },
        setTableauBaseUri(state, uri) {
            state.tableauBaseUri = uri;
        },
        setIframeKeys(state, keys) {
            state.currentIframeKeys = keys;
        },
        setCurrentViewUrls(state, urls) {
            state.currentViewUrls = urls;
        },
        setCurrentYear(state, currentYear) {
            state.currentYear = currentYear;
        },
        setValidYears(state, years) {
            state.validYears = years;
        }
    },


    getters: {
        validYears(state) {
            return state.validYears;
        },
        currentYear(state) {
            return state.currentYear;
        },
        tabsForDashboard(state, getters, rootState, rootGetters) {
            return (dashboardFeatureName, currentYear) => {
                let feature = rootGetters['system/getFeatureForName'](dashboardFeatureName);
                let tabs = [];
                for (let i = 0; i <= feature.options.viewsByYear.length - 1; i++) {
                    if (feature.options.viewsByYear[i].program_year == currentYear) {
                        tabs = feature.options.viewsByYear[i].tabs;
                    }
                }

                if (!feature || !tabs || !Array.isArray(tabs)) {
                    return [];
                }

                return tabs.map(tab => {
                    return tab.tabName;
                });
            }

        }
    }



}
