<template>
    <div :id="fieldName + '-project-summary'" class="form-group">
        <h2>Project Summary</h2>
        <div class="flex flex-col text-center">
            <div class="flex justify-between">
                <div class="w-1/5 flex-1 border-l border-r border-b p-2">&nbsp;</div>
                <div
                    v-for="phase in phases"
                    :key="phase.id"
                    class="border-l border-r border-b p-2 w-1/5 flex-1"
                >
                    {{ phase.title }}
                </div>
            </div>
            <div
                v-for="summary in phaseSummary"
                :key="summary.name"
                class="flex justify-between text-right border-b"
            >
                <div class="w-1/5 p-2 flex-1 border-l border-r">{{ summary.label }}</div>
                <div
                    v-for="phase in phases"
                    :key="phase.id"
                    class="border-l border-r p-2 w-1/5 flex-1"
                >
                    {{ summary[phase.name] }}
                </div>
            </div>
        </div>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>
    </div>
</template>
<script>
    import {FormFieldMixin} from "laravel-vue-forms";
    export default {
        mixins: [FormFieldMixin],

        name: "form-project-summary",

        computed: {
            phases() {
                if (!this.value) {
                    return [];
                }

                let phases = [];
                this.value.forEach(val => {
                    phases.push(val.phase);
                });

                return phases;
            },
            phaseSummary() {
                if (
                    !this.fieldConfig.field_extra.summaryFields ||
                    !Array.isArray(this.fieldConfig.field_extra.summaryFields) ||
                    this.phases.length === 0
                ) {
                    return [];
                }

                return this.fieldConfig.field_extra.summaryFields.map(
                    (fieldInfo => {
                        let mappedData = {
                            name: fieldInfo.name,
                            label: fieldInfo.label,
                        };

                        this.value.forEach(
                            (savingTotalSummary) => {
                                let roundingValue = null;
                                if ('rounding' in fieldInfo) {
                                    roundingValue = fieldInfo.rounding;
                                }

                                let value = savingTotalSummary.savingsTotals[fieldInfo.name];

                                if (value === null) {
                                    mappedData[savingTotalSummary.phase.name] = "";
                                    return;
                                }

                                if (roundingValue !== null && value) {
                                    value = Number(value)
                                        .toFixed(roundingValue)
                                        .toString()
                                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    value = value?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                                }

                                if (fieldInfo.is_money) {
                                    value = "$ " + value;
                                }

                                mappedData[savingTotalSummary.phase.name] = value;
                            }
                        );

                        return mappedData;
                    })
                );
            }
        }
    };
</script>
