<template>
    <div :id="mpid" class="measure_picker">
        <zero-information-warning v-if="show_zero_warning"></zero-information-warning>
        <div class="flex justify-between" v-if="show_scenarios">
            <div class="flex content-center items-center">
                <div class="mt-1 mb-2">Project Scenarios</div>

                <scenario-list></scenario-list>

                <div v-if="select_and_create_scenarios">
                    <input type="button" class="button" value="+ Add Scenario" @click="toggleModal"/>
                </div>
                <div class="bg-color1 mx-2 mt-1 mb-2" v-if="select_and_create_scenarios">
                    <div class="scenarioinfowhite"
                         title="Project Scenarios can be used to create and modify multiple versions of a project, allowing the user to swap out one or more measures and preview the results. Project Scenarios can be created based upon an existing scenario within the project or started from scratch."></div>
                </div>
            </div>
            <div>
                <form-float-label-text
                    v-model="currentScenarioText"
                    field-name="current-scenario-name"
                    label="Scenario Name"
                    :disabled="!select_and_create_scenarios ? 1 : 0"
                />
            </div>
        </div>
        <div id="measure_top_reference"></div>
        <div id="measure_picker_wrapper" class="flex bg-white" :class="{'full-picker': fullPicker }">
            <measure-list v-if="add_measures"></measure-list>
            <project-measure-container></project-measure-container>
        </div>
        <add-scenario-modal
            v-if="showScenarioModal"
            @close="toggleModal"
        ></add-scenario-modal>
    </div>

</template>
<script>
    import debounce from 'lodash/debounce';
    import MeasurePickerArea from 'etap/mixins/measure_picker/measure_picker_area';
    import {mapState, mapMutations, mapGetters } from 'vuex';
    import { guid } from 'etap/utilities/utils';

    export default {

        props: {
            measureCalculationConfiguration: {
                required: true,
            },
            project: {
                required: true,
            },
            currentScenario: {
                required: true,
                type: Object
            },
            phase: {
                required: true,
                type: Object
            },
            forAssessment: {
                type: Boolean,
                default: false,
            },
            isQc: {
                type: Boolean,
                default: false
            },
            isLocked: {
                type: Boolean,
                default: false
            },
            selectable: {
                type: Boolean,
                default: false,
            },
            disableRoomHoursInputs: {
                type: Boolean,
                default: false
            },
            disableScenarioPicker: {
                type: Boolean,
                default: false
            },
            editOnly: {
                type: Boolean,
                default: false
            },
            showAudits: {
                type: Boolean,
                default: false,
            },
            equipmentLibraryConfiguration: {
                required: true,
                type: Object,
            }
        },

        mixins: [
            MeasurePickerArea
        ],

        data() {
            return {
                newNickname: '',
                showScenarioModal: false
            }
        },

        created() {

            this.defaultMeasurePicker(this.mpid);
            this.defaultMPScenarios(this.mpid);
            this.defaultMPProjectMeasures(this.mpid);
            this.updateProject({mpid: this.mpid, project: this.project});
            this.setPhase({mpid: this.mpid, phase: this.phase});
            this.setMeasureCalculationConfiguration({mpid: this.mpid, configuration: this.measureCalculationConfiguration});
            this.updateCurrentScenario({
                mpid: this.mpid,
                scenario: this.currentScenario
            });

            if(this.forAssessment) {
                this.setMPIDForAssessment(this.mpid);
            }


            this.setFeature({
                mpid: this.mpid,
                feature: 'update_room_hours',
                value: !this.disableRoomHoursInputs
            });

            this.setFeature({
                mpid: this.mpid,
                feature: 'select_measures',
                value: this.selectable
            });

            this.setFeature({
                mpid: this.mpid,
                feature: 'show_scenarios',
                value: !this.disableScenarioPicker
            });

            this.setFeature({
                mpid: this.mpid,
                feature: 'show_pm_audits',
                value: this.showAudits
            })

            if(this.isQc) {
                this.setFeature({
                    mpid: this.mpid,
                    feature: 'only_selected_measures',
                    value: true
                });

                this.setFeature({
                    mpid: this.mpid,
                    feature: 'show_scenarios',
                    value: false
                });

                this.setFeature({
                    mpid: this.mpid,
                    feature: 'show_zero_warning',
                    value: false
                });

                this.setFeature({
                    mpid: this.mpid,
                    feature: 'show_review_filters',
                    value: true
                });

                this.toggleProjectMeasureDetails(this.mpid);
            }

            if(this.isLocked || this.editOnly) {
                this.setFeature({
                    mpid: this.mpid,
                    feature: 'add_measures',
                    value: false
                });
                this.setFeature({
                    mpid: this.mpid,
                    feature: 'delete_measures',
                    value: false
                });
                this.setFeature({
                    mpid: this.mpid,
                    feature: 'select_and_create_scenarios',
                    value: false
                });
            }

            if(this.isLocked) {

                this.setFeature({
                    mpid: this.mpid,
                    feature: 'edit_measures',
                    value: false
                });

                this.setFeature({
                    mpid: this.mpid,
                    feature: 'update_room_hours',
                    value: false
                });

            }

            this.setEquipmentLibraryConfiguration(
                this.equipmentLibraryConfiguration
            );
        },

        computed: {
            vuexCurrentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            fullPicker() {
                return this.$store.getters['mp_layout/getFullPicker'](this.mpid);
            },
            currentScenarioText: {
                get() {

                    if(!this.vuexCurrentScenario || !this.vuexCurrentScenario.scenario_nickname) {
                        return '';
                    }

                    return this.vuexCurrentScenario.scenario_nickname;
                },
                set(nickname) {
                    this.newNickname = nickname;
                    this.updateScenarioNickname();
                }
            },
            show_scenarios() {
                return this.$store.getters['mp_layout/measurePickerCan']('show_scenarios', this.mpid)
            },
            show_zero_warning() {
                return this.$store.getters['mp_layout/measurePickerCan']('show_zero_warning', this.mpid)
            },
            add_measures() {
                return this.$store.getters['mp_layout/measurePickerCan']('add_measures', this.mpid)
            },
            select_and_create_scenarios() {
                return this.$store.getters['mp_layout/measurePickerCan']('select_and_create_scenarios', this.mpid)
            }
        },

        methods: {
            ...mapMutations('mp_layout', [
                'defaultMeasurePicker',
                'setMeasureCalculationConfiguration',
                'updateProject',
                'setPhase',
                'setFeature',
                'toggleProjectMeasureDetails',
                'setMPIDForAssessment',
            ]),
            ...mapMutations('mp_scenarios', [
                'defaultMPScenarios',
                'updateCurrentScenario'
            ]),
            ...mapMutations('mp_project_measures', [
                'defaultMPProjectMeasures'
            ]),
            ...mapMutations('equipment_library', [
                'setEquipmentLibraryConfiguration'
            ]),
            updateScenarioNickname: debounce(function() {
                this.$store.dispatch('mp_scenarios/updateScenarioNickname', {newNickname: this.newNickname, mpid: this.mpid});
            }, 1200),
            toggleModal() {
                this.showScenarioModal = !this.showScenarioModal;
            }
        },

        provide() {

            let provide = {};

            Object.defineProperty(provide, 'mpid', {
                enumerable: true,
                get: () => this.mpid
            });

            return provide;
        },
    }
</script>
<style>
    .MeasurePicker {
        text-align: left;
    }
</style>
