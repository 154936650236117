import axios from 'axios';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        qc_progress: {},
        loadingProgress: false,
        project: {},
        phase: {}
    },
    actions: {
        getQCProgress(context) {

            if(context.state.loadingProgress) {
                return; // don't re-call loading the QC Progress
            }
            context.commit('setLoadingProgress', true);

            axios.get('/api/projects/' + context.state.project.id + '/qc_progress/' + context.state.phase.id).then(response => {

                if(response.data) {
                    context.commit('setQCProgress', response.data);
                }


                context.commit('setLoadingProgress', false);

            }).catch(error => {
                window.notify.apiError(error);
                context.commit('setLoadingProgress', false);
            });
        },
        setQCProgress(context, payload) {

            axios.post('/api/projects/' + context.state.project.id + '/qc_progress/' + context.state.phase.id,
                payload).then( response => {

                    context.commit('setQCProgressForType', payload);

                    if(window.Bus) {
                        window.Bus.$emit('updateChecklist');
                    }

            }).catch( error => {
                window.notify.apiError(error);
            });
        }
    },
    mutations: {
        setQCProgress(state, progress) {
            state.qc_progress = progress
        },
        setQCProgressForType(state, progressForType) {
            Vue.set(state.qc_progress, progressForType.type, progressForType.progress);
        },
        setLoadingProgress(state, loading) {
            state.loadingProgress = loading;
        },
        setProject(state, project) {
            state.project = project;
        },
        setPhase(state, phase) {
            state.phase = phase;
        }
    },
    getters: {
        qcProgressForType(state) {
            return (type) => {

                if(!Array.isArray(state.qc_progress)) {
                    return false;
                }

                var qcProgressItem = state.qc_progress.find(item => {
                    return item.name === type;
                });


                if(qcProgressItem) {
                    return qcProgressItem.checked;
                }

                return false;
            }
        }
    }
}
