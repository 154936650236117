import Model from 'etap/admin/classes/models/model';
import Audit from 'etap/admin/classes/models/audit';

class Measure extends Model {


    defaults() {
        return {
            name: '',
            list_name: '',
            shared_measure_id: null,
            measure_life: null,
            incentivized: true,
        }
    }

    casts() {
        return {
            incentivized: Boolean,
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {
            audits: Audit,
        }
    }

    routes() {
        return {

        }
    }

}

export default Measure;
