<script>
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {ucfirst} from "etap/utilities/utils";

export default {
    props: {
        record: {
            required: true,
            type: Object
        },
    },

    data() {
        return {
            icons: {
                faChevronDown,
                faChevronRight
            },
            editorOptions: {
                height: "200px",
                mode: "json",
                ace_options: [],
            },
            showingChanges: false,
        }
    },

    computed: {
        eventName() {
            return ucfirst(this.record.audit_event)
        },
        createdAt() {
            if(this.record.created_at) {
                return this.record.parseDate(this.record.created_at);
            }

            return null;
        },
        updatedAt() {
            if(this.record.updated_at) {
                return this.record.parseDate(this.record.updated_at);
            }

            return null;
        },
    }
}
</script>
<template>
    <div class="border-2 w-full rounded">
        <div
            class="flex items-center p-2 shadow-md"
            @click="showingChanges = !showingChanges"
        >
            <div
                class="cursor-pointer flex justify-between items-center w-full"
            >
                <div class="flex justify-around">
                    <div class="mx-2">
                        <font-awesome-icon
                            v-if="showingChanges"
                            :icon="icons.faChevronDown"
                            size="lg"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            :icon="icons.faChevronRight"
                            size="lg"
                        ></font-awesome-icon>
                    </div>
                </div>
                <div v-if="record.user_username">
                    {{ record.user_first_name }} {{ record.user_last_name }} ({{
                        record.user_username
                    }})
                </div>
                <div>{{ eventName }}&nbsp;</div>
                <div>
                    {{ createdAt }}
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div
                v-if="showingChanges"
                class="shadow-inner flex justify-between p-4"
            >
                <div class="w-1/4">
                    <div>
                        <label class="block font-bold">Audit User Agent</label>
                        <div>{{ record.audit_user_agent }}</div>
                    </div>

                    <div>
                        <label class="block font-bold">Audit Url</label>
                        <div>
                            {{ record.audit_url }}
                        </div>
                    </div>
                    <div>
                        <label class="block font-bold">Audit IP Address</label>
                        <div>
                            {{ record.audit_ip_address }}
                        </div>
                    </div>
                </div>

                <div class="w-2/3 pl-4">
                    <form-code
                        label="Modified"
                        field-name="audit-modified"
                        :value="record.modified"
                        :editor-options="editorOptions"
                    ></form-code>
                </div>
            </div>
        </transition>
    </div>
</template>
