<script>
import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default {

    mixins: [MeasurePickerUtils],

    props: {
        projectMeasure: {
            type: Object,
            required: true
        },
        openMeasureRow: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoSave: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            showPanel: this.openMeasureRow,
            showConfirmDeleteModal: false,
            deleting: false,
            icons: {
                eye: faEye
            }
        }
    },

    async created() {

        if (!this.measureEquipmentEligibilityItem) {
            await this.$store.dispatch('equipment_library/getMeasureEligibility', {
                measureId: this.projectMeasure.measure.id,
                projectId: this.mp_project?.id
            });
        }

    },

    watch: {
        openMeasureRow(newVal) {
            this.showPanel = newVal;
        }
    },

    computed: {
        formConfig() {
            return this.$store.getters['mp_layout/formConfigurationForProjectMeasure'](
                this.projectMeasure,
                this.mp_project
            );
        },
        measureEquipmentEligibilityItem() {
            if (!this.projectMeasure) {
                return null;
            }
            return this.$store.getters['equipment_library/getMeasureEquipmentEligibilityItemForMeasureId'](
                this.projectMeasure.measure.id
            )
        },
    },

    methods: {
        async runDeleteMeasure() {
            this.deleting = true;
            this.showConfirmDeleteModal = false;  // Close the modal after deletion
            await this.$store.dispatch('mp_project_measures/deleteProjectMeasure', {
                mpid: this.mpid,
                projectMeasureId: this.projectMeasure.id,
            });
            this.deleting = false;
            window.notify.success('Equipment removed from application.');
        },
        updateProjectMeasure(projectMeasure) {
            this.$store.dispatch('mp_project_measures/updateProjectMeasure', {projectMeasure, mpid: this.mpid});
            window.notify.success('Equipment details saved.');
        }
    }
}
</script>
<template>
    <div class="my-4">
        <expansion-panel v-model:show-panel="showPanel">
            <template #header-title>
                <div class="w-full text-left cursor-pointer">
                    <h4 class="text">{{ projectMeasure.measure.name }}</h4>
                </div>
            </template>
            <template #header-controls>
                <div class="flex justify-center w-1/5">
                    <div v-show="!deleting">
                        <fa-icon icon="edit" class="mx-2 cursor-pointer" v-if="!disabled"/>
                        <fa-icon :icon="icons.eye" class="mx-2 cursor-pointer" v-else />
                        <fa-icon icon="times" class="mx-2 cursor-pointer" @click.stop="showConfirmDeleteModal = true" v-if="!disabled"/>
                    </div>
                    <div v-show="deleting">
                        <fa-icon icon="spinner" class="mx-2" :spin="true"/>
                    </div>
                </div>
            </template>
            <template #default>
                <div>
                    <div
                        class="flex justify-center"
                        v-if="measureEquipmentEligibilityItem && measureEquipmentEligibilityItem.measure_eligibility_requirements_text"
                    >
                        <div
                            class="my-4 p-2 border-b border-t bg-lightest-gray rounded w-4/5"
                            v-html="measureEquipmentEligibilityItem.measure_eligibility_requirements_text"></div>
                    </div>
                    <vue-form
                        v-if="formConfig"
                        :form-config="formConfig"
                        :form-data="projectMeasure"
                        :actions="[{name: 'save', label: 'Save Details', action: 'submitForm'}]"
                        :disabled="deleting || disabled"
                        :form-submit-url="'/api/projects/' + projectMeasure.project_id + '/scenarios/' + projectMeasure.scenario_id  + '/rooms/' + projectMeasure.room_id +'/measures/' + projectMeasure.id"
                        @updated="updateProjectMeasure"
                        :auto-save="autoSave"
                        :auto-save-timeout="2500"
                    />
                    <p v-else>No form configuration found for this measure.</p>
                </div>
            </template>
        </expansion-panel>
        <modal
            modal-width="400px"
            :is-confirm="true"
            @confirmed="runDeleteMeasure"
            @close="showConfirmDeleteModal = false"
            v-if="showConfirmDeleteModal"
        >
            <template #header>
                <h4>Are you sure?</h4>
            </template>
            <template #body>
                <div>
                    Are you sure you want to remove this equipment from the application?  This action cannot be undone.
                </div>
            </template>
        </modal>
    </div>
</template>
